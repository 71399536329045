import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const ActiveVisualStat = status => {
  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          width: "100%",
          backgroundColor: "#fff",
          height: "190px",

          justifyContent: "center",
          alignItems: "center",
          borderRadius: "5px  5px 0 0",
        }}
      >
        <div style={{ maxWidth: "300px", margin: "2px", zIndex: "999" }}>
          <img
            className='blink_me5 shadow_me'
            style={{ borderRadius: "5px", height: "100%" }}
            src={"/incidentGraphics/Dispatch.png"}
            alt='...'
          />
        </div>
      </div>
    </Fragment>
  );
};

ActiveVisualStat.propTypes = {
  props: PropTypes,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveVisualStat);
