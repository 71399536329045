import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { clearUser } from "../../actions/users";

export const AdminMenu = ({
  menuShow,
  onClose,
  opcen_id: { opcen },
  opcenprofile: { profile },
  vaccinations: { vaxs },
  clearUser,
}) => {
  // Filter Array within an Array
  const opcenVaxData = vaxs?.filter((el) => {
    return (
      el.validation.length === 0 &&
      el.user.operation_center?.filter((op) => {
        return op.opcen === opcen?._id;
      })
    );
  });
  if (!menuShow) {
    return null;
  }
  return (
    <div
      style={{
        width: "20%",
        position: "fixed",
        top: "60px",
        left: "5px",
        bottom: "8px",
        background: " #fff",
        backgroundColor: "#215a75",
        zIndex: "999",
        borderRadius: "5px",
      }}
    >
      {/* Top Botton */}
      <Link
        to="/operation-center"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "5%",
          cursor: "pointer",
          borderRadius: "5px 5px 0px 0px",
        }}
        onClick={onClose}
      >
        <p
          style={{
            textAlign: "center",
            fontWeight: "200",
            width: "100%",
            color: "#fff",
            padding: "3px",
            borderRadius: "5px",
          }}
        >
          Home Menu
        </p>
      </Link>

      <div
        style={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "10px",
          }}
        >
          <div
            style={{ borderBottom: "5px solid #eee ", marginBottom: "10px" }}
          ></div>
          <div className="menuIcon">
            <p
              style={{
                marginLeft: "10px",
                textAlign: "center",
              }}
            >
              <i className="fa fa-map fa-lg" aria-hidden="true"></i>
            </p>
            <span
              style={{
                marginLeft: "17px",
                fontSize: "18px",
                fontWeight: "200",
                lineHeight: "10px",
              }}
            >
              Live Map
            </span>
          </div>

          <Link className="menuIcon" to="/notifMenu">
            <p
              style={{
                marginLeft: "10px",
                textAlign: "center",
                color: "#eee",
              }}
            >
              <i className="fa fa-bullhorn fa-lg" aria-hidden="true"></i>
            </p>
            <span
              style={{
                marginLeft: "17px",
                fontSize: "18px",
                fontWeight: "200",
                lineHeight: "10px",
                color: "#eee",
              }}
            >
              Notifications
            </span>
          </Link>
          <Link to="/opcen-dashboard" className="menuIcon">
            <p
              style={{
                marginLeft: "10px",
                textAlign: "center",
                color: "#eee",
              }}
            >
              <i className="fa fa-area-chart fa-lg" aria-hidden="true"></i>
            </p>
            <span
              style={{
                marginLeft: "17px",
                fontSize: "18px",
                fontWeight: "200",
                lineHeight: "10px",
                color: "#eee",
              }}
            >
              Incident
            </span>
          </Link>
          <Link to="/dispatch" className="menuIcon">
            <p
              style={{
                marginLeft: "10px",
                textAlign: "center",
                color: "#eee",
              }}
            >
              <i className="fa fa-headphones fa-lg" aria-hidden="true"></i>
            </p>
            <span
              style={{
                marginLeft: "17px",
                fontSize: "18px",
                fontWeight: "200",
                lineHeight: "10px",
                color: "#eee",
              }}
            >
              Dispatch
            </span>
          </Link>
          <div
            style={{
              borderBottom: "5px solid #eee",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          ></div>

          <Link className="menuIcon" to="/vax_list">
            <div>
              <div
                className={opcenVaxData?.length === 0 ? "displayNone" : null}
                style={{ position: "absolute", right: "20px" }}
              >
                <p
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "70px",
                    color: "#fff",
                    background: "#dc3545",
                    borderRadius: "100%",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  {opcenVaxData?.length}
                </p>
              </div>
              <p
                style={{
                  marginLeft: "10px",
                  textAlign: "center",
                  color: "#eee",
                }}
              >
                <i className="fa fa-id-badge fa-lg" aria-hidden="true"></i>
              </p>
            </div>

            <span
              style={{
                marginLeft: "17px",
                fontSize: "18px",
                fontWeight: "200",
                lineHeight: "10px",
                color: "#eee",
              }}
            >
              ID's/Passes
            </span>
          </Link>
          <Link className="menuIcon" to="/volunteers">
            <p
              style={{
                marginLeft: "10px",
                textAlign: "center",
                color: "#eee",
              }}
            >
              <i className="fa fa-male fa-lg" aria-hidden="true"></i>
            </p>
            <span
              style={{
                marginLeft: "17px",
                fontSize: "18px",
                fontWeight: "200",
                lineHeight: "10px",
                color: "#eee",
              }}
            >
              Volunteers
            </span>
          </Link>
          <Link className="menuIcon" to="/partnermenu">
            <p
              style={{
                marginLeft: "10px",
                textAlign: "center",
                color: "#eee",
              }}
            >
              <i className="fa fa-handshake-o fa-lg"></i>
            </p>
            <span
              style={{
                marginLeft: "17px",
                fontSize: "18px",
                fontWeight: "200",
                lineHeight: "10px",
                color: "#eee",
              }}
            >
              Partners
            </span>
          </Link>
          <Link className="menuIcon" to="/videoCall">
            <p
              style={{
                marginLeft: "10px",
                textAlign: "center",
              }}
            >
              <i className="fa fa-book fa-lg" aria-hidden="true"></i>
            </p>
            <span
              style={{
                marginLeft: "17px",
                fontSize: "18px",
                fontWeight: "200",
                lineHeight: "10px",
              }}
            >
              Inventory
            </span>{" "}
          </Link>
          <div className="menuIcon">
            <p
              style={{
                marginLeft: "10px",
                textAlign: "center",
              }}
            >
              <i className="fa fa-tachometer fa-lg " aria-hidden="true"></i>
            </p>
            <span
              style={{
                marginLeft: "17px",
                fontSize: "18px",
                fontWeight: "200",
                lineHeight: "10px",
              }}
            >
              Reports
            </span>
          </div>

          <div
            style={{
              borderBottom: "5px solid #eee ",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          ></div>

          <div>
            {!profile ? (
              <Link className="menuIcon" to="/addoperation-center/profile">
                <p
                  style={{
                    marginLeft: "10px",
                    textAlign: "center",
                    color: "#eee",
                  }}
                >
                  <i className="fa fa-building fa-lg" aria-hidden="true"></i>{" "}
                </p>

                <span
                  style={{
                    marginLeft: "17px",
                    fontSize: "18px",
                    fontWeight: "200",
                    lineHeight: "10px",
                    color: "#eee",
                  }}
                >
                  Profile
                </span>
              </Link>
            ) : (
              <Link
                className="menuIcon"
                to={`/edit-operation-center/profile/${profile._id}`}
              >
                <p
                  style={{
                    marginLeft: "10px",
                    textAlign: "center",
                    color: "#eee",
                  }}
                >
                  <i className="fa fa-building fa-lg" aria-hidden="true"></i>{" "}
                </p>

                <span
                  style={{
                    marginLeft: "17px",
                    fontSize: "18px",
                    fontWeight: "200",
                    lineHeight: "10px",
                    color: "#eee",
                  }}
                >
                  Edit Profile
                </span>
              </Link>
            )}
          </div>
          <Link
            className="menuIcon"
            to={`/operation-center/responder`}
            onClick={() => clearUser()}
          >
            <p
              style={{
                marginLeft: "10px",
                textAlign: "center",
                color: "#eee",
              }}
            >
              <i className="fa fa-users fa-lg" aria-hidden="true"></i>
            </p>
            <span
              style={{
                marginLeft: "17px",
                fontSize: "18px",
                fontWeight: "200",
                lineHeight: "10px",
                color: "#eee",
              }}
            >
              Responders
            </span>
          </Link>
          <div className="menuIcon">
            <p
              style={{
                marginLeft: "10px",
                textAlign: "center",
              }}
            >
              <i className="fa fa-cogs fa-lg" aria-hidden="true"></i>
            </p>
            <span
              style={{
                marginLeft: "17px",
                fontSize: "18px",
                fontWeight: "200",
                lineHeight: "10px",
              }}
            >
              Teams
            </span>
          </div>
          <div className="menuIcon">
            <p
              style={{
                marginLeft: "10px",
                textAlign: "center",
              }}
            >
              <i className="fa fa-ambulance fa-lg" aria-hidden="true"></i>
            </p>
            <span
              style={{
                marginLeft: "17px",
                fontSize: "18px",
                fontWeight: "200",
                lineHeight: "10px",
              }}
            >
              Vehicles
            </span>
          </div>
          <div className="menuIcon">
            <p
              style={{
                marginLeft: "10px",
                textAlign: "center",
              }}
            >
              <i className="fa fa-h-square fa-lg" aria-hidden="true"></i>
            </p>
            <span
              style={{
                marginLeft: "17px",
                fontSize: "18px",
                fontWeight: "200",
                lineHeight: "10px",
              }}
            >
              Facilities
            </span>
          </div>

          <div
            style={{
              borderBottom: "5px solid #eee",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          ></div>
        </div>
        {/* close Botton */}
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            left: "5px",
            right: "5px",
            background: "#fff",
            border: "1px solid #fff",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          <p
            style={{
              textAlign: "center",
              width: "100%",
              color: "#215a75",
              padding: "3px",
              borderRadius: "5px",
            }}
          >
            Close
          </p>
        </div>
      </div>
    </div>
  );
};

AdminMenu.propTypes = {
  opcen: PropTypes.object,
  opcenprofile: PropTypes.object.isRequired,
  vaccinations: PropTypes.object,
  clearUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  opcen_id: state.opcen,
  opcenprofile: state.opcen_profile,
  vaccinations: state.vaccination,
});

const mapDispatchToProps = { clearUser };

export default connect(mapStateToProps, mapDispatchToProps)(AdminMenu);
