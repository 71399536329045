import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const Alert = ({ alerts }) =>
  alerts !== null &&
  alerts.length > 0 &&
  //map is a function like a for loop bu returns something -> jsx
  //using map function requires a key in this case the id that was generated by uuid
  alerts.map(alert => (
    // setting the `alert alert-${alert.alertType}` e.g danger, success
    <div key={alert.id} className={`alert alert-${alert.alertType}`}>
      {alert.msg}
    </div>
  ));
Alert.propTypes = {
  //alerts initial state is an array and is passing an array.. so the proptype is array
  alerts: PropTypes.array.isRequired,
};

//get the alert state
//map redux state to props
const mapStateToProps = state => ({
  //state.alert come from the state reducer from index.js
  // calling this will get the current state from the reducer
  alerts: state.alert,
});
//Connect get two props the
// 1. state that  you want to map.. e.g get state from other alert
// 2. object you want to use from actions and its props
export default connect(mapStateToProps)(Alert);
