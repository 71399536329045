import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PostItem from "./PostItem";
import PostForm from "./PostForm";
import { getProfiles, getCurrentProfile } from "../../actions/profile";
import { getAnnoucement } from "../../actions/annoucement";
import AnnoucementClient from "../Announcement/AnnoucementClient";
import moment from "moment";
import AnnoucementCarousel from "../Announcement/AnnoucementCarousel";
import Chose_Opcen from "../setup-account/Chose_Opcen";
import { getUser } from "../../actions/users";
import { clearPost, getPosts } from "../../actions/post";
import Spinner from "../layout/Spinner";

const Posts = ({
  isOpcenAdmin,
  getProfiles,
  getCurrentProfile,
  getAnnoucement,
  announcement,
  displayed,
  getPosts,
  clearPost,
  userLogin: { user },
  post: { posts, loading },
}) => {
  // const storedValueAsNumber = Number(localStorage.getItem("skip"));
  // const [skip, setSkip] = useState(
  //   Number.isInteger(storedValueAsNumber) ? storedValueAsNumber : 0
  // );

  let today = moment().startOf("day").add(1, "day");

  const [skip, setSkip] = useState(0);
  const [show, setShow] = useState(false);
  const [showOpcen, setShowOpcen] = useState(false);

  useEffect(() => {
    localStorage.setItem("skip", String(skip));
  }, [skip]);
  // useEffect(() => {
  //   getProfiles();
  //   getCurrentProfile();
  //   getPosts();
  // }, [getPosts, getProfiles, getCurrentProfile]);

  useEffect(() => {
    getProfiles();
    getCurrentProfile();
  }, [getProfiles, getCurrentProfile]);

  // // Execute on load
  useEffect(() => {
    clearPost();
    getPosts();
  }, [getPosts]);

  // Populate when skip is triggered or scrolled buttom
  useEffect(() => {
    if (skip >= 5) {
      getPosts(skip);
    }
  }, [skip]);

  useEffect(() => {
    getAnnoucement();
  }, [getAnnoucement]);

  // useEffect(() => {
  //   getProfiles();
  // }, [skip]);

  useEffect(() => {
    if (user) {
      getUser(user._id);
    }
  }, [getUser]);

  useEffect(() => {
    if (user) {
      {
        if (!user?.operation_center.length > 0) {
          setShowOpcen(true);
        }
      }
    }
  }, [user]);

  useEffect(() => {
    if (announcement && !displayed) {
      setShow(true);
    }
  }, [announcement]);

  window.onscroll = () => {
    // Check is the page have scrolled at the buttom
    if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
      setSkip(posts.length);
    }
  };

  let activeAnnoucement = announcement?.filter(el => {
    return moment(el.dateTo) >= today && moment(el.dateFrom) <= today;
  });

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      {isOpcenAdmin && <PostForm />}

      {activeAnnoucement.length > 0 && (
        <Fragment>
          <AnnoucementClient
            show={show}
            onClose={() => setShow(false)}
            activeAnnoucement={activeAnnoucement}
            displayed={displayed}
          />
          <div
            style={{
              border: "1px solid #ddd ",
              marginBottom: "5px",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "center",
                backgroundColor: "#fff",
                borderRadius: "10px",
                overflowX: "scroll",
              }}
            >
              <AnnoucementCarousel activeAnnoucement={activeAnnoucement} />
            </div>
          </div>
          <Chose_Opcen
            onClose={() => setShowOpcen(false)}
            showOpcen={showOpcen}
          />
        </Fragment>
      )}

      <div>
        {posts?.map((post, i) => (
          <div key={i}>
            <PostItem key={post._id} post={post} />
          </div>
        ))}
      </div>
    </Fragment>
  );
};

Posts.propTypes = {
  getProfiles: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isOpcenAdmin: PropTypes.string.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  announcement: PropTypes.array.isRequired,
  getAnnoucement: PropTypes.func.isRequired,
  displayed: PropTypes.bool.isRequired,
  userLogin: PropTypes.object.isRequired,
  getUser: PropTypes.func.isRequired,
  getPosts: PropTypes.func.isRequired,
  clearPost: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  post: state.post,
  posts: state.post.posts,
  profile: state.profile,
  isOpcenAdmin: state.auth.isOpcenAdmin,
  announcement: state.announcement.announcements,
  displayed: state.announcement.displayed,
  userLogin: state.auth,
});

export default connect(mapStateToProps, {
  getProfiles,
  getCurrentProfile,
  getAnnoucement,
  getUser,
  clearPost,
  getPosts,
})(Posts);
