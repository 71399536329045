import React, { Fragment, useState } from "react";
import { Link, Redirect } from "react-router-dom";
//Connect the component to redux
//See Export down end file
import { connect } from "react-redux";
//Bring actions in the action folders
import { setAlert } from "../../actions/alert";
import { registerUser } from "../../actions/auth";
import PropTypes from "prop-types";

const Register = ({ setAlert, registerUser, isNewUser }) => {
  //formdata is the state, setFormData is the function use to update the state
  //Use state hooks
  const [formData, setFormData] = useState({
    name: "",
    lname: "",
    number: "",
    email: "",
    password: "",
    password2: "",
  });
  //destructure so you would do formData.name formData.number
  //Object Syntax use {}
  const { name, lname, number, email, password, password2 } = formData;

  const onChange = async c =>
    setFormData({ ...formData, [c.target.name]: c.target.value });

  const onSubmit = async c => {
    c.preventDefault();
    if (password !== password2) {
      setAlert("Passwords dont match", "danger");
    } else {
      //register is the action from reducers
      registerUser({ name, lname, number, email, password });
    }
  };
  if (isNewUser) {
    return <Redirect to='/otp_register' />;
  }
  return (
    <Fragment>
      <div
        className=' main-container align-vertically'
        style={{ marginTop: "40px" }}
      >
        <div className='sub-container'>
          {/* <h1 className='large text-primary'>Register</h1> */}
          <p className='lead'>
            <i className='fas fa-user'></i> Create Your Account
          </p>
          <form className='form' onSubmit={c => onSubmit(c)}>
            <div className='form-group'>
              <input
                type='text'
                placeholder='First Name'
                name='name'
                //value is set value on state for onChange
                value={name}
                onChange={c => onChange(c)}
                required
              />
            </div>
            <div className='form-group'>
              <input
                type='text'
                placeholder='Last Name'
                name='lname'
                //value is set value on state for onChange
                value={lname}
                onChange={c => onChange(c)}
                required
              />
            </div>
            <div className='form-group'>
              <input
                type='tel'
                placeholder='09XXXXXXXXX'
                name='number'
                value={number}
                onChange={c => onChange(c)}
                pattern='^(09)\d{9}$'
                title='Input a valid Philippine mobile number with this format 09XXXXXXXXX'
                required
              />
              <small className='small-txt-read'>
                This site uses your mobile number for authentication, sending
                alerts and other communication.
              </small>
            </div>
            <div className='form-group'>
              <input
                type='email'
                placeholder='Email Address'
                name='email'
                value={email}
                onChange={c => onChange(c)}
              />
              <small className='form-text'></small>
            </div>
            <div className='form-group'>
              <input
                type='password'
                placeholder='Password'
                name='password'
                value={password}
                onChange={c => onChange(c)}
                pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}'
                title='Must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters'
              />
            </div>
            <div className='form-group'>
              <input
                type='password'
                placeholder='Confirm Password'
                value={password2}
                onChange={c => onChange(c)}
                name='password2'
                minLength='8'
              />
            </div>
            <input
              type='submit'
              className='btn btn-primary'
              style={{ borderRadius: "5px" }}
              value='Register'
            />
          </form>
          <p className='my-1' style={{ fontSize: "13px" }}>
            Already have an account? <Link to='/login'>Sign In</Link>
          </p>
        </div>
      </div>
    </Fragment>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  isNewUser: PropTypes.bool,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isNewUser: state.auth.isNewUser,
  auth: state.auth,
});

//Connect get two props the
// 1. state that  you want to map.. e.g get state from other actions
// 2. object you want to use from actions and its props
export default connect(mapStateToProps, { setAlert, registerUser })(Register);
