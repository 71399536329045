import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import axios from "axios";
import Progressbar from "../layout/ProgressBar";
import { createNotification } from "../../actions/expoNotification";

const AddAnnouncementModal = ({
  onClose,
  show,
  setAlert,
  opcenLogo,
  createNotification,
}) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    articleImage: "",
    dateFrom: "",
    dateTo: "",
  });

  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [preview, setPreview] = useState();
  const inputRef = useRef();
  const [progress, setProgress] = useState();
  const [error, setError] = useState();

  const { title, description, dateFrom, dateTo } = formData;

  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [image]);

  const onFileChange = (c) => {
    setImage(c.target.files[0]);
    setImageName(c.target.files[0].name);
  };

  const onChange = (c) => {
    setFormData({ ...formData, [c.target.name]: c.target.value });
  };

  const payload = new FormData();
  payload.append("title", title);
  payload.append("description", description);
  payload.append("articleImage", image);
  payload.append("dateFrom", dateFrom);
  payload.append("dateTo", dateTo);
  payload.append("opcenLogo", opcenLogo);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const sendNotif = async () => {
    try {
      const res = await axios.post("/api/announcement", payload, {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      });
      console.log("Send Announcement....", res);
      setAlert("Annoucement Created", "success");
      await delay(3000);
      window.location.reload();
    } catch (err) {
      console.log("Error from backend", err);
      setError(true);
      setAlert(`Post error ${err.response.statusText}`, "danger");
    }
  };

  const onSubmit = (c) => {
    c.preventDefault();
    // addPost(payload);
    sendNotif();
    createNotification({ title: title, body: description });
    setFormData({ title: "", description: "", dateFrom: "", dateTo: "" });
    inputRef.current.value = "";
    setPreview("");
    // put error catcher
  };

  if (!show) {
    return null;
  }
  return (
    <Fragment>
      <div className="notifModal">
        <div className="notification">
          <div className="notif_title">
            <p> Create Announcement</p>
          </div>
          <div className="previewtitle">
            <p> Preview</p>
          </div>

          {/* Change Here */}

          <div className="titlebody">
            <form
              className="form my-1"
              encType="multipart/form-data"
              onSubmit={(c) => onSubmit(c)}
            >
              <div style={{ padding: "8px" }}>
                <div className="Title">
                  <input
                    name="title"
                    type="text"
                    placeholder="Title"
                    onChange={(c) => onChange(c)}
                    value={title}
                    required
                  />
                </div>

                <div className="Message">
                  <textarea
                    name="description"
                    cols="50"
                    rows="4"
                    placeholder="Message"
                    value={description}
                    onChange={(c) => onChange(c)}
                    required
                  />
                </div>

                <div className="form-group">
                  <p className="lead">From Date</p>
                  <input
                    type="date"
                    name="dateFrom"
                    value={dateFrom}
                    onChange={(c) => onChange(c)}
                  />
                </div>

                <div className="form-group">
                  <p className="lead">To Date</p>
                  <input
                    type="date"
                    name="dateTo"
                    value={dateTo}
                    onChange={(c) => onChange(c)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "60px",
                  }}
                >
                  <input
                    className="btn btn-dark my-1"
                    style={{ background: "#174052", width: "100%" }}
                    // id='upload-button'
                    ref={inputRef}
                    type="file"
                    onChange={(c) => onFileChange(c)}
                    accept="image/*,video/mp4,video/x-m4v,video/*"
                    multiple={false}
                    placeholder={imageName}
                    // style={{ display: "none" }}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  {" "}
                  <div className="Send">
                    {/* <label htmlFor='file'> */}
                    <input
                      type="submit"
                      className="btn btn-success my-1"
                      value="Send"
                    />
                  </div>
                  <button onClick={onClose} className="btn  btn-danger">
                    Cancel
                  </button>
                </div>
              </div>

              {/* add image button here */}
            </form>
          </div>
          {!error && progress && <Progressbar progress={progress} />}

          <div className="preview">
            {!preview ? (
              <div>
                <p style={{ color: "#215a75", textAlign: "center" }}>
                  Please choose a image file.
                </p>
                <p
                  className="small-txt-read"
                  style={{ color: "#215a75", textAlign: "center" }}
                >
                  For better result, use portrait image with resolution
                  720×1280.
                </p>
              </div>
            ) : (
              <div>
                <img
                  style={{
                    width: "100%",
                    maxHeight: "480px",
                    padding: "10px",
                    borderRadius: "15px",
                  }}
                  src={preview}
                  alt="image loaded"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

AddAnnouncementModal.propTypes = {
  setAlert: PropTypes.func.isRequired,
  opcenLogo: PropTypes.object.isRequired,
  createNotification: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  opcenLogo: state.opcen_profile.profile.logo,
});

const mapDispatchToProps = { setAlert, createNotification };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAnnouncementModal);
