import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import SearchVolunteer from "./SearchVolunteer";
import SelectedVolunteerContainer from "./SelectedVolunteerContainer";
import Spinner from "../layout/Spinner";
import { getUsers } from "../../actions/users";
import VolunteerSuspended from "./VolunteerSuspended";
import { getSuspendedUsers } from "../../actions/suspend_user";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;

const Volunteer = ({
  opcen,
  opcen_profile: { profile, loading },
  suspended: { suspendedusers },
  getUsers,
  users,
  getSuspendedUsers,
}) => {
  let history = useHistory();

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    getSuspendedUsers();
  }, [getSuspendedUsers]);

  return loading && profile === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <div
        style={{
          position: "fixed",
          top: "57px",
          left: "0px",
          right: "0px",
          bottom: "0px",

          background: "#fff",
        }}
      >
        {/* Main Display here */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 2px 0px, 2px",
            background: "#fff",
            height: "90vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              background: "#fff",
              height: "100vh",
              width: "920px",
            }}
          >
            <SearchVolunteer />
            <SelectedVolunteerContainer
              users={users}
              suspendedusers={suspendedusers}
            />
            <VolunteerSuspended suspendedusers={suspendedusers} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Volunteer.propTypes = {
  getUsers: PropTypes.func.isRequired,
  opcen_profile: PropTypes.object.isRequired,
  opcen: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  getSuspendedUsers: PropTypes.func.isRequired,
  suspended: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  opcen_profile: state.opcen_profile,
  opcen: state.opcen.opcen,
  users: state.users.users,
  suspended: state.suspended,
});

const mapDispatchToProps = { getUsers, getSuspendedUsers };

export default connect(mapStateToProps, mapDispatchToProps)(Volunteer);
