import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { getOpcens } from "../../actions/opcen";
import OpcenItem from "./OpcenItem";

import {
  getOpcenProfile,
  getOpcenProfileById,
} from "../../actions/opcenprofile";

const Opcens = ({ getOpcens, user, opcen: { opcen, opcens, loading } }) => {
  useEffect(() => {
    getOpcens(user);
  }, [getOpcens, user]);
  return loading || opcens === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-around",
          flexWrap: "wrap",
          overflowY: "auto",
        }}
        className='align-vertically'
      >
        {opcens.map(opcen => (
          <div className='posts'>
            <OpcenItem key={opcen._id} opcen={opcen} />
          </div>
        ))}
      </div>
    </Fragment>
  );
};

Opcens.propTypes = {
  getOpcens: PropTypes.func.isRequired,
  getOpcenProfile: PropTypes.func.isRequired,
  opcen: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  opcen: state.opcen,
  user: state.auth.user._id,
});

export default connect(mapStateToProps, {
  getOpcens,
  getOpcenProfile,
  getOpcenProfileById,
})(Opcens);
