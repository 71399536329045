import { GET_MESSAGE, CREATE_MESSAGE, ERROR_MESSAGE } from "../actions/types";

const initialState = {
  messages: [],
  message: null,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_MESSAGE:
      return {
        ...state,
        message: payload,
        loading: false,
      };
    case GET_MESSAGE:
      return {
        ...state,
        messages: payload,
        loading: false,
      };

    case ERROR_MESSAGE:
      return {
        messages: [],
        message: null,
        loading: true,
        error: payload,
      };

    default:
      return state;
  }
}
