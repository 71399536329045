import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCurrentProfile, deleteAccount } from "../../actions/profile";
import Spinner from "../layout/Spinner";
import { Link } from "react-router-dom";
import DashboardActions from "./DashboardActions";
import Experience from "./Experience";
import Education from "./Education";
import ProfileTop from "../profile/ProfileTop";

const Dashboard = ({
  getCurrentProfile,
  deleteAccount,
  auth: { user },
  profile: { profile, loading },
  userloading,
}) => {
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);

  return loading && !userloading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className=' main-container align-vertically'>
        {!profile && (
          <p className='lead' style={{ fontWeight: "600", fontSize: "16px" }}>
            {/* <i className='fas fa-user'></i> */}
            Hi Guardian, {user?.name} {user?.lname}
          </p>
        )}

        {profile !== null ? (
          <Fragment>
            <ProfileTop profile={profile} />
            <DashboardActions />
            {/* pass the experience array from state */}
            <Experience experience={profile.experience} />
            {/* pass the education array from state */}
            <Education education={profile.education} />

            <div style={{ marginTop: "15px" }}>
              <div style={{ width: "100%", textAlign: "center" }}>
                <p style={{ fontSize: "13px" }}>
                  <Link to={"/privacy"}>
                    <span style={{ fontSize: "13px" }}> Privacy Policy </span>
                  </Link>
                  |
                  <Link to={"/termsofuse"}>
                    <span style={{ fontSize: "13px" }}>
                      {" "}
                      Terms & Conditions{" "}
                    </span>
                  </Link>
                  |
                  <Link onClick={() => deleteAccount()}>
                    <span style={{ fontSize: "13px" }}> Delete Account</span>
                  </Link>
                </p>
              </div>
              {/* 
              <button
                className='btn btn-danger'
                onClick={() => deleteAccount()}
              >
                <i className='fas fa-user-minus'></i> Delete Account
              </button> */}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <p className='lead'>
              Please add your profile to start commenting, react to post and
              view your ID or create an operation center.
            </p>
            {/* <small
              className='form-text'
              style={{ paddingLeft: "10px", color: "red" }}
            >
              (Warning: Fake accounts will be disabled.)
            </small> */}
            <div style={{ marginTop: "20px" }}>
              <Link to='/create-profile' className='btn btn-primary my-1'>
                Create Profile
              </Link>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  userloading: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  userloading: state.auth.user,
});
export default connect(mapStateToProps, {
  getCurrentProfile,
  deleteAccount,
})(Dashboard);
