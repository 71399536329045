import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { setCreateIncident } from "../../actions/incident";
import { clearIncident } from "../../actions/incident";
import { clearResponder } from "../../actions/responder";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;
const IncidentCloseModal = ({
  incidentClose,
  clearIncident,
  clearResponder,
}) => {
  const [close, setClose] = useState(false);

  const closeIncident = () => {
    clearIncident();
    clearResponder();
    setClose(true);
  };

  useEffect(() => {
    setTimeout(function () {
      closeIncident();
    }, 10 * 1000);
  }, []);

  if (close) {
    return <Redirect to='/posts' />;
  }

  return (
    <Fragment>
      <div className=' main-container ' style={{ height: "100vh" }}>
        <div className='align-vertically'>
          <div
            style={{
              color: "#333",
              textAlign: "center",
            }}
          >
            <h1 style={{ padding: "10px 10px 10px 10px", color: "#28a745" }}>
              {" "}
              REPORT CLOSED
            </h1>
          </div>
          <div
            className='sub-container'
            style={{ height: "400px", width: "380px" }}
          >
            <div className='align-vertically1' style={{ width: "450px" }}>
              {incidentClose?.report === "Yes" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <p style={{ paddingBottom: "20px" }}> by:</p>
                    <img
                      className='shadow_me'
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "100%",
                      }}
                      src={`${serverURI}/${incidentClose.opcenProfilepic}`}
                      alt=''
                    />
                    <p>{`${incidentClose.opcenName}`}</p>
                    <div style={{ paddingTop: "50px", width: "95%" }}>
                      <p
                        style={{
                          fontSize: "12px",
                          fontStyle: "italic",
                          padding: "10px",
                        }}
                      >
                        "The best way to find yourself is to lose yourself in
                        the service of others"{" "}
                        <p style={{ fontSize: "9px", fontWeight: "300" }}>
                          -Mahatma Gandhi
                        </p>
                      </p>
                      <h3>THANK YOU FOR VOLUNTEERING !</h3>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <p style={{ fontSize: "13px", paddingBottom: "30px" }}>
                      {" "}
                      status
                    </p>
                    <div
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        background: "#dc3545",
                        height: "80px",
                        fontSize: "50px",
                        marginBottom: "50px",
                        fontWeight: "700",
                        letterSpacing: "2px",
                      }}
                    >
                      <p>INVALID</p>
                    </div>

                    <p style={{ fontSize: "13px" }}>
                      Doing this again may result to account <b>SUSPENSION</b>
                      ...
                    </p>

                    <p style={{ fontSize: "13px" }}>
                      Saving lives and properties is everyones moral duty,please
                      <p> refrain to free up the emergency dispatchers line.</p>
                    </p>
                    <p style={{ fontSize: "10px", paddingTop: "30px" }}>
                      A reminder from:
                    </p>
                    <img
                      // className='shadow_me'
                      style={{ width: "80px", height: "80px" }}
                      src={`/opcenlogo/guardian_s.png`}
                      alt=''
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginBottom: "20px",
            }}
          >
            <button
              onClick={closeIncident}
              className='btn-primary '
              style={{ height: "50px", width: "100%" }}
            >
              Go Back to Notifications
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

IncidentCloseModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.object.isRequired,
  clearIncident: PropTypes.func.isRequired,
  incidentClose: PropTypes.object.isRequired,
  clearResponder: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  incidentClose: state.incident.incidentclose,
});

export default connect(mapStateToProps, {
  setCreateIncident,
  clearResponder,
  clearIncident,
})(IncidentCloseModal);
