import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { displayAnnoucement } from "../../actions/annoucement";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;
export const AnnoucementClient = ({
  show,
  onClose,
  activeAnnoucement,
  displayAnnoucement,
}) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const tick = () => setIndex(i => i + 1);

    const id = setInterval(tick, 8000);
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    displayAnnoucement();
  }, [displayAnnoucement]);

  if (!show) {
    return null;
  }

  return (
    <Fragment>
      <div
        className='full'
        style={{ backgroundColor: "#215a75" }}
        onClick={onClose}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className='annoucementImageContainer'>
            <div
              onClick={onClose}
              style={{
                position: "fixed",
                left: "20px",
                top: "20px",
                zIndex: "5",
                width: "40px",
                height: "40px",
                borderRadius: "50px",
                backgroundColor: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <i class='fa fa-times fa-lg' aria-hidden='true'></i>
            </div>
            <div>
              <img
                className='shadow_me '
                src={`${serverURI}/${
                  activeAnnoucement[index % activeAnnoucement?.length]
                    ?.articleImage
                }`}
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

AnnoucementClient.propTypes = { displayAnnoucement: PropTypes.func.isRequired };

const mapStateToProps = state => ({});

const mapDispatchToProps = { displayAnnoucement };

export default connect(mapStateToProps, mapDispatchToProps)(AnnoucementClient);
