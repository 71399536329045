import { React, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { getCurrentProfile } from "../../actions/profile";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { Link } from "react-router-dom";
import { QRCode } from "react-qrcode-logo";

const QrPhoto = ({ getCurrentProfile, profile: { profile, loading }, vax }) => {
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);

  return (
    <Fragment>
      {profile === null || loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className='  align-vertically' style={{ marginTop: "25px" }}>
            <div className='id-container'>
              <div
                style={{
                  background: "#666",
                  width: "60px",
                  height: "10px",
                  borderRadius: "5px",
                }}
              ></div>
              <div
                style={{
                  width: "99%",
                  border: "1px #ddd solid",
                  borderRadius: "5px",
                  marginTop: "10px",
                  height: "95%",
                }}
              >
                <Link to='/ID'>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "60%",
                      borderRadius: " 5px 5px 0px 0px",
                      backgroundColor: "#fff",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <div className='qr-code-id-big'>
                      <QRCode
                        size='200'
                        includeMargin='true'
                        fgColor='#333'
                        value={profile.user._id}
                      />
                    </div>
                  </div>
                </Link>
                <div style={{ height: "5px", backgroundColor: "#eee" }}></div>

                <div className='id-container-name'>
                  <span>
                    {profile.user.lname} , {profile.user.name}
                  </span>
                </div>
                <div style={{ height: "2px", backgroundColor: "#eee" }}></div>
                <p
                  style={{
                    background: "#fff",
                  }}
                >
                  Complete Name
                </p>
                <Link
                  to='/vaccine_data'
                  className={
                    vax?.sDoseDate === null
                      ? "id-container-access bg-primary"
                      : vax?.sDoseDate !== null
                      ? "id-container-access bg-success"
                      : "id-container-access bg-danger"
                  }
                >
                  {vax?.sDoseDate === null ? (
                    <p style={{ color: "#fff" }}>FIRST DOSE DONE</p>
                  ) : vax?.sDoseDate !== null ? (
                    <p style={{ color: "#fff" }}>VACCINATION COMPLETE</p>
                  ) : (
                    <p style={{ color: "#fff" }}>NO COVID VACCINATION DATA</p>
                  )}
                </Link>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "16%",
                    borderRadius: " 0px 0px  5px 5px",
                    backgroundColor: "#AFBFC6",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      margin: "auto",
                      padding: "10px",
                      borderRadius: "0 0 0 5px",
                      color: "#333",
                    }}
                  >
                    <h4 className='hide-sm'>
                      {profile.area} , {profile.city}
                    </h4>
                    <p style={{ color: "#fff" }}> {profile.completeaddress}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

QrPhoto.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  vax: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
  vax: state.vaccination.vax,
});
export default connect(mapStateToProps, { getCurrentProfile })(QrPhoto);
