import React from "react";
import { connect } from "react-redux";
import { Fragment } from "react";
import sLogo from "../../img/sLogo.png";
import { Link } from "react-router-dom";

const Logo = ({ layout }) => {
  return (
    <Fragment>
      {/* <div > */}

      <Link to='/'>
        <div className='Logo'>
          <img src={sLogo} alt='GUARDIAN' />
          {/* <p className='Htitle'> GUARDIAN</p> */}
        </div>
      </Link>
      {/* </div> */}
    </Fragment>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
