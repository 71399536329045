import React, { Fragment, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addLike, removeLike } from "../../actions/post";
import Spinner from "../layout/Spinner";
import Moment from "react-moment";
import { FacebookShareButton } from "react-share";
import CRUDModal from "./CRUDModal";
import PostReaction from "./PostReaction";
import CommentItem from "../post/CommentItem";
import CommentForm from "../post/CommentForm";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;

const MAX_LENGTH = 150;

const PostItemLarge = ({
  addLike,
  auth,
  profile: { profiles, loading },
  post: {
    _id,
    title,
    text,
    name,
    lname,
    articleImage,
    user,
    likes,
    loves,
    wows,
    sads,
    hahas,
    angrys,
    comments,
    date,
  },
  showActions,
}) => {
  const [shortComment, toggleShortComment] = useState(true);
  const [fullComment, toggleFullComment] = useState(false);
  const [showComment, toggleShowComment] = useState(false);
  const [hideComment, toggleHideComment] = useState(true);
  const [showCRUD, setShowCRUD] = useState(false);
  const [showReaction, setShowReaction] = useState(false);

  const fbQoute = title + "\n " + text;

  const timeDifference = () => {
    var current = new Date();
    var formatDate = new Date(date);

    var minutes = 60 * 1000;
    var hours = minutes * 60;
    var days = hours * 24;
    var months = days * 30;
    var years = days * 365;

    var elapsed = current - formatDate;

    if (elapsed < minutes) {
      return Math.round(elapsed / 1000) + " seconds ago";
    } else if (elapsed < hours) {
      return Math.round(elapsed / minutes) + " minutes ago";
    } else if (elapsed < days) {
      return Math.round(elapsed / hours) + " hours ago";
    } else if (elapsed < months) {
      return Math.round(elapsed / days) + " days ago";
    } else if (elapsed < years) {
      return Math.round(elapsed / months) + " months ago";
    } else {
      return Math.round(elapsed / years) + " years ago";
    }
  };

  let userLiked = likes?.filter((el) => {
    return el.user === auth.user._id;
  });
  let userLoved = loves?.filter((el) => {
    return el.user === auth.user._id;
  });
  let userWows = wows?.filter((el) => {
    return el.user === auth.user._id;
  });
  let userSads = sads?.filter((el) => {
    return el.user === auth.user._id;
  });
  let userHahas = hahas?.filter((el) => {
    return el.user === auth.user._id;
  });

  let userAngrys = angrys?.filter((el) => {
    return el.user === auth.user._id;
  });

  const onButtonClick = useCallback(
    (e) => {
      toggleShortComment(!shortComment);
      toggleFullComment(!fullComment);
    },
    [fullComment, shortComment]
  );

  const onButtonshowComment = useCallback(
    (e) => {
      toggleShowComment(!showComment);
      toggleHideComment(!hideComment);
    },
    [fullComment, shortComment]
  );

  const updateLike = (_id) => {
    addLike(_id);
    // window.location.reload();
  };

  const numFormatter = (num) => {
    if (num > 999 && num < 1000000) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
        : Math.sign(num) * Math.abs(num); // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + "M"
        : Math.sign(num) * Math.abs(num); // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  };

  const totalReactions = numFormatter(
    likes.length +
      loves.length +
      wows.length +
      sads.length +
      hahas.length +
      angrys.length
  );

  const totalComments = numFormatter(comments.length);
  return (
    <Fragment>
      {loading && _id ? (
        <Spinner />
      ) : (
        <div className="post-large">
          {/* Image */}
          <div className="postContainer">
            <div
              style={{
                background: "#eee",
                // width: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {articleImage.substring(0, 4) === "Post" ? (
                articleImage.substring(
                  articleImage.length - 3,
                  articleImage.length
                ) === "mp4" ? (
                  <video
                    className="postImageLarge "
                    controls
                    type="video/mp4"
                    alt="video"
                    src={`${serverURI}/video/${articleImage}`}
                  />
                ) : (
                  <img
                    // style={{ width: "305px", borderRadius: "5px" }}
                    className="postImageLarge "
                    src={`${serverURI}/${articleImage}`}
                    alt="..."
                  />
                )
              ) : null}
            </div>
          </div>

          {/* details */}
          <div
            style={{
              maxHeight: "95vh",
              overflowY: "scroll",
              backgroundColor: "#fff",
            }}
          >
            <div key={_id} className="comment bg-white">
              {/* map all profiles here */}

              {profiles.length > 0 ? (
                profiles?.map((profile) => {
                  if (profile.user._id === user) {
                    return (
                      <Fragment>
                        <div
                          key={[profile._id]}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            height: "70px",
                            margin: "0px 1px",
                            alignItems: "center",
                            paddingRight: "2%",
                            paddingLeft: "2%",
                            justifyContent: "space-around",
                            borderBottom: "1px solid #ddd",
                          }}
                        >
                          <Link to={`/profile/${user}`}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                paddingLeft: "10px",
                              }}
                            >
                              <img
                                className="post-profile"
                                src={`${serverURI}/${profile.profilepic}`}
                                alt="..."
                              />{" "}
                            </div>
                          </Link>
                          <div
                            style={{
                              width: "65%",
                              marginLeft: "20px",
                            }}
                          >
                            <div className="Htitle" style={{ margin: "0" }}>
                              <span className="p-11">{name}</span>
                              <span className="p-11">{lname}</span>{" "}
                            </div>

                            <p className="post-date p-11 ">
                              {" "}
                              {/* <span>{moment(date).format("LLLL")}</span> */}
                              {/* {"   "} - {format(date)} */}
                              <Moment format="lll">{date}</Moment> -{" "}
                              {timeDifference()}
                            </p>
                          </div>
                          <div style={{ width: "10%" }}></div>
                          <div style={{ width: "15%", position: "relative" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {(!auth.loading && user === auth.user._id) ||
                              auth.isOpcenAdmin ? (
                                <button
                                  onClick={() => {
                                    setShowCRUD(true);
                                  }}
                                  type="button"
                                  className="btn-post f-2"
                                >
                                  <i
                                    className="fa fa-ellipsis-h"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              ) : null}
                            </div>

                            {/* Modals here */}
                            {(!auth.loading && user === auth.user._id) ||
                            auth.isOpcenAdmin ? (
                              <CRUDModal
                                showCRUD={showCRUD}
                                onClose={() => setShowCRUD(false)}
                                auth={auth}
                                _id={_id}
                              />
                            ) : null}
                          </div>
                        </div>
                      </Fragment>
                    );
                  }
                })
              ) : (
                <div>
                  <img
                    className="post-profile"
                    src={`/img/Spotter.png`}
                    alt="..."
                  />
                </div>
              )}

              <div>
                <div className="Htitle"> {title}</div>
                <div>
                  {text.length > MAX_LENGTH ? (
                    <div className=" commentFont f-2">
                      {shortComment && (
                        <div>
                          {`${text.substring(0, MAX_LENGTH)}...`}
                          <button
                            className="btn-comment"
                            onClick={onButtonClick}
                            type="button"
                          >
                            see more
                          </button>
                        </div>
                      )}
                      {fullComment && (
                        <div>
                          <p
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {text}
                          </p>
                          <button
                            className="btn-comment"
                            onClick={onButtonClick}
                            type="button"
                          >
                            see less
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <p className="commentFont  f-2">{text}</p>
                  )}
                </div>

                <div className="show_reaction">
                  <PostReaction
                    showReaction={showReaction}
                    onClose={() => setShowReaction(false)}
                    _id={_id}
                    userLiked={userLiked}
                    userLoved={userLoved}
                    userWows={userWows}
                    userSads={userSads}
                    userHahas={userHahas}
                    userAngrys={userAngrys}
                  />

                  <div
                    style={{
                      display: "flex",
                      width: "50%",
                      height: "100%",
                      justifyContent: "flex-start",
                      marginLeft: "20px",
                      alignItems: "center",
                    }}
                  >
                    {likes?.length > 0 && (
                      <div className="flex_center_horizontal2 ">
                        <img
                          className="reactionImage"
                          src="\icons\reactions\like.png"
                          alt=""
                        />
                      </div>
                    )}
                    {loves?.length > 0 && (
                      <div className="flex_center_horizontal2 ">
                        <img
                          className="reactionImage"
                          src="\icons\reactions\love.png"
                          alt=""
                        />
                      </div>
                    )}
                    {wows?.length > 0 && (
                      <div className="flex_center_horizontal2 ">
                        <img
                          className="reactionImage"
                          src="\icons\reactions\wow.png"
                          alt=""
                        />
                      </div>
                    )}
                    {sads?.length > 0 && (
                      <div className="flex_center_horizontal2 ">
                        <img
                          className="reactionImage"
                          src="\icons\reactions\sad.png"
                          alt=""
                        />
                      </div>
                    )}
                    {hahas?.length > 0 && (
                      <div className="flex_center_horizontal2 ">
                        <img
                          className="reactionImage"
                          src="\icons\reactions\haha.png"
                          alt=""
                        />
                      </div>
                    )}
                    {angrys?.length > 0 && (
                      <div className="flex_center_horizontal2 ">
                        <img
                          className="reactionImage"
                          src="\icons\reactions\angry.png"
                          alt=""
                        />
                      </div>
                    )}
                    <span className="reactionCount">
                      {totalReactions != 0 && totalReactions}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "50%",
                      height: "100%",
                      justifyContent: "flex-end",
                      marginRight: "20px",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    {comments.length > 0 && (
                      <div>
                        {" "}
                        <span className="reactionCount">{totalComments}</span>
                        <span className="reactionCount">Comments</span>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "50px",
                    // margin: "0px 5px",
                    alignItems: "center",
                    justifyContent: "space-around",
                    position: "relative",
                    borderTop: "1px solid #ddd",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  {showActions && (
                    <Fragment>
                      {/* Button for like */}
                      <div
                        style={{ position: "relative" }}
                        onMouseOver={() => setShowReaction(true)}
                      >
                        <div
                          onClick={(c) => updateLike(_id)}
                          className="btn-post f-2 "
                        >
                          {userLiked?.length > 0 ? (
                            <div className="flex_center_horizontal ">
                              <img
                                style={{ width: "20px", height: "20px" }}
                                src="\icons\reactions\like.png"
                                alt="liked"
                              />
                              <span className={"marginLiked"}>Like</span>
                            </div>
                          ) : userLoved?.length > 0 ? (
                            <div className="flex_center_horizontal ">
                              <img
                                style={{ width: "20px", height: "20px" }}
                                src="\icons\reactions\love.png"
                                alt="love"
                              />
                              <span className={"marginLiked"}>Love</span>
                            </div>
                          ) : userWows?.length > 0 ? (
                            <div className="flex_center_horizontal ">
                              <img
                                style={{ width: "20px", height: "20px" }}
                                src="\icons\reactions\wow.png"
                                alt="wow"
                              />
                              <span className={"marginLiked"}>Wow</span>
                            </div>
                          ) : userSads?.length > 0 ? (
                            <div className="flex_center_horizontal ">
                              <img
                                style={{ width: "20px", height: "20px" }}
                                src="\icons\reactions\sad.png"
                                alt="sad"
                              />
                              <span className={"marginLiked"}>Sad</span>
                            </div>
                          ) : userHahas?.length > 0 ? (
                            <div className="flex_center_horizontal ">
                              <img
                                style={{ width: "20px", height: "20px" }}
                                src="\icons\reactions\haha.png"
                                alt="haha"
                              />
                              <span className={"marginLiked"}>Haha</span>
                            </div>
                          ) : userAngrys?.length > 0 ? (
                            <div className="flex_center_horizontal ">
                              <img
                                style={{ width: "20px", height: "20px" }}
                                src="\icons\reactions\angry.png"
                                alt="angry"
                              />
                              <span className={"marginLiked"}>Angry</span>
                            </div>
                          ) : (
                            <div>
                              <i
                                className="fa fa-thumbs-o-up"
                                aria-hidden="true"
                              ></i>
                              <span className="marginLike">Like</span>
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        onClick={onButtonshowComment}
                        className="btn-post f-2"
                      >
                        <i className="fa fa-comment-o" aria-hidden="true"></i>{" "}
                        <p style={{ marginLeft: "3px" }}>Comment </p>
                      </div>

                      <FacebookShareButton
                        className="btn-post f-2"
                        url={`https://guardian.ph`}
                        quote={fbQoute}
                        hashtag={"#BeOnGuard"}
                      >
                        {/* <FacebookIcon size={40} /> */}
                        <i
                          className="fa fa-share-square-o"
                          aria-hidden="true"
                        ></i>{" "}
                        <p style={{ marginLeft: "3px", fontSize: "13px" }}>
                          Share
                        </p>
                      </FacebookShareButton>
                    </Fragment>
                  )}
                </div>
                {showComment && <CommentForm postId={_id} />}

                <div className="comments">
                  {comments.map((comment) => (
                    <CommentItem key={_id} comment={comment} postId={_id} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

PostItemLarge.defaultProps = {
  showActions: true,
};

PostItemLarge.propTypes = {
  profile: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  addLike: PropTypes.func.isRequired,
  removeLike: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, {
  addLike,
  removeLike,
})(PostItemLarge);
