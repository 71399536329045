import React, { Fragment } from "react";
import { connect } from "react-redux";
import LocalVideo from "../VideoLocalView/LocalVideo";
import PropTypes from "prop-types";
import CallingDialog from "../VideoCallingDialog/CallingDialog";
import CallingRejected from "../VideoCallingRejected.js/CallingRejected";
import IncomingCallDialog from "../VideoIncomingCallDialog/IncomingCallDialog";

export const DirectCall = ({ call }) => {
  console.log("Direct Call Value of call", call);
  return (
    <Fragment>
      <LocalVideo localStream={call.localStream} />
      {/* <CallingDialog /> */}
      {/* <CallingRejected /> */}
      {/* <IncomingCallDialog /> */}
    </Fragment>
  );
};

DirectCall.propTypes = {
  call: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  call: state.call,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DirectCall);
