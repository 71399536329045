import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../logo/Logo";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Fragment } from "react";
import Spinner from "./Spinner";
import LogInAs from "./LogInAs";
import SocketContext from "../../SocketContext";
import { setAlert } from "../../actions/alert";
import OnlineOffline from "./OnlineOffline";
import AdminMenu from "../adminMenu/AdminMenu";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;
const Navbar = ({
  auth: { token, isAuthenticated, loading, layout, user },
  profile,
  incident,
  setAlert,
  socket,
}) => {
  const isDispatcher = user?.responder.filter(el => {
    return el.type === "Dispatch Operator";
  });

  const location = useLocation();
  console.log(location.pathname);

  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const [arrivalMessage, setArrivalMessage] = useState(null);

  const [isOnline, setNetwork] = useState(window.navigator.onLine);
  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
  };

  useEffect(() => {
    socket.on("getMessage", data => {
      // Put condition here for notification
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
      });
    });
    // eslint-disable-next-line
  }, []);

  console.log("New Message", arrivalMessage);
  useEffect(() => {
    if (!isAuthenticated) {
      setMenuShow(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      window.addEventListener("offline", updateNetwork);
      window.addEventListener("online", updateNetwork);
      return () => {
        window.removeEventListener("offline", updateNetwork);
        window.removeEventListener("online", updateNetwork);
      };
    }
  }, [isOnline, isAuthenticated]);

  useEffect(() => {
    if (incident) {
      // setShow(false);
      setAlert("Report is active, tap cancel to end", "danger");
    }
  }, [incident]);

  const authLinks = (
    <ul style={{ marginRight: "0" }}>
      <li>
        <Link to={`/incident`}>
          <div className='regular align-center marginLeft10'>
            <i className='fa fa-bullhorn ' aria-hidden='true'></i>
            <span className='marginLeft5 hide-sm'> Report </span>
          </div>
        </Link>
      </li>
      <li>
        <Link to='/ID'>
          <div className='regular align-center marginLeft10'>
            <i className='fa fa-id-badge ' aria-hidden='true'></i>{" "}
            <span className='marginLeft5 hide-sm'> ID </span>
          </div>
        </Link>
      </li>
      <li>
        <Link to='/posts'>
          <div className='regular align-center marginLeft10'>
            <i className='fa fa-bell-o '></i>{" "}
            <span className='marginLeft5 hide-sm'> Notifications </span>
          </div>
        </Link>
      </li>
      <li>
        <Link to='/dashboard'>
          <div className='regular align-center marginLeft10'>
            <i className='fas fa-user '></i>{" "}
            <span className='marginLeft5 hide-sm'> Profile </span>
          </div>
        </Link>
      </li>
    </ul>
  );

  const incidentMenu = (
    <ul style={{ marginRight: "0" }}>
      <div></div>
    </ul>
  );

  const opcenLinks = (
    <ul>
      <li>
        <Link to={`/operation-center`}>
          <div className='regular align-center marginLeft10'>
            <i className='fa fa-building-o' aria-hidden='true'></i>{" "}
            <span className='marginLeft5'> Operation Center </span>
          </div>
        </Link>
      </li>

      <li>
        <Link to='/profiles'>
          <div className='regular align-center marginLeft10'>
            <i className='fa fa-users'></i>{" "}
            <span className='marginLeft5'> Responders </span>
          </div>
        </Link>
      </li>
      <li>
        <Link to='/posts'>
          <div className='regular align-center marginLeft10'>
            <i className='fa fa-bell-o'></i>{" "}
            <span className='marginLeft5'> Notifications </span>
          </div>
        </Link>
      </li>

      <li>
        <Link to='/dashboard'>
          <div className='regular align-center marginLeft10'>
            <i className='fas fa-user'></i>{" "}
            <span className='marginLeft5'> Profile </span>
          </div>
        </Link>
      </li>
    </ul>
  );

  const responderLinks = (
    <ul>
      {isDispatcher?.length > 0 ? (
        <li>
          <Link className='hide-sm' to='/dispatch'>
            <div className='regular align-center marginLeft10'>
              <i className='fa fa-headphones' aria-hidden='true'></i>
              <span className='marginLeft5'> Dispatch </span>
            </div>
          </Link>
        </li>
      ) : null}

      <li>
        <Link to='/messenger'>
          <div
            className='regular align-center marginLeft10'
            style={{ position: "relative", width: "100%" }}
          >
            <div
              // className={opcenVaxData?.length === 0 ? "displayNone" : null}
              style={{
                position: "absolute",
                width: "100%",
                top: "-7px",
                left: "1px",
              }}
            >
              <div
                style={{
                  height: "12px",
                  width: "12px",
                  background: "#dc3545",
                  borderRadius: "100%",
                  fontSize: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p> 8 </p>
                {/* {opcenVaxData?.length} */}
              </div>
            </div>
            <i className='far fa-comment-alt hide-sm'></i>
            <span className='marginLeft5'> Messenger </span>
          </div>
        </Link>
      </li>

      <li>
        <Link to='/posts'>
          <div className='regular align-center marginLeft10'>
            <i className='fa fa-bell-o hide-sm'></i>{" "}
            <span className='marginLeft5'> Notifications </span>
          </div>
        </Link>
      </li>
    </ul>
  );

  const guestLinks = (
    <ul>
      <li>
        <Link to='/register'>
          <div className='regular align-center marginLeft10'>
            <i className='fas fa-user'></i>{" "}
            <span className='marginLeft5'>Register</span>
          </div>
        </Link>
      </li>
      <li>
        <Link to='/login'>
          <div className='regular align-center marginLeft10'>
            <i className='fas fa-sign-in-alt'></i>
            <span className='marginLeft5'>Login </span>
          </div>
        </Link>
      </li>
    </ul>
  );
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='navbar bg-dark'>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {layout === "admin" && (
            <div
              onClick={() => setMenuShow(!menuShow)}
              style={{ cursor: "pointer", marginLeft: "20px" }}
            >
              <i class='fa fa-bars fa-lg' aria-hidden='true'></i>
            </div>
          )}

          <Logo />
        </div>

        {!loading && (
          <div className='navbar-menu'>
            {/* {isAuthenticated && isOpcenAdmin
            ? opcenLinks
            : isAuthenticated
            ? authLinks
            : guestLinks} */}
            {layout === "admin"
              ? opcenLinks
              : layout === "volunteer" && !incident
              ? authLinks
              : layout === "volunteer" && incident
              ? incidentMenu
              : layout === "responder"
              ? responderLinks
              : guestLinks}
            {profile !== null && token !== null ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                }}
                onClick={() => setShow(!show)}
              >
                <img
                  className='post-profile'
                  src={`${serverURI}/${profile.profilepic}`}
                  alt=''
                />
              </div>
            ) : profile === null && token !== null ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                }}
                onClick={() => setShow(!show)}
              >
                <img
                  style={{
                    borderRadius: "100%",
                    height: "45px",
                    width: "45px",
                    border: "2px solid #eee",
                  }}
                  src={`/img/Spotter.png`}
                  alt=''
                />
              </div>
            ) : null}
          </div>
        )}

        <SocketContext.Consumer>
          {socket => (
            <LogInAs
              onClose={() => setShow(false)}
              show={show}
              socket={socket}
            />
          )}
        </SocketContext.Consumer>

        <AdminMenu menuShow={menuShow} onClose={() => setMenuShow(false)} />

        <SocketContext.Consumer>
          {socket => <OnlineOffline show={isOnline} socket={socket} />}
        </SocketContext.Consumer>
      </div>
    </Fragment>
  );
};

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object,
  incident: PropTypes.object,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile.profile,
  incident: state.incident.incident,
});

export default connect(mapStateToProps, { setAlert })(Navbar);
