import {
  GET_VAX_BY_ID,
  GET_VAX_BY_ID_ERROR,
  GET_ALL_VAX,
  GET_ALL_VAX_ERROR,
  ERROR_VALIDATION,
  ADD_VALIDATION,
  CLEAR_VAX,
} from "../actions/types";

const initialState = {
  vax: null,
  vaxs: null,
  validated: null,
  loading: true,
  error: {},
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_VAX:
      return {
        ...state,
        vaxs: payload, //from the action file
        loading: false,
      };
    case GET_VAX_BY_ID:
      return {
        ...state,
        vax: payload, //from the action file
        loading: false,
      };
    case ADD_VALIDATION:
      return {
        ...state,
        validated: payload, //from the action file
        loading: false,
      };

    case GET_VAX_BY_ID_ERROR:
    case GET_ALL_VAX_ERROR:
    case ERROR_VALIDATION:
    case CLEAR_VAX:
      return {
        ...state,
        vax: null,
        vaxs: null,
        validated: null,
        loading: true,
        error: {},
      };
    default:
      return state;
  }
}
