import React, { useState, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addComment } from "../../actions/post";
import { setAlert } from "../../actions/alert";
import { Redirect } from "react-router-dom";
import Dropzone from "react-dropzone";

const MIN_TEXTAREA_HEIGHT = 32;

//id in prop is the postid from actions
const CommentForm = ({
  postId,
  addComment,
  profile: { loading, profile },
  setAlert,
}) => {
  const [text, setText] = useState("");

  const textareaRef = useRef(null);
  const [image, setImage] = useState(null); // state for storing actual image
  const [previewSrc, setPreviewSrc] = useState(""); // state for storing previewImage
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false); // state to show preview only for images

  useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    textareaRef.current.style.height = "inherit";
    // Set height
    textareaRef.current.style.height = `${Math.max(
      textareaRef.current.scrollHeight,
      MIN_TEXTAREA_HEIGHT
    )}px`;
  }, [text]);

  const onDrop = (files) => {
    const [uploadedFile] = files;
    setImage(uploadedFile);

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewSrc(fileReader.result);
    };
    fileReader.readAsDataURL(uploadedFile);
    setIsPreviewAvailable(uploadedFile.name.match(/\.(jpeg|jpg|png)$/));
  };

  const onSubmit = async (c) => {
    c.preventDefault();
    if (!profile) {
      setAlert("Please add your profile to start commenting.. ", "danger");
    } else {
      addComment(postId, { text });
      setText("");
    }
  };

  return (
    <div className="post-form">
      <form className="form my-1" onSubmit={(c) => onSubmit(c)}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {image && (
            <div
              style={{
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "0.5px solid #ccc",
              }}
            >
              <img
                style={{ width: "40%", borderRadius: 10, padding: 5 }}
                src={previewSrc}
                alt="Preview"
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <textarea
              ref={textareaRef}
              style={{
                minHeight: MIN_TEXTAREA_HEIGHT,
                resize: "none",
                margin: 0,
              }}
              placeholder="Leave Comment"
              value={text}
              onChange={(c) => setText(c.target.value)}
              required
              autoFocus
            />
            <Dropzone onDrop={onDrop}>
              {/* accept='.jpeg,.png,.gif,.mp4' */}
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="image-button" style={{ color: "#215a75" }}>
                      <i className="fa fa-paperclip" aria-hidden="true"></i>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>

        <input type="submit" className="btn btn-dark my-1" value="Submit" />
      </form>
    </div>
  );
};

CommentForm.propTypes = {
  addComment: PropTypes.func.isRequired,
  postId: PropTypes.object.isRequired,
  profile: PropTypes.object,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { addComment, setAlert })(CommentForm);
