import React, { useState, useEffect, Fragment } from "react";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { setAlert } from "../../actions/alert";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getIncidents } from "../../actions/incident";
import { getDispatchersOnline } from "../../actions/dispatcher";
import Moment from "react-moment";
import moment from "moment";

Chart.register(...registerables);

const DispatchLog_in = ({
  setAlert,
  auth,
  getIncidents,
  incidents,
  getDispatchersOnline,
  dispatchers,
}) => {
  var date = new Date();

  useEffect(() => {
    getIncidents();
    getDispatchersOnline();
  }, [getIncidents, getDispatchersOnline]);

  var start = moment().startOf("day"); // set to 12:00 am today
  var end = moment().endOf("day"); // set to 23:59 pm today
  var reluanch = new Date("04/14/2023");

  console.log("Reluanch", reluanch);

  console.log("Incidents =======>", incidents);

  console.log("Dispathcehres =======>", dispatchers);

  const umapad = dispatchers?.filter((el) => {
    return el.name === "Umapad" && moment(el.createdAt) >= reluanch;
  });

  const subangdaku = dispatchers?.filter((el) => {
    return el.name === "Subangdaku" && moment(el.createdAt) >= reluanch;
  });

  const cubacub = dispatchers?.filter((el) => {
    return el.name === "Cubacub" && moment(el.createdAt) >= reluanch;
  });

  console.log("Cubacub", cubacub);

  const casuntingan = dispatchers?.filter((el) => {
    return el.name === "Casuntingan" && moment(el.createdAt) >= reluanch;
  });

  const mandaue = dispatchers?.filter((el) => {
    return el.name === "Mandaue" && moment(el.createdAt) >= reluanch;
  });

  const umapadIns = incidents?.filter((el) => {
    return moment(el.reporteDate) >= reluanch && moment(el.reporteDate) <= end;
  });

  console.log("Umapad", umapadIns);

  const subangdakuIns = incidents?.filter((el) => {
    return el.name === "Subangdaku" && moment(el.reporteDate) >= reluanch;
  });

  const cubacubIns = incidents?.filter((el) => {
    return el.name === "Cubacub" && moment(el.reporteDate) >= reluanch;
  });

  const casuntinganIns = incidents?.filter((el) => {
    return el.name === "Casuntingan" && moment(el.reporteDate) >= reluanch;
  });

  const mandaueIns = dispatchers?.filter((el) => {
    return el.name === "Mandaue" && moment(el.reporteDate) >= reluanch;
  });

  const dispatchOnline = {
    labels: [
      "Umapad",
      "Subangdaku",
      "Cubacub",
      "Casuntingan",
      "Command Center",
    ],
    datasets: [
      {
        label: "Dispatcher Online Count",
        data: [
          umapad?.length,
          subangdaku?.length,
          cubacub?.length,
          casuntingan?.length,
          mandaue?.length,
        ],
        backgroundColor: [
          "#28a745",
          "#174052",
          "#dc3545",
          "#c8d6dc",
          "#343d34",
        ],
        borderColor: ["#28a745", "#174052", "#dc3545", "#c8d6dc", "#343d34"],
        borderWidth: 2,
      },
    ],
  };

  const incidentData = {
    labels: [
      "Umapad",
      "Subangdaku",
      "Cubacub",
      "Casuntingan",
      "Command Center",
    ],
    datasets: [
      {
        label: "Dispatcher Online Count",
        data: [
          umapadIns?.length,
          subangdakuIns?.length,
          cubacubIns?.length,
          casuntinganIns?.length,
          mandaueIns?.length,
        ],
        backgroundColor: [
          "#28a745",
          "#174052",
          "#dc3545",
          "#c8d6dc",
          "#343d34",
        ],
        borderColor: ["#28a745", "#174052", "#dc3545", "#c8d6dc", "#343d34"],
        borderWidth: 2,
        borderWidth: 2,
      },
    ],
  };

  const options = {
    plugins: { legend: { display: false } },
    tooltips: {
      enabled: true,
    },
    layout: { padding: { bottom: 1, top: 10 } },
    //   scales: {
    //     y: {
    //       ticks: {
    //         color: "#333",
    //         font: {
    //           size: 10,
    //         },
    //       },
    //       grid: {
    //         color: "#333",
    //       },
    //     },
    //     x: {
    //       ticks: {
    //         color: "#333",
    //         font: {
    //           size: 10,
    //         },
    //       },
    //     },
    //   },
  };
  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <h3 style={{ paddingTop: "20px" }}>Dispatchers Online</h3>
        <Bar
          style={{ height: "90px", width: "90px" }}
          data={dispatchOnline}
          options={options}
        />
        <div style={{ display: "flex", padding: "20px" }}>
          <p style={{ fontSize: "13px" }}>
            Umapad: {umapad?.length} | Subangdaku: {subangdaku?.length}|
            Cubacub:
            {cubacub?.length} | Casuntingan: {casuntingan?.length} | Command
            Center {mandaue?.length}
          </p>
        </div>
        <h3 style={{ paddingTop: "20px" }}>Incidents Reported</h3>
        <Bar
          style={{ height: "90px", width: "90px" }}
          data={incidentData}
          options={options}
        />
        <div style={{ display: "flex", padding: "20px" }}>
          <p style={{ fontSize: "13px" }}>
            Umapad: {umapadIns?.length} | Subangdaku: {subangdakuIns?.length}|
            Cubacub:
            {cubacubIns?.length} | Casuntingan: {casuntinganIns?.length} |
            Command Center {mandaueIns?.length}
          </p>
        </div>
        <h1>
          <Moment format="dddd , LLL ">{date}</Moment>
        </h1>
      </div>
    </Fragment>
  );
};
DispatchLog_in.propTypes = {
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  getIncidents: PropTypes.func.isRequired,
  getDispatchersOnline: PropTypes.func.isRequired,
  dispatchers: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  incidents: state.incident.incidents,
  dispatchers: state.dispatchOnline.allDispatchers,
});
const mapDispatchToProps = { setAlert, getIncidents, getDispatchersOnline };
export default connect(mapStateToProps, mapDispatchToProps)(DispatchLog_in);
