import axios from "axios";
import { GET_CONVERSATION, ERROR_CONVERSATION } from "./types";

//Get All user profile
export const getConversation = userId => async dispatch => {
  try {
    //   change this
    const res = await axios.get(`/api/conversation_res/${userId}`);
    dispatch({
      type: GET_CONVERSATION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ERROR_CONVERSATION,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// Create Conversation
