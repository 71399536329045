import React from "react";
import { Fragment } from "react";

const ChatMenu = (props) => {
  return (
    <Fragment>
      <div className="incident-chat-menu">
        <div style={{ fontSize: "12px", fontWeight: "200" }}>
          Incident Live Chat
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            fontWeight: "200",
          }}
        >
          <span style={{ paddingRight: "20px" }}> Call scripts</span>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "170px",
            }}
          >
            <img
              className="incident-icon-menu"
              src="/icons/incidentType/Covid-res.png"
              alt=""
            />
            <img
              className="incident-icon-menu"
              src="/icons/incidentType/Fire.png"
              alt=""
            />
            <img
              className="incident-icon-menu"
              src="/icons/incidentType/Police.png"
              alt=""
            />
            <img
              className="incident-icon-menu"
              src="/icons/incidentType/Medical.png"
              alt=""
            />
            <img
              className="incident-icon-menu"
              src="/icons/incidentType/General.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ChatMenu;
