import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { QRCode } from "react-qrcode-logo";
import Moment from "react-moment";
import { incidentClose } from "../../actions/incident";
import { closeIncident } from "../../actions/incident";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;
export const IncidentForceCloseModal = ({
  onClose,
  show,
  forceCloseIncident,
  profile: { profiles },
  incidentClose,
  closeIncident,
  responder,
}) => {
  const incidentId = forceCloseIncident?._id;
  const reportedby_userId = forceCloseIncident?.user._id;
  const valid = "No";
  const summary = "Forced Close by Admin";

  const refreshPage = () => {
    setTimeout(function () {
      window.location.reload();
    }, 2 * 1000);
  };

  if (!show) {
    return null;
  }

  return (
    <Fragment>
      <div className="modalCloseIncidentClear" onClick={onClose}>
        <div className="modal-forceclose" onClick={(e) => e.stopPropagation()}>
          <div
            className="sub-container shadow_me"
            style={{ background: "#215a75" }}
          >
            <div className="incidentDetails">
              <div
                style={{
                  background: "#ddd",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "80%",
                  padding: "10px 0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <QRCode
                      size="70"
                      includeMargin="true"
                      fgColor="#000"
                      bgColor="#ddd"
                      quietZone="10"
                      eyeRadius={[
                        5, // top/left eye
                        10, // top/right eye
                        5, // bottom/left eye
                      ]}
                      value={forceCloseIncident?._id}
                    />

                    <p className="small-txt-blk">INCIDENT ID</p>
                  </div>

                  <div
                    style={{
                      textAlign: "center",
                      padding: "2px",
                      borderBottom: "2px solid #aaa",
                    }}
                  >
                    <h3 style={{ color: "#dc3545" }}>
                      {" "}
                      <Moment format="LT">{forceCloseIncident?.date}</Moment>
                    </h3>
                    <p className="small-txt-blk">TIME RECEIVED</p>
                  </div>
                  {/* <div style={{ textAlign: "center", padding: "2px" }}>
                <h4 style={{ color: "#28a745" }}>
                  <Stopwatch />
                </h4>
                <p className='small-txt-blk'>LAPSE TIME</p>
              </div> */}
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {forceCloseIncident?.type === "Covid" ? (
                    <img
                      className="incident-icon"
                      src="/icons/incidentType/Covid-res.png"
                      alt=""
                    />
                  ) : forceCloseIncident?.type === "Fire" ? (
                    <img
                      className="incident-icon"
                      src="/icons/incidentType/Fire.png"
                      alt=""
                    />
                  ) : forceCloseIncident?.type === "Medical" ? (
                    <img
                      className="incident-icon"
                      src="/icons/incidentType/Medical.png"
                      alt=""
                    />
                  ) : forceCloseIncident?.type === "Crime" ? (
                    <img
                      className="incident-icon"
                      src="/icons/incidentType/Police.png"
                      alt=""
                    />
                  ) : (
                    <img
                      className="incident-icon"
                      src="/icons/incidentType/General.png"
                      alt=""
                    />
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: "20px",
                      color: "#fff",
                    }}
                  >
                    <h1> {forceCloseIncident?.type} </h1>
                    <div style={{ cursor: "pointer" }}>
                      <p>
                        {forceCloseIncident?.scompleteaddress.substring(0, 130)}
                        ...
                      </p>
                      <p className="small-txt-blk text-red"> LOCATION</p>
                    </div>
                    <div style={{ paddingTop: "3px" }}>
                      <h3>
                        {" "}
                        {forceCloseIncident?.name} {forceCloseIncident?.lname}
                      </h3>
                      <p className="small-txt-blk text-red"> VOLUNTEER</p>
                    </div>

                    <div style={{ paddingTop: "3px" }}>
                      <p> {forceCloseIncident?.number}</p>
                      <p className="small-txt-blk text-red"> MOBILE NUMBER</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style={{ cursor: "pointer" }}>
                  {profiles.length > 0 ? (
                    profiles.map((profile, i) => {
                      if (profile?.user?._id === forceCloseIncident?.user._id) {
                        return (
                          <div>
                            <img
                              className="in_profile_img_offline"
                              alt="profile"
                              src={`${serverURI}/${profile.profilepic}`}
                            />
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div>
                      <img
                        className="post-profile"
                        src={`/img/Spotter.png`}
                        alt="..."
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <button
                className="btn btn-danger"
                style={{ padding: "10px", width: "200px" }}
                onClick={() => (
                  closeIncident({ responder, incidentId }),
                  incidentClose({
                    incidentId,
                    reportedby_userId,
                    valid,
                    summary,
                  }),
                  refreshPage()
                )}
              >
                Close Incident
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

IncidentForceCloseModal.propTypes = {
  profile: PropTypes.object.isRequired,
  incidentClose: PropTypes.func.isRequired,
  closeIncident: PropTypes.func.isRequired,
  responder: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  responder: state.auth.user._id,
});

const mapDispatchToProps = { incidentClose, closeIncident };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IncidentForceCloseModal);
