import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPosts } from "../../actions/post";
import { createNotification } from "../../actions/expoNotification";
import ProgressBar from "../layout/ProgressBar";
import axios from "axios";
import { setAlert } from "../../actions/alert";
//Webpush
// import { subscribeUser } from "../../subscription";

const MIN_TEXTAREA_HEIGHT = 32;

const PostForm = ({ setAlert, getPosts, createNotification }) => {
  //const [text, setText] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    text: "",
    articleImage: "",
  });

  const [image, setImage] = useState([]);
  const [imageName, setImageName] = useState("");
  const inputRef = useRef();
  const [progress, setProgress] = useState();
  const [error, setError] = useState();
  const textareaRef = useRef(null);

  const { title, text } = formData;

  // useEffect(() => {
  //   getPosts();
  // }, [getPosts]);

  useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    textareaRef.current.style.height = "inherit";
    // Set height
    textareaRef.current.style.height = `${Math.max(
      textareaRef.current.scrollHeight,
      MIN_TEXTAREA_HEIGHT
    )}px`;
  }, [text]);

  const onFileChange = (c) => {
    // setImage(c.target.files[0]);
    setImage(c.target.files);

    setImageName(c.target.files[0].name);
  };

  const onChange = (c) => {
    setFormData({ ...formData, [c.target.name]: c.target.value });
  };

  const payload = new FormData();
  payload.append("title", title);
  payload.append("text", text);
  // payload.append("articleImage", image);
  for (const key of Object.keys(image)) {
    payload.append("multiImage", image[key]);
  }

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const sendNotif = async () => {
    try {
      // const res = await axios.post("/api/posts", payload, {
      const res = await axios.post("/api/posts/multiple", payload, {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      });
      console.log("Send Notif response", res);
      setAlert("Notification Created", "success");
      await delay(3000);
      window.location.reload();
    } catch (err) {
      console.log("Error from backend", err);
      setAlert(`Post error ${err.response.statusText}`, "danger");
    }
  };

  const onSubmit = (c) => {
    c.preventDefault();
    // addPost(payload);
    sendNotif();
    // popNotif();
    // subscribeUser();
    createNotification({ title: title, body: text });
    setFormData({ title: "", text: "", articleImage: "" });
    inputRef.current.value = "";
    // put error catcher
  };

  //

  return (
    <div
      className="comment bg-white"
      style={{ margin: "10px 0px 10px 0px", padding: "2px" }}
    >
      <h1 className="large text-primary m-1"> Quick Notification</h1>
      <p style={{ fontSize: "14px", fontWeight: "500", marginLeft: "20px" }}>
        Update your volunteers and responders, this would help FREE UP your
        emergency lines.
      </p>
      <p style={{ fontSize: "10px", marginLeft: "20px" }}>
        (Accepts photo and video attachment of types, .jpg, .gif, .png and .mp4)
      </p>

      <form
        className="form my-1"
        encType="multipart/form-data"
        onSubmit={(c) => onSubmit(c)}
      >
        <div className="post-form-container">
          <div className="Title">
            <input
              name="title"
              type="text"
              placeholder="* Title"
              onChange={(c) => onChange(c)}
              value={title}
              required
            />
          </div>
          <div className="Message">
            <textarea
              name="text"
              ref={textareaRef}
              style={{
                minHeight: MIN_TEXTAREA_HEIGHT,
                resize: "none",
              }}
              placeholder="* Message"
              value={text}
              onChange={(c) => onChange(c)}
              required
            />
          </div>

          <div className="Attachment">
            <input
              className="btn btn-dark my-1"
              style={{ borderRadius: "5px", width: "100%" }}
              // id='upload-button'
              ref={inputRef}
              type="file"
              onChange={(c) => onFileChange(c)}
              accept="image/*,video/mp4,video/x-m4v,video/*"
              multiple={true}
              placeholder={imageName}
              required
              // style={{ display: "none" }}
            />
          </div>
          <div className="Send">
            {/* <label htmlFor='file'> */}
            <input
              type="submit"
              className="btn btn-dark my-1"
              style={{ width: "100%", margin: "auto", borderRadius: "5px" }}
              value="Send"
            />
          </div>
        </div>

        {/* add image button here */}
      </form>
      {!error && progress && <ProgressBar progress={progress} />}
    </div>
  );
};

PostForm.propTypes = {
  getPosts: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  // opcen_id: state.opcen.opcen._id,
});

export default connect(mapStateToProps, {
  getPosts,
  setAlert,
  createNotification,
})(PostForm);
