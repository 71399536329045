import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from "react-moment";
import { deleteAnnoucement, getAnnoucementId } from "../../actions/annoucement";
import { setAlert } from "../../actions/alert";
import EditAnnoucementModal from "./EditAnnoucementModal";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;

const AnnouncementList = ({
  annoucement,
  deleteAnnoucement,
  getAnnoucementId,
}) => {
  const [show, setShow] = useState(false);

  const annoucements = annoucement.map((res, i) => (
    <tr key={i + 1}>
      <td style={{ textAlign: "center" }}>{i + 1}</td>
      <td>{res.title}</td>
      <td>{res.description}</td>
      <td>
        <Moment format='ll'>{res.dateFrom}</Moment>
      </td>
      <td>
        {" "}
        <Moment format='ll'>{res.dateTo}</Moment>
      </td>

      <td style={{ textAlign: "center" }}>
        <a
          href={`${serverURI}/${res.articleImage}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            style={{ width: "50px", maxHeight: "100px" }}
            src={`${serverURI}/${res.articleImage}`}
            alt='vaxId'
          />
        </a>
      </td>
      <td
        style={{
          textAlign: "center",
        }}
      >
        <button
          onClick={() => {
            //   getannoucement by Id
            getAnnoucementId(res._id);
            setShow(true);
          }}
          className=' btn_table btn-dark'
          style={{ margin: "5px", padding: "8px" }}
        >
          <i class='fa fa-pencil' aria-hidden='true'></i> Edit
        </button>
        <button
          onClick={() => {
            try {
              deleteAnnoucement(res._id);
              window.location.reload();
            } catch (err) {
              setAlert(err, "danger");
            }
          }}
          className=' btn_table btn-danger'
          style={{ margin: "5px", padding: "8px" }}
        >
          <i className='fa fa-plus-square-o' aria-hidden='true'></i> Delete
        </button>
      </td>
    </tr>
  ));
  return (
    <Fragment>
      <div
        style={{
          display: "block",
          overflow: "hidden",
          overflowY: "scroll",
          height: "95%",
          // Change Hight Here
        }}
      >
        <table className='commonTable'>
          <thead>
            <tr>
              <th>Number</th>
              <th>Title</th>
              <th className='hide-sm'>Description</th>
              <th className='hide-sm'>Date From</th>
              <th>Date To</th>

              <th>Image</th>

              <th>Review</th>
            </tr>
          </thead>
          {/* Loop thru the database */}
          <tbody>{annoucements}</tbody>
        </table>
      </div>

      <EditAnnoucementModal show={show} onClose={() => setShow(false)} />
    </Fragment>
  );
};
AnnouncementList.propTypes = {
  deleteAnnoucement: PropTypes.func.isRequired,
  getAnnoucementId: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = { deleteAnnoucement, getAnnoucementId };

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementList);
