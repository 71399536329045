import PropTypes from "prop-types";
import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";

export const LocalVideo = ({ localStream }) => {
  const localVideoRef = useRef();

  // useEffect(() => {
  //   if (localStream) {
  //     const localVideo = localVideoRef.current;
  //     localVideo.srcObject = localStream;

  //     localVideo.onloadedmetadata = () => {
  //       localVideo.play();
  //     };
  //   }
  // }, [localStream]);

  return (
    <div className="localVideoContainer">
      <video
        style={{ height: "100%", width: "100%" }}
        ref={localVideoRef}
        autoPlay
        muted
      ></video>
    </div>
  );
};

LocalVideo.propTypes = {
  call: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  call: state.call,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LocalVideo);
