import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getPosts } from "../../actions/post";
import { getAnnoucement } from "../../actions/annoucement";

const Landing = ({ isAuthenticated, getAnnoucement, getPosts }) => {
  useEffect(() => {
    // getPosts();
    getAnnoucement();
  }, [getPosts, getAnnoucement]);

  const calculateTimeLeft = () => {
    let year = new Date().getFullYear();
    let difference = +new Date(`02/18/${year}`) - +new Date();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  const toGooglePlay = () => {
    window.open(
      `https://play.google.com/store/search?q=guardianPH&c=apps&hl=en`
    );
    return false;
  };

  const toAppStore = () => {
    window.open(`https://apps.apple.com/ph/app/guardianph/id6443960757`);
    return false;
  };

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  if (isAuthenticated) {
    return <Redirect to="/posts" />;
  }
  return (
    <section className="landing smooth_loading">
      <div className="dark-overlay">
        <div className="landing-inner">
          <div className="landing-header"></div>
          <div className="landing-title"></div>
          <div className=" landing-subTitle">
            <p className="x-large">GUARDIAN</p>
            <p className="subTitle1">
              Geographic Unified Assistanace and Response to Distress Incidents
              with Agile Networking
            </p>
            <p className="tagLine">Emergency Response at your Fingertips.</p>
            <div style={{ display: "flex", padding: "20px" }}>
              {" "}
              <Link
                to="/register"
                className="btn btn-primary"
                style={{ marginRight: "3px" }}
              >
                Register
              </Link>
              <Link
                to="/Login"
                className="btn btn-light"
                style={{ marginLeft: "3px" }}
              >
                Login
              </Link>
            </div>{" "}
          </div>

          <div className="landing-others">
            <div className="videoExplainerContainer">
              <video
                src={`video/explainer.mp4`}
                className="videoExplainer"
                alt="Explainer Video"
                type="video/mp4"
                controls
		autoPlay
              />
            </div>
            <p className="download">Download at google play and app store.</p>

            <div
              className="appStoreBtn"
              // style={{
              //   display: "flex",
              //   flexDirection: "row",
              //   alignItems: "center",
              //   justifyContent: "center",
              // }}
            >
              <img
                onClick={toGooglePlay}
                style={{ height: "50px", cursor: "pointer" }}
                src="icons/appLogo/google.png"
                alt="google play"
              />

              <img
                onClick={toAppStore}
                style={{ height: "50px", cursor: "pointer" }}
                src="icons/appLogo/apple.png"
                alt="App Store"
              />
            </div>

            <p className="productLine">
              Computer Aided Dispatch and Civilian Reporting System (CAD/CRS)
            </p>

            {/* <div className='buttons'>
              <p
                className='subTitle'
                style={{
                  letterSpacing: "1.5px",
                  fontWeight: "400",
                  fontSize: "18px",
                  paddingTop: "50px",
                }}
              >
                Command your team and Control your resources
              </p>

              <p
                style={{
                  letterSpacing: "1.5px",
                  fontWeight: "100",
                  fontSize: "7px",
                }}
              >
                For Local Goverment Units, Volunteer Organization...
              </p>

              <Link
                to='/typeopcen'
                className='btn btn-light'
                style={{ width: "303px", marginLeft: "3px" }}
              >
                Create Operation Center
              </Link>
            </div> */}
            {/* Timer Starts */}
            {/* <div
              className={`${timerComponents.length ? "" : "hide-btn"}`}
              style={{ padding: "18px" }}
            >
              <p
                style={{
                  fontSize: "18px",
                  marginTop: "18px",
                  fontWeight: "100",
                }}
              >
                Launching a web app version this February 18, 2022
              </p>

              {timerComponents.length ? (
                <p
                  style={{
                    fontSize: "28px",
                    color: "#fff",
                    background: "red",
                    padding: "8px 80px",

                    borderRadius: "5px",
                  }}
                >
                  {" "}
                  {timerComponents}
                </p>
              ) : (
                <span style={{ fontSize: "45px" }}>Web app released!</span>
              )}
            </div> */}
            {/* Timer Ends */}
          </div>
          <div className="landing-footer">
            <div className="small-txt">
              <p style={{ color: "#ddd" }}>
                <Link to={"/privacy"}>
                  <span style={{ color: "#ddd" }}> Privacy Policy </span>
                </Link>
                |
                <Link to={"/termsofuse"}>
                  <span style={{ color: "#ddd" }}> Terms & Conditions </span>
                </Link>
                |
                <Link>
                  <span style={{ color: "#ddd" }}> EULA </span>
                </Link>
                |
                <Link>
                  <span style={{ color: "#ddd" }}> Contact Us</span>
                </Link>
              </p>
            </div>
            <div className="buttons" style={{ paddingTop: "5px" }}>
              <div className="small-txt">
                <p>
                  Developed by Sugbotek Inc, an affiliate of 7Core
                  Communications, Inc. | © 2023 All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
Landing.propTypes = {
  isAuthenticated: PropTypes.bool,
  getPosts: PropTypes.func.isRequired,
  getAnnoucement: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { getPosts, getAnnoucement })(Landing);
