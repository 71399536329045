import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getIncidents } from "../../actions/incident";
import { getOpcenProfileById } from "../../actions/opcenprofile";
import Clock from "react-live-clock";
import Moment from "react-moment";
import Incidents from "./Incidents";
import Spinner from "../layout/Spinner";
import { useHistory } from "react-router-dom";
import SocketContext from "../../SocketContext";
import { setAlert } from "../../actions/alert";
const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;

const IncidentsMain = ({
  getIncidents,
  incident: { incidents, loading },
  socket,
  setAlert,
}) => {
  const [newIncident, setNewIncident] = useState(null);
  const [incidentList, setIncidentList] = useState([]);
  const [incidentClose, setIncidentClose] = useState(false);

  useEffect(() => {
    socket.on("getIncident", data => {
      setNewIncident({
        user: data.user,
        name: data.name,
        lname: data.lname,
        type: data.type,
        scompleteaddress: data.scompleteaddress,
        sarea: data.sarea,
        sstate: data.sstate,
        slat: data.slat,
        slng: data.slng,
        status: data.status,
        close_incident: [],
        date: Date.now(),
      });
    });
  }, []);

  useEffect(() => {
    socket.on("getOpcenCloseIncident", data => {
      setIncidentClose(true);
      setAlert(`Incident was closed`, "success");
      window.location.reload();
    });
  }, []);

  useEffect(() => {
    newIncident && setIncidentList(prev => [newIncident, ...prev]);
  }, [newIncident]);

  useEffect(() => {
    getIncidents();
    setIncidentList(incidents);
  }, [incidentClose]);

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className=' smooth_loading'>
        {/* reported incidents */}

        <div>
          <SocketContext.Consumer>
            {socket => (
              <Incidents incidentList={incidentList} socket={socket} />
            )}
          </SocketContext.Consumer>
        </div>
      </div>
    </Fragment>
  );
};

IncidentsMain.propTypes = {
  getIncidents: PropTypes.func.isRequired,
  incidents: PropTypes.array.isRequired,

  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  incident: state.incident,
});

const mapDispatchToProps = { getIncidents, setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(IncidentsMain);
