import React from "react";

const DispatchStatus = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        paddingRight: 10,
        paddingLeft: 10,
        height: "100%",
      }}
    >
      <button
        className="btn  btn-light"
        style={{ width: 130, padding: "10px" }}
        // onClick={() => {
        //   setShow(true);
        // }}
      >
        <i class="fa fa-ambulance" aria-hidden="true"></i>
        <span style={{ paddingLeft: "10px" }}>En Route</span>
      </button>
      <p className="regularBlack">--></p>
      <button
        className="btn btn-mid"
        style={{ width: 130, padding: "10px" }}
        // onClick={() => {
        //   setShow(true);
        // }}
      >
        <i class="fa fa-stethoscope" aria-hidden="true"></i>
        <span style={{ paddingLeft: "10px" }}>On Scene</span>
      </button>
      <p className="regularBlack">--></p>
      <button
        className="btn btn-success"
        style={{ width: 130, padding: "10px" }}
        // onClick={() => {
        //   setShow(true);
        // }}
      >
        <i class="fa fa-hospital-o" aria-hidden="true"></i>
        <span style={{ paddingLeft: "10px" }}>En Route To</span>
      </button>
      <p className="regularBlack">--></p>
      <button
        className="btn btn-primary"
        style={{ width: 130, padding: "10px" }}
        // onClick={() => {
        //   setShow(true);
        // }}
      >
        <i class="fa fa-building" aria-hidden="true"></i>
        <span style={{ paddingLeft: "10px" }}>RTB</span>
      </button>
    </div>
  );
};

export default DispatchStatus;
