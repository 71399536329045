import React from "react";
import { Fragment } from "react";

import SocketContext from "../../SocketContext";
import ActiveIncident from "./ActiveIncident";

export const ActiveIncidentContainer = props => {
  return (
    <Fragment>
      <div>
        <SocketContext.Consumer>
          {socket => <ActiveIncident socket={socket} />}
        </SocketContext.Consumer>
      </div>
    </Fragment>
  );
};

export default ActiveIncidentContainer;
