import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { available } from "../../actions/dispatcher";
import {
  clearIncident,
  incidentClose,
  closeIncident,
} from "../../actions/incident";
import { clearResponder } from "../../actions/responder";

const CloseIncidentModal = ({
  onClose,
  show,
  incident: { incident, incident_handled, handleIncident, createIncidentOpcen },
  incidentClose,
  available,
  clearIncident,
  responder,
  socket,
  closeIncident,
  reportCancelled,
  clearResponder,
  user,
}) => {
  const dispathcer1 = responder[0];

  const [formData, setFormData] = useState({
    valid: "",
    summary: "",
  });
  const ditpatchCheckIn = () => {
    socket.emit("addDispatcher", user);
  };

  const { valid, summary } = formData;

  if (!show) {
    return null;
  }

  const onChange = async (c) =>
    setFormData({ ...formData, [c.target.name]: c.target.value });

  const onSubmit = async (c) => {
    c.preventDefault();

    if (createIncidentOpcen) {
      const incidentId = incident._id;
      const reportedby_userId = incident.user;
      incidentClose({ incidentId, reportedby_userId, valid, summary });
      available();
      clearIncident();
      // Put clear responder
      clearResponder();
      ditpatchCheckIn();
    } else if (handleIncident) {
      const incidentId = incident_handled._id;
      const reportedby_userId = incident_handled?.user;
      const responder = dispathcer1.user._id;

      // Put Incident Close here at database
      closeIncident({ responder, incidentId });
      if (!reportCancelled) {
        incidentClose({ incidentId, reportedby_userId, valid, summary });
        available();
        clearIncident();
        clearResponder();
        ditpatchCheckIn();

        socket.emit("sendCloseIncident", {
          receiverId: incident_handled.user,
          senderId: dispathcer1.user._id,
          report: valid,
          summary: summary,
          opcenName: dispathcer1.opcen.name,
          opcenProfilepic: dispathcer1.profilee.profilepic,
        });
      } else if (reportCancelled) {
        incidentClose({ incidentId, reportedby_userId, valid, summary });
        available();
        clearIncident();
        clearResponder();
        ditpatchCheckIn();

        // socket.emit("sendCloseIncident", {
        //   receiverId: incident_handled.user,
        //   senderId: dispathcer1.user._id,
        //   report: valid,
        //   summary: summary,
        //   opcenName: dispathcer1.opcen.name,
        //   opcenProfilepic: dispathcer1.profilee.profilepic,
        // });
      }
    }
    // window.location.reload();
  };

  return (
    <Fragment>
      {reportCancelled ? (
        <Fragment>
          <div className="modalCloseIncident" onClick={onClose}>
            <div
              className="modal-contentIncidentClose"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="sub-container" style={{ height: "80%" }}>
                <h3 className=" text-red" style={{ textAlign: "center" }}>
                  REPORT CANCELLED
                </h3>

                <p>
                  REASON:{"  "}
                  {reportCancelled.reason}
                </p>

                <form className="form" onSubmit={(c) => onSubmit(c)}>
                  <div className="form-group">
                    <select
                      name="valid"
                      value={valid}
                      onChange={(c) => onChange(c)}
                      required
                    >
                      <option value="0"> * Incident/accident valid? </option>{" "}
                      <option value="No">No</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <textarea
                      name="summary"
                      cols="30"
                      rows="5"
                      placeholder="Please give a short summary"
                      value={summary}
                      onChange={(c) => onChange(c)}
                      required
                    ></textarea>
                  </div>
                  <input
                    type="submit"
                    // value=' CONFIRM CLOSE'
                    className="btn-full btn-danger "
                  />
                  <button onClick={onClose} className="btn-full  btn-success">
                    <p style={{ fontSize: "16px" }}> Cancel</p>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="modalCloseIncident" onClick={onClose}>
          <div
            className="modal-contentIncidentClose"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="sub-container" style={{ height: "80%" }}>
              <h3 className=" text-primary">
                Are your sure you want to close the incident?
              </h3>

              <small style={{ color: "red" }}>* = required field</small>
              <form className="form" onSubmit={(c) => onSubmit(c)}>
                <div className="form-group">
                  <select
                    name="valid"
                    value={valid}
                    onChange={(c) => onChange(c)}
                    required
                  >
                    <option value="0"> * Incident/accident valid? </option>{" "}
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div className="form-group">
                  <textarea
                    name="summary"
                    cols="30"
                    rows="5"
                    placeholder="Please give a short summary"
                    value={summary}
                    onChange={(c) => onChange(c)}
                    required
                  ></textarea>
                </div>
                <input
                  type="submit"
                  // value=' CONFIRM CLOSE'
                  className="btn-full btn-danger "
                />
                <button onClick={onClose} className="btn-full  btn-success">
                  <p style={{ fontSize: "16px" }}> Cancel</p>
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

CloseIncidentModal.propTypes = {
  incident: PropTypes.object.isRequired,
  incidentClose: PropTypes.func.isRequired,
  responder: PropTypes.array.isRequired,
  closeIncident: PropTypes.func.isRequired,
  clearResponder: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  incident: state.incident,
  responder: state.responder.responder,
  user: state.auth.user._id,
});

export default connect(mapStateToProps, {
  incidentClose,
  available,
  clearIncident,
  clearResponder,
  closeIncident,
})(CloseIncidentModal);
