import {
  GET_PROFILE,
  PROFILE_ERROR,
  CLEAR_PROFILE,
  UPDATE_PROFILE,
  GET_REPOS,
  GET_PROFILES,
  GET_PROFILE_BY_INCIDENT_USER_ID,
  GET_PROFILE_CRUD,
  CLEAR_PROFILE_CRUD,
} from "../actions/types";

const initialState = {
  profile: null,
  profiles: [],
  profileCRUD: null,
  // repos: [],
  incidentProfile: null,
  loading: true,
  error: {},
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_PROFILE:
    case UPDATE_PROFILE:
      return {
        ...state,
        profile: payload,
        loading: false,
      };
    case CLEAR_PROFILE_CRUD:
      return {
        ...state,
        profileCRUD: null,
        loading: false,
      };
    case GET_PROFILE_CRUD:
      return {
        ...state,
        profileCRUD: payload,
        loading: false,
      };

    case GET_PROFILE_BY_INCIDENT_USER_ID:
      return {
        ...state,
        incidentProfile: payload,
        loading: false,
      };

    case GET_PROFILES:
      return {
        ...state,
        profiles: payload,
        loading: false,
      };
    case PROFILE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        // profile: null,
      };
    case CLEAR_PROFILE:
      return {
        ...state,
        profile: null,
        profiles: [],
        profileCRUD: null,
        incidentProfile: null,
        loading: true,
        error: {},
      };
    case GET_REPOS:
      return {
        ...state,
        repos: payload,
        loading: false,
      };
    default:
      return state;
  }
}
