import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import { putOpcen } from "../../actions/users";
import { suspendUser } from "../../actions/suspend_user";

const SelectedVolunteers = ({
  users,
  suspendUser,
  suspendedusers,
  issuing_opcen,
}) => {
  const refreshPage = () => {
    setTimeout(function () {
      window.location.reload();
    }, 2 * 1000);
  };

  // Filter Array within an Array compare two array with same value
  const filter = users.filter(
    el => !suspendedusers.find(({ user_id }) => el._id === user_id._id)
  );

  const responder = filter.map((res, i) => (
    <tr key={i + 1}>
      <td>
        <Link to={`/profile/${res._id}`}>
          <p style={{ fontSize: "12px", fontWeight: "600" }}>{res.name}</p>
        </Link>
      </td>
      <td>{res.lname}</td>
      <td>{res.number}</td>
      <td>{res.email}</td>
      <td style={{ textAlign: "center" }}>
        <button
          onClick={() => {
            // Put action Opcen at users  operation_center table
            // putOpcen(user_id, res._id);
            suspendUser(res._id, res.email, issuing_opcen);
            refreshPage();
          }}
          className=' btn_table btn-danger'
        >
          <i className='fa fa-plus-square-o' aria-hidden='true'></i> Suspend
        </button>
      </td>
    </tr>
  ));

  return (
    <Fragment>
      <div
        style={{
          overflow: "hidden",
          overflowY: "scroll",
          height: "100%",
        }}
      >
        <table className='commonTable'>
          <thead>
            <tr>
              <th> Name</th>
              <th> Last Name</th>
              <th> Mobile Number</th>
              <th> Email</th>
              <th>Action</th>
            </tr>
          </thead>
          {/* Loop thru the database */}

          <tbody>{responder}</tbody>
        </table>
      </div>
    </Fragment>
  );
};

SelectedVolunteers.propTypes = {
  users: PropTypes.array.isRequired,
  user_id: PropTypes.object.isRequired,
  suspendUser: PropTypes.func.isRequired,
  issuing_opcen: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  user_id: state.auth.user._id,
  issuing_opcen: state.opcen.opcen._id,
});

const mapDispatchToProps = { suspendUser };

export default connect(mapStateToProps, mapDispatchToProps)(SelectedVolunteers);
