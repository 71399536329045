import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import AddAnnouncementModal from "./AddAnnouncementModal";
import { getAnnoucement } from "../../actions/annoucement";
import AnnouncementList from "./AnnouncementList";

const Announcement = ({ getAnnoucement, announcement }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    getAnnoucement();
  }, [getAnnoucement]);

  const showAnnouncementModal = () => {
    setShow(true);
  };
  return (
    <Fragment>
      <div
        className='main-container align-vertically  '
        style={{
          height: "720px",
          width: "1280px",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <p className='Htitle'>Announcements running:</p>
        </div>
        <AnnouncementList annoucement={announcement} />
      </div>

      <div className='add_btn'>
        <div
          style={{
            width: "80px",
            height: "80px",
            background: "#215a75",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            borderRadius: "50%",
            opacity: ".8",
          }}
          onClick={showAnnouncementModal}
        >
          <i class='fa fa-plus' aria-hidden='true'></i>
        </div>
      </div>
      <AddAnnouncementModal show={show} onClose={() => setShow(false)} />
    </Fragment>
  );
};

Announcement.propTypes = {
  getAnnoucement: PropTypes.func.isRequired,
  announcement: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  announcement: state.announcement.announcements,
});

const mapDispatchToProps = { getAnnoucement };

export default connect(mapStateToProps, mapDispatchToProps)(Announcement);
