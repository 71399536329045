import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { format } from "timeago.js";
import Moment from "react-moment";
import { getIncident } from "../../actions/incident";
import IncidentForceCloseModal from "./IncidentForceCloseModal";

const Incidents = ({ incidentList, getIncident, forceCloseIncident }) => {
  const [show, setShow] = useState(false);

  const incidentsList = incidentList
    .filter((inc) => inc.close_incident.length === 0)
    .map((inc) => (
      <tr key={inc._id}>
        <td
          className={
            inc.type === "Medical"
              ? "table_td_medical"
              : inc.type === "Fire"
              ? "table_td_fire"
              : inc.type === "Crime"
              ? "table_td_crime"
              : inc.type === "Covid"
              ? "table_td_corona"
              : "table_td_general"
          }
          style={{
            width: "68px",
            padding: "3px",
            alignItems: "center",
          }}
        >
          {inc.type === "Medical" ? (
            <img
              className="table_img"
              alt="Medical"
              src="/icons/incidentType/Medical.png"
            />
          ) : inc.type === "Fire" ? (
            <img
              className="table_img"
              alt="Fire"
              src="/icons/incidentType/Fire.png"
            />
          ) : inc.type === "Crime" ? (
            <img
              className="table_img"
              alt="Crime"
              src="/icons/incidentType/Police.png"
            />
          ) : inc.type === "Covid" ? (
            <img
              className="table_img"
              alt="Corona"
              src="/icons/incidentType/Covid-res.png"
            />
          ) : (
            <img
              className="table_img"
              alt="General"
              src="/icons/incidentType/General.png"
            />
          )}{" "}
        </td>
        <td
          className={
            inc.type === "Medical"
              ? "table_td_medical"
              : inc.type === "Fire"
              ? "table_td_fire"
              : inc.type === "Crime"
              ? "table_td_crime"
              : inc.type === "Covid"
              ? "table_td_corona"
              : "table_td_general"
          }
          style={{ textAlign: "center" }}
        >
          <h3>
            {inc.scompleteaddress.substring(
              inc.scompleteaddress.length - 19,
              inc.scompleteaddress
            )}
          </h3>
        </td>
        <td
          className={
            inc.type === "Medical"
              ? "table_td_medical"
              : inc.type === "Fire"
              ? "table_td_fire"
              : inc.type === "Crime"
              ? "table_td_crime"
              : inc.type === "Covid"
              ? "table_td_corona"
              : "table_td_general"
          }
          style={{ textAlign: "center" }}
        >
          <Moment format="LT">{inc.date}</Moment> | {format(inc.date)}
        </td>
        <td
          className={
            inc.type === "Medical"
              ? "table_td_medical"
              : inc.type === "Fire"
              ? "table_td_fire"
              : inc.type === "Crime"
              ? "table_td_crime"
              : inc.type === "Covid"
              ? "table_td_corona"
              : "table_td_general"
          }
          style={{ textAlign: "center", padding: "5px" }}
        >
          {inc.name} {inc.lname}
        </td>
        <td
          className={
            inc.type === "Medical"
              ? "table_td_medical"
              : inc.type === "Fire"
              ? "table_td_fire"
              : inc.type === "Crime"
              ? "table_td_crime"
              : inc.type === "Covid"
              ? "table_td_corona"
              : "table_td_general"
          }
          style={{ textAlign: "center", padding: "5px" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "60%",
              padding: "3px",
              justifyContent: "center",
              borderRadius: "2px",
              // color:
              background: "#28a745",
            }}
          >
            <p>{inc.status}</p>
          </div>
        </td>

        <td
          className={
            inc.type === "Medical"
              ? "table_td_medical"
              : inc.type === "Fire"
              ? "table_td_fire"
              : inc.type === "Crime"
              ? "table_td_crime"
              : inc.type === "Covid"
              ? "table_td_corona"
              : "table_td_general"
          }
          style={{ textAlign: "center" }}
        >
          {/* {inc.dispatcher.date} */}
        </td>
        <td
          className={
            inc.type === "Medical"
              ? "table_td_medical"
              : inc.type === "Fire"
              ? "table_td_fire"
              : inc.type === "Crime"
              ? "table_td_crime"
              : inc.type === "Covid"
              ? "table_td_corona"
              : "table_td_general"
          }
          style={{ textAlign: "center", margin: "3px" }}
        >
          <button
            onClick={() => {
              setShow(true);
              getIncident(inc._id);
            }}
            className="btn btn-danger"
          >
            Manual Close
          </button>
        </td>
      </tr>
    ));

  return (
    <Fragment>
      <div style={{}}>
        <div
          style={{
            overflowY: "scroll",
            overflow: "auto",

            height: 390,
          }}
        >
          <table className="commonTable">
            <thead>
              <tr>
                <th>Type</th>
                <th className="hide-sm">Location</th>
                <th className="hide-sm">Time</th>
                <th>Volunteer</th>
                <th>Status</th>
                <th>Dispatch</th>
                <th>Action</th>
              </tr>
            </thead>
            {/* Loop thru the database */}
            <tbody>{incidentsList}</tbody>
          </table>
        </div>
      </div>

      <IncidentForceCloseModal
        onClose={() => setShow(false)}
        show={show}
        forceCloseIncident={forceCloseIncident}
      />
    </Fragment>
  );
};

Incidents.propTypes = {
  getIncident: PropTypes.func.isRequired,
  forceCloseIncident: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  forceCloseIncident: state.incident.incident,
});

const mapDispatchToProps = { getIncident };

export default connect(mapStateToProps, mapDispatchToProps)(Incidents);
