import React from "react";
import { Fragment } from "react";
import SocketContext from "../../SocketContext";
import IncidentMessenger from "./IncidentMessenger";

export const IncidentMessengerContainer = props => {
  return (
    <Fragment>
      <div>
        <SocketContext.Consumer>
          {socket => <IncidentMessenger socket={socket} />}
        </SocketContext.Consumer>
      </div>
    </Fragment>
  );
};

export default IncidentMessengerContainer;
