import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import { getResponderbyOpcen } from "../../actions/responder";
import { DirectionsService, GoogleMap } from "@react-google-maps/api";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;

const ResponderOnline = ({
  socket,
  responders,
  currentId,
  setCurrentChat,
  getResponderbyOpcen,
  opcenId,
  volunteer,
  incident_handled,
}) => {
  const [onlineResponders, setOnlineResponders] = useState([]);
  const [dispatched, setDispatched] = useState(false);
  const [responder, setResponder] = useState();
  const [responderLocation, setResponderLocation] = useState();
  // State without the current user
  const [onlineRespondersUser, setOnlineRespondersUser] = useState([]);

  useEffect(() => {
    getResponderbyOpcen(opcenId);
  }, []);

  useEffect(() => {
    socket.emit("getOnlineUsers");
  }, []);

  useEffect(() => {
    socket.on("getUsers", (data) => {
      setOnlineResponders(
        data.filter((res) => res.user !== currentId && res.user !== volunteer)
      );
    });
  }, []);

  useEffect(() => {
    socket.on("getLocation", (data) => {
      console.log("Location Data", data);
      // setResponderLocation(data.);
      // setResponder(user);
    });
  }, []);

  useEffect(() => {
    setOnlineRespondersUser(
      responders?.filter((res) =>
        onlineResponders.some((u) => u.user === res.user._id)
      )
    );
  }, [responders, onlineResponders]);

  const dispatch = (responder) => {
    socket.emit("sendDispatch", {
      dispatcherId: currentId,
      responderId: responder,
      incident_handled,
    });
  };

  const handleClick = async (user) => {
    try {
      const res = await axios(`/api/conversation_res/${currentId}/${user}`);

      if (res.data === null) {
        console.log("no Coversation ");
        const conversation = {
          senderId: currentId,
          receiverId: user,
        };

        try {
          const res = await axios.post("/api/conversation_res", conversation);

          // emit to socket new conversation started
        } catch (err) {
          console.log(err);
        }
      } else {
        setCurrentChat(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  console.log("RSPONDER ONLINE", onlineRespondersUser);

  return (
    <Fragment>
      <div
        style={{
          background: "#333",
          width: "100%",
          height: "8%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "30%",
            textAlign: "center",
          }}
        >
          Responder Name
        </div>
        <div
          style={{
            width: "50%",
            textAlign: "center",
          }}
        >
          Status
        </div>
        <div
          style={{
            width: "20%",
            textAlign: "center",
          }}
        >
          Actions
        </div>
      </div>
      <div style={{ background: "#ddd", width: "100%", height: "95%" }}>
        {onlineRespondersUser?.map(
          (res) => (
            console.log(res.user._id),
            (
              <div
                className="chatResponderOnline"
                onClick={() => {
                  handleClick(res.user._id);
                }}
              >
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    height: "100%",

                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      height: 50,
                      width: 50,
                      padding: 2,
                      borderRadius: "50%",
                    }}
                    src={`${serverURI}/${res.profilee.profilepic}`}
                    alt="responder image"
                  />
                  <p style={{ paddingLeft: 6 }}></p> {res.user.name}{" "}
                  {res.user.lname}
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    height: "100%",

                    justifyContent: "space-evenly",
                  }}
                >
                  {dispatched ? (
                    <div>
                      <button
                        className="btn  btn-light"
                        style={{ width: 130, padding: "10px" }}
                      >
                        Chat
                      </button>

                      <button
                        className="btn  btn-light"
                        style={{ width: 130, padding: "10px" }}
                      >
                        Call
                      </button>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        height: "100%",

                        justifyContent: "space-evenly",
                      }}
                    >
                      <div>
                        <p>Current Location</p>
                      </div>
                      <div>
                        <p>ETA</p>
                      </div>
                    </div>
                  )}
                </div>
                {dispatched ? (
                  <button
                    className="btn  btn-danger"
                    style={{ width: 130, padding: "10px" }}
                  >
                    {" "}
                    Recall{" "}
                  </button>
                ) : (
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      height: "100%",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <button
                      className="btn  btn-success"
                      style={{ width: 130, padding: "10px" }}
                      onClick={() => {
                        dispatch(res.user._id);
                      }}
                    >
                      Dispatch
                    </button>
                  </div>
                )}
              </div>
            )
          )
        )}
      </div>
    </Fragment>
  );
};

ResponderOnline.propTypes = {
  responders: PropTypes.array.isRequired,
  getResponderbyOpcen: PropTypes.func.isRequired,
  opcenId: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  responders: state.responder.repondersOpcen,
  opcenId: state.opcen.opcen._id,
});

const mapDispatchToProps = { getResponderbyOpcen };

export default connect(mapStateToProps, mapDispatchToProps)(ResponderOnline);
