import {
  CREATE_INCIDENT,
  CREATE_INCIDENT_OPCEN,
  INCIDENT_SUMMIT_SUCCESS,
  GET_INCIDENTS,
  CLEAR_INCIDENT,
  GET_INCIDENT_BY_NUMBER_DATE,
  GET_INCIDENT_BY_NUMBER_DATE_FAIL,
  PUT_INCIDENT_DISPATCHER,
  HANDLE_INCIDENT,
  INCIDENT_CLOSE,
  VOLUNEER_INCIDENT_CLOSE,
  PUT_INCIDENT_CLOSE,
  GET_CLOSE_INCIDENT_BY_USER,
  INCIDENT_CANCELLED,
  GET_CANCELLED_INCIDENT_BY_USER,
  GET_INCIDENT_TODAY,
  GET_INCIDENT_TODAY_FAIL,
  GET_INCIDENT_CANCELLED,
  GET_INCIDENT_CANCELLED_FAIL,
  GET_INCIDENT_CLOSED,
  GET_INCIDENT_CLOSED_FAIL,
  GET_INCIDENT_BY_ID,
  GET_INCIDENT_BY_ID_FAIL,
  SAVE_MISSED_CALL,
  SAVE_MISSED_CALL_FAIL,
} from "../actions/types";

const initialState = {
  createIncident: false,
  createIncidentOpcen: false,
  type: null,
  incident: null,
  incident_handled: null,
  incidents: null,
  incidentbyopcen: null,
  incidentbyuser: null,
  incidentclose: null,
  incidentcancelled: false,
  usercancelled: null,
  loading: true,
  handleIncident: false,
  putincidentdispatcher: null,
  incidenttoday: null,
  incidentCloseCount: null,
  incidentCancelledCount: null,
  missedCall: null,
  missedCalls: null,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_INCIDENT_BY_ID:
      return {
        ...state,
        incident: payload,
        loading: false,
      };
    case GET_INCIDENT_CLOSED:
      return {
        ...state,
        incidentCloseCount: payload,
        loading: false,
      };
    case GET_INCIDENT_CANCELLED:
      return {
        ...state,
        incidentCancelledCount: payload,
        loading: false,
      };
    case CREATE_INCIDENT:
      return {
        ...state,
        createIncident: true,
        type: payload, //from the action file
        loading: false,
      };
    case INCIDENT_CANCELLED:
      return {
        ...state,
        incidentcancelled: payload,
        loading: false,
      };
    case GET_INCIDENT_TODAY:
      return {
        ...state,
        incidenttoday: payload,
        loading: false,
      };
    case GET_CANCELLED_INCIDENT_BY_USER:
      return {
        ...state,
        usercancelled: payload,
        loading: false,
      };
    case CREATE_INCIDENT_OPCEN:
      return {
        ...state,
        createIncidentOpcen: true,
        loading: false,
      };

    case GET_CLOSE_INCIDENT_BY_USER:
      return {
        ...state,
        incidentclose: payload,
        loading: false,
      };
    case HANDLE_INCIDENT:
      return {
        ...state,
        handleIncident: true,
        loading: false,
      };

    case PUT_INCIDENT_DISPATCHER:
      return {
        ...state,
        putincidentdispatcher: payload,
        loading: false,
      };
    case INCIDENT_CLOSE:
      return {
        ...state,
        incidentclose: payload,
      };
    case VOLUNEER_INCIDENT_CLOSE:
      return {
        ...state,
        incidentclose: payload,
      };
    case GET_INCIDENTS:
      return {
        ...state,
        createIncident: false,
        incidents: payload,
        loading: false,
      };
    case GET_INCIDENT_BY_NUMBER_DATE:
      return {
        ...state,
        incident_handled: payload,
        loading: false,
      };
    case INCIDENT_SUMMIT_SUCCESS:
      return {
        ...state,
        createIncident: true,
        incident: payload, //from the action file
        loading: false,
      };
    case SAVE_MISSED_CALL:
      return {
        ...state,
        missedCall: payload, //from the action file
        loading: false,
      };
    case CLEAR_INCIDENT:
    case GET_INCIDENT_BY_NUMBER_DATE_FAIL:
    case PUT_INCIDENT_CLOSE:
    case GET_INCIDENT_TODAY_FAIL:
    case GET_INCIDENT_CANCELLED_FAIL:
    case GET_INCIDENT_CLOSED_FAIL:
    case GET_INCIDENT_BY_ID_FAIL:
    case SAVE_MISSED_CALL_FAIL:
      return {
        createIncident: false,
        createIncidentOpcen: false,
        type: null,
        incident: null,
        incident_handled: null,
        incidents: null,
        incidentbyopcen: null,
        incidentbyuser: null,
        incidentclose: null,
        incidentcancelled: null,
        loading: true,
        incidenttoday: null,
        incidentCloseCount: null,
        incidentCancelledCount: null,
        handleIncident: false,
        missedCall: null,
        missedCalls: null,
      };

    default:
      return state;
  }
}
