import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Fragment } from "react";
import CloseIncidentModal from "./CloseIncidentModal";

const ActionsMain = ({ socket }) => {
  const [show, setShow] = useState(false);
  const [reportCancelled, setReportCanclled] = useState();

  useEffect(() => {
    socket.on("getCancelledIncident", (data) => {
      console.log("hitttttttttttttttttt cancelled");
      setShow(true);
      setReportCanclled({
        receiverId: data.receiverId,
        senderId: data.senderId,
        reason: data.reason,
        status: true,
      });
    });
  }, []);

  return (
    <Fragment>
      <div className="actionMain">
        <button
          className="btn btn-danger"
          style={{ width: "90%", padding: "10px" }}
          onClick={() => {
            setShow(true);
          }}
        >
          <i class="fas fa-plus"></i>{" "}
          <span style={{ paddingLeft: "10px" }}>CLOSE INCIDENT</span>
        </button>
      </div>
      <CloseIncidentModal
        onClose={() => setShow(false)}
        show={show}
        socket={socket}
        reportCancelled={reportCancelled}
      />
    </Fragment>
  );
};

ActionsMain.propTypes = {
  incident: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsMain);
