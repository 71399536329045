import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  setVolunteerLayout,
  setResponderLayout,
  setAdminLayout,
} from "../../actions/auth";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { logout, go_offlline } from "../../actions/auth";
import { setAlert } from "../../actions/alert";

const LogInAs = ({
  onClose,
  show,
  auth: { isAuthenticated, isOpcenAdmin, isResponder, user },
  setVolunteerLayout,
  setResponderLayout,
  setAdminLayout,
  logout,
  go_offlline,
  socket,
  userObj,
}) => {
  const [connect, setConnection] = useState(false);

  const email = user?.email;

  useEffect(() => {
    if (localStorage.token && userObj !== null) {
      const user = userObj._id;
      socket.emit("addUser", user);
    }
    // eslint-disable-next-line
  }, [userObj]);

  if (!userObj) {
    return null;
  }

  if (!show) {
    return null;
  }

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const socketLogOut = async () => {
    go_offlline(email);
    await delay(2000);
    socket.emit("logout", socket.id);
    logout();
  };

  return (
    <Fragment>
      <div className='menu_small' onClick={onClose}>
        <div className='menu_small_content' onClick={e => e.stopPropagation()}>
          <div className='menu_container' onClick={onClose}>
            <Link
              to='/posts'
              className='menu-item'
              onClick={() => setVolunteerLayout()}
            >
              <i className='fa fa-child' aria-hidden='true'></i>
              <span style={{ padding: "8px 0px 8px 15px" }}> Volunteer</span>
            </Link>

            {isResponder ? (
              <Link
                to='/posts'
                className='menu-item'
                onClick={() => {
                  setResponderLayout();
                }}
              >
                <i className='fa fa-id-badge' aria-hidden='true'></i>
                <span style={{ padding: "8px 0px 8px 15px" }}> Responder</span>
              </Link>
            ) : null}

            {isOpcenAdmin ? (
              <Fragment>
                <Link
                  to='/operation-center'
                  className='menu-item'
                  onClick={() => setAdminLayout()}
                >
                  <i className='fa fa-shield' aria-hidden='true'></i>
                  <span style={{ padding: "8px 0px 8px 15px" }}>
                    {" "}
                    Administrator
                  </span>
                </Link>
              </Fragment>
            ) : null}

            <Link className='menu-item' onClick={() => socketLogOut()} to='/'>
              <i className='fas fa-sign-out-alt' />{" "}
              <span style={{ padding: "8px 0px 8px 15px" }}> Logout </span>
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

LogInAs.propTypes = {
  auth: PropTypes.object.isRequired,
  setVolunteerLayout: PropTypes.func.isRequired,
  setResponderLayout: PropTypes.func.isRequired,
  setAdminLayout: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  userObj: PropTypes.object,
  setAlert: PropTypes.func.isRequired,
  go_offlline: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  userObj: state.auth.user,
});

const mapDispatchToProps = {
  setVolunteerLayout,
  setResponderLayout,
  setAdminLayout,
  logout,
  setAlert,
  go_offlline,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogInAs);
