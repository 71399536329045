import React, { useEffect } from "react";
import { Fragment } from "react";
import SocketContext from "../../SocketContext";
import DispatchMain from "./DispatchMain";
import { getResponders } from "../../actions/responder";
import PropTypes from "prop-types";
import { connect } from "react-redux";

export const DispatchContainer = ({ getResponders }) => {
  useEffect(() => {
    getResponders();
  });

  // Put you dont have a dispatchers rigths

  return (
    <Fragment>
      <div>
        <SocketContext.Consumer>
          {socket => <DispatchMain socket={socket} />}
        </SocketContext.Consumer>
      </div>
    </Fragment>
  );
};

DispatchContainer.propTypes = {
  getResponders: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { getResponders })(DispatchContainer);
