import {
  CHECK_IN_DISPATCH,
  GET_ONLINE_DISPATCH_BY_OPCEN_ID,
  GET_ONLINE_DISPATCH_BY_RESPONDER_ID,
  GET_ONLINE_DISPATCH_ALL,
  CHECK_OUT_DISPATCH,
  DISPATCH_ON_CALL,
  DISPATCH_AVAILABLE,
  CLEAR_DISPATCHER,
  GET_ALL_DISPATCHERS,
  GET_ALL_DISPATCHERS_ERROR,
} from "../actions/types";

const initialState = {
  checked_inDispatcher: null,
  checkOutDispatcher: null,
  onlineDispatcherByOpcen: null,
  onlineDispatcherByResponderID: null,
  onlineDispatcherAll: null,
  allDispatchers: null,
  loading: true,
  checkInStatus: false,
  onCall: false,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CHECK_IN_DISPATCH:
      return {
        ...state,
        checkInStatus: true,
        checked_inDispatcher: payload, //from the action file
        loading: false,
      };
    case GET_ONLINE_DISPATCH_BY_OPCEN_ID:
      return {
        ...state,
        onlineDispatcherByOpcen: payload,
        loading: false,
      };
    case DISPATCH_ON_CALL:
      return {
        ...state,
        onCall: payload,
        loading: false,
      };
    case GET_ALL_DISPATCHERS:
      return {
        ...state,
        allDispatchers: payload,
        loading: false,
      };
    case DISPATCH_AVAILABLE:
      return {
        ...state,
        onCall: payload,
        loading: false,
      };
    case GET_ONLINE_DISPATCH_BY_RESPONDER_ID:
      return {
        ...state,
        createIncident: true,
        onlineDispatcherByResponderID: payload, //from the action file
        loading: false,
      };
    case GET_ONLINE_DISPATCH_ALL:
      return {
        ...state,
        onlineDispatcherAll: payload,
        loading: false,
      };

    case CHECK_OUT_DISPATCH:
      return {
        ...state,
        checkInStatus: false,
        checked_inDispatcher: null, //from the action file
        loading: false,
      };
    case CLEAR_DISPATCHER:
    case GET_ALL_DISPATCHERS_ERROR:
      return {
        checked_inDispatcher: null,
        checkOutDispatcher: null,
        onlineDispatcherByOpcen: null,
        onlineDispatcherByResponderID: null,
        onlineDispatcherAll: null,
        allDispatchers: null,
        loading: true,
        checkInStatus: false,
        onCall: false,
        error: {},
      };

    default:
      return state;
  }
}
