import React, { Fragment, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import { setAlert } from "../../actions/alert";

const Login = ({
  login,
  auth: { isAuthenticated, isOpcenAdmin },
  createOpcen,
}) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChange = evnt => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const { email, password } = formData;

  const onChange = async c =>
    setFormData({ ...formData, [c.target.name]: c.target.value });

  const onSubmit = async c => {
    c.preventDefault();
    login(email, password);
  };

  if (isAuthenticated && createOpcen) {
    return <Redirect to='/create-operation-center' />;
  }

  if (isAuthenticated && !isOpcenAdmin) {
    return <Redirect to='/posts' />;
  }

  return (
    <Fragment>
      <div className=' main-container align-vertically'>
        <div className='sub-container'>
          {createOpcen ? (
            <p className='alert alert-danger '>
              Log-in or create an account first{" "}
            </p>
          ) : null}

          {/* <p className='lead'>
            <i className='fas fa-user'></i> Sign into your Account
          </p> */}
          <form className='form' onSubmit={c => onSubmit(c)}>
            <div className='form-group'>
              <input
                type='email'
                placeholder='Email Address'
                name='email'
                value={email}
                onChange={c => onChange(c)}
                autoComplete='username email'
              />
            </div>
            <div className='form-group'>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#aaa",
                }}
              >
                <div
                  onClick={togglePassword}
                  style={{
                    position: "absolute",
                    right: "7px",
                    width: "20px",
                    height: "20px",
                  }}
                >
                  <i class='fa fa-eye' aria-hidden='true'></i>
                </div>

                <input
                  type={passwordType}
                  placeholder='Password'
                  name='password'
                  value={password}
                  onChange={c => onChange(c)}
                  // autocomplete='off'
                  autoComplete='current-password'
                  minLength='8'
                />
              </div>
            </div>

            <input
              type='submit'
              className='btn btn-primary'
              style={{ borderRadius: "5px" }}
              value='Log-in'
            />
          </form>
          <p className='' style={{ fontSize: "13px", marginTop: "8px" }}>
            Don't have an account? <Link to='/register'>Register</Link>
          </p>
          <p className='' style={{ fontSize: "13px" }}>
            Forgot passsword? <Link to='/forgot_pass'>Forgot</Link>
          </p>
        </div>
      </div>
    </Fragment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  createOpcen: PropTypes.bool,
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  createOpcen: state.opcen.createOpcen,
  auth: state.auth,
});

export default connect(mapStateToProps, { login, setAlert })(Login);
