import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { format } from "timeago.js";
import axios from "axios";
import { Link } from "react-router-dom";

export const Messages = ({ message, own }) => {
  const [responder, setResponder] = useState([]);

  const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;

  // Convert to redux
  useEffect(() => {
    const getResponerbyId = async () => {
      try {
        const res = await axios(`/api/responder/user/${message.sender}`);
        setResponder(res.data);
      } catch (err) {
        console.error(err.message);
      }
    };
    getResponerbyId();
  }, [message]);

  return (
    <Fragment>
      <div className={own ? "chatMessages own" : "chatMessages"}>
        {responder.map(
          (res, i) =>
            !own && (
              <div key={i} className='conversation'>
                <img
                  className='converationImg'
                  src={`${serverURI}/${res.profilee.profilepic}`}
                  alt=''
                />
              </div>
            )
        )}

        {message.text.substring(0, 4) === "Chat" ? (
          message.text.substring(
            message.text.length - 3,
            message.text.length
          ) === "mp4" ? (
            <Link to={`${serverURI}/${message.text}`}>
              <video
                src={`${serverURI}/${message.text}`}
                className={"videoChat"}
                alt='SentVideo'
                type='video/mp4'
                controls
              />
              <div className='time'>{format(message.createdAt)}</div>
            </Link>
          ) : (
            <Link to={`${serverURI}/${message.text}`}>
              <img
                src={`${serverURI}/${message.text}`}
                className={"photoChat"}
                alt='SentPhoto'
              />
              <div className='time'>{format(message.createdAt)}</div>
            </Link>
          )
        ) : (
          <div>
            <span className=' chatTxt  '>{message.text}</span>
            <div className='time'>{format(message.createdAt)}</div>
          </div>
        )}
        <audio autoPlay src='/sound/sound2.mp3'></audio>
      </div>
    </Fragment>
  );
};

Messages.propTypes = {
  responder: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  responder: state.responder,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
