import axios from "axios";
import {
  CREATE_INCIDENT,
  CREATE_INCIDENT_OPCEN,
  ERROR_CREATE_INCIDENT,
  INCIDENT_SUMMIT_SUCCESS,
  INCIDENT_SUMMIT_FAIL,
  GET_INCIDENTS,
  GET_INCIDENTS_FAIL,
  CLEAR_INCIDENT,
  GET_INCIDENT_BY_OPCEN,
  GET_INCIDENT_BY_OPCEN_FAIL,
  GET_INCIDENT_BY_USER,
  GET_INCIDENT_BY_USER_FAIL,
  GET_INCIDENT_BY_NUMBER_DATE,
  GET_INCIDENT_BY_NUMBER_DATE_FAIL,
  PUT_INCIDENT_DISPATCHER,
  HANDLE_INCIDENT,
  INCIDENT_CLOSE,
  VOLUNEER_INCIDENT_CLOSE,
  PUT_INCIDENT_CLOSE,
  GET_CLOSE_INCIDENT_BY_USER,
  INCIDENT_CANCELLED,
  GET_CANCELLED_INCIDENT_BY_USER,
  GET_INCIDENT_TODAY,
  GET_INCIDENT_TODAY_FAIL,
  GET_INCIDENT_CANCELLED,
  GET_INCIDENT_CANCELLED_FAIL,
  GET_INCIDENT_CLOSED,
  GET_INCIDENT_CLOSED_FAIL,
  GET_INCIDENT_BY_ID,
  GET_INCIDENT_BY_ID_FAIL,
  SAVE_MISSED_CALL,
  SAVE_MISSED_CALL_FAIL,
} from "./types";
import { setAlert } from "./alert";

export const setCreateIncident = (incidentType) => (dispatch) => {
  try {
    dispatch({
      type: CREATE_INCIDENT,
      payload: incidentType,
    });
  } catch (err) {
    dispatch({
      type: ERROR_CREATE_INCIDENT,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const handleIncident = () => (dispatch) => {
  try {
    dispatch({
      type: HANDLE_INCIDENT,
    });
  } catch (err) {
    dispatch({
      type: ERROR_CREATE_INCIDENT,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const incidentToday = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/incident/today/date`);
    dispatch({
      type: GET_INCIDENT_TODAY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_INCIDENT_TODAY_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const create_incident_opcen = () => (dispatch) => {
  try {
    dispatch({
      type: CREATE_INCIDENT_OPCEN,
    });
  } catch (err) {
    dispatch({
      type: ERROR_CREATE_INCIDENT,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const volunteerIncidentClose =
  ({ senderId, report, summary, opcenName, opcenProfilepic, status }) =>
  (dispatch) => {
    const body = {
      senderId,
      report,
      summary,
      opcenName,
      opcenProfilepic,
      status,
    };
    try {
      dispatch({
        type: VOLUNEER_INCIDENT_CLOSE,
        payload: body,
      });
    } catch (err) {
      dispatch({
        type: ERROR_CREATE_INCIDENT,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };

export const clearIncident = () => (dispatch) => {
  try {
    dispatch({
      type: CLEAR_INCIDENT,
    });
  } catch (err) {
    dispatch({
      type: ERROR_CREATE_INCIDENT,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const submitIncident =
  ({
    user,
    name,
    lname,
    number,
    type,
    scompleteaddress,
    scity,
    sstate,
    sarea,
    slat,
    slng,
    status,
    reportedDate,
  }) =>
  async (dispatch) => {
    const body = JSON.stringify({
      user,
      name,
      lname,
      number,
      type,
      scompleteaddress,
      scity,
      sstate,
      sarea,
      slat,
      slng,
      status,
      reportedDate,
    });

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post("api/incident", body, config);
      dispatch({
        type: INCIDENT_SUMMIT_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      // const errors = err.response.data.errors;
      // if (errors) {
      //   errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      // }

      console.log("error submiting incident", err);

      dispatch({
        type: INCIDENT_SUMMIT_FAIL,
      });
    }
  };

export const missedCall =
  ({
    user,
    name,
    lname,
    number,
    type,
    scompleteaddress,
    scity,
    sstate,
    sarea,
    slat,
    slng,
    status,
    reportedDate,
  }) =>
  async (dispatch) => {
    const body = JSON.stringify({
      user,
      name,
      lname,
      number,
      type,
      scompleteaddress,
      scity,
      sstate,
      sarea,
      slat,
      slng,
      status,
      reportedDate,
    });

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post("api/incident/missed", body, config);
      dispatch({
        type: SAVE_MISSED_CALL,
        payload: res.data,
      });
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: SAVE_MISSED_CALL_FAIL,
      });
    }
  };

// put Incident

export const putincidentdispatcher =
  ({ incidentId, reportedby_userId, dispatcher_userId, time_received }) =>
  async (dispatch) => {
    const body = JSON.stringify({
      incidentId,
      reportedby_userId,
      dispatcher_userId,
      time_received,
    });

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        "api/incident/incident_dispatcher",
        body,
        config
      );
      dispatch({
        type: PUT_INCIDENT_DISPATCHER,
        payload: res.data,
      });
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        // errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
        errors.forEach((error) => console.log(error));
      }

      dispatch({
        type: INCIDENT_SUMMIT_FAIL,
      });
    }
  };

// Close Incident

export const incidentClose =
  ({ incidentId, reportedby_userId, valid, summary }) =>
  async (dispatch) => {
    const body = JSON.stringify({
      incidentId,
      reportedby_userId,
      valid,
      summary,
    });

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post("api/incident/incident_close", body, config);
      dispatch({
        type: INCIDENT_CLOSE,
        payload: res.data,
      });
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: INCIDENT_SUMMIT_FAIL,
      });
    }
  };

//Get incidents all by user

export const getIncidentByUser = (user_id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/operation_center/myopcen/${user_id}`);
    dispatch({
      type: GET_INCIDENT_BY_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_INCIDENT_BY_USER_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Get incidents all by incident ID

export const getIncidentById = (incident_id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/operation_center/myopcen/${incident_id}`);
    dispatch({
      type: GET_INCIDENT_BY_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_INCIDENT_BY_USER_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Get incidents all by incident ID

export const getIncidentByNumberDate = (reportedDate) => async (dispatch) => {
  console.log("Api", reportedDate);
  try {
    const res = await axios.get(`api/incident/datetime/${reportedDate}`);
    dispatch({
      type: GET_INCIDENT_BY_NUMBER_DATE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_INCIDENT_BY_NUMBER_DATE_FAIL,
      payload: { msg: err.response?.statusText, status: err.response.status },
    });
  }
};

// Get incident by opcen
export const getIncidentByOpcen = (opcen_id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/operation_center/myopcen/${opcen_id}`);
    dispatch({
      type: GET_INCIDENT_BY_OPCEN,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_INCIDENT_BY_OPCEN_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
// get IncidentAll Incident

export const getIncidents = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/incident`);
    dispatch({
      type: GET_INCIDENTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_INCIDENTS_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// get Incident by ID

export const getIncident = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/incident/${id}`);
    dispatch({
      type: GET_INCIDENT_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_INCIDENT_BY_ID_FAIL,
      payload: { msg: err.response, status: err.response.status },
    });
  }
};

//PUT Incident Close

export const closeIncident =
  ({ responder, incidentId }) =>
  async (dispatch) => {
    const body = JSON.stringify({
      responder,
      incidentId,
    });

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.put("/api/incident/close", body, config);

      dispatch({
        type: PUT_INCIDENT_CLOSE,
        payload: res.data,
      });

      dispatch(setAlert("Report Closed...", "success"));
    } catch (err) {
      dispatch({
        type: CLEAR_INCIDENT,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };

// GET CLOSED INCIDENT BY USER ID
// /api/incident/me/close/:userID

export const getCloseIncidentByUserId = (user_id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/incident/me/close/${user_id}`);
    dispatch({
      type: GET_CLOSE_INCIDENT_BY_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_INCIDENT_BY_USER_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Close Incident

export const incidentCancelled =
  ({ incidentId, reportedby_userId, reason }) =>
  async (dispatch) => {
    const body = JSON.stringify({
      incidentId,
      reportedby_userId,
      reason,
    });

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        "api/incident/incident_cancelled",
        body,
        config
      );
      dispatch({
        type: INCIDENT_CANCELLED,
        payload: true,
      });
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: INCIDENT_SUMMIT_FAIL,
      });
    }
  };

// GET CLOSED INCIDENT BY USER ID
// /api/incident/me/close/:userID

export const getCancelledIncidentByUserId = (user_id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/incident/me/cancelled/${user_id}`);
    dispatch({
      type: GET_CANCELLED_INCIDENT_BY_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_INCIDENT_BY_USER_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getIncidentClose = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/incident/all/incidentClose`);
    dispatch({
      type: GET_INCIDENT_CLOSED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_INCIDENT_CLOSED_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getIncidentCancelled = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/incident/all/incidentCancelled`);
    dispatch({
      type: GET_INCIDENT_CANCELLED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_INCIDENT_CANCELLED_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
