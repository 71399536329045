import React, { Fragment, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import axios from "axios";
import Progressbar from "../layout/ProgressBar";
import moment from "moment";
import { createNotification } from "../../actions/expoNotification";

export const CreateNotification = ({
  onClose,
  show,
  incident_handled,
  setAlert,
  createNotification,
}) => {
  const [formData, setFormData] = useState({
    title: "",
    text: "",
    articleImage: "",
  });

  const [anonymous, setAnonymous] = useState(false);
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [preview, setPreview] = useState();
  const inputRef = useRef();
  const [progress, setProgress] = useState();
  const [error, setError] = useState();
  const [notifBody, setNotifBody] = useState();

  const { title, text } = formData;

  useEffect(() => {
    if (incident_handled && !anonymous) {
      setNotifBody(
        "LOCATION:  " +
          incident_handled.scompleteaddress +
          "\nTIME RECEIVED:  " +
          moment(incident_handled.reportedDate).format("LLLL") +
          "\nREPORTED BY:  " +
          incident_handled.name +
          " " +
          incident_handled.lname
      );
    } else if (incident_handled && anonymous) {
      setNotifBody(
        "LOCATION:  " +
          incident_handled.scompleteaddress +
          "\nTIME RECEIVED:  " +
          moment(incident_handled.reportedDate).format("LLLL") +
          "\nREPORTED BY:  Anonymous Volunteer "
      );
    }
    // Else anonymous
  }, [incident_handled, anonymous]);

  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [image]);

  const onFileChange = (c) => {
    setImage(c.target.files[0]);
    setImageName(c.target.files[0].name);
  };

  const onChange = (c) => {
    setFormData({ ...formData, [c.target.name]: c.target.value });
  };

  const payload = new FormData();
  payload.append("title", incident_handled?.type + ": " + title);
  payload.append("text", notifBody + "\n\nADDITIONAL INFO: " + text);
  payload.append("articleImage", image);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const sendNotif = async () => {
    try {
      const res = await axios.post("/api/posts", payload, {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      });
      console.log("Send Notif response", res);
      setAlert("Notification Created", "success");
      await delay(3000);
      window.location.reload();
    } catch (err) {
      console.log("Error from backend", err);
      setError(true);
      setAlert(`Post error ${err.response.statusText}`, "danger");
    }
  };

  const onSubmit = (c) => {
    c.preventDefault();
    // addPost(payload);
    sendNotif();
    createNotification({
      title: title,
      body: notifBody + "\n\nADDITIONAL INFO: " + text,
    });
    setFormData({ title: "", text: "", articleImage: "" });
    inputRef.current.value = "";
    // put error catcher
  };

  if (!show) {
    return null;
  }

  return (
    <Fragment>
      <div className="notifModal">
        <div className="notification">
          <div className="notif_title">
            <p> Share and send to notification</p>
          </div>
          <div className="previewtitle">
            <p> Preview</p>
          </div>

          {/* Change Here */}

          <div className="titlebody">
            <form
              className="form my-1"
              encType="multipart/form-data"
              onSubmit={(c) => onSubmit(c)}
            >
              <div style={{ padding: "8px" }}>
                <div className="Title">
                  <input
                    name="title"
                    type="text"
                    placeholder="Title"
                    onChange={(c) => onChange(c)}
                    value={title}
                    required
                  />
                </div>

                <div className="form-group">
                  <p className="lead">
                    <input
                      type="checkbox"
                      onChange={(c) => {
                        setAnonymous(!anonymous);
                      }}
                    />{" "}
                    Anonymous Volunteer?
                  </p>
                </div>

                <div className="Message">
                  <textarea
                    name="text"
                    cols="50"
                    rows="4"
                    placeholder="Message"
                    value={notifBody}
                    onChange={(c) => onChange(c)}
                    required
                  />
                </div>

                <div className="Message">
                  <textarea
                    name="text"
                    cols="50"
                    rows="8"
                    placeholder="Additional Info"
                    value={text}
                    onChange={(c) => onChange(c)}
                    required
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "60px",
                  }}
                >
                  <input
                    className="btn btn-dark my-1"
                    style={{ background: "#174052", width: "100%" }}
                    // id='upload-button'
                    ref={inputRef}
                    type="file"
                    onChange={(c) => onFileChange(c)}
                    accept="image/*,video/mp4,video/x-m4v,video/*"
                    multiple={false}
                    placeholder={imageName}
                    // style={{ display: "none" }}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  {" "}
                  <div className="Send">
                    {/* <label htmlFor='file'> */}
                    <input
                      type="submit"
                      className="btn btn-success my-1"
                      value="Send"
                    />
                  </div>
                  <button onClick={onClose} className="btn  btn-danger">
                    Cancel
                  </button>
                </div>
              </div>

              {/* add image button here */}
            </form>
            {!error && progress && <Progressbar progress={progress} />}
          </div>

          <div className="preview">
            {!preview ? (
              <div>
                <p style={{ color: "#215a75", textAlign: "center" }}>
                  Please choose a file...
                </p>
              </div>
            ) : (
              <div
                style={{
                  width: "450px",
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    maxHeight: "500px",
                    padding: "10px",
                    borderRadius: "20px",
                  }}
                  src={preview}
                  alt="image loaded"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

CreateNotification.propTypes = {
  setAlert: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { setAlert, createNotification };

export default connect(mapStateToProps, mapDispatchToProps)(CreateNotification);
