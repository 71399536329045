import React, { Fragment, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MainMenu from "./MainMenu";
import DispatchStatus from "./DispatchStatus";
import ResponderOnline from "../responder-online/ResponderOnline";
import ActionsMain from "./ActionsMain";
import IncidentDetails from "./IncidentDetails";
import ChatMenu from "./ChatMenu";
import Dropzone from "react-dropzone";
import Spinner from "../layout/Spinner";
import { putincidentdispatcher } from "../../actions/incident";
import { setAlert } from "../../actions/alert";
import IncidentMessages from "./IncidentMessages";
import Messenger from "../messenger/Messenger";
import axios from "axios";
import Progressbar from "../layout/ProgressBar";

const DispatchMain = ({
  user,
  socket,
  dispatcher,
  incident: { createIncidentOpcen, incident, incident_handled, loading },
  putincidentdispatcher,
  responders,
}) => {
  const [newMessage, setNewMessage] = useState("");
  const scrollRef = useRef(null);
  const [arrivalMessage, setArrivalMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [progress, setProgress] = useState();
  const [error, setError] = useState();
  const [onlineUsers, setOnlineUsers] = useState();
  const [isTyping, setIsTyping] = useState(false);
  const [initialMsg, setInitialMsg] = useState();

  // Send Initial msg

  useEffect(() => {
    if (incident_handled !== null) {
      const incidentId = incident_handled._id;
      const reportedby_userId = incident_handled.user;
      const dispatcher_userId = user._id;
      const name = user.name;
      const lname = user.lname;
      const time_received = incident_handled.reportedDate;

      socket.emit("sendCallHandled", {
        incidentId,
        reportedby_userId,
        dispatcher_userId,
        time_received,
        name,
        lname,
      });

      putincidentdispatcher({
        incidentId,
        reportedby_userId,
        dispatcher_userId,
        time_received,
      });
    } else if (incident !== null) {
      const incidentId = incident._id;
      const reportedby_userId = incident.user;
      const dispatcher_userId = user._id;
      const time_received = incident.reportedDate;
      putincidentdispatcher({
        incidentId,
        reportedby_userId,
        dispatcher_userId,
        time_received,
      });
    }
  }, [incident_handled, incident]);

  useEffect(() => {
    const sendInitialMsg = async () => {
      const sendMessage = `Hi Guardian ${incident_handled?.name}, please confirm incident type of "${incident_handled?.type}", and incident location at ${incident_handled?.scompleteaddress}.`;
      if (incident_handled) {
        const message = {
          incidentId: incident_handled?._id,
          sender: user?._id,
          text: sendMessage,
        };
        const receiverId = incident_handled?.user;

        try {
          const res = await axios.post("/api/incident_msg", message);

          setMessages([...messages, res.data]);
          console.log("SENIDNNG INITIAL MSG");
          socket.emit("sendMessage", {
            incidentId: incident_handled._id,
            senderId: user._id,
            receiverId,
            text: sendMessage,
          });
        } catch (err) {
          setAlert(err, "danger");
        }
      }
    };
    sendInitialMsg();
  }, [incident_handled]);

  useEffect(() => {
    const receiverId = incident_handled?.user;
    socket.emit("sendTyping", {
      incidentId: incident_handled?._id,
      senderId: user?._id,
      receiverId,
    });

    console.log("Send Typing");
  }, [newMessage]);

  console.log("incident Handled", incident_handled);

  useEffect(() => {
    socket.on("getTyping", (data) => {
      if (data.sender === incident_handled?.user) {
        setIsTyping(true);
      }
    });
  }, []);

  useEffect(() => {
    socket.on("getMessage", (data) => {
      // if (data.senderId === incident_handled.user) {
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
      });
      // }
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsTyping(false);
    }, 3000);
  }, [isTyping]);

  useEffect(() => {
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    const getIncidentMessages = async () => {
      try {
        const res = await axios.get(
          `/api/incident_msg/${incident_handled._id}`
        );
        setMessages(res.data);
      } catch (err) {
        setAlert(err, "danger");
      }
    };

    getIncidentMessages();
  }, [incident_handled]);

  // Use Effect get all Incident msg

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  }, [newMessage]);

  const onDrop = async (files) => {
    console.log("Files for sending", files);
    try {
      const formData = new FormData();
      formData.append("file", files[0]);
      setError("");
      const res = await axios.post("/api/incident_msg/attachment", formData, {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      });

      if (res.data.success === false) {
        {
          const { code } = res.data.code;
          switch (code) {
            case "FILE_MISSING":
              setError("Please select a file before uploading!");
              break;
            case "LIMIT_FILE_SIZE":
              setError(
                "File size is too large. Please upload files below 1MB!"
              );
              break;
            case "INVALID_TYPE":
              setError(
                "This file type is not supported! Only .png, .jpg files are allowed"
              );
              break;
            default:
              setError("Sorry! Something went wrong. Please try again later");
              break;
          }

          setAlert({ error }, "danger");
        }
      } else if (res.data.success) {
        const message = {
          incidentId: incident_handled._id,
          sender: user,
          text: res.data.url.substring(
            res.data.url.length - 22,
            res.data.url.length
          ),
        };

        const receiverId = incident_handled.user;

        socket.emit("sendMessage", {
          senderId: user._id,
          receiverId,
          text: res.data.url.substring(
            res.data.url.length - 22,
            res.data.url.length
          ),
        });

        try {
          const res = await axios.post("/api/incident_msg", message);
          setMessages([...messages, res.data]);
          setNewMessage("");
          setProgress();
        } catch (err) {
          setAlert(err, "danger");
        }
      }
    } catch (err) {
      const errors = err.response.data.errors;
      setAlert(errors, "danger");
    }
  };

  const onSubmit = async (c) => {
    c.preventDefault();
    const sendMessage = newMessage.trim();
    if (sendMessage.length > 0) {
      if (incident_handled) {
        const message = {
          incidentId: incident_handled._id,
          sender: user._id,
          text: sendMessage,
        };
        const receiverId = incident_handled.user;

        try {
          const res = await axios.post("/api/incident_msg", message);

          setMessages([...messages, res.data]);
          setNewMessage("");
          setProgress();

          socket.emit("sendMessage", {
            incidentId: incident_handled._id,
            senderId: user._id,
            receiverId,
            text: sendMessage,
          });
        } catch (err) {
          setAlert(err, "danger");
        }
      } else if (incident) {
        const message = {
          incidentId: incident._id,
          sender: user._id,
          text: sendMessage,
        };
        const receiverId = incident.user;

        try {
          const res = await axios.post("/api/incident_msg", message);

          setMessages([...messages, res.data]);
          setNewMessage("");
          setProgress();
        } catch (err) {
          setAlert(err, "danger");
        }
      }
    }
  };

  return (
    <Fragment>
      {incident !== null || incident_handled !== null ? (
        <div className="full">
          <div className="dispatchContainer">
            <div className="details">
              {incident !== null && incident_handled === null ? (
                <IncidentDetails
                  incident={incident}
                  createIncidentOpcen={createIncidentOpcen}
                />
              ) : (
                <IncidentDetails
                  incident={incident_handled}
                  createIncidentOpcen={createIncidentOpcen}
                  socket={socket}
                />
              )}
              {/* <IncidentDetails /> */}
            </div>
            <div className="main-menu">
              <MainMenu
                socket={socket}
                user={user}
                createIncidentOpcen={createIncidentOpcen}
              />
            </div>
            <div className="dispatchMenu">
              <p style={{ fontSize: 12, paddingLeft: 5 }}>
                Dispatch Status (Send update to Volunteer)
              </p>
            </div>
            <div className="dispatchStatus">
              <DispatchStatus />
            </div>

            <div className="chat-menu">
              <ChatMenu />
            </div>
            <div className="chat">
              {messages?.map((m) => (
                <div ref={scrollRef}>
                  <IncidentMessages
                    key={m._id}
                    message={m}
                    own={m.sender === user._id}
                  />
                </div>
              ))}
              {isTyping && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 120,
                    left: 20,
                    backgroundColor: "#bbb",
                    height: 28,
                    width: 180,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "5px 5px 5px 5px",
                  }}
                >
                  <div style={{ paddingLeft: 30 }}> is typing ...</div>
                </div>
              )}
            </div>

            <div className="responderChatMenu">
              <p style={{ fontSize: "12px", fontWeight: "200" }}>Responder</p>
            </div>
            <div className="responder-menu">
              <ResponderOnline
                socket={socket}
                currentId={user._id}
                volunteer={incident_handled?.user}
                incident_handled={incident_handled}
              />
            </div>
            <div className="chat-input">
              <form className="incident-chat-Box" onSubmit={onSubmit}>
                <input
                  type="text"
                  className="incident-chat-Input"
                  placeholder="Type a message"
                  onChange={(e) => setNewMessage(e.target.value)}
                  value={newMessage}
                  onSubmit={onSubmit}
                  autoFocus
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    width: "100%",
                  }}
                >
                  <Dropzone
                    accept="image/*,video/mp4,video/x-m4v,video/*"
                    onDrop={onDrop}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div
                            className="image-button"
                            style={{
                              color: "#eee",
                            }}
                          >
                            <i
                              className="fa fa-paperclip"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  <button className="btn btn-success" type="submit">
                    <i class="fas fa-paper-plane"></i>
                    <span style={{ paddingLeft: "10px" }}>Send</span>
                  </button>
                </div>
              </form>

              {!error && progress && <Progressbar progress={progress} />}
            </div>
            <div className="btn-dis-action">
              <ActionsMain socket={socket} />
            </div>
          </div>
        </div>
      ) : incident_handled === null && incident === null ? (
        <div className="full">
          <div className="dispatchContainer">
            <div className="main-menu">
              <MainMenu socket={socket} user={user} />
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}

      {!dispatcher ? (
        <div className="modal_invisible_gray">
          <div className=" modal-Checkin text-red shadow_txt">
            <h1>EMERGENCY DISPATCH OPERATOR </h1>

            <p
              className="shadow_txt"
              style={{ color: "#215a75", fontSize: "30px", fontWeight: "600" }}
            >
              Guardian {user.name} {user.lname}
            </p>
            <h1>
              Please{" "}
              <span className="blink_me" style={{ color: "#28a745" }}>
                CHECK-IN
              </span>{" "}
              to start accepting calls...
            </h1>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

DispatchMain.propTypes = {
  user: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  dispatcher: PropTypes.bool,
  incident: PropTypes.object.isRequired,
  responders: PropTypes.array.isRequired,
  putincidentdispatcher: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  incident: state.incident,
  dispatcher: state.dispatchOnline.checkInStatus,
  profile: state.profile,
  responders: state.responder.reponders,
});

const mapDispatchToProps = {
  putincidentdispatcher,
};

export default connect(mapStateToProps, mapDispatchToProps)(DispatchMain);
