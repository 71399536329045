import {
  SUSPEND_USER,
  SUSPEND_USER_ERROR,
  GET_ALL_SUSPENDED_USERS,
} from "../actions/types";

const initialState = {
  suspendeduser: null,
  suspendedusers: null,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SUSPEND_USER:
      return {
        ...state,
        suspendeduser: payload, //from the action file
        loading: false,
      };
    case GET_ALL_SUSPENDED_USERS:
      return {
        ...state,
        suspendedusers: payload, //from the action file
        loading: false,
      };

    case SUSPEND_USER_ERROR:
      return {
        suspendeduser: null,
        loading: true,
        error: payload,
      };

    default:
      return state;
  }
}
