import React, { Fragment, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addLike, removeLike } from "../../actions/post";
import Spinner from "../layout/Spinner";
import Moment from "react-moment";
import { FacebookShareButton } from "react-share";
import CRUDModal from "./CRUDModal";
import PostReaction from "./PostReaction";
import { postProfileClick } from "../../actions/adminCRUD";
import { getProfileCRUD } from "../../actions/profile";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;

const MAX_LENGTH = 150;

const PostItem = ({
  addLike,
  auth,
  profile: { profiles, loading },
  post: {
    _id,
    title,
    text,
    name,
    lname,
    articleImage,
    user,
    likes,
    loves,
    wows,
    sads,
    hahas,
    angrys,
    comments,
    date,
    multiImage,
  },
  showActions,
  postProfileClick,
  getProfileCRUD,
}) => {
  const [shortComment, toggleShortComment] = useState(true);
  const [fullComment, toggleFullComment] = useState(false);
  const [showCRUD, setShowCRUD] = useState(false);
  const [showReaction, setShowReaction] = useState(false);

  const fbQoute = title + "\n " + text;

  const timeDifference = () => {
    var current = new Date();
    var formatDate = new Date(date);

    var minutes = 60 * 1000;
    var hours = minutes * 60;
    var days = hours * 24;
    var months = days * 30;
    var years = days * 365;

    var elapsed = current - formatDate;

    if (elapsed < minutes) {
      return Math.round(elapsed / 1000) + " seconds ago";
    } else if (elapsed < hours) {
      return Math.round(elapsed / minutes) + " minutes ago";
    } else if (elapsed < days) {
      return Math.round(elapsed / hours) + " hours ago";
    } else if (elapsed < months) {
      return Math.round(elapsed / days) + " days ago";
    } else if (elapsed < years) {
      return Math.round(elapsed / months) + " months ago";
    } else {
      return Math.round(elapsed / years) + " years ago";
    }
  };

  let userLiked = likes?.filter((el) => {
    return el.user === auth?.user?._id;
  });
  let userLoved = loves?.filter((el) => {
    return el.user === auth?.user?._id;
  });
  let userWows = wows?.filter((el) => {
    return el.user === auth?.user?._id;
  });
  let userSads = sads?.filter((el) => {
    return el.user === auth?.user?._id;
  });
  let userHahas = hahas?.filter((el) => {
    return el.user === auth?.user?._id;
  });

  let userAngrys = angrys?.filter((el) => {
    return el.user === auth?.user?._id;
  });

  const onButtonClick = useCallback(
    (e) => {
      toggleShortComment(!shortComment);
      toggleFullComment(!fullComment);
    },
    [fullComment, shortComment]
  );

  const updateLike = (_id) => {
    addLike(_id);
    // window.location.reload();
  };

  const numFormatter = (num) => {
    if (num > 999 && num < 1000000) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
        : Math.sign(num) * Math.abs(num); // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + "M"
        : Math.sign(num) * Math.abs(num); // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  };

  const totalReactions = numFormatter(
    likes?.length +
      loves?.length +
      wows?.length +
      sads?.length +
      hahas?.length +
      angrys?.length
  );
  const totalComments = numFormatter(comments.length);

  return (
    <Fragment>
      {loading && _id ? (
        <Spinner />
      ) : (
        <div key={_id} className="comment bg-white">
          {/* map all profiles here */}

          {profiles.length > 0 ? (
            profiles?.map((profile) => {
              if (profile.user?._id === user) {
                return (
                  <Fragment>
                    <div
                      key={[profile._id]}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        height: "70px",
                        margin: "0px 1px",
                        alignItems: "center",
                        paddingRight: "2%",
                        paddingLeft: "2%",
                        justifyContent: "space-around",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <Link
                        to={`/profile/${user}`}
                        onClick={() => {
                          postProfileClick();
                          getProfileCRUD(profile.user?._id);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingLeft: "10px",
                          }}
                        >
                          <img
                            className="post-profile"
                            src={`${serverURI}/${profile.profilepic}`}
                            alt="..."
                          />{" "}
                        </div>
                      </Link>
                      <div
                        style={{
                          width: "65%",
                          marginLeft: "20px",
                        }}
                      >
                        <div className="Htitle" style={{ margin: "0" }}>
                          <span className="p-11">{name}</span>
                          <span className="p-11">{lname}</span>{" "}
                        </div>

                        <p className="post-date p-11 ">
                          {" "}
                          {/* <span>{moment(date).format("LLLL")}</span> */}
                          {/* {"   "} - {format(date)} */}
                          <Moment format="lll">{date}</Moment> -{" "}
                          {timeDifference()}
                        </p>
                      </div>
                      {/* <div style={{ width: "10%" }}></div> */}
                      <div style={{ width: "15%", position: "relative" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {(!auth.loading && user === auth.user?._id) ||
                          auth.isOpcenAdmin ? (
                            <button
                              onClick={() => {
                                setShowCRUD(true);
                              }}
                              type="button"
                              className="btn-post f-2"
                            >
                              <i
                                className="fa fa-ellipsis-h"
                                aria-hidden="true"
                              ></i>
                            </button>
                          ) : null}
                        </div>

                        {/* Modals here */}
                        {(!auth.loading && user === auth.user?._id) ||
                        auth.isOpcenAdmin ? (
                          <CRUDModal
                            showCRUD={showCRUD}
                            onClose={() => setShowCRUD(false)}
                            auth={auth}
                            _id={_id}
                          />
                        ) : null}
                      </div>
                    </div>
                  </Fragment>
                );
              }
            })
          ) : (
            <div>
              <img
                className="post-profile"
                src={`/img/Spotter.png`}
                alt="..."
              />
            </div>
          )}

          <div>
            <div className="Htitle"> {title}</div>
            <div>
              {text.length > MAX_LENGTH ? (
                <div className=" commentFont f-2">
                  {shortComment && (
                    <p
                      style={{
                        whiteSpace: "pre-line",
                      }}
                    >
                      {`${text.substring(0, MAX_LENGTH)}...`}
                      <button
                        className="btn-comment"
                        onClick={onButtonClick}
                        type="button"
                      >
                        <p
                          className="f-1"
                          style={{
                            color: "#215a75",
                          }}
                        >
                          see more
                        </p>
                      </button>
                    </p>
                  )}
                  {fullComment && (
                    <div>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                        }}
                      >
                        {text}
                      </p>
                      <button
                        className="btn-comment"
                        onClick={onButtonClick}
                        type="button"
                      >
                        <p
                          style={{
                            color: "#215a75",
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                        >
                          see less
                        </p>
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <p className="commentFont  f-2">{text}</p>
              )}
            </div>
            <div
              style={{
                background: "#eee",
                margin: "auto",
                maxWidth: "100%",
              }}
            >
              {articleImage && (
                <>
                  {articleImage.substring(0, 4) === "Post" ? (
                    articleImage.substring(
                      articleImage.length - 3,
                      articleImage.length
                    ) === "mp4" ? (
                      <Link to={`/posts/${_id}/${articleImage}`}>
                        <video
                          className="postImage"
                          controls
                          type="video/mp4"
                          alt="video"
                          src={`${serverURI}/video/${articleImage}`}
                        />
                      </Link>
                    ) : (
                      <Link to={`/posts/${_id}/${articleImage}`}>
                        <img
                          // style={{ width: "305px", borderRadius: "5px" }}
                          className="postImage"
                          src={`${serverURI}/video/${articleImage}`}
                          alt="..."
                        />
                      </Link>
                    )
                  ) : null}
                </>
              )}
              {multiImage && (
                <div>
                  {multiImage.length === 1 && (
                    <div>
                      <img
                        style={{
                          width: "100%",
                          height: "auto",
                          borderRadius: "5px",
                        }}
                        className="postImage"
                        src={`${serverURI}/video/${multiImage[0]}`}
                        alt="..."
                      />
                    </div>
                  )}
                  {multiImage.length === 2 && (
                    <div className="post2Image">
                      <div className="post2image1">
                        <img
                          style={{ objectFit: "cover" }}
                          src={`${serverURI}/video/${multiImage[0]}`}
                          alt="..."
                        />
                      </div>
                      <div className="post2image2">
                        <img
                          style={{ objectFit: "cover" }}
                          src={`${serverURI}/video/${multiImage[1]}`}
                          alt="..."
                        />
                      </div>
                    </div>
                  )}
                  {multiImage.length === 3 && (
                    <div className="post3Image">
                      <div className="post3image1">
                        <img
                          style={{
                            maxHeight: "auto",
                            width: "300px",
                            borderRadius: "5px",
                          }}
                          src={`${serverURI}/video/${multiImage[0]}`}
                          alt="..."
                        />
                      </div>
                      <div className="post3image2">
                        <img
                          style={{
                            maxHeight: "auto",
                            width: "300px",
                            borderRadius: "5px",
                          }}
                          src={`${serverURI}/video/${multiImage[1]}`}
                          alt="..."
                        />
                      </div>
                      <div className="post3image3">
                        <img
                          style={{
                            maxHeight: "auto",
                            width: "300px",
                            borderRadius: "5px",
                          }}
                          src={`${serverURI}/video/${multiImage[2]}`}
                          alt="..."
                        />
                      </div>
                    </div>
                  )}
                  {multiImage.length === 4 && (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{ width: "305px", borderRadius: "5px" }}
                          className="postImage"
                          src={`${serverURI}/video/${multiImage[0]}`}
                          alt="..."
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{ width: "305px", borderRadius: "5px" }}
                          className="postImage"
                          src={`${serverURI}/video/${multiImage[1]}`}
                          alt="..."
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{ width: "305px", borderRadius: "5px" }}
                          className="postImage"
                          src={`${serverURI}/video/${multiImage[2]}`}
                          alt="..."
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{ width: "305px", borderRadius: "5px" }}
                          className="postImage"
                          src={`${serverURI}/video/${multiImage[3]}`}
                          alt="..."
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="show_reaction">
              <PostReaction
                showReaction={showReaction}
                onClose={() => setShowReaction(false)}
                _id={_id}
                userLiked={userLiked}
                userLoved={userLoved}
                userWows={userWows}
                userSads={userSads}
                userHahas={userHahas}
                userAngrys={userAngrys}
              />

              <div
                style={{
                  display: "flex",
                  width: "50%",
                  height: "100%",
                  justifyContent: "flex-start",
                  marginLeft: "20px",
                  alignItems: "center",
                }}
              >
                {likes?.length > 0 && (
                  <div className="flex_center_horizontal2 ">
                    <img
                      className="reactionImage"
                      src="\icons\reactions\like.png"
                      alt=""
                    />
                  </div>
                )}
                {loves?.length > 0 && (
                  <div className="flex_center_horizontal2 ">
                    <img
                      className="reactionImage"
                      src="\icons\reactions\love.png"
                      alt=""
                    />
                  </div>
                )}
                {wows?.length > 0 && (
                  <div className="flex_center_horizontal2 ">
                    <img
                      className="reactionImage"
                      src="\icons\reactions\wow.png"
                      alt=""
                    />
                  </div>
                )}
                {sads?.length > 0 && (
                  <div className="flex_center_horizontal2 ">
                    <img
                      className="reactionImage"
                      src="\icons\reactions\sad.png"
                      alt=""
                    />
                  </div>
                )}
                {hahas?.length > 0 && (
                  <div className="flex_center_horizontal2 ">
                    <img
                      className="reactionImage"
                      src="\icons\reactions\haha.png"
                      alt=""
                    />
                  </div>
                )}
                {angrys?.length > 0 && (
                  <div className="flex_center_horizontal2 ">
                    <img
                      className="reactionImage"
                      src="\icons\reactions\angry.png"
                      alt=""
                    />
                  </div>
                )}
                <span className="reactionCount">
                  {totalReactions !== 0 && totalReactions}
                </span>
              </div>

              <Link
                to={`/posts/${_id}`}
                style={{
                  display: "flex",
                  width: "50%",
                  height: "100%",
                  justifyContent: "flex-end",
                  marginRight: "20px",
                  alignItems: "center",
                  color: "#333",
                }}
              >
                {" "}
                {comments.length > 0 && (
                  <div>
                    {" "}
                    <span className="reactionCount">{totalComments}</span>
                    <span className="reactionCount">Comments</span>
                  </div>
                )}
              </Link>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "50px",
                // margin: "0px 5px",
                alignItems: "center",
                justifyContent: "space-around",
                position: "relative",
                borderTop: "1px solid #ddd",
              }}
            >
              {showActions && (
                <Fragment>
                  {/* Button for like */}
                  <div
                    style={{ position: "relative" }}
                    onMouseOver={() => setShowReaction(true)}
                    // onMouseOut={() => setShowReaction(false)}
                  >
                    <div
                      onClick={(c) => {
                        updateLike(_id);
                        setShowReaction(!showReaction);
                      }}
                      className="btn-post f-2 "
                    >
                      {userLiked?.length > 0 ? (
                        <div className="flex_center_horizontal ">
                          <img
                            style={{ width: "20px", height: "20px" }}
                            src="\icons\reactions\like.png"
                            alt="liked"
                          />
                          <span className={"marginLiked"}>Like</span>
                        </div>
                      ) : userLoved?.length > 0 ? (
                        <div className="flex_center_horizontal ">
                          <img
                            style={{ width: "20px", height: "20px" }}
                            src="\icons\reactions\love.png"
                            alt="liked"
                          />
                          <span className={"marginLiked"}>Love</span>
                        </div>
                      ) : userWows?.length > 0 ? (
                        <div className="flex_center_horizontal ">
                          <img
                            style={{ width: "20px", height: "20px" }}
                            src="\icons\reactions\wow.png"
                            alt="liked"
                          />
                          <span className={"marginLiked"}>Wow</span>
                        </div>
                      ) : userSads?.length > 0 ? (
                        <div className="flex_center_horizontal ">
                          <img
                            style={{ width: "20px", height: "20px" }}
                            src="\icons\reactions\sad.png"
                            alt="liked"
                          />
                          <span className={"marginLiked"}>Sad</span>
                        </div>
                      ) : userHahas?.length > 0 ? (
                        <div className="flex_center_horizontal ">
                          <img
                            style={{ width: "20px", height: "20px" }}
                            src="\icons\reactions\haha.png"
                            alt="liked"
                          />
                          <span className={"marginLiked"}>Haha</span>
                        </div>
                      ) : userAngrys?.length > 0 ? (
                        <div className="flex_center_horizontal ">
                          <img
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                            src="\icons\reactions\angry.png"
                            alt="liked"
                          />
                          <span className={"marginLiked"}>Angry</span>
                        </div>
                      ) : (
                        <div>
                          <i
                            className="fa fa-thumbs-o-up"
                            aria-hidden="true"
                          ></i>
                          <span className="marginLike">Like</span>
                        </div>
                      )}

                      {/* <span>
                        {likes.length > 0 && (
                          <p style={{ marginLeft: "5px" }}>{likes.length}</p>
                        )}
                      </span> */}
                      {/* <span
                        className={
                          userLiked.length > 0 ? "marginLiked " : "marginLike"
                        }
                      >
                        Like
                      </span> */}
                    </div>
                  </div>

                  <Link to={`/posts/${_id}`} className="btn-post f-2">
                    <i className="fa fa-comment-o" aria-hidden="true"></i>{" "}
                    <p style={{ marginLeft: "3px", color: "#333" }}>Comment </p>
                  </Link>

                  <FacebookShareButton
                    className="btn-post f-2"
                    url={`https://guardian.ph`}
                    quote={fbQoute}
                    hashtag={"#BeOnGuard"}
                  >
                    {/* <FacebookIcon size={40} /> */}
                    <i
                      className="fa fa-share-square-o"
                      aria-hidden="true"
                    ></i>{" "}
                    <p style={{ marginLeft: "3px", fontSize: "13px" }}>Share</p>
                  </FacebookShareButton>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

PostItem.defaultProps = {
  showActions: true,
};

PostItem.propTypes = {
  profile: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  addLike: PropTypes.func.isRequired,
  removeLike: PropTypes.func.isRequired,
  postProfileClick: PropTypes.func.isRequired,
  getProfileCRUD: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, {
  addLike,
  removeLike,
  postProfileClick,
  getProfileCRUD,
})(PostItem);
