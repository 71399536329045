import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteResponder } from "../../actions/responder";
import { deleteUserResponder } from "../../actions/users";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;
const DisplayResponderList = ({
  responder,
  deleteResponder,
  deleteUserResponder,
}) => {
  const responders = responder?.map(resp => (
    <tr key={resp._id}>
      <td>
        {/* <Link to=> */}
        <img
          className='commonTable_img'
          style={{ borderRadius: "100%" }}
          src={`${serverURI}/${resp?.profilee.profilepic}`}
          alt=''
        />
        {/* </Link> */}
      </td>

      <td>{resp?.user.name}</td>
      <td>{resp?.user.lname}</td>
      <td>{resp?.type}</td>

      <td style={{ textAlign: "center" }}>
        {/* <button
          onClick={() => deleteResponder(resp._id)}
          className='btn_table btn-success'
        >
          <i className='fa fa-user' aria-hidden='true'></i>
        </button> */}
        <button
          onClick={() => {
            deleteResponder(resp._id);
            deleteUserResponder(resp.user._id, resp._id);
          }}
          // resp.user is the user ID
          className='btn_table btn-danger'
        >
          <i className='fa fa-trash-o' aria-hidden='true'></i>
        </button>
      </td>
    </tr>
  ));

  return (
    <Fragment>
      <h4 className='my-1'>Current Responders </h4>
      <div
        style={{
          borderRadius: "5px",
          overflow: "hidden",
          overflowY: "scroll",
          maxHeight: "67vh",
          border: "1px solid #eee",
        }}
      >
        <table className='commonTable'>
          <thead>
            <tr>
              <th>Photo</th>

              <th> First Name</th>
              <th> Last Name</th>
              <th> Designation</th>
              <th> Action</th>
            </tr>
          </thead>
          {/* Loop thru the database */}
          <tbody>{responders}</tbody>
        </table>
      </div>
    </Fragment>
  );
};

DisplayResponderList.propTypes = {
  responder: PropTypes.array.isRequired,
  deleteResponder: PropTypes.func.isRequired,
  deleteUserResponder: PropTypes.func.isRequired,
};

export default connect(null, { deleteResponder, deleteUserResponder })(
  DisplayResponderList
);
