import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { putOpcen } from "../../actions/users";

const SelectedOpcen = ({ opcens, user_id, putOpcen }) => {
  const responder = opcens.map(res => (
    <tr key={res._id}>
      <td>{res.name}</td>

      <td style={{ textAlign: "center" }}>
        <button
          onClick={() => {
            // Put action Opcen at users  operation_center table
            putOpcen(user_id, res._id);
          }}
          className=' btn_table btn-success'
        >
          <i className='fa fa-plus-square-o' aria-hidden='true'></i> Volunteer
        </button>
      </td>
    </tr>
  ));

  return (
    <Fragment>
      <div style={{ overflow: "hidden", overflowY: "scroll", height: "200px" }}>
        <table className='commonTable'>
          <thead>
            <tr>
              <th> Name</th>

              <th>Action</th>
            </tr>
          </thead>
          {/* Loop thru the database */}

          <tbody>{responder}</tbody>
        </table>
      </div>
    </Fragment>
  );
};

SelectedOpcen.propTypes = {
  opcens: PropTypes.array.isRequired,
  user_id: PropTypes.object.isRequired,
  putOpcen: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user_id: state.auth.user._id,
});

const mapDispatchToProps = { putOpcen };

export default connect(mapStateToProps, mapDispatchToProps)(SelectedOpcen);
