import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Progressbar from "../layout/ProgressBar";
import axios from "axios";
import { setAlert } from "../../actions/alert";

export const AddVaccineData = ({ setAlert }) => {
  const [formData, setFormData] = useState({
    id_no: "",
    vaccinationSite: "",
    vaccineType: "",
    fDoseDate: "",
    sDoseDate: "",
    scheduledDate: "",
    vaxCardPhoto: "",
    sBoosterDate: "",
    boosterType: "",
    boosterPhoto: "",
    onScheduled: false,
  });

  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const inputRef = useRef();
  const [preview, setPreview] = useState();
  const [progress, setProgress] = useState();
  const [error, setError] = useState();

  const {
    id_no,
    vaccinationSite,
    vaccineType,
    fDoseDate,
    sDoseDate,
    sBoosterDate,
    boosterType,
    scheduledDate,
    onScheduled,
  } = formData;

  const [toDateDisabled, toggleDisabled] = useState(false);

  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [image]);
  const onChange = async c =>
    setFormData({ ...formData, [c.target.name]: c.target.value });

  const onFileChange = c => {
    setImage(c.target.files[0]);
    setImageName(c.target.files[0].name);
  };

  const payload = new FormData();
  payload.append("id_no", id_no);
  payload.append("vaccinationSite", vaccinationSite);
  payload.append("vaccineType", vaccineType);
  payload.append("fDoseDate", fDoseDate);
  payload.append("sDoseDate", sDoseDate);
  payload.append("sBoosterDate", sBoosterDate);
  payload.append("boosterType", boosterType);
  payload.append("scheduledDate", scheduledDate);
  payload.append("vaxCardPhoto", image);
  payload.append("updated", true);

  const sendVaxData = async () => {
    try {
      const res = await axios.post("/api/vaccination", payload, {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      });
      console.log("Send Notif response", res);
      setAlert("Submitted, please allow 3-5 days for validation", "success");
    } catch (err) {
      console.log("Error from backend", err);
      setError(true);
      setAlert(`Post error ${err.response.statusText}`, "danger");
    }
  };

  const onSubmit = async c => {
    c.preventDefault();

    sendVaxData();
  };
  return (
    <Fragment>
      <div
        style={{ marginTop: "20px" }}
        className=' main-container align-vertically'
      >
        <div className='sub-container'>
          <h2 className=' text-primary'> Covid Vaccination </h2>
          <p className='lead'>
            <i className='fas fa-id-card'></i> Submit needed information for
            verification by your Operations Center.
          </p>
          <small style={{ color: "red" }}>* = required field</small>
          <form className='form' onSubmit={c => onSubmit(c)}>
            <div className='form-group'>
              <input
                type='text'
                placeholder='* Vax Card Number'
                name='id_no'
                value={id_no}
                onChange={c => onChange(c)}
                required
              />
            </div>
            <div className='form-group'>
              <input
                type='text'
                placeholder='* Vaccination site'
                name='vaccinationSite'
                value={vaccinationSite}
                onChange={c => onChange(c)}
                required
              />
            </div>

            <div className='form-group'>
              <select
                name='vaccineType'
                value={vaccineType}
                onChange={c => onChange(c)}
                required
              >
                <option value=''>*Choose a Type</option>
                <option value='Pfizer'>Pfizer</option>
                <option value='AstraZeneca'>AstraZeneca</option>
                <option value='Sinovac'>Sinovac</option>
                <option value='Sputnik V'>Sputnik V</option>
                <option value='Johnson and Johnsons Janssen'>
                  Johnson and Johnson's Janssen
                </option>
                <option value='Bharat BioTech'>Bharat BioTech</option>
                <option value='Moderna'>Moderna</option>
                <option value='Sinopharm'> Sinopharm</option>
              </select>
            </div>

            <div className='form-group'>
              <h4>*First Dose Date</h4>
              <input
                type='date'
                name='fDoseDate'
                value={fDoseDate}
                onChange={c => onChange(c)}
                required
              />
            </div>
            <div className='form-group'>
              <p>
                <input
                  type='checkbox'
                  name='onScheduled'
                  value={onScheduled}
                  onChange={c => {
                    setFormData({ ...formData, onScheduled: !onScheduled });
                    toggleDisabled(!toDateDisabled);
                  }}
                />{" "}
                Second dose on scheduled?
              </p>
            </div>

            {onScheduled ? (
              <div className='form-group'>
                <h4 style={{ color: "red" }}>Scheduled date</h4>
                <input
                  type='date'
                  name='scheduledDate'
                  value={scheduledDate}
                  onChange={c => onChange(c)}
                  // disabled={toDateDisabled ? "disabled" : ""}
                />
              </div>
            ) : (
              <div className='form-group'>
                <h4>Second Dose Date</h4>
                <input
                  type='date'
                  name='sDoseDate'
                  value={sDoseDate}
                  onChange={c => onChange(c)}
                  // disabled={toDateDisabled ? "disabled" : ""}
                />
              </div>
            )}

            <div className='form-group'>
              <h4>Booster</h4>
              <input
                type='date'
                name='sBoosterDate'
                value={sBoosterDate}
                onChange={c => onChange(c)}
                // disabled={toDateDisabled ? "disabled" : ""}
              />

              <select
                type='text'
                name='boosterType'
                value={boosterType}
                onChange={c => onChange(c)}
              >
                <option value=''>Booster Type</option>
                <option value='Pfizer'>Pfizer</option>
                <option value='AstraZeneca'>AstraZeneca</option>
                <option value='Sinovac'>Sinovac</option>
                <option value='Sputnik V'>Sputnik V</option>
                <option value='Johnson and Johnsons Janssen'>
                  Johnson and Johnson's Janssen
                </option>
                <option value='Bharat BioTech'>Bharat BioTech</option>
                <option value='Moderna'>Moderna</option>
                <option value='Sinopharm'> Sinopharm</option>
              </select>
            </div>

            <div className='form-group'></div>

            <p className='lead'>Upload Photo of Vaccination Card</p>

            <div className='preview'>
              {!preview ? null : (
                <img
                  style={{
                    width: "80%",
                    padding: "10px",
                    borderRadius: "20px",
                  }}
                  src={preview}
                  alt=''
                />
              )}
            </div>
            <input
              className='btn btn-dark my-1'
              style={{ background: "#174052", width: "100%" }}
              // id='upload-button'
              ref={inputRef}
              type='file'
              onChange={c => onFileChange(c)}
              accept='image/*,video/mp4,video/x-m4v,video/*'
              multiple={false}
              placeholder={imageName}
              // style={{ display: "none" }}
            />
            <div />
            <div style={{ paddingBottom: "38px" }}></div>

            <input type='submit' className='btn btn-primary my-1' />
            <Link className='btn btn-mid my-1' to='/ID'>
              Go Back
            </Link>
            {!error && progress && <Progressbar progress={progress} />}
          </form>
        </div>
      </div>
    </Fragment>
  );
};

AddVaccineData.propTypes = {
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = { setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(AddVaccineData);
