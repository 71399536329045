import {
  SEARCH_USERS,
  SEARCH_USERS_ERROR,
  GET_USER,
  GET_USER_ERROR,
  ERROR_DELETE_USER_RESPONDER,
  DELETE_USER_RESPONDER,
  ERROR_ADD_USER_RESPONDER,
  ADD_USER_RESPONDER,
  CLEAR_USERS,
  CLEAR_USER,
  PUT_OPCEN,
  ERROR_PUT_OPCEN,
  GET_USERS,
  GET_USERS_FAIL,
  GET_VOLUNTEERS,
  GET_VOLUNTEERS_FAIL,
} from "../actions/types";

const initialState = {
  user: null,
  users: null,
  volunteers: null,
  responder: null,
  responder_status: null,
  loading: true,
  error: {},
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_USERS:
    case GET_USERS:
      return {
        ...state,
        users: payload, //from the action file
        loading: false,
      };

    case GET_VOLUNTEERS:
      return {
        ...state,
        volunteers: payload, //from the action file
        loading: false,
      };

    case ADD_USER_RESPONDER:
      return {
        ...state,
        responder_status: payload, //from the action file
        loading: false,
      };

    case PUT_OPCEN:
      return {
        ...state,
        responder: payload,
        loading: false,
      };
    case DELETE_USER_RESPONDER:
      return {
        ...state,
        responder_status: payload, //from the action file
        loading: false,
      };
    case GET_USER:
      return {
        ...state,
        responder: payload, //from the action file
        loading: false,
      };
    case CLEAR_USER:
      return {
        ...state,
        users: null,
        loading: true,
      };
    case SEARCH_USERS_ERROR:
    case ERROR_ADD_USER_RESPONDER:
    case GET_USER_ERROR:
    case ERROR_DELETE_USER_RESPONDER:
    case CLEAR_USERS:
    case GET_USERS_FAIL:
    case ERROR_PUT_OPCEN:
    case GET_VOLUNTEERS_FAIL:
      return {
        user: null,
        users: null,
        volunteers: null,
        responder_status: null,
        responder: null,
        loading: true,
        error: {},
      };
    default:
      return state;
  }
}
