import React, { Fragment, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ActiveVisualStat from "../incidentUser/ActiveVisualStat";
import { Redirect } from "react-router-dom";
import ActiveStatusETA from "../incidentUser/ActiveStatusETA";
import ActiveDetails from "../incidentUser/ActiveDetails";
import ActiveComms from "../incidentUser/ActiveComms";
import WaitingforOpcenModal from "./WaitingforOpcenModal";
import { getResponderbyIncidentUserId } from "../../actions/responder";
import { setAlert } from "../../actions/alert";
import { handleIncident } from "../../actions/incident";
import { volunteerIncidentClose } from "../../actions/incident";
import CancelModal from "./CancelModal";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;

const ActiveIncident = ({
  socket,
  getResponderbyIncidentUserId,
  responder,
  incident,
  setAlert,
  handleIncident,
  status,
  volunteerIncidentClose,
  incidentcancelled,
}) => {
  const [close, setClose] = useState(false);
  const [show, setShow] = useState(false);

  const [ringing, setRinging] = useState(false);
  const [callProgress, setCallProgress] = useState(false);
  const [callAccepted, setCallAccepted] = useState(false);

  const remoteVideoRef = useRef();
  const currentUserVideoRef = useRef();
  const [dispatcherID, setDispatcherID] = useState(null);

  console.log("Current video ref", currentUserVideoRef.current);

  let peerConnection;

  const defaulConstrains = {
    audio: false,
    video: {
      width: { min: 1024, ideal: 1280, max: 1920 },
      height: { min: 576, ideal: 720, max: 1080 },
    },
    // video: true,
  };

  const config = {
    iceServers: [
      { urls: [process.env.REACT_APP_ICE_SERVER_URLS] },
      {
        username: process.env.REACT_APP_ICE_SERVER_USERNAME,
        credential: process.env.REACT_APP_ICE_SERVER_CREDENTIAL,
        urls: [
          process.env.REACT_APP_ICE_SERVER_TURN_1,
          process.env.REACT_APP_ICE_SERVER_TURN_2,
          process.env.REACT_APP_ICE_SERVER_TURN_3,
          process.env.REACT_APP_ICE_SERVER_TURN_4,
          process.env.REACT_APP_ICE_SERVER_TURN_5,
          process.env.REACT_APP_ICE_SERVER_TURN_6,
        ],
      },
    ],
  };

  useEffect(() => {
    socket.on("getCallHandled", (data) => {
      getResponderbyIncidentUserId(data.dispatcher_userId);
      handleIncident();
    });
  }, [responder]);

  useEffect(() => {
    peerConnection = new RTCPeerConnection(config);

    navigator.mediaDevices.getUserMedia(defaulConstrains).then((stream) => {
      if (callAccepted) {
        currentUserVideoRef.current.srcObject = stream;
      }

      for (const track of stream.getTracks()) {
        peerConnection.addTrack(track, stream);
      }
    });

    peerConnection.ontrack = ({ streams: [stream] }) => {
      remoteVideoRef.current.srcObject = stream;
    };

    peerConnection.onicecandidate = (e) => {
      console.log("Geeting ice candidates ---->>>>>>>>>");
      if (e.candidate) {
        socket.emit("sendWebrtcCandidate", {
          // senderId: user._id,
          receiverId: dispatcherID,
          candidate: e.candidate,
        });
      }
    };

    peerConnection.onconnectionstatechange = (e) => {
      setCallProgress(true);
      if (peerConnection.connectionState === "connected") {
        console.log("peer area connected");
      } else {
        console.log("peer Disconneted");
      }
    };
  }, [callAccepted]);

  // Get Pre Offerrrrr

  useEffect(() => {
    socket.on("getPreOffer", (data) => {
      setRinging(true);
      setDispatcherID(data);
    });
  }, []);

  // Answer Pre Offer
  // tap the answer key
  const answerPreOffer = () => {
    if (responder) {
      setCallAccepted(true);
      socket.emit("sendAnswerPreOffer", { to: responder.user._id });
    } else {
      console.log("responder is null");
    }
  };

  //Get offfeer
  useEffect(() => {
    socket.on("getOffer", ({ receiverId, senderId, name, offer }) => {
      console.log("OFFFFFFFFFFFFFFFFFFFFFFFFFFER", offer);
      const getOffer = async () => {
        await peerConnection.setRemoteDescription(offer);
        const answer = await peerConnection.createAnswer();
        await peerConnection.setLocalDescription(answer);

        socket.emit("sendOfferAnswer", {
          to: senderId,
          answer: answer,
        });
      };
      getOffer();
    });
  }, [callAccepted]);

  // Handling Ice Candidate
  useEffect(() => {
    socket.on("getWebrtcCandidate", ({ senderId, candidate }) => {
      const getWebrtcCandidate = async () => {
        await peerConnection.addIceCandidate(candidate);
      };

      getWebrtcCandidate();
      console.log("getting ice candidate =============>", candidate);
    });
  }, []);

  useEffect(() => {
    socket.on(
      "getCloseIncident",
      ({ senderId, report, summary, opcenName, opcenProfilepic }) => {
        volunteerIncidentClose({
          senderId,
          report,
          summary,
          opcenName,
          opcenProfilepic,
          status: true,
        });
        setClose(true);
      }
    );
  }, []);

  const cancelIncident = () => {
    setShow(true);
  };

  const leaveCall = (id) => {
    setCallProgress(false);

    socket.emit("sendCallEnded", { receiverId: id, senderId: incident.user });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  useEffect(() => {
    socket.on("getCallEnded", (data) => {
      setCallProgress(false);
    });
  }, [callProgress]);

  if (close || incidentcancelled) {
    return <Redirect to="/incidentCloseOpcen" />;
  }

  return responder === null ? (
    <WaitingforOpcenModal />
  ) : !callProgress && !ringing ? (
    <div
      style={{
        position: "absolute",
        top: "57px",
        bottom: "0px",
        left: "0px",
        right: "0px",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="active-incident">
        <ActiveVisualStat />
        <ActiveComms
          socket={socket}
          responder={responder}
          incident={incident}
          close={close}
        />
        <ActiveStatusETA status={status} />
        <ActiveDetails incident={incident} />

        <div
          style={{
            width: "100%",
            height: "100px",
            marginTop: "5px",
            backgroundColor: "#fff",
            borderRadius: "0px  0px 5px 5px",
          }}
        >
          <div className="incident-partition-container">
            <div
              style={{
                width: "25%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                className="incident-icon-user shadow_me"
                src={`${serverURI}/${responder.profilee.profilepic}`}
                alt=""
              />
            </div>
            <div style={{ width: "75%", fontSize: "15px", fontWeight: "500" }}>
              <div>
                <p>{responder.opcen.name}</p>
              </div>
            </div>
          </div>
          <div>
            <p
              className="text-red"
              style={{
                fontSize: "10px",
                textAlign: "center",
                paddingTop: "5px",
              }}
            >
              ( OPERATION CENTER )
            </p>
          </div>
        </div>
        <button
          style={{
            borderRadius: "5px",
            width: "320px",
            height: "45px",

            padding: "13px",
            marginTop: "20px",
          }}
          className="btn-full alert-danger shadow_me"
          onClick={(e) => cancelIncident()}
        >
          <p style={{ fontSize: "14px" }}> CANCEL REPORT</p>
        </button>

        <CancelModal
          onClose={() => setShow(false)}
          show={show}
          socket={socket}
          incident={incident}
          responder={responder}
        />
      </div>
    </div>
  ) : ringing && !callAccepted ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        backgroundColor: "#215a75",
      }}
    >
      <audio autoPlay loop src="/sound/incidentSound/general.mp3"></audio>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <h2
            className="blink_me"
            style={{ marginBottom: "20%", color: "#dc3545" }}
          >
            Incoming video call
          </h2>
        </div>
        <img
          className="profile-img"
          src={`${serverURI}/${responder.profilee.profilepic}`}
          alt="..."
        />
        <div>
          <p style={{ color: "#fff" }}>{responder.opcen.name}</p>
        </div>

        <div>
          <p style={{ margin: "5px", color: "#fff" }}>
            Incident type: {incident.type}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "20%",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          <div
            className="btn btn-danger"
            style={{
              display: "flex",
              height: "60px",
              width: "60px",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "100%",
            }}
            onClick={() => leaveCall(responder.user._id)}
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
          <div
            className="btn btn-success"
            style={{
              display: "flex",
              height: "60px",
              width: "60px",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "100%",
            }}
            onClick={() => answerPreOffer()}
          >
            <i class="fa fa-phone" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>
  ) : callAccepted ? (
    <Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          backgroundColor: "#215a75",
        }}
      >
        {/* <div> {peerId}</div> */}
        <div
          style={{
            height: "100%",
            display: "flex",
            // alignItems: "center",
            justifyContent: "center",
          }}
        >
          <video
            style={{
              display: "flex",

              height: "100%",

              // position: "absolute",
              // top: "0",
              // bottom: "0",

              borderRadius: "5px",
              borderColor: "gray",
            }}
            alt="video"
            ref={currentUserVideoRef}
            autoPlay
          />
          {/* other video */}
          <div
            style={{
              position: "absolute",
              bottom: "10%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80px",
              width: "80px",
              backgroundColor: "#dc3545",
              borderRadius: "50%",
              zIndex: 99,
              cursor: "pointer",
            }}
            onClick={() => leaveCall(responder.user._id)}
          >
            <text style={{ color: "#fff", fontSize: "20px" }}>
              <i class="fa fa-times" aria-hidden="true"></i>
            </text>
          </div>
        </div>

        <video
          style={{
            display: "none",
            // background: "#DCDCDC",
            position: "absolute",
            padding: 20,
            borderRadius: "20%",
            width: "40%",
            height: "80%",
            zIndex: 99,
            bottom: 20,
          }}
          ref={remoteVideoRef}
          autoPlay
        />
      </div>
    </Fragment>
  ) : null;
};

ActiveIncident.propTypes = {
  getResponderbyIncidentUserId: PropTypes.func.isRequired,
  responder: PropTypes.object.isRequired,
  incident: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  handleIncident: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
  volunteerIncidentClose: PropTypes.func.isRequired,
  incidentcancelled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  responder: state.responder.responder,
  incident: state.incident.incident,
  status: state.incident.handleIncident,
  incidentcancelled: state.incident.incidentcancelled,
});

const mapDispatchToProps = {
  getResponderbyIncidentUserId,
  setAlert,
  handleIncident,
  volunteerIncidentClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveIncident);
