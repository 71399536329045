import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { searchOpcen } from "../../actions/opcen";

const SearchOpcen = ({ searchOpcen }) => {
  const [formData, setFormData] = useState({
    field: "",
  });
  const { field } = formData;
  const onChange = async c =>
    setFormData({ ...formData, [c.target.name]: c.target.value });
  const onSubmit = async c => {
    c.preventDefault();
    searchOpcen(field);
  };
  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <form className='form' onSubmit={c => onSubmit(c)}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: " 3fr .5fr",
              gridGap: "5px",

              margin: "2px 0px 2px 0px",
              width: "100%",
            }}
          >
            <input
              type='text'
              name='field'
              value={field}
              placeholder='Name, type or category'
              onChange={c => onChange(c)}
              required
            />
            <input
              type='submit'
              className='btn'
              style={{ paddingTop: "10px" }}
              value='Search'
            />
          </div>
        </form>
      </div>
    </Fragment>
  );
};

SearchOpcen.propTypes = {
  searchOpcen: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = { searchOpcen };

export default connect(mapStateToProps, mapDispatchToProps)(SearchOpcen);
