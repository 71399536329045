import React from "react";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const CallChart = ({ incidentCloseCount, incidentCancelledCount }) => {
  const data = {
    labels: ["Close", "Cancelled"],
    datasets: [
      {
        label: "# of Calls",
        data: [incidentCloseCount?.length, incidentCancelledCount?.length],
        backgroundColor: ["#28a745", "#dc3545"],
        borderColor: ["#28a745", "#dc3545"],
        borderWidth: 2,
      },
    ],
  };

  const options = {
    plugins: { legend: { display: false } },
    tooltips: {
      enabled: false,
    },
    layout: { padding: { bottom: 1, top: 10 } },
    //   scales: {
    //     y: {
    //       ticks: {
    //         color: "#333",
    //         font: {
    //           size: 10,
    //         },
    //       },
    //       grid: {
    //         color: "#333",
    //       },
    //     },
    //     x: {
    //       ticks: {
    //         color: "#333",
    //         font: {
    //           size: 10,
    //         },
    //       },
    //     },
    //   },
  };
  return (
    <div>
      <Doughnut
        style={{ height: "90px", width: "90px" }}
        data={data}
        options={options}
      />
    </div>
  );
};

export default CallChart;
