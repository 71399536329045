import {
  CREATE_INCIDENT_MSG,
  CREATE_INCIDENT_MSG_ERROR,
  CLEAR_INCIDENT_MSG,
  GET_INCIDENT_MESSAGES_ERROR,
  GET_INCIDENT_MESSAGES,
} from "../actions/types";

const initialState = {
  incident_msg_new: null,
  incident_messages: null,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_INCIDENT_MSG:
      return {
        ...state,
        incident_msg_new: payload, //from the action file
        loading: false,
      };

    case GET_INCIDENT_MESSAGES:
      return {
        ...state,
        incident_messages: payload, //from the action file
        loading: false,
      };

    case CREATE_INCIDENT_MSG_ERROR:
    case GET_INCIDENT_MESSAGES_ERROR:
      return {
        error: payload, //from the action file
      };
    case CLEAR_INCIDENT_MSG:
      return {
        incident_msg_new: null,
        incident_messages: null,
        loading: true,
      };

    default:
      return state;
  }
}
