import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getUser, getUsers } from "../../actions/users";
import { connect } from "react-redux";
import users from "../../reducers/users";
const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;
const VideoActiveUsers = ({ onlineUsers, getUser, users, getUsers }) => {
  return (
    <Fragment>
      <div className="chatOnline">
        {onlineUsers?.map((res) => (
          <div
            className="chatResponderOnline"
            // onClick={() => {
            //   handleClick(res.user._id);
            // }}
          >
            <Fragment>
              <div className="chatResponderImg">
                <img
                  className="converationImg"
                  src={`${serverURI}/OP-1637642488300.png`}
                  alt=""
                />

                <div className="chatBadge"></div>
              </div>
              <p className="converationName">
                {res.name} {res.lname}
              </p>
            </Fragment>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

VideoActiveUsers.propTypes = {
  getUser: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  users: state.users.users,
});

const mapDispatchToProps = { getUser, getUsers };

export default connect(mapStateToProps, mapDispatchToProps)(VideoActiveUsers);
