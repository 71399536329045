import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { QRCode } from "react-qrcode-logo";
import { connect } from "react-redux";
const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;
const ProfileTop = ({
  auth,
  profile: {
    status,
    profilepic,
    organization,
    location,
    website,
    social,
    user: { _id, name, lname, avatar },
  },
}) => {
  return (
    <div className='profile-top bg-primary p-2'>
      <div>
        <img
          className='profile-img'
          src={`${serverURI}/${profilepic}`}
          alt='...'
        />
      </div>
      {/* {!auth.loading && _id === auth.user._id && (
        <div className='qr-code'>
          <QRCode
            size='160'
            includeMargin='true'
            fgColor='#000000'
            value={_id}
          />
        </div>
      )} */}

      <h1>
        {name} {lname}
      </h1>
      <p className='small-txt-wht'>
        {status} {organization && <p> at {organization}</p>}
      </p>
      <p className='small-txt-wht'>{location && <span> at {location}</span>}</p>
      <div className='icons my-1'>
        {website && (
          <a href={website} target='_blank'>
            <i className='fas fa-globe fa-2x'></i>
          </a>
        )}
        {social?.twitter && social?.twitter !== "undefined" && (
          <a href={social?.twitter} target='_blank' rel='noopener noreferrer'>
            <i className='fab fa-twitter fa-2x'></i>
          </a>
        )}

        {social?.facebook && social?.facebook !== "undefined" && (
          <a href={social?.facebook} target='_blank' rel='noopener noreferrer'>
            <i className='fab fa-facebook fa-2x'></i>
          </a>
        )}

        {social?.linkedin && social?.linkedin !== "undefined" && (
          <a href={social?.linkedin} target='_blank' rel='noopener noreferrer'>
            <i className='fab fa-linkedin fa-2x'></i>
          </a>
        )}

        {social?.youtube && social?.youtube !== "undefined" && (
          <a href={social?.youtube} target='_blank' rel='noopener noreferrer'>
            <i className='fab fa-youtube fa-2x'></i>
          </a>
        )}

        {social?.instagram && social?.instagram !== "undefined" && (
          <a to={social?.instagram} target='_blank' rel='noopener noreferrer'>
            <i className='fab fa-instagram fa-2x'></i>
          </a>
        )}
      </div>
    </div>
  );
};

ProfileTop.propTypes = {
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps, null)(ProfileTop);
