import {
  CALL_SET_LOCAL_STREAM,
  ERROR_CALL_SET_LOCAL_STREAM,
  callStates,
  CALL_SET_CALL_STATE,
} from "../actions/types";

const initialState = {
  localStream: null,
  callState: callStates.CALL_UNAVAILABLE,
  callingDialogVisible: false,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CALL_SET_LOCAL_STREAM:
      console.log("reducer payload", payload);
      return {
        ...state,
        localStream: payload,
        loading: false,
      };
    case ERROR_CALL_SET_LOCAL_STREAM:
      return {
        localStream: null,
        loading: true,
        error: {},
      };
    case CALL_SET_CALL_STATE:
      return {
        ...state,
        callState: payload,
        loading: false,
      };

    default:
      return state;
  }
}
