import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { clearUser } from "../../actions/users";

const PartnerMenu = ({ clearUser }) => {
  return (
    <Fragment>
      <div
        className=' main-container align-vertically'
        style={{
          height: "390px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "150px",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Link
            className='bigIcon'
            to='/PartnerIndividual'
            onClick={() => clearUser()}
          >
            <div style={{ padding: "12px" }}>
              <i
                class='fa fa-users'
                aria-hidden='true'
                style={{ padding: "15px" }}
              ></i>
              <p style={{ fontSize: "15px", lineHeight: "10px" }}>Individual</p>
              <div
                style={{
                  padding: "8px 0px",
                  fontSize: "10px",
                  lineHeight: "10px",
                  color: "#333",
                }}
              >
                (add page collaborator and co-admin)
              </div>
            </div>
          </Link>
          <Link className='bigIcon' to={`/operation-center/responder`}>
            <div style={{ padding: "12px" }}>
              <i
                class='fa fa-building-o'
                aria-hidden='true'
                style={{ padding: "15px" }}
              ></i>
              <p style={{ fontSize: "15px", lineHeight: "10px" }}>
                Organization
              </p>
              <div
                style={{
                  padding: "8px 0px",
                  fontSize: "10px",
                  lineHeight: "10px",
                  color: "#333",
                }}
              >
                (manage groups outside your opcen)
              </div>
            </div>
          </Link>
        </div>
      </div>
    </Fragment>
  );
};
PartnerMenu.propTypes = {
  clearUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = { clearUser };

export default connect(mapStateToProps, mapDispatchToProps)(PartnerMenu);
