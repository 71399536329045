import axios from "axios";
import { setAlert } from "./alert";
import {
  CHECK_IN_DISPATCH,
  CHECK_OUT_DISPATCH,
  DISPATCH_ON_CALL,
  DISPATCH_AVAILABLE,
  GET_ALL_DISPATCHERS,
  GET_ALL_DISPATCHERS_ERROR,
} from "./types";

export const checkinDispatch =
  ({ responderId, user_id, name, lname, opcen_id, online, updatedAt }) =>
  async dispatch => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      responderId,
      user_id,
      name,
      lname,
      opcen_id,
      online,
      updatedAt,
    });

    try {
      const res = await axios.post("/api/dispatchOnline", body, config);
      dispatch({
        type: CHECK_IN_DISPATCH,
        payload: res.data,
      });

      dispatch(setAlert("Check-in Success", "success"));
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
      }
    }
  };

export const checkOutDispatch =
  ({ responderId, user_id, name, lname, opcen_id, online, updatedAt }) =>
  async dispatch => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      responderId,
      user_id,
      name,
      lname,
      opcen_id,
      online,
      updatedAt,
    });

    try {
      const res = await axios.post("/api/dispatchOnline", body, config);
      dispatch({
        type: CHECK_OUT_DISPATCH,
        payload: res.data,
      });

      dispatch(setAlert("Check-out Success", "danger"));
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
      }
    }
  };

export const onCall = () => async dispatch => {
  try {
    dispatch({
      type: DISPATCH_ON_CALL,
      payload: true,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const available = () => async dispatch => {
  try {
    dispatch({
      type: DISPATCH_AVAILABLE,
      payload: false,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const getDispatchersOnline = () => async dispatch => {
  try {
    const res = await axios.get(`/api/dispatchOnline`);
    dispatch({
      type: GET_ALL_DISPATCHERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_DISPATCHERS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
