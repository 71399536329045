import axios from "axios";
import {
  SEARCH_USERS,
  SEARCH_USERS_ERROR,
  GET_USER,
  GET_USER_ERROR,
  ADD_USER_RESPONDER,
  ERROR_ADD_USER_RESPONDER,
  DELETE_USER_RESPONDER,
  ERROR_DELETE_USER_RESPONDER,
  PUT_OPCEN,
  ERROR_PUT_OPCEN,
  GET_USERS,
  GET_USERS_FAIL,
  GET_VOLUNTEERS,
  GET_VOLUNTEERS_FAIL,
  CLEAR_USERS,
  CLEAR_USER,
} from "./types";
import { setAlert } from "./alert";

// get All Volunter Users
export const getVolunteers = () => async dispatch => {
  try {
    const res = await axios.get(`/api/users/volunteer/all`);
    dispatch({
      type: GET_VOLUNTEERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_VOLUNTEERS,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// get All users

export const getUsers = () => async dispatch => {
  try {
    const res = await axios.get(`/api/users`);
    dispatch({
      type: GET_USERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_USERS_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const searchUser = field => async dispatch => {
  try {
    const res = await axios.get(`/api/users/${field}`);

    dispatch({
      type: SEARCH_USERS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: SEARCH_USERS_ERROR,
    });
  }
};

export const getUser = id => async dispatch => {
  try {
    const res = await axios.get(`/api/users/responder/${id}`);

    dispatch({
      type: GET_USER,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: GET_USER_ERROR,
    });
  }
};

// Add responder status to User

export const addUserResponder =
  ({ opcen, type, user }) =>
  async dispatch => {
    const body = JSON.stringify({
      opcen,
      type,
      user,
    });

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.put("/api/users/responder", body, config);

      dispatch({
        type: ADD_USER_RESPONDER,
        payload: res.data,
      });
      dispatch({ type: CLEAR_USERS });
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: ERROR_ADD_USER_RESPONDER,
      });
    }
  };

export const deleteUserResponder = (user_id, resp_id) => async dispatch => {
  try {
    const res = await axios.delete(
      `/api/users/responder/${user_id}/${resp_id}`
    );
    dispatch({
      type: DELETE_USER_RESPONDER,

      payload: res.data,
    });

    // dispatch(setAlert("Responder Deleted", "success"));
  } catch (err) {
    dispatch({
      type: ERROR_DELETE_USER_RESPONDER,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add opcen status to User

export const putOpcen = (user, opcen) => async dispatch => {
  const body = JSON.stringify({
    user,
    opcen,
  });
  console.log("REQUEST BODY", body);
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.put("/api/users/opcen", body, config);

    dispatch({
      type: PUT_OPCEN,
      payload: res.data,
    });
    dispatch(setAlert("Operation center added", "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: ERROR_PUT_OPCEN,
    });
  }
};

export const clearUser = () => dispatch => {
  try {
    dispatch({
      type: CLEAR_USER,
    });
  } catch (err) {
    dispatch({
      type: ERROR_PUT_OPCEN,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
