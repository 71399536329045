import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import { getVolunteers } from "../../actions/users";
import { getResponderbyOpcen } from "../../actions/responder";
import { incidentToday } from "../../actions/incident";
import { getPosts } from "../../actions/post";
import { getIncidentCancelled } from "../../actions/incident";
import { getIncidentClose } from "../../actions/incident";
import { clearUser } from "../../actions/users";

const Dashboard_opcen = ({
  opcen: { _id, user, name, category, type, discription },
  opcenprofile: { profile, profiles, loading },
  vaccinations: { vaxs, vax },
  getVolunteers,
  getResponderbyOpcen,
  incidentToday,
  getPosts,
  getIncidentClose,
  getIncidentCancelled,
  clearUser,
}) => {
  useEffect(() => {
    getVolunteers();
    getResponderbyOpcen(_id);
    incidentToday();
    getPosts();
    // eslint-disable-next-line
  }, [getVolunteers, getResponderbyOpcen, incidentToday, getPosts]);

  useEffect(() => {
    getIncidentCancelled();
    getIncidentClose();
  }, [getIncidentCancelled, getIncidentClose]);

  // Filter Array within an Array
  const opcenVaxData = vaxs?.filter((el) => {
    return (
      el.validation.length === 0 &&
      el.user.operation_center.filter((op) => {
        return op.opcen === _id;
      })
    );
  });

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 2px 0px, 2px",
          background: "#fff",
          height: "90vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "930px", margin: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-around",
              height: "150px",
            }}
          >
            <div className="smallIcon">
              {profile === null ? (
                <Link to="/addoperation-center/profile">
                  <div>
                    <div>
                      <p style={{ height: "40px" }}>
                        <i
                          className="fa fa-building fa-2x"
                          aria-hidden="true"
                        ></i>
                      </p>

                      <p style={{ fontSize: "12px", lineHeight: "10px" }}>
                        Profile
                      </p>
                      <div
                        style={{
                          fontSize: "10px",
                          lineHeight: "10px",
                          color: "#333",
                        }}
                      >
                        (update your opcen profile)
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          padding: "3px",
                        }}
                      ></div>
                    </div>
                  </div>
                </Link>
              ) : (
                <Link to={`/edit-operation-center/profile/${profile._id}`}>
                  <div>
                    <div>
                      <p style={{ height: "40px" }}>
                        <i
                          className="fa fa-building fa-2x"
                          aria-hidden="true"
                        ></i>
                      </p>

                      <p style={{ lineHeight: "16px" }}>Edit Profile</p>
                      <div
                        style={{
                          fontSize: "10px",
                          lineHeight: "10px",
                          color: "#333",
                        }}
                      >
                        (update your opcen profile)
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          padding: "3px",
                        }}
                      ></div>
                    </div>
                  </div>
                </Link>
              )}
            </div>

            <Link
              className="smallIcon"
              to={`/operation-center/responder`}
              onClick={() => clearUser()}
            >
              <div>
                <p style={{ height: "40px" }}>
                  <i className="fa fa-users fa-2x" aria-hidden="true"></i>
                </p>

                <p style={{ lineHeight: "16px" }}>Responders</p>
                <div
                  style={{
                    fontSize: "10px",
                    lineHeight: "10px",
                    color: "#333",
                  }}
                >
                  (manage your responders)
                </div>
              </div>
            </Link>
            <Link className="smallIcon">
              <div>
                <p style={{ height: "40px" }}>
                  {" "}
                  <i className="fa fa-cogs fa-2x" aria-hidden="true"></i>
                </p>

                <p style={{ lineHeight: "16px" }}>Teams</p>
                <div
                  style={{
                    fontSize: "10px",
                    lineHeight: "10px",
                    color: "#333",
                  }}
                >
                  (manage/monitor your teams)
                </div>
              </div>
            </Link>
            <Link className="smallIcon">
              <div>
                <p style={{ height: "40px" }}>
                  <i className="fa fa-ambulance fa-2x" aria-hidden="true"></i>
                </p>

                <i aria-hidden="true"></i>
                <p style={{ lineHeight: "16px" }}>Vehicles</p>
                <div
                  style={{
                    fontSize: "10px",
                    lineHeight: "10px",
                    color: "#333",
                  }}
                >
                  (manage your mobile assets)
                </div>
              </div>
            </Link>
            <Link className="smallIcon">
              <div>
                <p style={{ height: "40px" }}>
                  <i className="fa fa-h-square fa-2x" aria-hidden="true"></i>
                </p>

                <p style={{ lineHeight: "16px" }}>Facilities</p>
                <div
                  style={{
                    fontSize: "10px",
                    lineHeight: "10px",
                    color: "#333",
                  }}
                >
                  (facilities operating/monitoring)
                </div>
              </div>
            </Link>
          </div>
        </div>

        {/* Another row start */}

        <div style={{ width: "930px", margin: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <Link className="bigIcon">
              <div>
                <p style={{ height: "40px" }}>
                  {" "}
                  <i className="fa fa-map fa-2x" aria-hidden="true"></i>
                </p>

                <p>Live Map</p>
                <div
                  style={{
                    fontSize: "10px",
                    lineHeight: "10px",
                    color: "#333",
                  }}
                >
                  (current location of your responders and other resources)
                </div>
              </div>
            </Link>
            <Link className="bigIcon" to="/notifMenu">
              <div>
                <p style={{ height: "40px" }}>
                  <i className="fa fa-bullhorn fa-2x" aria-hidden="true"></i>
                </p>

                <p>Notifications</p>
                <div
                  style={{
                    fontSize: "10px",
                    lineHeight: "10px",
                    color: "#333",
                  }}
                >
                  (notify your contituents about urgent matters concerning the
                  city)
                </div>
              </div>
            </Link>
            <Link className="bigIcon" to="/opcen-dashboard">
              <div>
                <i className="fa fa-area-chart fa-2x" aria-hidden="true"></i>
                <i aria-hidden="true"></i>
                <p>Incident</p>
                <div
                  style={{
                    fontSize: "10px",
                    lineHeight: "10px",
                    color: "#333",
                  }}
                >
                  (dashboard of current incidents the opcen is addressing)
                </div>
              </div>
            </Link>
            <Link to="/dispatch" className="bigIcon">
              <div>
                <i className="fa fa-headphones fa-2x" aria-hidden="true"></i>
                <i aria-hidden="true"></i>
                <p>Dispatch</p>
                <div
                  style={{
                    fontSize: "10px",
                    lineHeight: "10px",
                    color: "#333",
                  }}
                >
                  (emergency dispatch operator log-in)
                </div>
              </div>
            </Link>
          </div>
        </div>
        {/* Another Row */}

        <div style={{ width: "930px", margin: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-around",
              height: "150px",
            }}
          >
            <Link className="smallIcon" to="/vax_list">
              <div>
                <div
                  className={opcenVaxData?.length === 0 ? "displayNone" : null}
                  style={{ position: "relative" }}
                >
                  <p
                    style={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "70px",
                      color: "#fff",
                      background: "#dc3545",
                      borderRadius: "100%",
                      fontSize: "14px",
                    }}
                  >
                    {opcenVaxData?.length}
                  </p>
                </div>
                <p style={{ height: "40px" }}>
                  <i className="fa fa-id-badge fa-2x" aria-hidden="true"></i>
                </p>

                <i aria-hidden="true"></i>
                <p style={{ lineHeight: "16px" }}>ID's/Passes</p>
                <div
                  style={{
                    fontSize: "10px",
                    lineHeight: "10px",
                    color: "#333",
                  }}
                >
                  (issue identifaction for access)
                </div>
              </div>
            </Link>
            <Link className="smallIcon" to="/volunteers">
              <div>
                <p style={{ height: "40px" }}>
                  <i className="fa fa-male fa-2x" aria-hidden="true"></i>
                </p>

                <p style={{ lineHeight: "16px" }}>Volunteers</p>
                <div
                  style={{
                    fontSize: "10px",
                    lineHeight: "10px",
                    color: "#333",
                  }}
                >
                  (manage you volunteers)
                </div>
              </div>
            </Link>
            <Link className="smallIcon" to="/partnermenu">
              <div>
                <p style={{ height: "40px" }}>
                  <i className="fa fa-handshake-o fa-2x"></i>
                </p>

                <p style={{ lineHeight: "16px" }}>Partners</p>
                <div
                  style={{
                    fontSize: "10px",
                    lineHeight: "10px",
                    color: "#333",
                  }}
                  className="small-txt-blk"
                >
                  (partner groups and individuals)
                </div>
              </div>
            </Link>
            <Link className="smallIcon" to="/videoCall">
              <div>
                <p style={{ height: "40px" }}>
                  <i className="fa fa-book fa-2x" aria-hidden="true"></i>
                </p>

                <i aria-hidden="true"></i>
                <p style={{ lineHeight: "16px" }}>Inventory</p>
                <div
                  style={{
                    fontSize: "10px",
                    lineHeight: "10px",
                    color: "#333",
                  }}
                  className="small-txt-blk"
                >
                  (download and/or retrieve )
                </div>
              </div>
            </Link>
            <Link className="smallIcon">
              <div>
                <p style={{ height: "40px" }}>
                  <i className="fa fa-tachometer fa-2x " aria-hidden="true"></i>
                </p>

                <i aria-hidden="true"></i>
                <p style={{ lineHeight: "16px" }}>Reports</p>
                <div
                  style={{
                    fontSize: "10px",
                    lineHeight: "10px",
                    color: "#333",
                  }}
                  className="small-txt-blk"
                >
                  (download and/or retrieve )
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Dashboard_opcen.propTypes = {
  opcen: PropTypes.object.isRequired,
  opcenprofile: PropTypes.object.isRequired,
  getVolunteers: PropTypes.func.isRequired,
  getResponderbyOpcen: PropTypes.func.isRequired,
  incidentToday: PropTypes.func.isRequired,
  getPosts: PropTypes.func.isRequired,
  getIncidentClose: PropTypes.func.isRequired,
  getIncidentCancelled: PropTypes.func.isRequired,
  vaccination: PropTypes.func.isRequired,
  clearUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  opcen: state.opcen.opcen,
  opcenprofile: state.opcen_profile,
  vaccinations: state.vaccination,
});

const mapDispatchToProps = {
  getVolunteers,
  getResponderbyOpcen,
  incidentToday,
  getPosts,
  getIncidentCancelled,
  getIncidentClose,
  clearUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard_opcen);
