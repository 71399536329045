import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { deleteComment, deletePost, getPost } from "../../actions/post";
import { connect } from "react-redux";
import EditPost from "./EditPost";
import EditComment from "./EditComment";

const CRUDModal = ({
  showCRUD,
  onClose,
  postId,
  _id,
  deletePost,
  getPost,
  commentFlag,
  deleteComment,
  editText,
}) => {
  const [show, setShow] = useState(false);
  const [showEditComment, setShowEditComment] = useState(false);
  const refreshPage = () => {
    setTimeout(function () {
      window.location.reload(false);
    }, 2 * 1000);
  };
  if (!showCRUD) {
    return null;
  }
  return (
    <Fragment>
      <div className={"menu_post_small"} onClick={onClose}>
        <div className='menu_post' onClick={e => e.stopPropagation()}>
          {/* if comment Flag True */}
          {commentFlag ? (
            <div className='menu_post_container'>
              <div
                onClick={c => {
                  // Get Comment ID
                  setShowEditComment(true);
                  // onClose();
                }}
                className='menu-item'
              >
                <i class='fa fa-pencil' aria-hidden='true'></i>{" "}
                <span
                  style={{
                    width: "100%",
                    marginLeft: "8px",
                    textAlign: "center",
                  }}
                >
                  Edit
                </span>
              </div>
              <div
                onClick={c => {
                  deleteComment(postId, _id);
                  // refreshPage();
                }}
                className='menu-item'
              >
                <i className='fa fa-trash-o fa-xs' aria-hidden='true'></i>
                <span
                  style={{
                    width: "100%",
                    marginLeft: "8px",
                    textAlign: "center",
                  }}
                >
                  Delete
                </span>
              </div>
            </div>
          ) : (
            <div className='menu_post_container'>
              <div
                onClick={c => {
                  getPost(_id);
                  setShow(true);
                  // onClose();
                }}
                // onClick={onClose}
                className='menu-item'
              >
                <i class='fa fa-pencil' aria-hidden='true'></i>{" "}
                <span
                  style={{
                    width: "100%",
                    marginLeft: "8px",
                    textAlign: "center",
                  }}
                >
                  Edit
                </span>
              </div>
              <div
                onClick={c => {
                  deletePost(_id);
                  refreshPage();
                }}
                className='menu-item'
              >
                <i className='fa fa-trash-o fa-xs' aria-hidden='true'></i>
                <span
                  style={{
                    width: "100%",
                    marginLeft: "8px",
                    textAlign: "center",
                  }}
                >
                  Delete
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <EditPost show={show} onClose={() => setShow(false)} />
      <EditComment
        showEditComment={showEditComment}
        onClose={() => setShowEditComment(false)}
        postId={postId}
        _id={_id}
        editText={editText}
      />
    </Fragment>
  );
};

CRUDModal.propTypes = {
  deletePost: PropTypes.func.isRequired,
  getPost: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { deletePost, getPost, deleteComment })(
  CRUDModal
);
