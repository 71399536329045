import axios from "axios";
import { setAlert } from "./alert";
import {
  SUSPEND_USER,
  SUSPEND_USER_ERROR,
  GET_ALL_SUSPENDED_USERS,
} from "./types";

export const suspendUser =
  (user_id, email, issuing_opcen) => async dispatch => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ user_id, email, issuing_opcen });

    console.log("Suspend User Body", body);
    try {
      const res = await axios.post("api/suspendUser", body, config);
      dispatch({
        type: SUSPEND_USER,
        payload: res.data,
      });
      dispatch(setAlert("Added to suspended users.", "danger"));
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: SUSPEND_USER_ERROR,
      });
    }
  };

export const getSuspendedUsers = () => async dispatch => {
  try {
    const res = await axios.get(`/api/suspendUser/`);
    dispatch({
      type: GET_ALL_SUSPENDED_USERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SUSPEND_USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Delete post

export const removeSuspended = user_id => async dispatch => {
  try {
    await axios.delete(`/api/suspendUser/${user_id}`);

    dispatch(setAlert("Volunteer Reinstated", "success"));
  } catch (err) {
    dispatch({
      type: SUSPEND_USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
