import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

export const CallingDialog = (props) => {
  return <div className="calling_dialog">Calling Dialog</div>;
};

CallingDialog.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CallingDialog);
