import { setCallState, setLocalStream } from "../../actions/callAction";
import { callStates } from "../../actions/types";
import { store } from "../../store";

const defaulConstrains = {
  audio: true,
  video: {
    width: { min: 1024, ideal: 1280, max: 1920 },
    height: { min: 576, ideal: 720, max: 1080 },
  },
};

export const getLocalStream = () => {
  navigator.mediaDevices
    .getUserMedia(defaulConstrains)
    .then((stream) => {
      store.dispatch(setLocalStream(stream));
      store.dispatch(setCallState(callStates.CALL_AVAILABLE));
    })
    .catch((err) => {
      console.log("error getting stream", err);
    });
};
