import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dashboard from "../opcens/Dashboard_opcen";
import Spinner from "../layout/Spinner";
import { getOpcenProfileById } from "../../actions/opcenprofile";
import { getOpcens } from "../../actions/opcen";
import { withRouter } from "react-router";
import { getIncidents } from "../../actions/incident";
import { Redirect, useHistory } from "react-router-dom";
import { getVaccinations } from "../../actions/vaccination";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;
const OpcenConsole = ({
  user,
  getOpcenProfileById,
  opcen_id,
  opcen,
  opcen_profile: { profile, loading },
  getIncidents,
  getVaccinations,
}) => {
  let history = useHistory();

  useEffect(() => {
    getOpcens(user);
    getOpcenProfileById(opcen_id);
    getIncidents();
    getVaccinations();
  }, [getOpcenProfileById, getVaccinations]);

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div
        style={{
          position: "fixed",
          top: "57px",
          left: "0px",
          right: "0px",
          bottom: "0px",

          background: "#fff",
        }}
      >
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            background: "#174052",
            marginBottom: "2px",
            height: "12%",
            width: "100%",
            color: "#fff",
          }}
        >
          <div
            style={{
              height: "60px",
              width: "60px",
              padding: "2px",
              marginLeft: "2%",
            }}
          >
            {profile === null ? (
              <img
                className='post-profile shadow_me'
                src={`/opcenlogo/guardian.png`}
                alt=''
              />
            ) : (
              <div>
                <img
                  className='post-profile shadow_me'
                  src={`${serverURI}/${profile.logo}`}
                  alt=''
                />
              </div>
            )}
          </div>
          <div style={{ marginLeft: "15px", lineHeight: "18px" }}>
            <p style={{ fontSize: "20px", fontWeight: "600" }}>{opcen.name}</p>
            <p style={{ fontSize: "12px", fontWeight: "200" }}>
              {opcen.category}
            </p>
          </div>
          <div style={{ alignItems: "center", margin: "auto" }}>
           
          </div>
          <div
            style={{
              width: "40px",
              marginRight: "20px",
              padding: "10px",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={history.goBack}
          >
            <i className='fa fa-long-arrow-left' aria-hidden='true'></i>
          </div>
        </div> */}
        <Dashboard />
      </div>
    </Fragment>
  );
};

OpcenConsole.propTypes = {
  getOpcenProfileById: PropTypes.func.isRequired,
  opcen: PropTypes.object.isRequired,
  opcen_id: PropTypes.object.isRequired,
  opcen_profile: PropTypes.object.isRequired,
  getVaccinations: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  opcen: state.opcen.opcen,
  opcen_id: state.opcen.opcen._id,
  opcen_profile: state.opcen_profile,
  user: state.auth._id,
});

export default connect(mapStateToProps, {
  getOpcenProfileById,
  getIncidents,
  getOpcens,
  getVaccinations,
})(withRouter(OpcenConsole));
