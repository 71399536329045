import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import IncidentMessages from "../dispatch/IncidentMessages";
import Dropzone from "react-dropzone";
import axios from "axios";
import Progressbar from "../layout/ProgressBar";
import { setAlert } from "../../actions/alert";
import { useHistory, Redirect } from "react-router-dom";
import { volunteerIncidentClose } from "../../actions/incident";

const IncidentMessenger = ({
  incident,
  user,
  socket,
  responder,
  profilepic,
  opcenname,
  volunteerIncidentClose,
}) => {
  let history = useHistory();
  const [newMessage, setNewMessage] = useState("");
  const scrollRef = useRef(null);
  const [arrivalMessage, setArrivalMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [progress, setProgress] = useState();
  const [error, setError] = useState();
  const [close, setClose] = useState(false);

  useEffect(() => {
    socket.on("getMessage", data => {
      if (data.senderId === responder._id) {
        setArrivalMessage({
          sender: data.senderId,
          text: data.text,
          createdAt: Date.now(),
        });
      }
    });
  }, []);

  useEffect(() => {
    socket.on(
      "getCloseIncident",
      ({ senderId, report, summary, opcenName, opcenProfilepic }) => {
        volunteerIncidentClose({
          senderId,
          report,
          summary,
          opcenName,
          opcenProfilepic,
          status: true,
        });
        setClose(true);
      }
    );
  }, []);

  useEffect(() => {
    arrivalMessage && setMessages(prev => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    const getIncidentMessages = async () => {
      try {
        const res = await axios.get(`/api/incident_msg/${incident._id}`);
        setMessages(res.data);
      } catch (err) {
        setAlert(err, "danger");
      }
    };

    getIncidentMessages();
  }, [incident]);

  useEffect(() => {
    if (progress === 100) {
      setProgress();
    }
  }, [messages]);

  const onDrop = async files => {
    try {
      const formData = new FormData();
      formData.append("file", files[0]);
      setError("");
      const res = await axios.post("/api/incident_msg/attachment", formData, {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      });

      if (res.data.success === false) {
        {
          const { code } = res.data.code;
          switch (code) {
            case "FILE_MISSING":
              setError("Please select a file before uploading!");
              break;
            case "LIMIT_FILE_SIZE":
              setError(
                "File size is too large. Please upload files below 1MB!"
              );
              break;
            case "INVALID_TYPE":
              setError(
                "This file type is not supported! Only .png, .jpg and .jpeg files are allowed"
              );
              break;
            default:
              setError("Sorry! Something went wrong. Please try again later");
              break;
          }

          // Send Error
          setAlert({ error }, "danger");
        }
      } else if (res.data.success) {
        // Here
        const message = {
          incidentId: incident._id,
          sender: user._id,
          text: res.data.url.substring(
            res.data.url.length - 22,
            res.data.url.length
          ),
        };

        const receiverId = responder._id;

        socket.emit("sendMessage", {
          senderId: user._id,
          receiverId,
          text: res.data.url.substring(
            res.data.url.length - 22,
            res.data.url.length
          ),
        });

        try {
          const res = await axios.post("/api/incident_msg", message);
          setMessages([...messages, res.data]);
          setNewMessage("");
        } catch (err) {
          setAlert(err, "danger");
        }
      }
    } catch (err) {
      const errors = err.response.data.errors;

      setAlert(`error Messenger", ${errors}`, "danger");
    }
  };

  const onSubmit = async c => {
    c.preventDefault();
    const sendMessage = newMessage.trim();
    if (sendMessage.length > 0) {
      const message = {
        incidentId: incident._id,
        sender: user._id,
        text: sendMessage,
      };

      const receiverId = responder._id;

      try {
        const res = await axios.post("api/incident_msg", message);

        setMessages([...messages, res.data]);
        setNewMessage("");
        setProgress();

        socket.emit("sendMessage", {
          senderId: user._id,
          receiverId,
          text: sendMessage,
        });
      } catch (err) {
        setAlert(err, "danger");
      }
    }
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  }, [newMessage]);

  const handleBack = () => {
    history.goBack();
  };

  if (close) {
    return <Redirect to='/incidentCloseOpcen' />;
  }
  return (
    <Fragment>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className='incidentChatHeader'
            style={{ borderRadius: "5px 5px 0px 0px", height: "40px" }}
          >
            <p style={{ marginLeft: "10px" }}> {opcenname.name}</p>
            <div
              style={{
                display: "flex",
                width: "20px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={handleBack}
              >
                <i className='fa fa-times-circle' aria-hidden='true'></i>
              </div>
            </div>
          </div>
          <div className='incident-chat'>
            {messages?.map(m => (
              <div ref={scrollRef}>
                <IncidentMessages
                  profilepic={profilepic}
                  message={m}
                  own={m.sender === user._id}
                />
              </div>
            ))}
          </div>
          <div className='chat-input-incident' style={{ background: "#fff" }}>
            <form className='incident-chat-Box' onSubmit={onSubmit}>
              <input
                type='text'
                className='incident-chat-Input'
                placeholder='Type a message'
                onChange={e => setNewMessage(e.target.value)}
                value={newMessage}
                onSubmit={onSubmit}
                autoFocus
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <Dropzone
                  accept='image/*,video/mp4,video/x-m4v,video/*'
                  onDrop={onDrop}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div
                          className='image-button'
                          style={{
                            color: "#215a75",
                          }}
                        >
                          <i className='fa fa-paperclip' aria-hidden='true'></i>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
                <button className='btn btn-success' type='submit'>
                  <div className='hide-sm'>
                    {" "}
                    <i class='fas fa-paper-plane'></i>
                  </div>

                  <span style={{ paddingLeft: "10px" }}>Send</span>
                </button>
              </div>
            </form>

            {!error && progress && <Progressbar progress={progress} />}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

IncidentMessenger.propTypes = {
  user: PropTypes.object.isRequired,
  incident: PropTypes.object.isRequired,
  incident_messages: PropTypes.object.isRequired,
  responder: PropTypes.object.isRequired,
  profilepic: PropTypes.object.isRequired,
  opcenname: PropTypes.object.isRequired,
  volunteerIncidentClose: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  incident_messages: state.incident_msg.incident_messages,
  user: state.auth.user,
  incident: state.incident.incident,
  responder: state.responder.responder.user,
  profilepic: state.responder.responder.profilee.profilepic,
  opcenname: state.responder.responder.opcen,
});

const mapDispatchToProps = {
  volunteerIncidentClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentMessenger);
