import React, { Fragment, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addExperience } from "../../actions/profile";

const AddExperience = ({ addExperience, history }) => {
  const [formData, setFormData] = useState({
    title: "",
    company: "",
    location: "",
    from: "",
    to: "",
    current: false,
    description: "",
  });
  //disable to date if still connected to experience
  const [toDateDisabled, toggleDisabled] = useState(false);

  const { company, title, location, from, to, current, description } = formData;

  const onChange = async c =>
    setFormData({ ...formData, [c.target.name]: c.target.value });

  const onSubmit = async c => {
    c.preventDefault();
    addExperience(formData, history);
  };

  return (
    <Fragment>
      <div className='  main-container align-vertically'>
        <div className='sub-container'>
          <h2 className='text-primary'>Trainings/Job Experience</h2>
          <div className='lead'>
            <i className='fas fa-code-branch'></i>{" "}
            <small>
              {" "}
              Add your job expereinces and/or trainings and certifications{" "}
            </small>
          </div>
          <small style={{ color: "red" }}>* = required field</small>
          <form className='form' onSubmit={c => onSubmit(c)}>
            <div className='form-group'>
              <input
                type='text'
                placeholder='* Training/Job Title'
                name='title'
                value={title}
                onChange={c => onChange(c)}
                required
              />
            </div>
            <div className='form-group'>
              <input
                type='text'
                placeholder='* Company'
                name='company'
                value={company}
                onChange={c => onChange(c)}
                required
              />
            </div>
            <div className='form-group'>
              <input
                type='text'
                placeholder='Location'
                name='location'
                value={location}
                onChange={c => onChange(c)}
              />
            </div>
            <div className='form-group'>
              <h4>From Date</h4>
              <input
                type='date'
                name='from'
                value={from}
                onChange={c => onChange(c)}
              />
            </div>
            <div className='form-group'>
              <p>
                <input
                  type='checkbox'
                  name='current'
                  checked={current}
                  value={current}
                  onChange={c => {
                    setFormData({ ...formData, current: !current });
                    toggleDisabled(!toDateDisabled);
                  }}
                />{" "}
                Current Job
              </p>
            </div>
            <div className='form-group'>
              <h4>To Date</h4>
              <input
                type='date'
                name='to'
                value={to}
                onChange={c => onChange(c)}
                disabled={toDateDisabled ? "disabled" : ""}
              />
            </div>
            <div className='form-group'>
              <textarea
                name='description'
                cols='30'
                rows='5'
                placeholder='Job Description'
                value={description}
                onChange={c => onChange(c)}
              ></textarea>
            </div>
            <input type='submit' className='btn btn-primary my-1' />
            <Link className='btn btn-mid my-1' to='/dashboard'>
              Go Back
            </Link>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

AddExperience.propTypes = {
  addExperience: PropTypes.func.isRequired,
};

export default connect(null, { addExperience })(withRouter(AddExperience));
