import {
  ADD_RESPONDER,
  GET_RESPONDERS_BY_OPCEN,
  GET_RESPONDERS_BY_OPCEN_FAIL,
  GET_RESPONDER_BY_ID,
  GET_RESPONDERS,
  GET_RESPONDERS_FAIL,
  DELETE_RESPONDER_BY_ID,
  ADD_RESPONDER_FAIL,
  DELETE_RESPONDER_ERROR,
  CLEAR_RESPONDER,
} from "../actions/types";

const initialState = {
  repondersOpcen: null,
  responders: null,
  responder: null,
  loading: true,
  error: {},
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_RESPONDERS:
      return {
        ...state,
        reponders: payload, //from the action file
        loading: false,
      };

    case GET_RESPONDERS_BY_OPCEN:
      return {
        ...state,
        repondersOpcen: payload, //from the action file
        loading: false,
      };
    case GET_RESPONDER_BY_ID:
      return {
        ...state,
        responder: payload, //from the action file
        loading: false,
      };
    case DELETE_RESPONDER_BY_ID:
      return {
        ...state,
        repondersOpcen: state.repondersOpcen.filter(
          responder => responder._id !== payload
        ),
        loading: false,
      };

    case ADD_RESPONDER:
      return {
        ...state,
        responder: payload, //from the action file
        loading: false,
      };
    case ADD_RESPONDER_FAIL:
    case GET_RESPONDERS_BY_OPCEN_FAIL:
    case GET_RESPONDERS_FAIL:
    case DELETE_RESPONDER_ERROR:
    case CLEAR_RESPONDER:
      return {
        // ...state,
        repondersOpcen: null,
        reponders: null,
        responder: null,
        loading: true,
        error: payload,
      };
    default:
      return state;
  }
}
