import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setCreateIncident } from "../../actions/incident";

const AreaNotAvailableModal = ({ onClose, show, supportedAddress }) => {
  if (!show) {
    return null;
  }

  return (
    <Fragment>
      <div className='incident_modal shadow_me' style={{}} onClick={onClose}>
        <div
          className='incident-modal-content'
          style={{ width: "420px", height: "230px" }}
          onClick={e => e.stopPropagation()}
        >
          <audio autoPlay src='/sound/sound2.mp3'></audio>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              background: "#dc3545",
              borderRadius: "5px 5px 0 0",
              color: "#fff",
            }}
          >
            Area Not Supported
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "90%",
              textAlign: "center",
              margin: "auto",
              padding: "12px",
            }}
          >
            <img
              style={{ height: "50px", width: "50px" }}
              src='incidentGraphics/NoService.png'
              alt='No Service'
            />
            <p
              style={{
                fontSize: "16px",
                paddingBottom: "5px",
                fontWeight: "600",
                paddingBottom: "0px",
                color: " #215a75",
              }}
            >
              Sorry, no active responder near you...
            </p>
            <p style={{ fontSize: "12px", paddingBottom: "5px" }}>location:</p>
            <p style={{ fontSize: "14px" }}>
              {supportedAddress.substring(0, 45)} ...
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",

              flexDirection: "column",
            }}
          >
            <Link
              to='/posts'
              className='btn btn-success shadow_me'
              style={{ width: "80%", paddingBottom: "5px" }}
            >
              Back to Notifications
            </Link>
            {/* <button
              onClick={onClose}
              className='btn btn-danger shadow_me'
              style={{ width: "80%", paddingBottom: "5px" }}
            >
              Close
            </button> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

AreaNotAvailableModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.object.isRequired,
  scompleteaddress: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { setCreateIncident })(
  AreaNotAvailableModal
);
