import React, { Fragment, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MessageModal from "./MessageModal";

import { getIncidentMessages } from "../../actions/incident_msg";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import MissedCallModal from "./MissedCallModal";

const ActiveComms = ({
  socket,
  responder,
  incident,
  getIncidentMessages,
  close,
}) => {
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [show, setShow] = useState(false);

  const [vshow, setvShow] = useState(false);
  const [missedCallShow, setmissedCallShow] = useState(false);

  useEffect(() => {
    socket.on("getMessage", (data) => {
      if (data.senderId === responder.user._id) {
        setArrivalMessage({
          sender: data.senderId,
          text: data.text,
          createdAt: Date.now(),
        });
        setShow(true);
        getIncidentMessages(incident._id);
      }
    });
  }, []);

  useEffect(() => {
    socket.on("getHangUp", (data) => {
      setShow(false);

      setmissedCallShow(true);
      // connectionRef.current.destroy();

      window.location.reload();
    });
  }, []);

  useEffect(() => {
    setShow(false);
  }, [close]);

  if (close) {
    return <Redirect to="/incidentCloseOpcen" />;
  }
  return (
    <Fragment>
      <div
        style={{
          width: "100%",
          height: "80px",
          marginTop: "5px",
          backgroundColor: "#fff",
        }}
      >
        <div
          style={{
            display: "flex",
            height: "80px",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          {/* <div className='incident-comms' style={{ cursor: "pointer" }}>
            <img
              style={{ height: "35px", width: "35px" }}
              src='/incidentGraphics/phone1.png'
              alt=''
            />
            <p className='small-txt-read'>Call</p>
          </div> */}

          <Link
            to="/incidentmessenger"
            className="incident-comms"
            style={{ cursor: "pointer" }}
          >
            {/* putt indicator the a message has arrive on message icon */}
            <img
              style={{ height: "35px", width: "35px" }}
              src="/incidentGraphics/message1.png"
              alt=""
            />
            <p className="small-txt-read">Message</p>
          </Link>
        </div>
      </div>

      {/* <IncidentMessengerContainer
        onClose={() => setMShow(false)}
        show={mshow}
      /> */}

      <MessageModal
        onClose={() => setShow(false)}
        show={show}
        arrivalMessage={arrivalMessage}
        socket={socket}
      />

      <MissedCallModal
        onClose={() => setvShow(false)}
        show={missedCallShow}
        responder={responder}
      />
    </Fragment>
  );
};

ActiveComms.propTypes = {
  getIncidentMessages: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { getIncidentMessages };

export default connect(mapStateToProps, mapDispatchToProps)(ActiveComms);
