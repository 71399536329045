import PropTypes from "prop-types";
import React, { Fragment, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import {
  addLike,
  addLove,
  addWow,
  addSad,
  addHaha,
  addAngry,
  removeLike,
  removeLove,
  removeWow,
  removeSad,
  removeHaha,
  removeAngry,
  getPost,
} from "../../actions/post";

export const PostReaction = ({
  addLike,
  addLove,
  addWow,
  addSad,
  addHaha,
  addAngry,
  removeLike,
  removeLove,
  removeWow,
  removeSad,
  removeHaha,
  removeAngry,
  showReaction,
  onClose,
  _id,
  userLiked,
  userLoved,
  userWows,
  userSads,
  userHahas,
  userAngrys,
  post,
}) => {
  const removePrevReaction = useCallback(
    e => {
      if (userLiked?.length > 0) {
        removeLike(_id);
      } else if (userLoved?.length > 0) {
        removeLove(_id);
      } else if (userWows?.length > 0) {
        removeWow(_id);
      } else if (userSads?.length > 0) {
        removeSad(_id);
      } else if (userHahas?.length > 0) {
        removeHaha(_id);
      } else if (userAngrys?.length > 0) {
        removeAngry(_id);
      }
      {
        post &&
          setTimeout(() => {
            window.location.reload();
          }, 1000);
      }
    },
    [userLiked, userAngrys, userHahas, userLoved, userSads, userWows]
  );

  if (!showReaction) {
    return null;
  }
  return (
    <Fragment>
      <div
        onClick={onClose}
        style={{
          position: "absolute",
          bottom: "0",
          width: "100%",
          height: "500px",
        }}
      >
        <div className='menu_post_reaction'>
          <div onClick={onClose} className='menu_post_reaction_item'>
            <img
              onClick={c => {
                removePrevReaction();
                setTimeout(() => {
                  addLike(_id);
                }, 1000);
              }}
              style={{ width: "34px", height: "34px", borderRadius: "100%" }}
              src='\icons\reactions\like.png'
              alt=''
            />
          </div>
          <div onClick={onClose} className='menu_post_reaction_item'>
            <img
              onClick={c => {
                removePrevReaction();
                setTimeout(() => {
                  addLove(_id);
                }, 1000);
              }}
              style={{ width: "34px", height: "34px" }}
              src='\icons\reactions\love.png'
              alt=''
            />
          </div>
          <div onClick={onClose} className='menu_post_reaction_item'>
            <img
              onClick={c => {
                removePrevReaction();
                setTimeout(() => {
                  addWow(_id);
                }, 1000);
              }}
              style={{ width: "34px", height: "34px" }}
              src='\icons\reactions\wow.png'
              alt=''
            />
          </div>
          <div onClick={onClose} className='menu_post_reaction_item'>
            <img
              onClick={c => {
                removePrevReaction();
                setTimeout(() => {
                  addSad(_id);
                }, 1000);
              }}
              style={{ width: "34px", height: "34px" }}
              src='\icons\reactions\sad.png'
              alt=''
            />
          </div>
          <div onClick={onClose} className='menu_post_reaction_item'>
            <img
              onClick={c => {
                removePrevReaction();
                setTimeout(() => {
                  addHaha(_id);
                }, 1000);
              }}
              style={{ width: "34px", height: "34px" }}
              src='\icons\reactions\haha.png'
              alt=''
            />
          </div>
          <div onClick={onClose} className='menu_post_reaction_item'>
            <img
              onClick={c => {
                removePrevReaction();
                setTimeout(() => {
                  addAngry(_id);
                }, 1000);
              }}
              style={{ width: "34px", height: "34px" }}
              src='\icons\reactions\angry.png'
              alt=''
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

PostReaction.propTypes = {
  addLike: PropTypes.func.isRequired,
  addLove: PropTypes.func.isRequired,
  addWow: PropTypes.func.isRequired,
  addSad: PropTypes.func.isRequired,
  addHaha: PropTypes.func.isRequired,
  addAngry: PropTypes.func.isRequired,
  removeLike: PropTypes.func.isRequired,
  removeLove: PropTypes.func.isRequired,
  removeWow: PropTypes.func.isRequired,
  removeSad: PropTypes.func.isRequired,
  removeHaha: PropTypes.func.isRequired,
  removeAngry: PropTypes.func.isRequired,
  getPost: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  post: state.post.post,
});

const mapDispatchToProps = {
  addLike,
  addLove,
  addWow,
  addSad,
  addHaha,
  addAngry,
  removeLike,
  removeLove,
  removeWow,
  removeSad,
  removeHaha,
  removeAngry,
  getPost,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostReaction);
