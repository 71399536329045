import React, { Fragment, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addEducation } from "../../actions/profile";

const AddEducation = ({ addEducation, history }) => {
  const [formData, setFormData] = useState({
    school: "",
    degree: "",
    fieldofstudy: "",
    from: "",
    to: "",
    current: false,
    description: "",
  });
  //disable to date if still connected to experience
  const [toDateDisabled, toggleDisabled] = useState(false);

  const { school, degree, fieldofstudy, from, to, current, description } =
    formData;

  const onChange = async c =>
    setFormData({ ...formData, [c.target.name]: c.target.value });

  const onSubmit = async c => {
    c.preventDefault();
    addEducation(formData, history);
  };

  return (
    <Fragment>
      <div className=' main-container align-vertically'>
        <div className='sub-container'>
          <h2 className=' text-primary'> Education</h2>
          <p className='lead'>
            <i className='fas fa-graduation-cap'></i> Add school, bootcamp, that
            you have attended
          </p>
          <small style={{ color: "red" }}>* = required field</small>
          <form className='form' onSubmit={c => onSubmit(c)}>
            <div className='form-group'>
              <input
                type='text'
                placeholder='* School or Bootcamp'
                name='school'
                value={school}
                onChange={c => onChange(c)}
                required
              />
            </div>
            <div className='form-group'>
              <input
                type='text'
                placeholder='* Degree or Certificate'
                name='degree'
                value={degree}
                onChange={c => onChange(c)}
                required
              />
            </div>
            <div className='form-group'>
              <input
                type='text'
                placeholder='Field Of Study'
                name='fieldofstudy'
                value={fieldofstudy}
                onChange={c => onChange(c)}
              />
            </div>
            <div className='form-group'>
              <h4>From Date</h4>
              <input
                type='date'
                name='from'
                value={from}
                onChange={c => onChange(c)}
              />
            </div>
            <div className='form-group'>
              <p>
                <input
                  type='checkbox'
                  name='current'
                  value={current}
                  onChange={c => {
                    setFormData({ ...formData, current: !current });
                    toggleDisabled(!toDateDisabled);
                  }}
                />{" "}
                Current School or Bootcamp
              </p>
            </div>
            <div className='form-group'>
              <h4>To Date</h4>
              <input
                type='date'
                name='to'
                value={to}
                onChange={c => onChange(c)}
                disabled={toDateDisabled ? "disabled" : ""}
              />
            </div>
            <div className='form-group'>
              <textarea
                name='description'
                cols='30'
                rows='5'
                placeholder='Program Description'
                value={description}
                onChange={c => onChange(c)}
              ></textarea>
            </div>
            <input type='submit' className='btn btn-primary my-1' />
            <Link className='btn btn-mid my-1' to='/dashboard'>
              Go Back
            </Link>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

AddEducation.propTypes = {
  addEducation: PropTypes.func.isRequired,
};

export default connect(null, { addEducation })(withRouter(AddEducation));
