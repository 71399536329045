import React, { Fragment, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { format } from "timeago.js";

export const IncidentMessages = ({
  message,
  own,
  profilepic,
  profiles,
  incident: { incident, incident_handled },
  key,
}) => {
  const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;

  const scrollRef = useRef(null);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  }, []);
  console.log("Incidnent Handled", incident_handled);
  console.log("own", own);
  console.log("profiles", profiles.length);

  return (
    <Fragment>
      <div
        ref={scrollRef}
        className={own ? "chatMessages own" : "chatMessages"}
      >
        {message.text.substring(0, 4) === "Chat" ? (
          message.text.substring(
            message.text.length - 3,
            message.text.length
          ) === "mp4" ? (
            <div>
              <video
                src={`${serverURI}/${message.text}`}
                className="videoChat"
                alt="SentVideo"
                type="video/mp4"
                controls
              />
              <div className={own ? "time" : "time_own"}>
                {format(message.createdAt)}
              </div>
            </div>
          ) : message.text.substring(
              message.text.length - 3,
              message.text.length
            ) === "mov" ? (
            <div>
              <video
                src={`${serverURI}/${message.text}`}
                className="videoChat"
                alt="SentVideo"
                type="video/quicktime"
                controls
              />
              <div className={own ? "time" : "time_own"}>
                {format(message.createdAt)}
              </div>
            </div>
          ) : (
            <div>
              <img
                src={`${serverURI}/${message.text}`}
                className="photoChat"
                alt="SentPhoto"
              />
              <div className={own ? "time" : "time_own"}>
                {format(message.createdAt)}
              </div>
            </div>
          )
        ) : (
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {!own && incident ? (
                <img
                  className="shadow_me"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                  src={`${serverURI}/${profilepic}`}
                  alt="test"
                />
              ) : !own && incident_handled ? (
                <div>
                  {profiles.length > 0 ? (
                    profiles.map((profile) => {
                      if (profile?.user?._id === incident_handled?.user) {
                        return (
                          <div>
                            {" "}
                            <img
                              className="shadow_me"
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                marginRight: "10px",
                                marginLeft: "10px",
                              }}
                              src={`${serverURI}/${profile.profilepic}`}
                            />
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div>
                      <img
                        className="post-profile"
                        src={`/img/Spotter.png`}
                        alt="..."
                      />
                    </div>
                  )}
                </div>
              ) : null}

              <span className=" chatTxt  ">{message.text}</span>
            </div>

            <div className={own ? "time" : "time_own"}>
              {format(message.createdAt)}
            </div>
          </div>
        )}
        {!own ? <audio autoPlay src="/sound/sound2.mp3"></audio> : null}
      </div>
    </Fragment>
  );
};

IncidentMessages.propTypes = {
  incident: PropTypes.object.isRequired,
  profiles: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  incident: state.incident,
  profiles: state.profile.profiles,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentMessages);
