import axios from "axios";
import { setAlert } from "./alert";
import {
  ADD_POST,
  DELETE_POST,
  GET_POSTS,
  GET_POST,
  POST_ERROR,
  UPDATE_LIKES,
  UPDATE_LOVES,
  UPDATE_WOWS,
  UPDATE_SADS,
  UPDATE_HAHAS,
  UPDATE_ANGRYS,
  CLEAR_POST,
  ADD_COMMENT,
  REMOVE_COMMENT,
  GET_POST_IMAGE,
  POST_IMG_ERROR,
} from "./types";

//get posts with skipp

export const getPosts = skip => async dispatch => {
  try {
    const res = await axios.get(`/api/posts?skip=${skip}`);
    dispatch({
      type: GET_POSTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

//get posts with skipp

export const clearPost = () => async dispatch => {
  try {
    dispatch({
      type: CLEAR_POST,
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

//Add Like Update post

export const addLike = id => async dispatch => {
  try {
    const res = await axios.put(`/api/posts/like/${id}`);
    dispatch({
      type: UPDATE_LIKES,
      payload: { id, likes: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Remove Like Update post

export const removeLike = id => async dispatch => {
  try {
    const res = await axios.put(`/api/posts/unlike/${id}`);
    dispatch({
      type: UPDATE_LIKES,
      payload: { id, likes: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Add Love Update post

export const addLove = id => async dispatch => {
  try {
    const res = await axios.put(`/api/posts/love/${id}`);
    dispatch({
      type: UPDATE_LOVES,
      payload: { id, loves: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Remove Love Update post

export const removeLove = id => async dispatch => {
  try {
    const res = await axios.put(`/api/posts/unlove/${id}`);
    dispatch({
      type: UPDATE_LOVES,
      payload: { id, loves: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Add Like Update post

export const addWow = id => async dispatch => {
  try {
    const res = await axios.put(`/api/posts/wow/${id}`);
    dispatch({
      type: UPDATE_WOWS,
      payload: { id, wows: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Remove Wow Update post

export const removeWow = id => async dispatch => {
  try {
    const res = await axios.put(`/api/posts/unwow/${id}`);
    dispatch({
      type: UPDATE_WOWS,
      payload: { id, likes: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Add Sad Update post

export const addSad = id => async dispatch => {
  try {
    const res = await axios.put(`/api/posts/sad/${id}`);
    dispatch({
      type: UPDATE_SADS,
      payload: { id, sads: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Remove Like Update post

export const removeSad = id => async dispatch => {
  try {
    const res = await axios.put(`/api/posts/unsad/${id}`);
    dispatch({
      type: UPDATE_SADS,
      payload: { id, likes: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Add Haha Update post

export const addHaha = id => async dispatch => {
  try {
    const res = await axios.put(`/api/posts/haha/${id}`);
    dispatch({
      type: UPDATE_HAHAS,
      payload: { id, hahas: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Remove Like Update post

export const removeHaha = id => async dispatch => {
  try {
    const res = await axios.put(`/api/posts/unhaha/${id}`);
    dispatch({
      type: UPDATE_HAHAS,
      payload: { id, hahas: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Add Like Update post

export const addAngry = id => async dispatch => {
  try {
    const res = await axios.put(`/api/posts/angry/${id}`);
    dispatch({
      type: UPDATE_ANGRYS,
      payload: { id, angrys: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Remove Like Update post

export const removeAngry = id => async dispatch => {
  try {
    const res = await axios.put(`/api/posts/unangry/${id}`);
    dispatch({
      type: UPDATE_ANGRYS,
      payload: { id, angrys: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Delete post

export const deletePost = id => async dispatch => {
  try {
    await axios.delete(`/api/posts/${id}`);
    dispatch({
      type: DELETE_POST,
      payload: id,
    });

    dispatch(setAlert("Post Removed", "success"));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//ADD post

export const addPost = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  try {
    const res = await axios.post("/api/posts", formData, config);

    dispatch({
      type: ADD_POST,
      payload: res.data,
    });

    dispatch(setAlert("Post Created", "success"));
  } catch (err) {
    console.log("Error from backend", err);
    dispatch(setAlert(`Post error ${err.response.statusText}`, "danger"));

    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Get post

export const getPost = id => async dispatch => {
  try {
    const res = await axios.get(`/api/posts/${id}`);
    dispatch({
      type: GET_POST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Get imagePost

export const getimagePost = (id, articleImage) => async dispatch => {
  try {
    const res = await axios.get(`/api/posts/${id}/${articleImage}`);
    dispatch({
      type: GET_POST_IMAGE,
      payload: res.data,
    });
    console.log(res);
  } catch (err) {
    dispatch({
      type: POST_IMG_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Add Comment

export const addComment = (postId, formData) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `/api/posts/comment/${postId}`,
      formData,
      config
    );
    dispatch({
      type: ADD_COMMENT,
      payload: res.data,
    });

    dispatch(setAlert("Comment Added", "success"));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Edit Comment

export const editComment = (postId, _id, formData) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `/api/posts/comment/edit/${postId}/${_id}`,
      formData,
      config
    );
    dispatch({
      type: ADD_COMMENT,
      payload: res.data,
    });

    dispatch(setAlert("Comment edited", "success"));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//RemoveComment

export const deleteComment = (postId, commentId) => async dispatch => {
  try {
    await axios.delete(`/api/posts/comment/${postId}/${commentId}`);
    dispatch({
      type: REMOVE_COMMENT,
      payload: commentId,
    });

    dispatch(setAlert("Comment Removed", "success"));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const updateComment = (postId, commentId) => async dispatch => {
  try {
    await axios.delete(`/api/posts/comment/${postId}/${commentId}`);
    dispatch({
      type: REMOVE_COMMENT,
      payload: commentId,
    });

    dispatch(setAlert("Comment Updated", "success"));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
