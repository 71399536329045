import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  ACCOUNT_DELETED,
  // FORGOT_PASSWORD,
  RESET_PASSWORD,
  USER_DOEST_EXIST,
  CHANGE_PASSWORD_FAIL,
  PASSWORD_CHANGED,
  USER_OPCEN_ADMIN,
  REGISTER_OTP,
  USER_RESPONDER_ROLE,
  SET_VOLUNTEER_LAYOUT,
  SET_ADMIN_LAYOUT,
  SET_RESPONDER_LAYOUT,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  isNewUser: null,
  isUser: null,
  loading: true,
  user: null,
  isOpcenAdmin: false,
  isResponder: false,
  layout: null,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      };

    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
    case PASSWORD_CHANGED:
      //save token to local storage
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        isUser: true,
        loading: false,
        layout: "volunteer",
      };

    case REGISTER_OTP:
      //save token to local storage

      return {
        ...state,
        ...payload,
        isNewUser: true,
        isUser: false,
        loading: false,
      };

    case USER_OPCEN_ADMIN:
      //save token to local storage

      return {
        ...state,
        isAuthenticated: true,
        isUser: true,
        isOpcenAdmin: payload,
        loading: false,
      };

    case USER_RESPONDER_ROLE:
      return {
        ...state,
        isAuthenticated: true,
        isUser: true,
        isResponder: payload,
        loading: false,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        ...payload,
        isAuthenticated: false,
        isUser: true,
        loading: false,
      };

    case REGISTER_FAIL:
    case CHANGE_PASSWORD_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
    case ACCOUNT_DELETED:
    case USER_DOEST_EXIST:
      localStorage.removeItem("token");
      localStorage.clear();

      return {
        token: null,
        isAuthenticated: false,
        loading: false,
        isOpcenAdmin: false,
        layout: null,
        isNewUser: null,
        user: null,
      };

    case SET_RESPONDER_LAYOUT:
      return {
        ...state,
        layout: payload,
      };
    case SET_ADMIN_LAYOUT:
      return {
        ...state,
        layout: payload,
      };
    case SET_VOLUNTEER_LAYOUT:
      return {
        ...state,
        layout: payload,
      };

    default:
      return state;
  }
}
