import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
// import AddAnnouncementModal from "./AddAnnouncementModal";
import { getAnnoucement } from "../../actions/annoucement";
// import AnnouncementList from "./AnnouncementList";
import SearchResponder from "../responder/SearchResponder";
import SUserContainer from "../responder/SUserContainer";
import { setPartnerIndividual, clearCRUD } from "../../actions/adminCRUD";

const PartnerIndividual = ({
  getAnnoucement,
  announcement,
  setPartnerIndividual,
  clearCRUD,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    getAnnoucement();
    clearCRUD();
  }, [getAnnoucement]);

  useEffect(() => {
    setPartnerIndividual();
  }, [setPartnerIndividual]);

  const showAnnouncementModal = () => {
    setShow(true);
  };
  return (
    <Fragment>
      <div
        className='main-container align-vertically  '
        style={{
          height: "720px",
          width: "1280px",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <p className='Htitle'>Your individual partner and their role:</p>
        </div>
        {/* <AnnouncementList annoucement={announcement} /> */}
        <SearchResponder />
        <SUserContainer />
      </div>

      <div className='add_btn'>
        <div
          style={{
            width: "80px",
            height: "80px",
            background: "#215a75",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            borderRadius: "50%",
            opacity: ".8",
          }}
          onClick={showAnnouncementModal}
        >
          <i class='fa fa-plus' aria-hidden='true'></i>
        </div>
      </div>
      {/* <AddAnnouncementModal show={show} onClose={() => setShow(false)} /> */}
    </Fragment>
  );
};

PartnerIndividual.propTypes = {
  getAnnoucement: PropTypes.func.isRequired,
  announcement: PropTypes.object.isRequired,
  setPartnerIndividual: PropTypes.func.isRequired,
  clearCRUD: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  announcement: state.announcement.announcements,
});

const mapDispatchToProps = { getAnnoucement, setPartnerIndividual, clearCRUD };

export default connect(mapStateToProps, mapDispatchToProps)(PartnerIndividual);
