import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;
const ProfileItem = ({
  profile: { user, status, organization, profilepic, location, skills },
}) => {
  return (
    <div className="profile">
      <Link to={`/profile/${user?._id}`}>
        <img
          className="profile-img"
          src={`${serverURI}/${profilepic}`}
          alt="..."
        />
        <h4 className="profile-exp">
          {user?.name} {user?.lname}
        </h4>
      </Link>
      <div>
        <p className="lead">
          <b> {status} </b>
          {organization && <span> @ {organization}</span>}
        </p>
        <p className=" lead my"> {location && <span> {location}</span>}</p>
        <Link to={`/profile/${user?._id}`} className="btn btn-primary">
          View Profile
        </Link>
      </div>
      <ul>
        {skills.slice(0, 4).map((skill, index) => (
          <li key={index} className="text-primary">
            <i className="fas fa-check"></i> {skill}
          </li>
        ))}
      </ul>
    </div>
  );
};

ProfileItem.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default ProfileItem;
