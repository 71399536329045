import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import SelectedVolunteer from "./SelectedVolunteer";

export const SelectedVolunteerContainer = ({ suspendedusers, users }) => {
  return (
    <Fragment>
      <div
        style={{
          background: "#fff",
          display: "grid",
          alignItems: "center",
          borderRadius: "5px",
          height: "50%",

          border: "1px solid #eee",
        }}
      >
        {users === null || users.length === 0 ? (
          <Fragment>
            <p className='text-center text-red'>
              0 SEARCH MATCH <br /> Make sure you type the field values on case
              sensitive.
            </p>
          </Fragment>
        ) : (
          <Fragment>
            <SelectedVolunteer users={users} suspendedusers={suspendedusers} />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

SelectedVolunteerContainer.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = state => ({
  users: state.users.users,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedVolunteerContainer);
