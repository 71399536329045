import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import SearchResponder from "./SearchResponder";
import SUserContainer from "./SUserContainer";
import Spinner from "../layout/Spinner";
import DisplayResponderList from "./DispalyResponderList";
import { getResponderbyOpcen } from "../../actions/responder";
import { setResponderCRUD, clearCRUD } from "../../actions/adminCRUD";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;

const AddResponder = ({
  responder: { repondersOpcen, loading },
  opcen_profile: { profile },
  opcen_id,
  getResponderbyOpcen,
  opcen,
  setResponderCRUD,
  clearCRUD,
}) => {
  let history = useHistory();

  useEffect(() => {
    getResponderbyOpcen(opcen_id);
    clearCRUD();
  }, [getResponderbyOpcen]);

  useEffect(() => {
    setResponderCRUD();
  }, [setResponderCRUD]);

  return loading && profile === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <div
        style={{
          position: "fixed",
          top: "57px",
          left: "0px",
          right: "0px",
          bottom: "0px",

          background: "#fff",
        }}
      >
        {/* Main Display here */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 2px 0px, 2px",
            background: "#fff",
            height: "90vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ background: "#fff", height: "90vh", width: "920px" }}>
            <SearchResponder />
            <SUserContainer />
            {repondersOpcen === null ? (
              <div>
                You have no responders <br /> Try search and add them
              </div>
            ) : (
              <DisplayResponderList responder={repondersOpcen} />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

AddResponder.propTypes = {
  opcen_profile: PropTypes.object.isRequired,
  responder: PropTypes.object.isRequired,
  opcen_id: PropTypes.string.isRequired,
  opcen: PropTypes.object.isRequired,
  setResponderCRUD: PropTypes.func.isRequired,
  clearCRUD: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  opcen_profile: state.opcen_profile,
  responder: state.responder,
  opcen_id: state.opcen.opcen._id,
  opcen: state.opcen.opcen,
});

const mapDispatchToProps = { getResponderbyOpcen, setResponderCRUD, clearCRUD };

export default connect(mapStateToProps, mapDispatchToProps)(AddResponder);
