import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import IncidentModal from "../layout/IncidentModal";
import { connect } from "react-redux";
import { Fragment } from "react";
import PastIncident from "./PastIncident";
import CancelledIncidents from "./CancelledIncidents";
import {
  getCloseIncidentByUserId,
  getCancelledIncidentByUserId,
} from "../../actions/incident";
import Spinner from "../layout/Spinner";
import CreateProfileModal from "./CreateProfileModal";

const Incident = ({
  user_id,
  getCloseIncidentByUserId,
  getCancelledIncidentByUserId,
  incident: { incidentclose, usercancelled, loading },
  profile,
}) => {
  const [show, setShow] = useState(false);
  const [profileShow, setProfileShow] = useState(false);

  useEffect(() => {
    getCloseIncidentByUserId(user_id);
    getCancelledIncidentByUserId(user_id);
  }, [getCloseIncidentByUserId, getCancelledIncidentByUserId]);

  const incidentModal = () => {
    if (profile) {
      setShow(true);
    } else {
      setProfileShow(true);
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div
        style={{
          width: "100%",
          height: "90vh",
          background: "#fff",
          borderRadius: "10px",
        }}
      >
        {incidentclose?.length > 0 || usercancelled?.length > 0 ? (
          <div>
            <div style={{ height: "55vh" }}>
              {incidentclose?.length > 0 ? (
                <PastIncident incidentclose={incidentclose} loading={loading} />
              ) : (
                <p> No past reports to show... </p>
              )}
            </div>

            <div>
              {usercancelled?.length > 0 ? (
                <CancelledIncidents
                  usercancelled={usercancelled}
                  loading={loading}
                />
              ) : (
                <div className='align-center ' style={{ height: "38vh" }}>
                  <p> No cancelled report to show... </p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            style={{
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                color: "#28a745",
                fontWeight: "500",
              }}
            >
              {" "}
              No report submitted..
            </p>
            <p style={{ fontSize: "12px", fontWeight: "500" }}>
              Start saving lives and/or properties by reporting incident in your
              community.{" "}
            </p>
          </div>
        )}
      </div>

      {/* Emergecny button */}
      <div className='emergencybtn' onClick={() => incidentModal()}>
        <img src='/icons/incident/Button.png' alt='' />
      </div>
      <IncidentModal onClose={() => setShow(false)} show={show} />
      {/* <EmergencyBtn /> */}

      <CreateProfileModal
        onClose={() => setProfileShow(false)}
        show={profileShow}
      />
    </Fragment>
  );
};

Incident.propTypes = {
  getCloseIncidentByUserId: PropTypes.func.isRequired,
  getCancelledIncidentByUserId: PropTypes.func.isRequired,
  user_id: PropTypes.string.isRequired,
  incident: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user_id: state.auth.user._id,
  incident: state.incident,
  profile: state.profile.profile,
});

const mapDispatchToProps = {
  getCloseIncidentByUserId,
  getCancelledIncidentByUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(Incident);
