import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

export const CallingRejected = (props) => {
  return <div className="calling_dialog">Calling Rejected</div>;
};

CallingRejected.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CallingRejected);
