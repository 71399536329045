import React, { useEffect } from "react";
import { Fragment } from "react";
import SocketContext from "../../SocketContext";
import Navbar from "./Navbar";

export const NavbarContainer = props => {
  return (
    <Fragment>
      <div>
        <SocketContext.Consumer>
          {socket => <Navbar socket={socket} />}
        </SocketContext.Consumer>
      </div>
    </Fragment>
  );
};

export default NavbarContainer;
