import React, { Fragment } from "react";

// eslint-disable-next-line
export default () => (
  <Fragment>
    <div
      className=' align-vertically'
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src='/opcenlogo/guardian_small.png' className='rotate_me'></img>
      <p
        style={{
          color: "#28a745",
          paddingTop: "10px",
          fontSize: "12px",
          fontWeight: "300",
        }}
        className='blink_me'
      >
        Connecting
      </p>
    </div>
  </Fragment>
);
