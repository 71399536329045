import axios from "axios";
import { setAlert } from "./alert";
import {
  GET_ALL_ANNOUCEMENT,
  GET_ALL_ANNOUCEMENT_ERROR,
  DELETE_ANNOUCEMENT,
  GET_ANNOUNCEMENT,
  CLEAR_ANNOUNCEMENT,
  DISPLAYED_ANNOUNCEMENT,
} from "./types";

export const getAnnoucement = () => async dispatch => {
  try {
    const res = await axios.get(`/api/announcement`);
    dispatch({
      type: GET_ALL_ANNOUCEMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_ANNOUCEMENT_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

//Delete post

export const deleteAnnoucement = id => async dispatch => {
  try {
    await axios.delete(`/api/announcement/${id}`);
    dispatch({
      type: DELETE_ANNOUCEMENT,
      payload: id,
    });

    dispatch(setAlert("Post Removed", "success"));
  } catch (err) {
    dispatch({
      type: GET_ALL_ANNOUCEMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Get Annoucement id

export const getAnnoucementId = id => async dispatch => {
  try {
    const res = await axios.get(`/api/announcement/${id}`);
    dispatch({
      type: GET_ANNOUNCEMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_ANNOUCEMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Clear Redux state for responder

export const clearAnnouncement = () => dispatch => {
  try {
    dispatch({
      type: CLEAR_ANNOUNCEMENT,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_ANNOUCEMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const displayAnnoucement = () => async dispatch => {
  try {
    dispatch({
      type: DISPLAYED_ANNOUNCEMENT,
      payload: true,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_ANNOUCEMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    setAlert(err.response.statusText, "danger");
  }
};
