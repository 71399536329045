import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  create_incident_opcen,
  getIncidentByNumberDate,
  handleIncident,
} from "../../actions/incident";
import { onCall } from "../../actions/dispatcher";
import { getResponderbyUserId } from "../../actions/responder";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;

const StanbyModal = ({
  incident_handled,
  create_incident_opcen,
  getIncidentByNumberDate,
  handleIncident,
  newIncident,
  profile: { profiles },
  dispatchOnline,
  onCall,
  socket,
  user: { name, lname, _id },
  getResponderbyUserId,
}) => {
  useEffect(() => {
    getResponderbyUserId(_id);
  });

  const type = newIncident?.type;

  if (!dispatchOnline) {
    return null;
  }
  console.log("Incident Handled date", newIncident);

  const acceptCall = () => {
    handleIncident();
    // PUT action distpatcher cre // putincidentdispatcher();
    // Socket Emit Call answered
    socket.emit("sendAnswered", {
      responder: _id,
      name,
      lname,
      type: newIncident?.type,
      volunteer: newIncident?.user,
    });
  };

  if (handleIncident && incident_handled !== null) {
    return <Redirect to="/dispatch" />;
  }

  return (
    // <div className='modal_invisible' onClick={onClose}>
    <Fragment>
      {newIncident === null ? (
        <div className="modal_invisible">
          <div
            className="modal-standby shadow_txt "
            onClick={(e) => e.stopPropagation()}
          >
            <div className="standbyModal ">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  // background: "red",
                  height: "50px",
                  marginBottom: "38px",
                }}
              >
                <h1 className="blink_me text-red shadow_txt">
                  ON ACTIVE STAND-BY, WAITING FOR A CALL
                </h1>
              </div>

              <button
                className="btn btn-success"
                onClick={() => {
                  create_incident_opcen();
                }}
                style={{ width: "90%", padding: "10px" }}
              >
                <i class="fas fa-plus"></i>{" "}
                <span style={{ paddingLeft: "10px" }}>CREATE INCIDENT</span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="modal_invisible">
          <div
            className="modal-standby_incident shadow_txt"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="incidentDetailsModal ">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {newIncident.type === "Covid" ? (
                    <div>
                      <img
                        className="incident-icon"
                        src="/icons/incidentType/Covid-res.png"
                        alt=""
                      />
                      <audio
                        autoPlay
                        loop
                        src="/sound/incidentSound/covid.mp3"
                      />
                    </div>
                  ) : newIncident.type === "Fire" ? (
                    <div>
                      <img
                        className="incident-icon"
                        src="/icons/incidentType/Fire.png"
                        alt=""
                      />
                      <audio
                        autoPlay
                        loop
                        src="/sound/incidentSound/fire.mp3"
                      />
                    </div>
                  ) : newIncident.type === "Medical" ? (
                    <div>
                      <img
                        className="incident-icon"
                        src="/icons/incidentType/Medical.png"
                        alt=""
                      />
                      <audio
                        autoPlay
                        loop
                        src="/sound/incidentSound/ambulance.mp3"
                      />
                    </div>
                  ) : newIncident.type === "Crime" ? (
                    <div>
                      <img
                        className="incident-icon"
                        src="/icons/incidentType/Police.png"
                        alt=""
                      />
                      <audio
                        autoPlay
                        loop
                        src="/sound/incidentSound/police.mp3"
                      />
                    </div>
                  ) : (
                    <div>
                      <img
                        className="incident-icon"
                        src="/icons/incidentType/General.png"
                        alt=""
                      />
                      <audio
                        autoPlay
                        loop
                        src="/sound/incidentSound/general.mp3"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "20px",
                  color: "#fff",
                  width: "380px",
                }}
              >
                <h1> {newIncident.type} </h1>
                <div>
                  <p>{newIncident.scompleteaddress.substring(0, 90)}</p>
                  <p className="small-txt-blk text-red"> LOCATION</p>
                </div>
                <div>
                  <p>
                    {" "}
                    {newIncident.name} {newIncident.lname}
                  </p>
                  <p className="small-txt-blk text-red"> VOLUNTEER</p>
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {profiles.length > 0 ? (
                    profiles.map((profile, i) => {
                      console.log("profile ====>", profile?.user?._id);
                      if (profile?.user?._id === newIncident.user) {
                        return (
                          <img
                            className="in_profile_img_online"
                            src={`${serverURI}/${profile.profilepic}`}
                            alt={`img/Spotter.png`}
                          />
                        );
                      }
                    })
                  ) : (
                    <div>
                      <img
                        className="post-profile"
                        src={`/img/Spotter.png`}
                        alt="..."
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                paddingTop: "8px",
              }}
            >
              <button
                className="btn btn-danger"
                onClick={() => {
                  {
                    console.log(
                      "Standby Modal Date",
                      newIncident?.reportedDate
                    );
                  }
                  acceptCall();
                  getIncidentByNumberDate(newIncident?.reportedDate);
                  onCall();
                  // PUT action distpatcher credentials to incidentdetails
                  // Socket Emit Call answered
                }}
                style={{ width: "80%", padding: "10px" }}
              >
                <i class="fas fa-plus"></i>{" "}
                <span style={{ paddingLeft: "10px" }}>ACCEPT CALL</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

StanbyModal.propTypes = {
  standbyModal: PropTypes.bool,
  onClose: PropTypes.object.isRequired,
  create_incident_opcen: PropTypes.func.isRequired,
  getIncidentByNumberDate: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  handleIncident: PropTypes.func.isRequired,
  incident_handled: PropTypes.object.isRequired,
  dispatchOnline: PropTypes.bool.isRequired,
  onCall: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  getResponderbyUserId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  incident_handled: state.incident.incident_handled,
  dispatchOnline: state.dispatchOnline.checkInStatus,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  create_incident_opcen,
  getIncidentByNumberDate,
  handleIncident,
  onCall,
  getResponderbyUserId,
})(StanbyModal);
