import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import SelectedOpcen from "./SelectedOpcen";

export const ResultSearchOpcen = ({ opcens }) => {
  return (
    <Fragment>
      <div
        style={{
          background: "#fff",
          display: "grid",
          alignItems: "center",
          borderRadius: "5px",
          height: "200px",

          border: "1px solid #eee",
        }}
      >
        {opcens === null || opcens.length === 0 ? (
          <Fragment>
            <p className='text-center text-red'>
              0 SEARCH MATCH <br /> Make sure you type the field values on case
              sensitive.
            </p>
          </Fragment>
        ) : (
          <Fragment>
            <SelectedOpcen opcens={opcens} />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

ResultSearchOpcen.propTypes = {};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ResultSearchOpcen);
