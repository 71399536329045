import React, { Fragment } from "react";
import Messenger from "./Messenger";
import SocketContext from "../../SocketContext";

export const MessengerContainer = props => {
  return (
    <Fragment>
      <div className='messengerContainer'>
        <SocketContext.Consumer>
          {socket => <Messenger socket={socket} />}
        </SocketContext.Consumer>
      </div>
    </Fragment>
  );
};

export default MessengerContainer;
