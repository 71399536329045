import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from "react-moment";
import VaxValidation from "./VaxValidation";
import { getVaccinationById } from "../../actions/vaccination";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;

const VaxsList = ({
  opcenVaxData,
  getVaccinationById,
  vaccinationData,
  user,
}) => {
  const [show, setShow] = useState(false);

  const list = opcenVaxData.map((vax) => (
    <tr key={vax._id} style={{ height: "40px" }}>
      <td style={{ textAlign: "center" }}>
        <img
          style={{ width: "40px", borderRadius: "50%" }}
          className="shadow_me"
          src={`${serverURI}/${vax.profilepic}`}
          alt="profilepic"
        />
      </td>
      <td style={{ padding: "5px" }}>{vax.user.name}</td>
      <td style={{ padding: "5px" }}>{vax.user.lname}</td>
      <td style={{ padding: "5px" }}>{vax.id_no}</td>
      <td style={{ textAlign: "center" }}>{vax.vaccinationSite}</td>
      <td style={{ textAlign: "center" }}>{vax.vaccineType}</td>

      <td style={{ textAlign: "center" }}>
        <Moment format="ll">{vax.fDoseDate}</Moment>
      </td>

      <td style={{ textAlign: "center" }}>
        <Moment format="ll">{vax.sDoseDate}</Moment>
      </td>

      <td style={{ textAlign: "center" }}>
        <Moment format="ll">{vax.sBoosterDate}</Moment>
      </td>
      <td style={{ textAlign: "center" }}>{vax.boosterType}</td>
      <td style={{ textAlign: "center" }}>
        <img
          style={{ width: "80px", maxHeight: "40px" }}
          src={`${serverURI}/resize/${vax.vaxCardPhoto}`}
          alt="vaxId"
        />
      </td>
      <td style={{ textAlign: "center", margin: "3px" }}>
        <button
          onClick={() => {
            getVaccinationById(vax.user._id);
            setShow(true);
          }}
          className="btn btn-success"
        >
          <span> Review </span>
          <i className="fa fa-trash-o" aria-hidden="true"></i>
        </button>
      </td>
    </tr>
  ));
  return (
    <Fragment>
      <div
        style={{
          display: "block",
          overflow: "hidden",
          overflowY: "scroll",
          height: "90vh",
          // Change Hight Here
        }}
      >
        <table className="commonTable">
          <thead>
            <tr>
              <th>Photo</th>
              <th className="hide-sm">Name</th>
              <th className="hide-sm">Last Name</th>
              <th>Vax ID No.</th>

              <th>Vaccination Site</th>
              <th>Vaccine Type</th>
              <th>First Dose</th>
              <th> Second Dose </th>
              <th>Booster</th>
              <th>Booster Type</th>
              <th>Vax Card</th>
              <th>Review</th>
            </tr>
          </thead>
          {/* Loop thru the database */}
          <tbody>{list}</tbody>
        </table>
      </div>
      <VaxValidation onClose={() => setShow(false)} show={show} user={user} />
    </Fragment>
  );
};

VaxsList.propTypes = {
  getVaccinationById: PropTypes.func.isRequired,
  vaccinationData: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  vaccinationData: state.vaccination.vax,
  user: state.auth.user,
});

const mapDispatchToProps = { getVaccinationById };

export default connect(mapStateToProps, mapDispatchToProps)(VaxsList);
