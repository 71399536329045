import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { removeSuspended } from "../../actions/suspend_user";

export const VolunteerSuspended = ({ suspendedusers, removeSuspended }) => {
  const refreshPage = () => {
    setTimeout(function () {
      window.location.reload();
    }, 2 * 1000);
  };
  const suspendedUsers = suspendedusers?.map((res, i) => (
    <tr key={i + 1}>
      <td>
        <Link to={`/profile/${res.user_id._id}`}>
          <p style={{ fontSize: "12px", fontWeight: "600" }}>
            {res.user_id.name}
          </p>
        </Link>
      </td>
      <td>{res.user_id.lname}</td>
      <td>{res.user_id.number}</td>
      <td>{res.user_id.email}</td>
      <td style={{ textAlign: "center" }}>
        <button
          onClick={() => {
            removeSuspended(res.user_id._id);
            refreshPage();
          }}
          className=' btn_table btn-success'
        >
          <i className='fa fa-plus-square-o' aria-hidden='true'></i> Remove
        </button>
      </td>
    </tr>
  ));
  return (
    <Fragment>
      <p>Suspended Volunteers</p>
      <div style={{ overflow: "hidden", overflowY: "scroll", height: "50%" }}>
        <table className='commonTable'>
          <thead>
            <tr>
              <th> Name</th>
              <th> Last Name</th>
              <th> Mobile Number</th>
              <th> Email</th>
              <th>Action</th>
            </tr>
          </thead>
          {/* Loop thru the database */}

          <tbody>{suspendedUsers}</tbody>
        </table>
      </div>
    </Fragment>
  );
};

VolunteerSuspended.propTypes = {
  removeSuspended: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = { removeSuspended };

export default connect(mapStateToProps, mapDispatchToProps)(VolunteerSuspended);
