import React, { Fragment, useState } from "react";

// eslint-disable-next-line
const Progressbar = ({ progress }) => {
  const [style, setStyle] = useState({});

  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: `${progress}%`,
    };

    setStyle(newStyle);
  }, 500);

  return (
    <Fragment>
      <div className='progress'>
        <div className='progress-done' style={style}>
          {progress}%
        </div>
      </div>
    </Fragment>
  );
};

export default Progressbar;
