import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { connect } from "react-redux";
import Logo from "../logo/Logo";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import LogInAs from "../layout/LogInAs";
// import SocketContext from "../../SocketContext";
import { checkinDispatch, checkOutDispatch } from "../../actions/dispatcher";
import StanbyModal from "./StanbyModal";
import { setAlert } from "../../actions/alert";
import { getCurrentProfile } from "../../actions/profile";
import IncidentModal from "../layout/IncidentModal";
import CreateNotification from "../notif/CreateNotification";
import moment from "moment";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;

const MainMenu = ({
  socket,
  user,
  incident: { createIncidentOpcen, incident, incident_handled, handleIncident },
  profile,
  checkinDispatch,
  checkOutDispatch,
  onlineDispatcher,
  setAlert,
  getCurrentProfile,
  onCall,
}) => {
  let history = useHistory();
  const [show, setShow] = useState(false);
  const [displayCheckIn, toggleDisplayCheckin] = useState(true);
  const [notifShow, setNotifShow] = useState(false);
  const [newIncident, setNewIncident] = useState(null);
  const [dispatchOnline, setDispatchOnline] = useState(true);

  useEffect(() => {
    if (!createIncidentOpcen) {
      socket.on("getIncident", (data) => {
        setNewIncident({
          user: data.user,
          name: data.name,
          lname: data.lname,
          type: data.type,
          number: data.number,
          scompleteaddress: data.scompleteaddress,
          sarea: data.sarea,
          sstate: data.sstate,
          slat: data.slat,
          slng: data.slng,
          status: data.status,
          reportedDate: data.reportedDate,
        });
      });
    }
  }, []);

  useEffect(() => {
    socket.on("getAnswered", (data) => {
      if (!handleIncident && !incident_handled) {
        setNewIncident(null);

        //  Add logic and ( if dili ikaw ang nag tubag)
        // setAlert(
        //   `${data.type} call is answered by GUARDIAN "${data.name}"`,
        //   "success"
        // );
      }
    });
  }, [handleIncident, incident_handled]);

  useEffect(() => {
    getCurrentProfile();
  }, []);

  const ditpatchCheckIn = () => {
    socket.emit("addDispatcher", user);
  };

  const ditpatchCheckOut = () => {
    socket.emit("removeDispatcher", socket.id);
  };

  const routeToNotification = () => {
    setNotifShow(true);
  };

  const responderDetails = user.responder.find((res) => res._id);

  const responderId = responderDetails._id;
  const user_id = user._id;
  const name = user.name;
  const lname = user.lname;
  const opcen_id = responderDetails.opcen;
  const online = dispatchOnline;
  const updatedAt = moment().format();

  const incidentMenu = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        padding: "10px 20px",
        fontSize: "12px",
        fontWeight: "200",
      }}
    >
      {/* <div>
        <Link to={`/`} style={{ color: "#fff" }}>
          <span className='hide-sm'>Alarm Level</span>
        </Link>
      </div>
      <div>
        <Link to={`/`} style={{ color: "#fff" }}>
          <span className='hide-sm'>MCI</span>
        </Link>
      </div>
      <div>
        <Link to='/' style={{ color: "#fff" }}>
          <span className='hide-sm'>SOAP</span>
        </Link>
      </div>
      <div>
        <Link to='/' style={{ color: "#fff" }}>
          <span className='hide-sm'>IMT</span>
        </Link>
      </div> */}
      <div>
        <div
          onClick={routeToNotification}
          style={{ color: "#fff", cursor: "pointer" }}
        >
          <span className="hide-sm"> Share </span>
        </div>
      </div>
    </div>
  );

  const handleMenu = () => {
    if (onlineDispatcher) {
      setAlert("Please check-out first!!!", "danger");
    } else {
      setShow(!show);
    }
  };

  const handleBack = () => {
    if (onlineDispatcher) {
      setAlert("Please check-out first!!!", "danger");
    } else {
      history.goBack();
    }
  };

  return (
    <Fragment>
      <div
        className={
          incident !== null || incident_handled !== null
            ? "incident-main-menu"
            : "incident-not-active-main-menu"
        }
      >
        <Logo />
        <div
          className={
            incident === null && incident_handled === null ? "hide-btn" : null
          }
        >
          {incidentMenu}
        </div>

        {/* <div>{incidentMenu}</div> */}
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {!onlineDispatcher && (
            <button
              className={!onlineDispatcher ? "btn btn-success" : "hide-btn"}
              onClick={() => {
                setDispatchOnline(!dispatchOnline);
                checkinDispatch({
                  responderId,
                  user_id,
                  name,
                  lname,
                  opcen_id,
                  online,
                  updatedAt: null,
                });
                // Socket
                ditpatchCheckIn();
              }}
            >
              Check-in
            </button>
          )}

          {onlineDispatcher && (
            <button
              className={
                onlineDispatcher && !onCall ? "btn btn-danger" : "hide-btn"
              }
              style={{ width: "100%", margin: "0px 18px" }}
              onClick={() => {
                {
                  incident !== null || incident_handled !== null
                    ? setAlert(
                        `Incident  is still ACTIVE. Please close it first..`,
                        "danger"
                      )
                    : toggleDisplayCheckin(!displayCheckIn);
                  setDispatchOnline(!dispatchOnline);
                  checkOutDispatch({
                    responderId,
                    user_id,
                    name,
                    lname,
                    opcen_id,
                    online,
                    updatedAt,
                  });

                  ditpatchCheckOut();
                }
              }}
            >
              Check-Out
            </button>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              height: "45px",
            }}
          >
            {profile !== null ? (
              <div onClick={() => handleMenu()}>
                <img
                  style={{
                    borderRadius: "100%",
                    height: "45px",
                    width: "45px",
                    border: "2px solid #eee",
                  }}
                  src={`${serverURI}/${profile.profilepic}`}
                  alt=""
                />
              </div>
            ) : profile === null ? (
              <div onClick={() => handleMenu()}>
                <img
                  style={{
                    borderRadius: "100%",
                    height: "45px",
                    width: "45px",
                    border: "2px solid #eee",
                  }}
                  src={`/img/Spotter.png`}
                  alt=""
                />
              </div>
            ) : null}
          </div>

          <div
            style={{
              width: "40px",

              textAlign: "center",
              cursor: "pointer",
              marginLeft: "10px",
            }}
            onClick={handleBack}
          >
            <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
          </div>

          <LogInAs onClose={() => setShow(false)} show={show} socket={socket} />
          {!createIncidentOpcen ? (
            <StanbyModal
              // onClose={() => setstandbyModalShow(false)}
              socket={socket}
              newIncident={newIncident}
            />
          ) : createIncidentOpcen && incident === null ? (
            // Trace why Create Opcen is True on log-out
            <IncidentModal onClose={() => setShow(false)} show={true} />
          ) : null}
        </div>
      </div>
      <CreateNotification
        onClose={() => setNotifShow(false)}
        show={notifShow}
        incident_handled={incident_handled}
      />
    </Fragment>
  );
};

MainMenu.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object,
  checkinDispatch: PropTypes.func.isRequired,
  checkOutDispatch: PropTypes.func.isRequired,
  onlineDispatcher: PropTypes.bool,
  setAlert: PropTypes.func.isRequired,
  incident: PropTypes.object.isRequired,
  getCurrentProfile: PropTypes.object.isRequired,
  clearIncident: PropTypes.func.isRequired,
  onCall: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile.profile,
  onlineDispatcher: state.dispatchOnline.checkInStatus,
  onCall: state.dispatchOnline.onCall,
  incident: state.incident,
});

const mapDispatchToProps = {
  checkinDispatch,
  checkOutDispatch,
  setAlert,
  getCurrentProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
