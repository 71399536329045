import React from "react";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const IncidentChartAll = ({
  fireAll,
  crimeAll,
  medicalAll,
  generalAll,
  covidAll,
}) => {
  const data = {
    labels: ["Fire", "Crime", "Medical", "General", "CoVid"],
    datasets: [
      {
        label: "# of Reports",
        data: [
          +fireAll?.length,
          +crimeAll?.length,
          +medicalAll?.length,
          generalAll?.length,
          covidAll?.length,
        ],
        backgroundColor: ["red", "black", "blue", "green", "maroon"],
        // borderColor: ["#215a75"],
        // borderWidth: 2,
      },
    ],
  };

  const options = {
    plugins: { legend: { display: false } },
    layout: { padding: { bottom: 1, top: 10 } },
    // scales: {
    //   y: {
    //     ticks: {
    //       color: "#333",
    //       font: {
    //         size: 10,
    //       },
    //     },
    //     grid: {
    //       color: "#333",
    //     },
    //   },
    //   x: {
    //     ticks: {
    //       color: "#333",
    //       font: {
    //         size: 10,
    //       },
    //     },
    //   },
    // },
  };

  return (
    <div className='IncidentChart'>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <p style={{ color: "#333", fontSize: "15px" }}>To Date</p>
        <Bar data={data} options={options} />
        <p style={{ color: "#333", fontSize: "13px" }}>Call Type</p>
      </div>
    </div>
  );
};

export default IncidentChartAll;
