import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as webRTCHandler from "../../utils/webRTC/webRTCHandler";
import DirectCall from "../VideoDirectCall/DirectCall";
import VideoActiveUsers from "./VideoActiveUsers";
import { getUser, getUsers } from "../../actions/users";

const CallDashBoard = ({ socket, user, users, getUser, getUsers }) => {
  const [onlineUsers, setOnlineUsers] = useState();
  const [onlineUsersFilter, setOnlineUsersFilter] = useState();

  console.log("User", users);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    socket.emit("getOnlineUsers");
  }, []);

  useEffect(() => {
    socket.on("getUsers", (data) => {
      setOnlineUsers(data.filter((res) => res.user !== user));
    });
  }, []);

  console.log("Online User", onlineUsers);

  useEffect(() => {
    if (onlineUsers) {
      setOnlineUsersFilter(
        users?.filter((res) => onlineUsers.some((u) => u.user === res._id))
      );
    }
  }, [users, onlineUsers]);

  console.log("fileterd user online", onlineUsersFilter);

  console.log("Online Users", onlineUsers);
  useEffect(() => {
    webRTCHandler.getLocalStream();
  }, []);

  return (
    <div className="full">
      <div className="videoCallDashboard">
        <div className="videoPlayer">
          <div className="videoPlayer">
            <DirectCall />
          </div>
        </div>
        <div className="onlineUsers">
          <VideoActiveUsers onlineUsers={onlineUsersFilter} />
        </div>
        <div className="rooms">Rooms</div>
      </div>
    </div>
  );
};

CallDashBoard.propTypes = {
  user: PropTypes.object.isRequired,
  getUser: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user._id,
  users: state.users.users,
});
const mapDispatchToProps = { getUsers };

export default connect(mapStateToProps, mapDispatchToProps)(CallDashBoard);
