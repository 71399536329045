import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { incidentCancelled } from "../../actions/incident";
import { setAlert } from "../../actions/alert";

const CancelModal = ({
  onClose,
  show,
  socket,
  incidentCancelled,
  incident,
  responder,
}) => {
  const [formData, setFormData] = useState({
    reason: "",
  });
  const { reason } = formData;
  if (!show) {
    return null;
  }

  const onChange = async c =>
    setFormData({ ...formData, [c.target.name]: c.target.value });

  const onSubmit = async c => {
    c.preventDefault();

    try {
      setAlert("Report is cancelled...", "danger");
      const incidentId = incident._id;
      const reportedby_userId = incident.user;
      incidentCancelled({ incidentId, reportedby_userId, reason });

      socket.emit("sendCancelledIncident", {
        receiverId: responder.user._id,
        senderId: incident.user,
        reason: reason,
      });
    } catch (error) {
      setAlert("Error cancelling report", "danger");
    }
  };
  return (
    <Fragment>
      <div
        className=' align-vertically '
        style={{
          display: "flex",
          justifyContent: "center",
          width: "95%",
        }}
        onClick={onClose}
      >
        <div
          className='incident-modal-content-cancelled '
          onClick={e => e.stopPropagation()}
        >
          <audio autoPlay src='/sound/sound2.mp3'></audio>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              background: "#215a75",
              borderRadius: "5px 5px 0 0",
              color: "#fff",
            }}
          ></div>
          <div className='sub-container' style={{ height: "80%" }}>
            <p style={{ fontSize: "17px", color: "#215a75" }}>
              Are your sure you want to CANCEL?
            </p>

            <form className='form' onSubmit={c => onSubmit(c)}>
              <div className='form-group'>
                <textarea
                  name='reason'
                  cols='30'
                  rows='5'
                  placeholder='* Please give a short reason'
                  value={reason}
                  onChange={c => onChange(c)}
                  required
                ></textarea>
              </div>
              <input
                type='submit'
                // value=' CONFIRM CLOSE'
                className='btn-full btn-danger '
              />
              <button onClick={onClose} className='btn-full  btn-success'>
                <p style={{ fontSize: "16px" }}> Cancel</p>
              </button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

CancelModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.object.isRequired,
  incidentCancelled: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  incidentcancelled: state.incident.incidentcancelled,
});

const mapDispatchToProps = { incidentCancelled };

export default connect(mapStateToProps, mapDispatchToProps)(CancelModal);
