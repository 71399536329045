import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Clock from "react-live-clock";
import Moment from "react-moment";
import SocketContext from "../../SocketContext";
import IncidentChart from "./IncidentChart";
import IncidentChartAll from "./IncidentChartAll";
import NotificationChart from "./NotificationChart";
import VolunteerChart from "./VolunteerChart";
import { setAlert } from "../../actions/alert";
import Incidents from "../incidentOpCen/Incidents";
import Spinner from "../layout/Spinner";
import moment from "moment";
import { getVolunteers } from "../../actions/users";
import { getResponderbyOpcen } from "../../actions/responder";
// import { incidentToday } from "../../actions/incident";
import { getPosts } from "../../actions/post";
import { getIncidents } from "../../actions/incident";
import { getIncidentClose } from "../../actions/incident";
import { getIncidentCancelled } from "../../actions/incident";
import { getOpcenProfileById } from "../../actions/opcenprofile";
import CallChart from "./CallsChart";
import { useHistory } from "react-router-dom";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;

export const IncidentDashBoard = ({
  getVolunteers,
  getResponderbyOpcen,
  getPosts,
  getIncidentClose,
  getIncidentCancelled,
  getOpcenProfileById,
  opcen: { name, user, category, _id },
  opcen_profile,
  socket,
  getIncidents,
  incident: { incidents, incidentCloseCount, incidentCancelledCount, loading },
  currentUser_id,
  users: { volunteers },
  responder: { repondersOpcen },
  post: { posts },
}) => {
  let history = useHistory();
  var date = new Date();
  var lastMonthThisDay = moment().endOf("day").subtract(1, "month");
  // var last7DayStart = moment().startOf("day").subtract(1, "week");
  var currentMonthStart = moment().startOf("month").format();
  var currentMonthEnd = moment().endOf("month").format();

  var previousMonthStart = moment()
    .startOf("month")
    .subtract(1, "month")
    .format();
  var previousMonthEnd = moment().endOf("month").subtract(1, "month").format();

  const incidentNumber = Number(
    incidentCloseCount?.length + incidentCancelledCount?.length
  );

  const [newIncident, setNewIncident] = useState(null);
  const [incidentList, setIncidentList] = useState([]);
  const [incidentClose, setIncidentClose] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [onlineDispatchers, setOnlineDispatchers] = useState([]);
  const [allVolunteers, setAllVolunteers] = useState([]);
  const [allResponder, setAllResponder] = useState([]);

  useEffect(() => {
    socket.on("getIncident", (data) => {
      setNewIncident({
        user: data.user,
        name: data.name,
        lname: data.lname,
        type: data.type,
        scompleteaddress: data.scompleteaddress,
        sarea: data.sarea,
        sstate: data.sstate,
        slat: data.slat,
        slng: data.slng,
        status: data.status,
        close_incident: [],
        date: Date.now(),
      });
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (incidents?.length > 0) {
      setIncidentList(incidents);
    }
  }, [incidents]);

  useEffect(() => {
    getVolunteers();
    getResponderbyOpcen(_id);
    getPosts();
    getIncidents();

    getIncidentClose();
    getIncidentCancelled();
    getOpcenProfileById(_id);
    // eslint-disable-next-line
  }, [
    getIncidentClose,
    getIncidentCancelled,
    getVolunteers,
    getResponderbyOpcen,
    getOpcenProfileById,
    getPosts,
    getIncidents,
    incidentClose,
  ]);

  var start = moment().startOf("day"); // set to 12:00 am today
  var end = moment().endOf("day"); // set to 23:59 pm today

  const refreshPage = () => {
    setTimeout(function () {
      window.location.reload();
    }, 60 * 1000);
  };

  useEffect(() => {
    socket.on("getOpcenCloseIncident", (data) => {
      setIncidentClose(true);
      setAlert(`Incident close`, "success");
      window.location.reload();
    });
    // eslint-disable-next-line
  }, []);

  // Get online users Socket
  useEffect(() => {
    socket.on("getUsers", (data) => {
      setOnlineUsers(data.filter((res) => res.user !== currentUser_id));
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    socket.on("getDispatcher", (data) => {
      setOnlineDispatchers(data.filter((res) => res.user !== currentUser_id));
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    socket.emit("online_Dispatchers", { data: "Request online dispatchers" });
  }, []);

  useEffect(() => {
    setAllVolunteers(volunteers);
    setAllResponder(repondersOpcen);
    // eslint-disable-next-line
  }, [volunteers, repondersOpcen]);

  useEffect(() => {
    newIncident && setIncidentList((prev) => [newIncident, ...prev]);
  }, [newIncident]);

  const incident24hrs = incidents?.filter(
    (incident) =>
      moment(incident.reportedDate) >= start &&
      moment(incident.reportedDate) <= end
  );

  const incidenttodayFire = incident24hrs?.filter((inc) => inc.type === "Fire");
  const incidenttodayCrime = incident24hrs?.filter(
    (inc) => inc.type === "Crime"
  );
  const incidenttodayMedical = incident24hrs?.filter(
    (inc) => inc.type === "Medical"
  );
  const incidenttodayGeneral = incident24hrs?.filter(
    (inc) => inc.type === "General"
  );
  const incidenttodayCovid = incident24hrs?.filter(
    (inc) => inc.type === "Covid"
  );

  const activeIncident = incidentList?.filter(
    (inc) => inc.close_incident.length === 0
  );

  const incidentCount = incidentList?.filter(
    (inc) => inc.close_incident.length === 0
  );
  // Onine Volunteers

  const onlineVolunteer = allVolunteers?.filter((el) => {
    return onlineUsers?.some((f) => {
      return f.user === el._id;
    });
  });

  const onlineResponder = allResponder?.filter((el) => {
    return onlineUsers.some((f) => {
      return f.user === el.user._id;
    });
  });

  const allDispatchers = allResponder?.filter((el) => {
    return el.type === "Dispatch Operator";
  });

  const fireAll = incidents?.filter((el) => {
    return el.type === "Fire";
  });

  const crimeAll = incidents?.filter((el) => {
    return el.type === "Crime";
  });

  const medicalAll = incidents?.filter((el) => {
    return el.type === "Medical";
  });

  const generalAll = incidents?.filter((el) => {
    return el.type === "General";
  });

  const covidAll = incidents?.filter((el) => {
    return el.type === "Covid";
  });

  const lastMonFire = incidents?.filter((el) => {
    return el.type === "Fire" && moment(el.reportedDate) >= lastMonthThisDay;
  });

  const postThisMonth = posts?.filter((el) => {
    return (
      moment(el.date) >= moment(currentMonthStart) &&
      moment(el.date) <= moment(currentMonthEnd)
    );
  });

  const postPreviousMonth = posts?.filter((el) => {
    return (
      moment(el.date) >= moment(previousMonthStart) &&
      moment(el.date) <= moment(previousMonthEnd)
    );
  });

  const volThisMonth = volunteers?.filter((el) => {
    return (
      moment(el.date) >= moment(currentMonthStart) &&
      moment(el.date) <= moment(currentMonthEnd)
    );
  });

  const volPreviousMonth = volunteers?.filter((el) => {
    return (
      moment(el.date) >= moment(previousMonthStart) &&
      moment(el.date) <= moment(previousMonthEnd)
    );
  });

  const lastMonCrime = incidents?.filter((el) => {
    return el.type === "Crime" && moment(el.reportedDate) >= lastMonthThisDay;
  });

  const lastMonMedical = incidents?.filter((el) => {
    return el.type === "Medical" && moment(el.reportedDate) >= lastMonthThisDay;
  });

  const lastMonGeneral = incidents?.filter((el) => {
    return el.type === "General" && moment(el.reportedDate) >= lastMonthThisDay;
  });

  const lastMonCovid = incidents?.filter((el) => {
    return el.type === "Covid" && moment(el.reportedDate) >= lastMonthThisDay;
  });

  const fireIncident = activeIncident?.filter((inc) => inc.type === "Fire");
  const medicalIncident = activeIncident?.filter(
    (inc) => inc.type === "Medical"
  );
  const coVidIncident = activeIncident?.filter((inc) => inc.type === "Covid");
  const crimeIncident = activeIncident?.filter((inc) => inc.type === "Crime");
  const generalIncident = activeIncident?.filter(
    (inc) => inc.type === "General"
  );

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      {refreshPage()}
      <div className="full">
        <div className="opcen_DashBoard">
          <div className="opcendate">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1.2fr 2fr 1.6fr",
                justifyContent: "space-around",
                alignItems: "center",
                color: "#fff",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{
                    height: "45px",
                    width: "45px",
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "100%",
                  }}
                  className="shadow_me"
                  src={`${serverURI}/${opcen_profile?.logo}`}
                  alt=""
                />
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      letterSpacing: "0.2px",
                      lineHeight: "18px",
                    }}
                  >
                    {name}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      letterSpacing: "0.2px",
                      fontWeight: "300",
                      lineHeight: "16px",
                    }}
                  >
                    {opcen_profile?.completeaddress}
                  </p>
                </div>
              </div>

              <div> </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  backgroundColor: "#215a75",
                  padding: "2px 10px 2px 10px",
                  maxWidth: "600px",
                  color: "#eee",
                }}
              >
                <h1>
                  <Moment format="dddd , LL ">{date}</Moment>
                </h1>
                <h1>&nbsp; | &nbsp; </h1>
                <h1>
                  <Clock
                    format={"h:mm:ssa"}
                    ticking={true}
                    timezone={"Asia/Manila"}
                  />
                </h1>
                <div
                  style={{
                    width: "20px",
                    marginRight: "10px",
                    padding: "10px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={history.goBack}
                >
                  <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="opcen_incidents">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr 1fr",
                gridGap: "5px",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  flexFlow: "column",
                }}
              >
                <img
                  // className='shadow_me'
                  style={{ width: 100 }}
                  className="shadow_me"
                  src="/incidentGraphics/siren.gif"
                  alt=""
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",

                  flexDirection: "column",
                }}
              >
                <p className="x-large">{activeIncident?.length}</p>
                <p style={{ color: "#dc3545" }}>active</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <p className="x-large">{incident24hrs?.length}</p>
                <p> today </p>
              </div>
            </div>
          </div>
          <div className="opcen_volunteers">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr 1fr",
                gridGap: "5px",
                alignItems: "center",

                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <img
                  className="shadow_me"
                  style={{ width: "100px" }}
                  src="/incidentGraphics/founder.png"
                  alt=""
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",

                  flexDirection: "column",
                }}
              >
                <p className="x-large">{onlineVolunteer?.length}</p>
                <p style={{ color: "#28a745" }}>online</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <p className="x-large">{allVolunteers?.length}</p>

                <p>total</p>
              </div>
            </div>
          </div>
          <div className="opcen_responders">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr 1fr",
                gridGap: "5px",
                alignItems: "center",

                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <img
                  className="shadow_me"
                  style={{ width: "100px" }}
                  src="/incidentGraphics/Guardian.png"
                  alt=""
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",

                  flexDirection: "column",
                }}
              >
                <p className="x-large">{onlineResponder?.length}</p>
                <p style={{ color: "#28a745" }}>online</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <p className="x-large">{allResponder?.length}</p>
                <p>total</p>
              </div>
            </div>
          </div>
          <div className="opcen_dispatchers">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr 1fr",
                gridGap: "5px",
                alignItems: "center",

                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <img
                  className="shadow_me"
                  style={{ width: "100px" }}
                  src="/incidentGraphics/Dispatch.png"
                  alt=""
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                {/* CHANGE THIS TO Socket */}
                <p className="x-large">{onlineDispatchers?.length}</p>
                <p style={{ color: "#28a745" }}>online</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <p className="x-large">{allDispatchers?.length}</p>
                <p>total</p>
              </div>
            </div>
          </div>
          <div className="active_incidents">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <p className="subTitle2">Incidents/Reports</p>
            </div>
          </div>
          <div className="closed_incidents">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <p className="subTitle2">Count</p>
            </div>
          </div>
          <div className="active_volunteers">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <p className="subTitle2">Volunteers</p>
            </div>
          </div>
          <div className="offline_volunteers">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <p className="subTitle2">Count</p>
            </div>
          </div>
          <div className=" active_responders">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <p className="subTitle2">Responders</p>
            </div>
          </div>
          <div className="offline_responders">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <p className="subTitle2">Count</p>
            </div>
          </div>
          <div className=" active_dispatchers">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <p className="subTitle2">Dispatchers</p>
            </div>
          </div>
          <div className="offline_dispatchers">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <p className="subTitle2">Count</p>
            </div>
          </div>
          <div className="opcen_fire">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "0.8fr 1fr",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  className="shadow_me"
                  style={{
                    width: "58px",
                  }}
                  src="/icons/incidentType/Fire.png"
                  alt=""
                />
              </div>

              <p style={{ fontSize: "25px", color: "#fff", padding: "20px" }}>
                Fire
              </p>
            </div>
          </div>
          <div className="active_fire">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "50%",
                  borderRight: "1px solid #000",
                }}
              >
                <p className="x-large">{fireIncident?.length}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "50%",
                }}
              >
                <p className="x-large">{incidenttodayFire?.length}</p>
              </div>
            </div>
          </div>
          <div
            className={
              incidentCount?.length > 0
                ? "active_incident"
                : "no_activeincident"
            }
          >
            <div className=" smooth_loading">
              {/* reported incidents */}

              {incidentCount?.length > 0 ? (
                <div>
                  <SocketContext.Consumer>
                    {(socket) => (
                      <Incidents incidentList={incidentList} socket={socket} />
                    )}
                  </SocketContext.Consumer>
                </div>
              ) : (
                <div>
                  <p
                    style={{
                      fontSize: "28px",
                      fontWeight: "800",
                      color: "#28a745",
                    }}
                  >
                    Great Job!
                  </p>
                  <p style={{ fontSize: "18px", fontWeight: "600" }}>
                    No active incident(s) to display.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="opcen_crime">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "0.8fr 1fr",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  className="shadow_me"
                  style={{
                    width: "58px",
                  }}
                  src="/icons/incidentType/Police.png"
                  alt=""
                />
              </div>

              <p style={{ fontSize: "25px", color: "#fff", padding: "20px" }}>
                Crime
              </p>
            </div>
          </div>
          <div className="active_crime">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "50%",
                  borderRight: "1px solid #000",
                }}
              >
                <p className="x-large">{crimeIncident?.length}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "50%",
                }}
              >
                <p className="x-large">{incidenttodayCrime?.length}</p>
              </div>
            </div>
          </div>

          <div className="opcen_medical">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "0.8fr 1fr",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  className="shadow_me"
                  style={{
                    width: "58px",
                  }}
                  src="/icons/incidentType/Medical.png"
                  alt=""
                />
              </div>

              <p style={{ fontSize: "25px", color: "#fff", padding: "10px" }}>
                Medical
              </p>
            </div>
          </div>
          <div className="active_medical">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "50%",
                  borderRight: "1px solid #000",
                }}
              >
                <p className="x-large">{medicalIncident?.length}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "50%",
                }}
              >
                <p className="x-large">{incidenttodayMedical?.length}</p>
              </div>
            </div>
          </div>

          <div className="opcen_general">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "0.8fr 1fr",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  className="shadow_me"
                  style={{
                    width: "58px",
                  }}
                  src="/icons/incidentType/General.png"
                  alt=""
                />
              </div>

              <p style={{ fontSize: "25px", color: "#fff", padding: "10px" }}>
                General
              </p>
            </div>
          </div>
          <div className="active_general">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "50%",
                  borderRight: "1px solid #000",
                }}
              >
                <p className="x-large">{generalIncident?.length}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "50%",
                }}
              >
                <p className="x-large">{incidenttodayGeneral?.length}</p>
              </div>
            </div>
          </div>

          <div className="opcen_corona">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "0.8fr 1fr",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  className="shadow_me"
                  style={{
                    width: "58px",
                  }}
                  src="/icons/incidentType/Covid-res.png"
                  alt=""
                />
              </div>

              <p style={{ fontSize: "25px", color: "#fff", padding: "10px" }}>
                CoVid
              </p>
            </div>
          </div>
          <div className="active_corona">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "50%",
                  borderRight: "1px solid #000",
                }}
              >
                <p className="x-large">{coVidIncident?.length}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "50%",
                }}
              >
                <p className="x-large">{incidenttodayCovid?.length}</p>
              </div>
            </div>
          </div>

          <div className="incident_graph">
            <div className="incident_stats_graph">
              <div
                className="title_incident_graph"
                // style={{ borderBottom: "1px solid #555" }}
              >
                <div className="flex_center_vertical">
                  <p
                    style={{
                      color: "#215a75",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Handled a total of{"   "}
                    <span
                      style={{
                        color: "#dc3545",
                        fontWeight: "800",
                        fontSize: "30px",
                        padding: "0px 10px 0px 10px ",
                      }}
                    >
                      {/* Put Command Separator toLocaleString */}
                      {incidentNumber.toLocaleString()}
                    </span>
                    emergency & non-emergency calls
                  </p>
                </div>
              </div>
              <div
                className="incident_graph_current"
                // style={{ border: "1px solid #555" }}
              >
                <IncidentChart
                  lastMonFire={lastMonFire}
                  lastMonCrime={lastMonCrime}
                  lastMonMedical={lastMonMedical}
                  lastMonGeneral={lastMonGeneral}
                  lastMonCovid={lastMonCovid}
                />
              </div>
              <div
                className="incident_graph_previous"
                // style={{ border: "1px solid #555" }}
              >
                <IncidentChartAll
                  fireAll={fireAll}
                  crimeAll={crimeAll}
                  medicalAll={medicalAll}
                  generalAll={generalAll}
                  covidAll={covidAll}
                />
              </div>
            </div>
          </div>

          {/* Stats HERE -----------------> */}
          <div className="personnel_graph">
            {/* Notification -----> */}
            <div className="stats_graph">
              <div className="title_notif">
                <div className="flex_center_horizontal">
                  <p>Notifications</p>
                </div>
              </div>

              <div className="notif_current">
                <div className="flex_center_vertical">
                  <p style={{ fontSize: "12px" }}> Current Month </p>
                  <p className="medium">{postThisMonth.length}</p>
                </div>
              </div>
              <div className="notif_previous">
                <div className="flex_center_vertical">
                  <p style={{ fontSize: "12px" }}> Previous Month </p>
                  <p className="medium">{postPreviousMonth.length}</p>
                </div>
              </div>

              <div className="notif_all flex_center_vertical">
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "2fr 1fr",
                    color: "#fff",
                  }}
                >
                  <div className="flex_center_horizontal">
                    <p className="medium">{posts.length}</p>
                    <p style={{ lineHeight: ".5px" }}>To date</p>
                  </div>
                  <div className="flex_center_vertical">
                    <NotificationChart
                      posts={posts}
                      postThisMonth={postThisMonth}
                      postPreviousMonth={postPreviousMonth}
                    />
                  </div>
                </div>
              </div>

              {/* <div className='notif_stats'>
                <div className='flex_center_vertical'>
                  <p style={{ fontSize: "13px" }}>
                    <span> Like 30 </span> | <span> Unlike 12 </span> |
                    <span> Share 5 </span> | <span> Comments 40 </span>
                  </p>
                </div>
              </div> */}

              {/*  Volunteers -------------> */}
              <div className="title_volunteer">
                <div className="flex_center_horizontal">
                  <p>Volunteers</p>
                </div>
              </div>

              <div className="vonlunteer_month">
                <div className="flex_center_vertical">
                  <p style={{ fontSize: "12px" }}> Current Month </p>
                  <p className="medium">{volThisMonth.length}</p>
                  {/* <p style={{ fontSize: "9px", color: "#28a745" }}>New</p> */}
                </div>
              </div>
              <div className="top_brgy">
                <div className="flex_center_vertical">
                  <p style={{ fontSize: "12px" }}> Previous Month </p>
                  <p className="medium">{volPreviousMonth.length}</p>
                </div>
              </div>

              <div className="volunteer_all flex_center_vertical">
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "2fr 1fr",
                    color: "#fff",
                  }}
                >
                  <div className="flex_center_horizontal">
                    <p className="medium">{allVolunteers?.length}</p>
                    <p style={{ lineHeight: ".5px" }}>To date</p>
                  </div>
                  <div className="flex_center_horizontal">
                    <VolunteerChart
                      volunteers={volunteers}
                      volThisMonth={volThisMonth}
                      volPreviousMonth={volPreviousMonth}
                    />
                  </div>
                </div>
              </div>

              {/* <div className='volunteer_demographics'>
                <div className='flex_center_vertical'>
                  <p style={{ fontSize: "13px" }}>
                    
                    <span> Men 30 </span> | <span> Women 12 </span> |{" "}
                    <span> LGBT 12 </span>
                  </p>
                  <p style={{ fontSize: "13px" }}>
                    <span> Teens 12</span> | <span> Adult 1,009</span>|
                    <span> Mid 2,093 </span> | <span> Senior 23 </span>
                  </p>
                </div>
              </div> */}

              {/* Call Stats ----------------> */}
              <div className="calls_stats">
                <div className="flex_center_horizontal">
                  <p>Calls</p>
                </div>
              </div>

              <div className="call_valid">
                <div className="flex_center_vertical">
                  <p style={{ fontSize: "12px", color: " #28a745" }}> Valid </p>
                  <p className="medium">{incidentCloseCount?.length}</p>
                </div>
              </div>
              <div className="call_cancelled">
                <div className="flex_center_vertical">
                  <p style={{ fontSize: "12px", color: "#dc3545" }}>
                    {" "}
                    Cancelled/Drop{" "}
                  </p>
                  <p className="medium">{incidentCancelledCount?.length}</p>
                </div>
              </div>

              <div className="most_active_volunteer flex_center_vertical">
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "2fr 1fr",
                    color: "#fff",
                  }}
                >
                  <div className="flex_center_horizontal">
                    <p className="medium">
                      {incidentCancelledCount?.length +
                        incidentCloseCount?.length}
                    </p>
                    <p style={{ lineHeight: ".5px" }}>To date</p>
                  </div>
                  <div className="flex_center_horizontal">
                    <CallChart
                      incidentCloseCount={incidentCloseCount}
                      incidentCancelledCount={incidentCancelledCount}
                    />
                  </div>
                </div>
              </div>

              {/* <div className='responder_demographics'>
                <div className='flex_center_vertical'>
                  <p style={{ fontSize: "13px" }}>
                    <span> Valid 30 </span> | <span> Cancelled 12 </span>
                  </p>
                </div>
              </div> */}
            </div>
          </div>

          {/* Stats Ends Here */}

          <div className="footer">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1.5fr 2fr 1.5fr",
                justifyContent: "space-around",
                alignItems: "center",
                color: "#fff",
                height: "100%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{
                    height: "25px",
                    width: "45px",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                  src="/opcenlogo/guardian_small.png"
                  alt=""
                />
                <div>
                  <p style={{ fontSize: "13px", letterSpacing: "0.2px" }}>
                    GUARDIAN Command and Control
                  </p>
                  <p className="footer_txt">
                    Computer Aided Dispatch and Civilian Reporting System
                  </p>
                </div>
              </div>
              <div>{/* Marquee News Updates here */}</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  marginRight: "10px",
                }}
              >
                <p className="footer_txt">
                  Developed by Sugbotek Inc, an affiliate of 7Core
                  Communications, Inc.
                </p>
                <p className="footer_txt"> © 2023 All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

IncidentDashBoard.propTypes = {
  opcen: PropTypes.object.isRequired,
  opcen_profile: PropTypes.object.isRequired,
  getIncidents: PropTypes.func.isRequired,
  incident: PropTypes.func.isRequired,
  currentUser_id: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  responder: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  getVolunteers: PropTypes.func.isRequired,
  getResponderbyOpcen: PropTypes.func.isRequired,
  incidentToday: PropTypes.func.isRequired,
  getPosts: PropTypes.func.isRequired,
  getIncidentClose: PropTypes.func.isRequired,
  getOpcenProfileById: PropTypes.func.isRequired,
  getIncidentCancelled: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  opcen: state.opcen.opcen,
  opcen_profile: state.opcen_profile.profile,
  incident: state.incident,
  currentUser_id: state.auth.user._id,
  users: state.users,
  responder: state.responder,
  post: state.post,
});

const mapDispatchToProps = {
  getIncidents,
  getVolunteers,
  getResponderbyOpcen,
  getPosts,
  getIncidentClose,
  getIncidentCancelled,
  getOpcenProfileById,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentDashBoard);
