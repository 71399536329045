import { React, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { getCurrentProfile } from "../../actions/profile";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { Link, Redirect } from "react-router-dom";
import { getOpcenProfileById } from "../../actions/opcenprofile";
import { getOpcen } from "../../actions/opcen";
import { getVaccinationById } from "../../actions/vaccination";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;
// Comment
const Id = ({
  getCurrentProfile,
  profile: { profile, loading },
  user,
  getOpcenProfileById,
  getOpcen,
  opcenPic,
  opcen,
  getVaccinationById,
  vax,
}) => {
  useEffect(() => {
    getCurrentProfile();
    getOpcenProfileById(user.operation_center[0]?.opcen);
    getOpcen(user._id, user.operation_center[0]?.opcen);
    getVaccinationById(user._id);
  }, [getCurrentProfile, getVaccinationById, getOpcenProfileById, getOpcen]);

  if (!profile) {
    return <Redirect to='/dashboard' />;
  }

  return (
    <Fragment>
      {profile === null || !opcen || loading || !opcenPic ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className=' align-vertically' style={{ marginTop: "25px" }}>
            <div className='id-container'>
              <div
                style={{
                  background: "#666",
                  width: "60px",
                  height: "10px",
                  borderRadius: "5px",
                }}
              ></div>
              <div
                style={{
                  width: "99%",
                  border: "1px #ddd solid",
                  borderRadius: "5px",
                  marginTop: "10px",
                  height: "95%",
                }}
              >
                <Link to='/QR'>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "44%",
                      borderRadius: " 5px 5px 0px 0px",
                      backgroundColor: "#fff",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ margin: "auto", paddingTop: "10px" }}>
                      <img
                        className='profile-id'
                        src={`${serverURI}/${profile.profilepic}`}
                        alt=''
                      />
                    </div>

                    <p className='.f'> Tap photo to show QR code</p>
                  </div>
                </Link>
                <div style={{ height: "5px", backgroundColor: "#eee" }}></div>
                <div className='id-container-validity bg-success'>
                  <p style={{ color: "#fff" }}>VALID UNTIL REVOKED</p>
                </div>
                <div style={{ height: "2px", backgroundColor: "#eee" }}></div>
                <div className='id-container-name'>
                  <span>
                    {profile.user.lname} , {profile.user.name}
                  </span>
                </div>
                <div style={{ height: "2px", backgroundColor: "#eee" }}></div>
                <p
                  style={{
                    background: "#fff",
                  }}
                >
                  Complete Name
                </p>
                <Link
                  to={!vax ? "/vaccine_data" : "/editVaccine_data"}
                  className={
                    !vax
                      ? "id-container-access bg-danger"
                      : vax?.sDoseDate === null && vax?.validation.length > 0
                      ? "id-container-access bg-mid"
                      : vax?.sDoseDate !== null &&
                        vax?.fDoseDate !== null &&
                        vax?.validation.length > 0
                      ? "id-container-access bg-success"
                      : "id-container-access bg-primary"
                    // vax?.sDoseDate === null && vax?.validation.length > 0
                    //   ? "id-container-access bg-primary"
                    //   : vax?.sDoseDate !== null
                    //   ? "id-container-access bg-success"
                    //   : vax?.validation.length === 0
                    //   ? "id-container-access bg-mid"
                    //   : "id-container-access bg-danger"
                  }
                >
                  {!vax ? (
                    <div>
                      <p style={{ color: "#fff" }}>NO COVID VACCINATION DATA</p>
                      <p style={{ color: "#fff" }}>(Tap and fill up)</p>
                    </div>
                  ) : vax?.sDoseDate === null && vax?.validation.length > 0 ? (
                    <p style={{ color: "#fff" }}>FIRST DOSE DONE</p>
                  ) : vax?.sDoseDate !== null &&
                    vax?.fDoseDate !== null &&
                    vax?.validation.length > 0 ? (
                    <p style={{ color: "#fff" }}>VACCINATION COMPLETE</p>
                  ) : (
                    <p style={{ color: "#fff" }}>FOR VALIDATION</p>
                  )}
                </Link>
                <div style={{ height: "5px", backgroundColor: "#eee" }}></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    lineHeight: "20px",
                    padding: "10px 8px 8px 10px",
                    height: "15%",

                    background: "#fff",
                  }}
                >
                  <h4>{profile.status}</h4>
                  <p className='post-date p-11'>at</p>
                  <p style={{ fontSize: "15px", fontWeight: "500" }}>
                    {" "}
                    {profile.organization}
                  </p>
                </div>
                <div style={{ height: "2px", backgroundColor: "#eee" }}></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "10%",
                    borderRadius: " 0px 0px  5px 5px",
                    backgroundColor: "#AFBFC6",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "50%",
                      height: "100%",
                      margin: "auto",

                      padding: "5px",
                      borderRadius: "0 0 0 5px",
                      color: "#333",
                    }}
                  >
                    <h4> {opcen?.name.substring(0, 13)} </h4>
                    <p> Operation Center</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      margin: "auto",
                      width: "40%",
                    }}
                  >
                    <img
                      style={{
                        height: "45px",
                        margin: "auto",
                        width: "45px",
                        borderRadius: "50%",
                      }}
                      src={`${serverURI}/${opcenPic?.logo}`}
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

Id.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  getOpcenProfileById: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  opcenPic: PropTypes.object.isRequired,
  getOpcen: PropTypes.func.isRequired,
  opcen: PropTypes.object.isRequired,
  getVaccinationById: PropTypes.func.isRequired,
  vax: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
  user: state.auth.user,
  opcenPic: state.opcen_profile.profile,
  opcen: state.opcen.opcen,
  vax: state.vaccination.vax,
});
export default connect(mapStateToProps, {
  getCurrentProfile,
  getOpcenProfileById,
  getOpcen,
  getVaccinationById,
})(Id);
