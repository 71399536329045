import {
  GET_CONVERSATION,
  CREATE_CONVERSATION,
  ERROR_CONVERSATION,
  CLEAR_CONVERSATION,
} from "../actions/types";

const initialState = {
  conversations: [],
  conversation: null,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CONVERSATION:
      return {
        ...state,
        conversation: payload,
        loading: false,
      };
    case GET_CONVERSATION:
      return {
        ...state,
        conversations: payload,
        loading: false,
      };

    case ERROR_CONVERSATION:
    case CLEAR_CONVERSATION:
      return {
        conversations: [],
        conversation: null,
        loading: true,
        error: payload,
      };

    default:
      return state;
  }
}
