import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { searchUser } from "../../actions/users";

const SearchVolunteer = ({ searchUser }) => {
  const [formData, setFormData] = useState({
    field: "",
  });
  const { field } = formData;
  const onChange = async c =>
    setFormData({ ...formData, [c.target.name]: c.target.value });

  const onSubmit = async c => {
    c.preventDefault();
    searchUser(field);
  };
  return (
    <Fragment>
      <form className='form' onSubmit={c => onSubmit(c)}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: " 2fr .5fr",
            gridGap: "5px",
            height: "7.2vh",
            alignItems: "center",
            justifyContent: "center",
            margin: "2px 0px 2px 0px",
          }}
        >
          <input
            type='text'
            name='field'
            value={field}
            placeholder='Search the First Name, Last Name or Mobile Number'
            onChange={c => onChange(c)}
            required
          />
          <input type='submit' className='btn btn-primary ' value='Search' />
        </div>
      </form>
    </Fragment>
  );
};

SearchVolunteer.propTypes = {
  searchUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = { searchUser };

export default connect(mapStateToProps, mapDispatchToProps)(SearchVolunteer);
