import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import SearchOpcen from "./SearchOpcen";
import ResultSearchOpcen from "./ResultSearchOpen";
import { getAllOpcens } from "../../actions/opcen";

const Chose_Opcen = ({
  getAllOpcens,
  onClose,
  showOpcen,
  auth: { user, loading },
  opcens,
  opcenAdded,
}) => {
  useEffect(() => {
    getAllOpcens();
  }, [getAllOpcens]);

  if (!showOpcen || opcenAdded.responder?.operation_center.length > 0) {
    return null;
  }
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='incident_modal shadow_me' onClick={onClose}>
        <div
          className='incident-modal-content'
          style={{ width: "500px", height: "450px", textAlign: "center" }}
          onClick={e => e.stopPropagation()}
        >
          <p
            style={{
              fontSize: "22px",
              fontWeight: "600",
              paddingTop: "20px",
              color: "#215a75",
            }}
          >
            {" "}
            Welcome !
          </p>
          <span
            style={{ fontSize: "17px", fontWeight: "400", paddingTop: "20px" }}
          >
            Guardian{" "}
          </span>
          <span
            style={{ fontSize: "17px", fontWeight: "400", paddingTop: "20px" }}
          >
            {user?.name}
          </span>{" "}
          {"  "}
          <span
            style={{ fontSize: "17px", fontWeight: "400", paddingTop: "20px" }}
          >
            {user?.lname}
          </span>
          <div>
            {" "}
            <p
              style={{
                fontSize: "13px",
                fontWeight: "400",
              }}
            >
              Please choose an Operation Center and start volunteering!
            </p>
          </div>
          <div
            style={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              margin: "auto",
              paddingBottom: "10px",
            }}
          >
            <SearchOpcen />

            {/* If   */}
            <ResultSearchOpcen opcens={opcens} />
          </div>
          <button
            onClick={onClose}
            className='btn btn-danger shadow_me'
            style={{ width: "80%", padding: "5px" }}
          >
            {user?.operation_center.length > 0 ? "Done" : "Later"}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

Chose_Opcen.propTypes = {
  auth: PropTypes.object.isRequired,
  opcens: PropTypes.object.isRequired,
  getAllOpcens: PropTypes.func.isRequired,
  opcenAdded: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  opcens: state.opcen.opcens,
  opcenAdded: state.users,
});

const mapDispatchToProps = { getAllOpcens };

export default connect(mapStateToProps, mapDispatchToProps)(Chose_Opcen);
