import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { updateComment, editComment } from "../../actions/post";

export const EditComment = ({
  showEditComment,
  onClose,
  postId,
  _id, //Comment Id
  editText,
  editComment,
  updateComment,
}) => {
  const [text, setText] = useState(editText);
  if (!showEditComment) {
    return null;
  }
  return (
    <div className='editCommentModal'>
      <div className='editForm shadow_me'>
        <div className='subTitle2'>Edit Comment</div>
        <form
          className='form my-1'
          onSubmit={c => {
            c.preventDefault();

            editComment(postId, _id, { text });
            setTimeout(() => {
              updateComment(postId, _id);
            }, 200);

            setText("");
            onClose();
          }}
        >
          <textarea
            name='text'
            cols='30'
            rows='8'
            placeholder='Leave Comment'
            value={text}
            onChange={c => setText(c.target.value)}
            required
            autoFocus
          ></textarea>
          <input type='submit' className='btn btn-dark my-1' value='Update' />
          <button className='btn ' onClick={onClose}>
            {" "}
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

EditComment.propTypes = {
  editComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = { editComment, updateComment };

export default connect(mapStateToProps, mapDispatchToProps)(EditComment);
