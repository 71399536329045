import {
  ADD_RESPONDER_CRUD,
  ADD_PARTNER_INDIVIDUAL_CRUD,
  ADD_PARTNER_ORGANIZATION_CRUD,
  CRUD_ERROR,
  CLEAR_CRUD,
  POST_PROFILE,
} from "../actions/types";

const initialState = {
  addResponderCRUD: false,
  addPartnerIndividualCRUD: false,
  addPartnerOrganizationCRUD: false,
  postProfile: false,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_RESPONDER_CRUD:
      return {
        ...state,
        addResponderCRUD: true,
      };

    case ADD_PARTNER_INDIVIDUAL_CRUD:
      return {
        ...state,
        addPartnerIndividualCRUD: true,
      };
    case ADD_PARTNER_ORGANIZATION_CRUD:
      return {
        ...state,
        addPartnerOrganizationCRUD: true,
      };
    case POST_PROFILE:
      return {
        ...state,
        postProfile: true,
      };
    case CRUD_ERROR:
      // case GET_INCIDENT_MESSAGES_ERROR:
      return {
        error: payload, //from the action file
      };
    case CLEAR_CRUD:
      return {
        addResponderCRUD: false,
        addPartnerIndividualCRUD: false,
        addPartnerOrganizationCRUD: false,
        postProfile: false,
        error: {},
      };

    default:
      return state;
  }
}
