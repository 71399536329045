import React, { Fragment } from "react";
import PropTypes from "prop-types";

const OnlineOffline = ({ show, socket }) => {
  if (show) {
    return null;
  }

  return (
    <Fragment>
      <div className='offline smooth_loading' style={{ opacity: "-1" }}>
        <div className='offline_content'>
          <i className='fa fa-wifi fa-3x'></i>
          <p
            className='shadow_me_white'
            style={{
              color: "red",
              fontSize: "23px",
              fontWeight: "700",
            }}
          >
            Something went wrong,
          </p>
          <p
            className='shadow_me_white'
            style={{
              color: "red",
              fontSize: "18px",
              fontWeight: "300",
            }}
          >
            {" "}
            please check internet connection.
          </p>
          <i class='fa fa-wifi-slash'></i>
        </div>
      </div>
    </Fragment>
  );
};

OnlineOffline.propTypes = {};

export default OnlineOffline;
