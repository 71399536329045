import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { searchUser } from "../../actions/users";
import { clearProfileCRUD } from "../../actions/profile";

const SearchResponder = ({ searchUser, clearProfileCRUD }) => {
  const [formData, setFormData] = useState({
    field: "",
  });

  useEffect(() => {
    clearProfileCRUD();
  }, [clearProfileCRUD]);

  const { field } = formData;
  const onChange = async c =>
    setFormData({ ...formData, [c.target.name]: c.target.value });
  const onSubmit = async c => {
    c.preventDefault();
    searchUser(field);
    clearProfileCRUD();
  };
  return (
    <Fragment>
      <form className='form' onSubmit={c => onSubmit(c)}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: " 2fr .5fr",
            gridGap: "5px",
            height: "7.2vh",
            alignItems: "center",
            justifyContent: "center",
            margin: "2px 0px 2px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <input
              type='text'
              name='field'
              style={{ padding: "10px", margin: "2px" }}
              value={field}
              placeholder='Search the First Name, Last Name or Mobile Number'
              onChange={c => onChange(c)}
              required
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input type='submit' className='btn btn-primary ' value='Search' />
          </div>
        </div>
      </form>
    </Fragment>
  );
};

SearchResponder.propTypes = {
  searchUser: PropTypes.func.isRequired,
  clearProfileCRUD: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = { searchUser, clearProfileCRUD };

export default connect(mapStateToProps, mapDispatchToProps)(SearchResponder);
