import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";

import { format } from "timeago.js";
import axios from "axios";
const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;
const MAX_LENGTH = 27;
export const Conversation = ({ conversation, user, socket }) => {
  const [responder, setResponder] = useState([]);
  const [responderMsg, setResponderMsg] = useState([]);
  const [lastMessage, setLastMsg] = useState("");

  // Socket Declarations

  const [arrivalMessage, setArrivalMessage] = useState(null);

  useEffect(() => {
    socket.on("getMessage", data => {
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
      });
    });
  }, []);

  // Convert to redux

  useEffect(() => {
    const userId = conversation.responders.find(res => res !== user);
    if (userId !== null) {
      const getResponerbyId = async () => {
        try {
          const res = await axios(`/api/responder/user/${userId}`);
          setResponder(res.data);
        } catch (err) {
          console.error(err.message);
        }
      };
      getResponerbyId();
    }

    // convert to redux
    const getSenderMessages = async () => {
      try {
        const res = await axios.get(`/api/messages_res/${conversation._id}`);
        setResponderMsg(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getSenderMessages();
  }, [conversation, user]);

  useEffect(() => {
    const lastMsg = responderMsg[responderMsg.length - 1];
    setLastMsg(lastMsg);
  }, [responderMsg]);

  return (
    <Fragment>
      {/* Map responder array */}
      {responder.map(res => (
        <Fragment>
          <div className='conversation'>
            <img
              className='converationImg'
              src={`${serverURI}/${res.profilee.profilepic}`}
              alt=''
            />

            <p className='converationName'>
              <span>
                {res.user.name} {res.user.lname}
              </span>

              {lastMessage && arrivalMessage === null ? (
                <div>
                  {lastMessage.text.length > MAX_LENGTH ? (
                    <span className='lastMessage'>{`${lastMessage.text.substring(
                      0,
                      MAX_LENGTH
                    )}...`}</span>
                  ) : (
                    <span className='lastMessage'>{lastMessage.text} </span>
                  )}
                  <span className='time'>{format(lastMessage.createdAt)}</span>

                  {/* <span className='time'> {res.user._id}</span> */}
                </div>
              ) : lastMessage &&
                arrivalMessage !== null &&
                arrivalMessage.sender === res.user._id ? (
                <div>
                  {arrivalMessage.text.length > MAX_LENGTH ? (
                    <p className='lastMessage'>{`${arrivalMessage.text.substring(
                      0,
                      MAX_LENGTH
                    )}...`}</p>
                  ) : (
                    <p className='ownMsg'>{arrivalMessage.text} </p>
                  )}

                  <span className='time'>
                    {format(arrivalMessage.createdAt)}
                  </span>
                  <audio autoPlay src='/sound/sound2.mp3'></audio>
                </div>
              ) : lastMessage ? (
                <div>
                  {lastMessage.text.length > MAX_LENGTH ? (
                    <p className='lastMessage'>{`${lastMessage.text.substring(
                      0,
                      MAX_LENGTH
                    )}...`}</p>
                  ) : (
                    <p className='lastMessage'>{lastMessage.text} </p>
                  )}

                  <span className='time'>{format(lastMessage.createdAt)}</span>
                </div>
              ) : null}
            </p>
          </div>
        </Fragment>
      ))}
    </Fragment>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Conversation);
