import React from "react";

function Locate({ panTo }) {
  return (
    <div
      className='map-center'
      onClick={e => {
        e.preventDefault();
        navigator.geolocation.getCurrentPosition(
          position => {
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },

          () => null
        );
      }}
    >
      <img src='/icons/map/center.png' alt='' />
    </div>
  );
}

export default Locate;
