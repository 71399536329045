import React, { Fragment, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import NavbarContainer from "./components/layout/NavbarContainer";
import Landing from "./components/layout/Landing";
// import Login from "./components/auth/Login";
import LoginWrapper from "./components/auth/LoginWrapper";
import Register from "./components/auth/Register";
import Alert from "./components/layout/Alert";
import { Link, Redirect } from "react-router-dom";
//Redux
import { Provider } from "react-redux";
import { store, persistedStore } from "./store";
// Redux Persist
import { PersistGate } from "redux-persist/integration/react";
import setAuthToken from "./utils/setAuthToken";
import { loadUser } from "./actions/auth";
import Dashboard from "./components/dashboard/Dashboard";
import PrivateRoute from "./components/routing/PrivateRoute";
import CreateProfile from "./components/profile-forms/CreateProfile";
import EditProfile from "./components/profile-forms/EditProfile";
import AddExperience from "./components/profile-forms/AddExperience";
import AddEducation from "./components/profile-forms/AddEducation";
import Profiles from "./components/profiles/Profiles";
import Profile from "./components/profile/Profile";
import Posts from "./components/posts/Posts";
import Post from "./components/post/Post";
import NotFound from "./components/layout/NotFound";
import ForgotPassword from "./components/auth/ForgotPassword";
import PostImage from "./components/post/PostImage";
import ID from "./components/id/Id";
import QrPhoto from "./components/id/QrPhoto";
import Otp from "./components/auth/Otp";
import OtpRegister from "./components/auth/OtpRegister";
import ChangePassword from "./components/auth/ChangePassword";
// Operation Center
import TypeOpCen from "./components/operation-center/Type_opcen";
import CreateOperationCenter from "./components/operation-center/Create_opcen";
import OpcenDashBoard from "./components/opcens/OpcenConsole";
import Opcens from "./components/opcens/Opcens";
import OpcenProfile from "./components/opcenProfile-form/CreateOpcenProfile";
import EditOpcenProfile from "./components/opcenProfile-form/EditOpcenProfile";
import Chose_Opcen from "./components/setup-account/Chose_Opcen";
// incident
import IncidentContainer from "./components/incident-form/IncidentContainer";
import Incident from "./components/incidentUser/Incident";
import IncidentOpcenContainer from "./components/incidentOpCen/IncidentOpcenContainer";
import IncidentCloseModal from "./components/incidentUser/IncidentCloseModal";
// Responder Command Center
import Responder from "./components/responder/AddResponder";
import ResponderModal from "./components/responder/ResponderModal";
// Dispatch
import DispatchContainer from "./components/dispatch/DispatchContainer";
import ActiveIncidentContainer from "./components/incidentUser/ActiveIncidentContainer";
import MessengerContainer from "./components/messenger/MessengerContainer";
import IncidentMessengerContainer from "./components/incidentUser/IncidentMessengerContainer";
// Volunteer OPCEN Management
import Volunteer from "./components/volunteer/Volunteer";
// Socket Provider
import * as io from "socket.io-client";
import SocketContext from "./SocketContext";
import IncidentMapContainer from "./components/dispatch/IncidentMapContainer";
import CreateNotificationContainer from "./components/notif/CreateNotificationContainer";
import IncidentDashBoardContainer from "./components/opcenDashboard/IncidenDashBoardContainer";
import AddVaccineData from "./components/id/AddVaccineData";
import EditVaccineData from "./components/id/EditVaccineData";
import CovidVax from "./components/id-opcen/CovidVax";
import DispatchLog_in from "./components/opcenDashboard/DispatchLog_in";
import notifMenu from "./components/notif_Dashboard/notifMenu";
import Announcement from "./components/notif_Dashboard/Announcement";
// import { onMessageListener } from "./firebase";
// import FCMNotification from "./components/layout/FCMNotification";
// import FirebaseNotification from "./components/firebaseNotification/FirebaseNotification";
import PartnerMenu from "./components/partners/PartnerMenu";
import PartnerIndividual from "./components/partners/PartnerIndividual";
import TermsOfUse from "./components/legal/TermsOfUse";
import Privacy from "./components/legal/Privacy";
import CallDashBoardContainer from "./components/VideoCallDashBoard/CallDashBoardContiner";

// const socket = io("http://10.128.50.106:5000");
// const socket = io("http://localhost:5000");

const socket = io(process.env.REACT_APP_BACKEND_SERVER_SOCKET);

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  const [show, setShow] = useState(false);

  // FCM Configuration
  // const [notification, setNotification] = useState({ title: "", body: "" });

  // onMessageListener()
  //   .then(payload => {
  //     setShow(true);
  //     setNotification({
  //       title: payload.notification.title,
  //       body: payload.notification.body,
  //     });
  //   })
  //   .catch(err => console.log("failed: ", err));

  return (
    <Provider store={store}>
      <SocketContext.Provider value={socket}>
        <PersistGate loading={null} persistor={persistedStore}>
          <BrowserRouter>
            <Fragment>
              <NavbarContainer />
              <Route exact path="/" component={Landing} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/termsofuse" component={TermsOfUse} />

              <section className="container">
                {/* {show ? (
                  <FCMNotification
                    onClose={() => setShow(false)}
                    title={notification.title}
                    body={notification.body}
                  />
                ) : null}
                <FirebaseNotification /> */}
                <Alert />
                <Switch>
                  <Route exact path="/login" component={LoginWrapper} />
                  <Route exact path="/register" component={Register} />
                  <Route exact path="/profiles" component={Profiles} />
                  <Route exact path="/forgot_pass" component={ForgotPassword} />
                  <Route exact path="/otp" component={Otp} />
                  <Route exact path="/otp_register" component={OtpRegister} />

                  <PrivateRoute
                    exact
                    path="/vaccine_data"
                    component={AddVaccineData}
                  ></PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/editVaccine_data"
                    component={EditVaccineData}
                  ></PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/vax_list"
                    component={CovidVax}
                  ></PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/stats"
                    component={DispatchLog_in}
                  ></PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/videoCall"
                    component={CallDashBoardContainer}
                  ></PrivateRoute>
                  <Route
                    exact
                    path="/changepassword"
                    component={ChangePassword}
                  />
                  <Route exact path="/typeopcen" component={TypeOpCen} />
                  <Route exact path="/profile/:id" component={Profile} />

                  <PrivateRoute
                    exact
                    path="/create-operation-center"
                    component={CreateOperationCenter}
                  />
                  <PrivateRoute
                    exact
                    path="/account_setup"
                    component={Chose_Opcen}
                  />
                  <PrivateRoute
                    exact
                    path="/volunteers"
                    component={Volunteer}
                  />
                  <PrivateRoute
                    exact
                    path="/operation-center"
                    component={Opcens}
                  />
                  <PrivateRoute
                    exact
                    path="/operation-center/:user_id/:id"
                    component={OpcenDashBoard}
                  />
                  {/* Responder Routes */}
                  <PrivateRoute
                    exact
                    path="/operation-center/responder"
                    component={Responder}
                  />
                  <PrivateRoute
                    exact
                    path="/incidentCloseOpcen"
                    component={IncidentCloseModal}
                  />
                  <PrivateRoute
                    exact
                    path="/operation-center/add-responder"
                    component={ResponderModal}
                  />
                  <PrivateRoute
                    exact
                    path="/addoperation-center/profile"
                    component={OpcenProfile}
                  />
                  <PrivateRoute
                    exact
                    path="/edit-operation-center/profile/:_id"
                    component={EditOpcenProfile}
                  />
                  <PrivateRoute exact path="/dashboard" component={Dashboard} />
                  <PrivateRoute
                    exact
                    path="/create-profile"
                    component={CreateProfile}
                  />
                  <PrivateRoute exact path="/ID" component={ID} />
                  <PrivateRoute exact path="/QR" component={QrPhoto} />
                  <PrivateRoute
                    exact
                    path="/edit-profile"
                    component={EditProfile}
                  />
                  <PrivateRoute
                    exact
                    path="/add-experience"
                    component={AddExperience}
                  />
                  <PrivateRoute
                    exact
                    path="/add-education"
                    component={AddEducation}
                  />
                  <PrivateRoute
                    exact
                    path="/incidentmap/:id"
                    component={IncidentMapContainer}
                  />
                  <PrivateRoute exact path="/posts" component={Posts} />
                  <PrivateRoute exact path="/posts/:id" component={Post} />
                  <PrivateRoute
                    exact
                    path="/posts/:id/:articleImage"
                    component={PostImage}
                  />
                  <PrivateRoute
                    exact
                    path="/createnotification"
                    component={CreateNotificationContainer}
                  />
                  {/* Incidents related routes */}
                  <PrivateRoute
                    exact
                    path="/incident-create"
                    component={IncidentContainer}
                  />
                  {/* user Side */}
                  <PrivateRoute exact path="/incident" component={Incident} />
                  <PrivateRoute
                    exact
                    path="/active-incident"
                    component={ActiveIncidentContainer}
                  />
                  {/* OPcen DashBoard */}
                  <PrivateRoute
                    exact
                    path="/opcen-dashboard"
                    component={IncidentDashBoardContainer}
                  />
                  {/* Operation Center Side */}
                  <PrivateRoute
                    exact
                    path="/incidents"
                    component={IncidentOpcenContainer}
                  />
                  {/* dispatch */}
                  <PrivateRoute
                    exact
                    path="/dispatch"
                    component={DispatchContainer}
                  />
                  <PrivateRoute
                    exact
                    path="/messenger"
                    component={MessengerContainer}
                  />
                  <PrivateRoute
                    exact
                    path="/incidentmessenger"
                    component={IncidentMessengerContainer}
                  />
                  <PrivateRoute exact path="/notifmenu" component={notifMenu} />
                  <PrivateRoute
                    exact
                    path="/announcement"
                    component={Announcement}
                  />
                  <PrivateRoute
                    exact
                    path="/partnermenu"
                    component={PartnerMenu}
                  />
                  <PrivateRoute
                    exact
                    path="/partnerIndividual"
                    component={PartnerIndividual}
                  />
                  {/* <Route component={NotFound} /> */}
                </Switch>
              </section>
            </Fragment>
          </BrowserRouter>
        </PersistGate>
      </SocketContext.Provider>
    </Provider>
  );
};

export default App;
