import React, { Fragment, useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  LoadScript,
  TrafficLayer,
} from "@react-google-maps/api";
import MapStyles from "../layout/MapStyles";

const libraries = ["places"];

const mapContainerStyle = {
  height: "100%",
  width: "100%",
  borderRadius: "5px",
  border: "2px solid #ddd",
};

const options = {
  styles: MapStyles,
  //   disableDefaultUI: true,
  //   zoomControl: true,
};

const IncidentMap = ({
  incident: { type, name, lname, number, scompleteaddress, slat, slng },
  socket,
}) => {
  const [close, setClose] = useState(false);

  // Change to Redux
  useEffect(() => {
    socket.on("getCloseIncident", () => {
      setClose(true);
    });
  }, []);

  const lng = parseFloat(slng);
  const lat = parseFloat(slat);
  const [marker, setMarker] = useState({
    lat: lat,
    lng: lng,
  });

  const [selected, setSelected] = useState(null);
  const [mapRef, setMapRef] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  // put socket here to detect incident CLOSE and CLOSE this window

  if (loadError) return "Error Loading Map";
  if (!isLoaded) return <Spinner />;
  // if (com_address)
  if (close) window.close();

  return (
    <Fragment>
      <div className='modal' style={{ zIndex: "2" }}>
        <div
          style={{
            display: "flex",
            position: "fixed",
            left: "20px",
            bottom: "50px",
            width: "400px",
            height: "200px",
            background: "#215a75",
            zIndex: "99",
            borderRadius: "10px",
            padding: "10px",
            opacity: "0.9",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {type === "Covid" ? (
                <img
                  className='incident-icon'
                  src='/icons/incidentType/Covid-res.png'
                  alt=''
                />
              ) : type === "Fire" ? (
                <img
                  className='incident-icon'
                  src='/icons/incidentType/Fire.png'
                  alt=''
                />
              ) : type === "Medical" ? (
                <img
                  className='incident-icon'
                  src='/icons/incidentType/Medical.png'
                  alt=''
                />
              ) : type === "Crime" ? (
                <img
                  className='incident-icon'
                  src='/icons/incidentType/Police.png'
                  alt=''
                />
              ) : (
                <img
                  className='incident-icon'
                  src='/icons/incidentType/General.png'
                  alt=''
                />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "20px",
                  color: "#fff",
                }}
              >
                <h1> {type} </h1>
                <div>
                  <p>{scompleteaddress.substring(0, 130)}...</p>
                  <p className='small-txt-blk text-red'> LOCATION</p>
                </div>
                <div style={{ paddingTop: "3px" }}>
                  <h3>
                    {" "}
                    {name} {lname}
                  </h3>
                  <p className='small-txt-blk text-red'> VOLUNTEER</p>
                </div>

                <div style={{ paddingTop: "3px" }}>
                  <p> {number}</p>
                  <p className='small-txt-blk text-red'> MOBILE NUMBER</p>
                </div>

                {/* <div>
                  <div style={{ marginTop: "25px" }}></div>
                  <p> Luis Ben Claude A. Dedicatoria</p>
                  <p className='small-txt-blk text-red'> INCIDENT COMMANDER</p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={17}
          center={marker}
          options={options}
          onLoad={map => setMapRef(map)}
        >
          <div
            style={{
              display: "flex",
              position: "relative",

              justifyContent: "space-between",
              width: "100%",
              zIndex: "1",
              margin: "1px 2px 2px 1px",
              marginTop: "2px",
            }}
          >
            <div
              style={{
                display: "flex",
                position: "absolute",
                alignContent: "center",
                justifyContent: "center",
                width: "100%",
                zIndex: "1",
              }}
            ></div>
          </div>

          <Marker
            position={{
              lat: marker.lat,
              lng: marker.lng,
            }}
            icon={{
              url: "/icons/map/pin.png",
              scaledSize: new window.google.maps.Size(30, 30),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(15, 15),
            }}
            onClick={() => {
              setSelected(marker);
            }}
          />

          {selected ? (
            <InfoWindow
              position={{ lat: selected.lat, lng: selected.lng }}
              onCloseClick={() => {
                setSelected(null);
              }}
            >
              <div
                style={{
                  display: "block",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <h4></h4>
                </div>

                <div>
                  <p> Incident!</p>
                </div>
                <p>
                  Date{" "}
                  {/* <Moment fromNow ago='LLLL'>
                  {selected.time}
                </Moment> */}
                </p>
              </div>
            </InfoWindow>
          ) : null}
          <TrafficLayer autoUpdate />
        </GoogleMap>
      </div>
    </Fragment>
  );
};

IncidentMap.propTypes = {
  incident: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  incident: state.incident.incident_handled,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentMap);
