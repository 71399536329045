import React, { Fragment } from "react";
import SocketContext from "../../SocketContext";
import CreateNotification from "./CreateNotification";

const CreateNotificationContainer = props => {
  return (
    <Fragment>
      <div className='messengerContainer'>
        <SocketContext.Consumer>
          {socket => <CreateNotification socket={socket} />}
        </SocketContext.Consumer>
      </div>
    </Fragment>
  );
};

export default CreateNotificationContainer;
