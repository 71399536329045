import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const ActiveDetails = ({ incident }) => {
  return (
    <Fragment>
      <div
        style={{
          width: "100%",
          height: "100px",
          marginTop: "5px",
          backgroundColor: "#fff",
        }}
      >
        <div className='incident-partition-container'>
          <div
            style={{
              width: "25%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {incident.type === "Covid" ? (
              <img
                className='incident-icon-user shadow_me'
                src='/icons/incidentType/Covid-res.png'
                alt=''
              />
            ) : incident.type === "Fire" ? (
              <img
                className='incident-icon-user shadow_me'
                src='/icons/incidentType/Fire.png'
                alt=''
              />
            ) : incident.type === "Medical" ? (
              <img
                className='incident-icon-user shadow_me'
                src='/icons/incidentType/Medical.png'
                alt=''
              />
            ) : incident.type === "Crime" ? (
              <img
                className='incident-icon-user shadow_me'
                src='/icons/incidentType/Police.png'
                alt=''
              />
            ) : (
              <img
                className='incident-icon-user shadow_me'
                src='/icons/incidentType/General.png'
                alt=''
              />
            )}
          </div>

          <div style={{ display: "flex" }}>
            <small style={{ fontSize: "12px" }}>
              {incident.scompleteaddress.substring(0, 40)}
            </small>
          </div>
        </div>
        <div>
          <p
            className='text-red'
            style={{ fontSize: "10px", textAlign: "center", paddingTop: "5px" }}
          >
            ( TYPE/LOCATION )
          </p>
        </div>
      </div>
    </Fragment>
  );
};

ActiveDetails.propTypes = {
  props: PropTypes,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveDetails);
