import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setCreateIncident } from "../../actions/incident";

const IncidentModal = ({ onClose, show, arrivalMessage }) => {
  if (!show) {
    return null;
  }

  return (
    <Fragment>
      <div
        className=' align-vertically '
        style={{
          display: "flex",
          justifyContent: "center",
          width: "95%",
          zIndex: "999",
        }}
        onClick={onClose}
      >
        <div
          className='incident-modal-content-cancelled'
          style={{ height: "200px", width: "95%" }}
          onClick={e => e.stopPropagation()}
        >
          <audio autoPlay src='/sound/sound2.mp3'></audio>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              background: "#215a75",
              borderRadius: "5px 5px 0 0",
              color: "#fff",
            }}
          >
            Message from Operation Center
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "65%",
              width: "80%",
              textAlign: "justify",
              margin: "auto",
            }}
          >
            <p style={{ fontSize: "15px" }}>
              {arrivalMessage.text.substring(0, 150)}
              {arrivalMessage.text.length > 150 ? <span>...</span> : null}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              height: "10%",
            }}
          >
            <Link
              to='/incidentmessenger'
              className='btn btn-success shadow_me'
              style={{ width: "100px" }}
            >
              Reply
            </Link>
            <button
              onClick={onClose}
              className='btn btn-danger shadow_me'
              style={{ width: "100px" }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

IncidentModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { setCreateIncident })(IncidentModal);
