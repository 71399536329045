import {
  CREATE_NOTIFICATION,
  ERROR_CREATE_NOTIFICATION,
} from "../actions/types";

const initialState = {
  notification: null,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_NOTIFICATION:
      return {
        ...state,
        notification: payload,
        loading: false,
      };
    case ERROR_CREATE_NOTIFICATION:
      return {
        error: payload, //from the action file
      };
    default:
      return state;
  }
}
