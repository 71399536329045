import React, { Fragment, useState, useEffect } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getProfileCRUD } from "../../actions/profile";
import { getUser } from "../../actions/users";
import Spinner from "../layout/Spinner";
import ResponderModal from "./ResponderModal";

const SelectedUser = ({
  loading,
  users,
  user,
  getUser,
  getProfileCRUD,
  adminCRUD,
  profile: { profiles, profileCRUD },
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (profileCRUD) {
      setShow(true);
    }
  }, [profileCRUD]);

  // Filter Array and Compare with other array
  const userWithProfile = users.filter(user =>
    profiles.some(profile => user._id === profile.user._id)
  );

  const responder = userWithProfile.map(res => (
    <tr key={res._id}>
      <td>{res.name}</td>
      <td>{res.lname}</td>
      <td>{res.number}</td>
      <td>{res.email}</td>
      <td style={{ textAlign: "center" }}>
        {
          adminCRUD?.addResponderCRUD ? (
            <button
              to='/operation-center/add-responder'
              // mutliple function firing onClick
              onClick={() => {
                getProfileCRUD(res._id);
                getUser(res._id);
              }}
              className=' btn_table btn-success'
            >
              <i className='fa fa-plus-square-o' aria-hidden='true'></i> Add
              Responder
            </button>
          ) : adminCRUD?.addPartnerIndividualCRUD ? (
            <button
              // mutliple function firing onClick
              onClick={() => {
                getProfileCRUD(res._id);
                getUser(res._id);
              }}
              className=' btn_table btn-success'
            >
              <i className='fa fa-plus-square-o' aria-hidden='true'></i> Add
              Partner
            </button>
          ) : null
          // add other CRUD flags here of Add Organization / add facilities etc.
        }
      </td>
    </tr>
  ));

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div style={{ overflow: "hidden", overflowY: "scroll", height: "200px" }}>
        <table className='commonTable'>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Mobile Number</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          {/* Loop thru the database */}
          <tbody>{responder}</tbody>
        </table>
      </div>
      <ResponderModal
        onClose={() => setShow(false)}
        show={show}
        profileCRUD={profileCRUD}
      />
    </Fragment>
  );
};

SelectedUser.propTypes = {
  users: PropTypes.array.isRequired,
  getProfileCRUD: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  profiles: PropTypes.array.isRequired,
  adminCRUD: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  loading: state.users.loading,
  user: state.users.user,
  adminCRUD: state.adminCRUD,
  profile: state.profile,
});

const mapDispatchToProps = { getProfileCRUD, getUser };

export default connect(mapStateToProps, mapDispatchToProps)(SelectedUser);
