import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const ActiveStatusETA = ({ status }) => {
  if (!status) {
    return null;
  }
  return (
    <Fragment>
      <div
        style={{
          width: "100%",
          height: "80px",
          marginTop: "5px",
          backgroundColor: "#fff",
        }}
      >
        {status ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "60px",
              justifyContent: "space-evenly",
              alignItems: "center",
              color: "#333",
            }}
          >
            <p className='text-green '> ACKNOWLEDGE</p>
            {/* <p> {dispatcher}</p> */}
            <p
              className='text-red'
              style={{ fontSize: "10px", lineHeight: "3px" }}
            >
              ( STATUS )
            </p>
            {/* Put dispatch condition here with ETA */}
            {/* <small> ETA: </small> */}
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

ActiveStatusETA.propTypes = {
  props: PropTypes,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveStatusETA);
