import React, { Fragment, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Messages from "./Messages";
import Conversation from "../conversations/Conversation";
import { getConversation } from "../../actions/conversations";
import Spinner from "../layout/Spinner";
import axios from "axios";
// import io from "socket.io-client";
import ResponderOnline from "../responder-online/ResponderOnline";
import { getResponderbyOpcen } from "../../actions/responder";
import Dropzone from "react-dropzone";
import { setAlert } from "../../actions/alert";
import SocketContext from "../../SocketContext";
import ProgressBar from "../layout/ProgressBar";

const Messenger = ({
  // user = to user._id
  user,
  responderDetails,
  getConversation,
  getResponderbyOpcen,
  stateConversations: { conversations, loading },
  responder,
  socket,
  setAlert,
}) => {
  const [currentChat, setCurrentChat] = useState("");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const scrollRef = useRef();
  const [receiver, setReceiver] = useState([]);
  const [onlineUser, setOnlineUsers] = useState([]);
  const opcen_id = responderDetails.find((res) => res.opcen);
  // Multer
  const [progress, setProgress] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    socket.on("getMessage", (data) => {
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
      });
    });
  }, []);

  useEffect(() => {
    getConversation(user);
    getResponderbyOpcen(opcen_id.opcen);
  }, [user]);

  useEffect(() => {
    if (
      arrivalMessage &&
      currentChat &&
      currentChat.responders.includes(arrivalMessage.sender)
    ) {
      setMessages((prev) => [...prev, arrivalMessage]);
    }
    // arrivalMessage &&
    //   currentChat &&
    //   currentChat.responders.includes(arrivalMessage.sender) &&
    //   setMessages(prev => [...prev, arrivalMessage]);
  }, [arrivalMessage, currentChat]);

  // Send User to server
  useEffect(() => {
    socket.on("messageFromServer", (dataFromServer) => {});

    socket.on("getUsers", (users) => {
      setOnlineUsers(users);
    });
    socket.on("left", (data) => {
      setAlert(` ${data} `, "danger");
    });
  }, [user]);

  // Convert to redux

  useEffect(() => {
    const getMessages = async () => {
      try {
        const res = await axios.get(`/api/messages_res/${currentChat._id}`);
        setMessages(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getMessages();
  }, [currentChat]);

  //  Scroll to last Message
  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [messages]);

  // get receiver name
  useEffect(() => {
    if (currentChat) {
      const receiverId = currentChat.responders.find((res) => res !== user);

      setReceiver(responder.find((res) => res.user._id === receiverId));
    }
  }, [currentChat, responder]);

  // Convert to redux
  const onDrop = async (files) => {
    try {
      const formData = new FormData();
      formData.append("file", files[0]);
      setError("");
      const res = await axios.post("/api/messages_res/attachment", formData, {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      });

      if (res.data.success === false) {
        {
          const { code } = res.data.code;
          switch (code) {
            case "FILE_MISSING":
              setError("Please select a file before uploading!");
              break;
            case "LIMIT_FILE_SIZE":
              setError(
                "File size is too large. Please upload files below 1MB!"
              );
              break;
            case "INVALID_TYPE":
              setError(
                "This file type is not supported! Only .png, .jpg files are allowed"
              );
              break;
            default:
              setError("Sorry! Something went wrong. Please try again later");
              break;
          }

          // Send Error
          setAlert({ error }, "danger");
        }
      } else if (res.data.success) {
        // Here
        const message = {
          sender: user,
          text: res.data.url.substring(
            res.data.url.length - 22,
            res.data.url.length
          ),
          conversationId: currentChat._id,
        };

        const receiverId = currentChat.responders.find((res) => res !== user);

        socket.emit("sendMessage", {
          senderId: user,
          receiverId,
          text: res.data.url.substring(
            res.data.url.length - 22,
            res.data.url.length
          ),
        });

        try {
          const res = await axios.post("/api/messages_res", message);
          setMessages([...messages, res.data]);
          setNewMessage("");
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      const errors = err.response.data.errors;

      setAlert(`Message error ${errors}`, "danger");
    }
  };

  // convert to redux
  const onSubmit = async (c) => {
    c.preventDefault();

    // Clear white spaces
    const sendMessage = newMessage.trim();
    // Check if input has characters
    if (sendMessage.length > 0) {
      const message = {
        sender: user,
        text: sendMessage,
        conversationId: currentChat._id,
      };

      const receiverId = currentChat.responders.find((res) => res !== user);

      try {
        const res = await axios.post("/api/messages_res", message);
        setMessages([...messages, res.data]);
        setNewMessage("");
        setProgress();

        socket.emit("sendMessage", {
          senderId: user,
          receiverId,
          text: sendMessage,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="chatComponentContainer">
        {/* Conversations */}
        <div className="chatMenu">
          <div className="searchContainer">
            <input type="text" placeholder="Search Responder" />
          </div>
          <div style={{ marginTop: "10px" }}>
            {conversations.map((c) => (
              <div onClick={() => setCurrentChat(c)}>
                <SocketContext.Consumer>
                  {(socket) => (
                    <Conversation
                      conversation={c}
                      user={user}
                      socket={socket}
                    />
                  )}
                </SocketContext.Consumer>
              </div>
            ))}
          </div>
        </div>

        {/* Chat box starts here */}
        <div className="chatBox">
          {currentChat ? (
            <Fragment>
              <div
                style={{
                  height: "30px",
                  backgroundColor: "#215a75",
                  color: "#fff",
                  padding: "2px 10px",
                }}
              >
                {receiver.length !== 0 ? (
                  <div style={{ height: "100%" }}>
                    <span>
                      {receiver.user.name} {receiver.user.lname} (
                      {receiver.type})
                    </span>
                    {/* <div
                      style={{
                        width: "100px",
                        height: "100%",
                        display: "flex",
                        float: "right",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <i className='fa fa-phone' aria-hidden='true'></i>
                      <div onClick={() => setShow(true)}>
                        <i
                          className='fa fa-video-camera'
                          aria-hidden='true'
                        ></i>
                      </div>

                      <i className='fa fa-mobile' aria-hidden='true'></i>
                    </div> */}
                  </div>
                ) : null}
              </div>

              <div className="chatBoxContainer">
                {messages?.map((m) => (
                  <div ref={scrollRef}>
                    <Messages message={m} own={m.sender === user} />
                  </div>
                ))}
              </div>
              <div className="chat-input" style={{ background: "#fff" }}>
                <form
                  className="incident-chat-Box"
                  style={{
                    padding: "10px  20px 5px 20px",
                    borderTop: "5px solid #eee",
                  }}
                  onSubmit={onSubmit}
                >
                  <input
                    type="text"
                    className="incident-chat-Input"
                    placeholder="Type a message"
                    onChange={(e) => setNewMessage(e.target.value)}
                    value={newMessage}
                    onSubmit={onSubmit}
                    autoFocus
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      width: "100%",
                    }}
                  >
                    <Dropzone onDrop={onDrop}>
                      {/* accept='.jpeg,.png,.gif,.mp4' */}
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div
                              className="image-button"
                              style={{ color: "#215a75" }}
                            >
                              <i
                                className="fa fa-paperclip"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    <button className="btn btn-primary" type="submit">
                      {" "}
                      <i class="fas fa-paper-plane"></i>
                      <span className="hide-sm" style={{ paddingLeft: "10px" }}>
                        Send
                      </span>
                    </button>
                  </div>
                </form>
                {!error && progress && <ProgressBar progress={progress} />}
              </div>
            </Fragment>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <p className="noConversations">
                Choose a TEAM member/group, to start a conversation.
              </p>
            </div>
          )}
        </div>

        {/* Responder Online */}

        <div className="chatAds">
          <div>
            <div className="chatHeader">
              <p>Online</p>
            </div>
            <SocketContext.Consumer>
              {(socket) => (
                <ResponderOnline
                  currentId={user}
                  setCurrentChat={setCurrentChat}
                  socket={socket}
                />
              )}
            </SocketContext.Consumer>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Messenger.propTypes = {
  user: PropTypes.object.isRequired,
  stateConversations: PropTypes.object.isRequired,
  responderDetails: PropTypes.array.isRequired,
  getResponderbyOpcen: PropTypes.func.isRequired,
  responder: PropTypes.array.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user._id,
  stateConversations: state.conversations,
  responderDetails: state.auth.user.responder,
  responder: state.responder.repondersOpcen,
});

const mapDispatchToProps = { getConversation, getResponderbyOpcen, setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(Messenger);
