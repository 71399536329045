import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { otpMatchReg, sendOtpReg } from "../../actions/sms";
import { register } from "../../actions/auth";

const OtpRegister = ({ auth, otpMatchReg, sendOtpReg, isMatch, register }) => {
  // Display hide div
  const [displayTimer, hideTimer] = useState(false);
  const [displayBtn, hideBtn] = useState(true);
  // Timer State
  const [seconds, setSeconds] = useState(300);

  const generate = Math.floor(Math.random() * (772881 - 111181 + 1) + 111181);

  const [otp, setOtp] = useState(
    Math.floor(Math.random() * (772881 - 111181 + 1) + 111181)
  );

  const name = auth.name;
  const lname = auth.lname;
  const email = auth.email;
  const number = auth.number;
  const password = auth.password;

  const msg = `Hi ${name}, Proceed with your registration for GUARDIAN Account, Your One-Time Password is ${otp} OTP will expire in 15 minutes, If you did not initiate this request, please call your Operation Center administrator`;

  useEffect(() => {
    setOtp(generate);
    sendOtpReg(number, name, msg, otp);
    // eslint-disable-next-line
  }, []);

  const [formData, setFormData] = useState({
    sent_otp: "",
  });

  const { sent_otp } = formData;

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds("BOOOOM!");
      hideTimer(true);
      hideBtn(false);
    }
  }, [seconds]);

  const onChange = async c =>
    setFormData({ ...formData, [c.target.name]: c.target.value });

  const onResend = () => {
    sendOtpReg(number, name, msg, otp);
    setOtp(generate);
    hideTimer(!displayTimer);
    hideBtn(!displayBtn);
    setSeconds(300);
  };

  const checkOTPMatch = () => {
    otpMatchReg(number, sent_otp);
  };

  const onSubmit = async c => {
    c.preventDefault();

    register({ name, lname, number, email, password });
  };

  if (auth.isAuthenticated) {
    return <Redirect to='/dashboard' />;
  }

  return (
    <Fragment>
      <div className=' main-container align-vertically'>
        <div
          style={{
            display: "block",
            borderRadius: "10px",
            padding: "20px",
          }}
        >
          {/* <h1 className='large text-primary'>Register/Sign-up</h1> */}
          <p className='lead'>
            <i className='fa fa-key' aria-hidden='true'></i> You will receive a
            One-Time Password (OTP) on your registered mobile number.
          </p>

          <form className='form' onSubmit={c => onSubmit(c)}>
            <div className='form-group'>
              <input
                style={{
                  textAlign: "center",
                  fontSize: "40px",
                  letterSpacing: "8px",
                }}
                type='password'
                placeholder='XXXXXX'
                name='sent_otp'
                value={sent_otp}
                onChange={c => onChange(c)}
                required
                minLength='6'
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <small
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    fontSize: "12px",
                    letterSpacing: ".5px",
                    padding: "2px",
                  }}
                >
                  Did you receive an OTP?
                </small>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                {displayBtn && (
                  <small
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      fontSize: "12px",
                      letterSpacing: ".5px",
                      padding: "2px",
                    }}
                  >
                    Resend OTP after {seconds} seconds
                  </small>
                )}
                {displayTimer && (
                  // <input
                  //   type='submit'
                  //   className='btn btn-primary'
                  //   value='Resend'
                  // />
                  <button
                    onClick={onResend}
                    type='button'
                    style={{ padding: "3px" }}
                  >
                    Resend OTP
                  </button>
                )}
              </div>
            </div>

            <small
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                fontSize: "12px",
                letterSpacing: ".5px",
                padding: "2px",
              }}
            >
              If you need to change your mobile number, you may do so through
              Update Profile, or by reaching out to your Operation Center
              Administrator at admin@guardian.ph
            </small>
            {!isMatch ? (
              <div>
                <button
                  onClick={checkOTPMatch}
                  type='button'
                  className='btn btn-primary'
                >
                  Proceed
                </button>
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <input
                  type='submit'
                  className='btn btn-success'
                  value='Register'
                  style={{ borderRadius: "5px" }}
                />
                <div
                  className='small-txt'
                  style={{ color: "red", fontSize: "14px", fontWeight: "400" }}
                >
                  By clicking register you agree to the terms and condition and
                  privacy policy.
                </div>
                <div
                  style={{ color: "red", fontSize: "14px", fontWeight: "400" }}
                  className='small-txt'
                >
                  <p style={{ color: "#000" }}>
                    <Link to={"/privacy"}>
                      <span style={{ color: "#000" }}> Privacy Policy </span>
                    </Link>
                    |
                    <Link to={"/termsofuse"}>
                      <span style={{ color: "#000" }}>
                        {" "}
                        Terms & Conditions{" "}
                      </span>
                    </Link>
                    {/* |
                    <Link>
                      <span style={{ color: "#000" }}> Contact Us</span>
                    </Link> */}
                  </p>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </Fragment>
  );
};

OtpRegister.propTypes = {
  sendOtpReg: PropTypes.func.isRequired,
  otpMatchReg: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isMatch: PropTypes.bool,
};

const mapStateToProps = state => ({
  sms: state.sms,
  isMatch: state.sms.isMatch,
  auth: state.auth,
});
export default connect(mapStateToProps, { register, sendOtpReg, otpMatchReg })(
  OtpRegister
);
