import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import VaxsList from "./VaxsList";

import { getVaccinations } from "../../actions/vaccination";

const CovidVax = ({
  vaccinations: { vaxs, loading },
  opcen: { _id },
  getVaccinations,
}) => {
  useEffect(() => {
    getVaccinations();
  }, [getVaccinations]);

  const opcenVaxData = vaxs?.filter((el) => {
    return (
      el.validation.length === 0 ||
      (el.validation[0].approve === false &&
        el.user.operation_center.filter((op) => {
          return op.opcen === _id;
        }))
    );
  });

  return loading ? (
    Spinner
  ) : (
    <Fragment>
      <div
        className=" smooth_loading"
        style={{ position: "fixed", left: "20px", right: "20px" }}
      >
        <div>
          <p className="lead">Covid vaccination for review</p>
        </div>
        {opcenVaxData.length > 0 ? (
          <VaxsList opcenVaxData={opcenVaxData} />
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <p className="lead">Nothing to display</p>
          </div>
        )}
      </div>
    </Fragment>
  );
};

CovidVax.propTypes = {
  vaccinations: PropTypes.object.isRequired,
  getVaccinations: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  vaccinations: state.vaccination,
  opcen: state.opcen.opcen,
});

const mapDispatchToProps = { getVaccinations };

export default connect(mapStateToProps, mapDispatchToProps)(CovidVax);
