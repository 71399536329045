import React, { Fragment } from "react";
import IncidentDashBoard from "./IncidentDashBoard";
import SocketContext from "../../SocketContext";

export const IncidentDashBoardContainer = props => {
  // const refreshPage = () => {
  //   setTimeout(function () {
  //     window.location.reload();
  //   }, 60 * 1000);
  // };

  return (
    <Fragment>
      {/* {refreshPage()} */}
      <div
        style={{
          position: "fixed",
          left: "0px",
          right: "0px",
          bottom: "0",
          top: "0px",
          zIndex: "10",
          background: "#174052",
        }}
      >
        <SocketContext.Consumer>
          {socket => <IncidentDashBoard socket={socket} />}
        </SocketContext.Consumer>
      </div>
    </Fragment>
  );
};

export default IncidentDashBoardContainer;
