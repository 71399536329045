import React, { Fragment } from "react";
import IncidentsMain from "./IncidentsMain";
import SocketContext from "../../SocketContext";

export const IncidentOpcenContainer = props => {
  const refreshPage = () => {
    setTimeout(function () {
      window.location.reload();
    }, 60 * 1000);
  };

  return <Fragment>{refreshPage()}</Fragment>;
};

export default IncidentOpcenContainer;
