import React, { Fragment, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import CRUDModal from "../posts/CRUDModal";
import { format } from "timeago.js";

const MAX_LENGTH = 150;
const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;

const CommentItem = ({
  profile: { profiles, loading },
  postId,
  comment: { _id, text, name, lname, profilepic, user, date },
  auth,
}) => {
  const [shortComment, toggleShortComment] = useState(true);
  const [fullComment, toggleFullComment] = useState(false);
  const [showCRUD, setShowCRUD] = useState(false);
  const [commentFlag, setCommentFlag] = useState(false);

  const onButtonClick = useCallback(
    (e) => {
      toggleShortComment(!shortComment);
      toggleFullComment(!fullComment);
    },
    [fullComment, shortComment]
  );

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <div className="comment bg-white p" style={{ marginTop: "5px" }}>
          <div style={{ width: "100%" }}>
            {/* map all profiles here */}
            {profiles?.length > 0 ? (
              profiles.map((profile) => {
                if (profile?.user?._id === user) {
                  return (
                    <Fragment>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "15%",
                          }}
                        >
                          <Link to={`/profile/${user}`}>
                            <img
                              className="post-profile"
                              src={`${serverURI}/${profile.profilepic}`}
                              alt="..."
                            />
                          </Link>
                        </div>
                        <div
                          style={{
                            width: "80%",
                            marginLeft: "10px",
                          }}
                        >
                          <div className="Htitle">
                            <span>{name}</span>{" "}
                            <span className="p-11">{lname}</span>{" "}
                          </div>

                          {/* Comment Text starts here */}

                          <div
                            style={{
                              display: "flex",

                              background: "#eee",
                              borderRadius: "5px",
                            }}
                          >
                            <div>
                              {text.length > MAX_LENGTH ? (
                                <div className=" commentFont m f-2">
                                  {shortComment && (
                                    <p
                                      style={{
                                        whiteSpace: "pre-line",
                                      }}
                                    >
                                      {`${text.substring(0, MAX_LENGTH)}...`}
                                      <button
                                        className="btn-comment"
                                        onClick={onButtonClick}
                                        type="button"
                                      >
                                        <p
                                          className="f-1"
                                          style={{
                                            color: "#215a75",
                                          }}
                                        >
                                          see more
                                        </p>
                                      </button>
                                    </p>
                                  )}
                                  {fullComment && (
                                    <div>
                                      <p
                                        style={{
                                          whiteSpace: "pre-line",
                                        }}
                                      >
                                        {text}
                                      </p>
                                      <button
                                        className="btn-comment"
                                        onClick={onButtonClick}
                                        type="button"
                                      >
                                        see less
                                      </button>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <p className="commentFont m f-2">{text}</p>
                              )}
                            </div>
                          </div>

                          <p className="post-date p-11 ">{format(date)}</p>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {(!auth.loading && user === auth.user._id) ||
                          auth.isOpcenAdmin ? (
                            <button
                              onClick={() => {
                                setShowCRUD(true);
                                setCommentFlag(true);
                              }}
                              type="button"
                              className="btn-post f-2"
                            >
                              <i
                                className="fa fa-ellipsis-h"
                                aria-hidden="true"
                              ></i>
                            </button>
                          ) : null}
                        </div>
                        {(!auth.loading && user === auth.user._id) ||
                        auth.isOpcenAdmin ? (
                          <CRUDModal
                            showCRUD={showCRUD}
                            onClose={() => setShowCRUD(false)}
                            auth={auth}
                            _id={_id}
                            editText={text}
                            postId={postId}
                            commentFlag={commentFlag}
                          />
                        ) : null}
                      </div>
                    </Fragment>
                  );
                }
              })
            ) : (
              <div style={{ textAlign: "center" }}>
                <img
                  className="post-profile"
                  src={`/img/Spotter.png`}
                  alt="..."
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

CommentItem.propTypes = {
  postId: PropTypes.number.isRequired,
  comment: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});
export default connect(mapStateToProps, {})(CommentItem);
