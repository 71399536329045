import React from "react";
import { Fragment } from "react";

import SocketContext from "../../SocketContext";
import CallDashBoard from "./CallDashBoard";

export const CallDashBoardContainer = (props) => {
  return (
    <Fragment>
      <div>
        <SocketContext.Consumer>
          {(socket) => <CallDashBoard socket={socket} />}
        </SocketContext.Consumer>
      </div>
    </Fragment>
  );
};

export default CallDashBoardContainer;
