import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const MissedCallModal = ({ onClose, show, responder }) => {
  if (!show) {
    return null;
  }

  return (
    <Fragment>
      <div className='incident_modal' onClick={onClose}>
        <div
          className='incident-modal-content'
          onClick={e => e.stopPropagation()}
        >
          <div
            style={{
              width: "100%",
              textAlign: "center",
              background: "#215a75",
              borderRadius: "5px 5px 0 0",
              color: "#fff",
            }}
          >
            {responder.user.name}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "65%",
              width: "80%",
              textAlign: "justify",
              margin: "auto",
            }}
          >
            <p
              style={{ fontSize: "18px", color: "#dc3545", fontWeight: "500" }}
            >
              MISSED CALL
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              height: "10%",
            }}
          >
            <button
              onClick={onClose}
              className='btn btn-danger shadow_me'
              style={{
                borderRadius: "5px",
                width: "100%",
                height: "50px",
                margin: "0px 10px",
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

MissedCallModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(MissedCallModal);
