import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Fragment } from "react";
import Moment from "react-moment";
import { QRCode } from "react-qrcode-logo";
import { setAlert } from "../../actions/alert";
import Spinner from "../layout/Spinner";
// import { setAlert } from "../../actions/alert";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;

const IncidentDetails = ({
  incident,
  socket,
  profile: { profiles, loading },
  setAlert,
  handleIncident,
}) => {
  const [online, setOnline] = useState(true);
  const [onlineUsers, setOnlineUsers] = useState([]);

  useEffect(() => {
    if (incident !== null && handleIncident) {
      socket.on("left", (data) => {
        setOnline(false);

        setAlert(`Volunter ${incident.name}, has disconnected`, "danger");
      });
      socket.on("getUsers", (users) => {
        setOnlineUsers(users);
      });
    }
  }, [incident]);

  useEffect(() => {
    // Add Detect User Connection retored...

    if (onlineUsers && incident !== null) {
      const userOnline = onlineUsers.find(
        (online) => online.user === incident.user
      );
      if (userOnline) {
        setOnline(true);
        setAlert(`Volunteer ${incident.name}, is back online`, "success");
      }
    }
  }, [onlineUsers]);

  const routeToProfile = () => {
    window.open(`/profile/${incident.user}`);
    return false;
  };

  const routeToMap = () => {
    window.open(`/incidentmap/${incident._id}`);
    return false;
  };

  return !incident ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="incidentDetails">
        <div
          style={{
            width: "90%",
            height: "90%",
            background: "#ddd",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                textAlign: "center",
              }}
            >
              <QRCode
                size="80"
                includeMargin="true"
                fgColor="#000"
                bgColor="#ddd"
                eyeRadius={[
                  5, // top/left eye
                  10, // top/right eye
                  5, // bottom/left eye
                ]}
                value={incident._id}
              />

              <p className="small-txt-blk">INCIDENT ID</p>
            </div>

            <div
              style={{
                textAlign: "center",
                padding: "2px",
                borderBottom: "2px solid #aaa",
              }}
            >
              <h3 style={{ color: "#dc3545" }}>
                {" "}
                <Moment format="LT">{incident.date}</Moment>
              </h3>
              <p className="small-txt-blk">TIME RECEIVED</p>
            </div>
            {/* <div style={{ textAlign: "center", padding: "2px" }}>
                <h4 style={{ color: "#28a745" }}>
                  <Stopwatch />
                </h4>
                <p className='small-txt-blk'>LAPSE TIME</p>
              </div> */}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {incident.type === "Covid" ? (
              <img
                className="incident-icon"
                src="/icons/incidentType/Covid-res.png"
                alt=""
              />
            ) : incident.type === "Fire" ? (
              <img
                className="incident-icon"
                src="/icons/incidentType/Fire.png"
                alt=""
              />
            ) : incident.type === "Medical" ? (
              <img
                className="incident-icon"
                src="/icons/incidentType/Medical.png"
                alt=""
              />
            ) : incident.type === "Crime" ? (
              <img
                className="incident-icon"
                src="/icons/incidentType/Police.png"
                alt=""
              />
            ) : (
              <img
                className="incident-icon"
                src="/icons/incidentType/General.png"
                alt=""
              />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "20px",
                color: "#fff",
              }}
            >
              <h1> {incident.type} </h1>
              <div onClick={routeToMap} style={{ cursor: "pointer" }}>
                <p>{incident?.scompleteaddress?.substring(0, 130)}...</p>
                <p className="small-txt-blk text-red"> LOCATION</p>
              </div>
              <div style={{ paddingTop: "3px" }}>
                <h3>
                  {" "}
                  {incident.name} {incident.lname}
                </h3>
                <p className="small-txt-blk text-red"> VOLUNTEER</p>
              </div>

              <div style={{ paddingTop: "3px" }}>
                <p> {incident?.number}</p>
                <p className="small-txt-blk text-red"> MOBILE NUMBER</p>
              </div>

              {/* <div>
                  <div style={{ marginTop: "25px" }}></div>
                  <p> Luis Ben Claude A. Dedicatoria</p>
                  <p className='small-txt-blk text-red'> INCIDENT COMMANDER</p>
                </div> */}
            </div>
          </div>
        </div>
        <div>
          <div onClick={routeToProfile} style={{ cursor: "pointer" }}>
            {profiles.length > 0 ? (
              profiles.map((profile, i) => {
                if (profile?.user?._id === incident.user) {
                  return (
                    <img
                      className={
                        online
                          ? `in_profile_img_online`
                          : `in_profile_img_offline`
                      }
                      alt="profile"
                      src={`${serverURI}/${profile.profilepic}`}
                    />
                  );
                }
              })
            ) : (
              <div>
                <img
                  className="post-profile"
                  src={`/img/Spotter.png`}
                  alt="..."
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

IncidentDetails.propTypes = {
  profile: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  handleIncident: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

const mapDispatchToProps = { setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(IncidentDetails);
