import {
  CALL_SET_LOCAL_STREAM,
  ERROR_CALL_SET_LOCAL_STREAM,
  CALL_SET_CALL_STATE,
  CALL_SET_CALLING_DIALOG_VISIBLE,
} from "./types";

export const setLocalStream = (localStream) => (dispatch) => {
  console.log("action", localStream);
  try {
    dispatch({
      type: CALL_SET_LOCAL_STREAM,
      payload: localStream,
    });
  } catch (err) {
    dispatch({
      type: ERROR_CALL_SET_LOCAL_STREAM,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const setCallState = (callState) => (dispatch) => {
  try {
    dispatch({
      type: CALL_SET_CALL_STATE,
      payload: callState,
    });
  } catch (err) {
    dispatch({
      type: ERROR_CALL_SET_LOCAL_STREAM,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const setCallingDialogVisible = (visible) => (dispatch) => {
  try {
    dispatch({
      type: CALL_SET_CALLING_DIALOG_VISIBLE,
      payload: visible,
    });
  } catch (err) {
    dispatch({
      type: ERROR_CALL_SET_LOCAL_STREAM,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
