import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

export const IncomingCallDialog = (props) => {
  const answer = () => {
    // Answer
  };
  const reject = () => {
    // reject
  };
  return (
    <div className="calling_dialog">
      <div> Incoming CallDialog</div>
      <div>
        <button onClick={answer}> Answer </button>
        <button onClick={reject}> Reject on</button>
      </div>
    </div>
  );
};

IncomingCallDialog.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(IncomingCallDialog);
