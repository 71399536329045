import {
  GET_ALL_ANNOUCEMENT,
  GET_ALL_ANNOUCEMENT_ERROR,
  DELETE_ANNOUCEMENT,
  GET_ANNOUNCEMENT,
  CLEAR_ANNOUNCEMENT,
  DISPLAYED_ANNOUNCEMENT,
} from "../actions/types";

const initialState = {
  announcements: [],
  announcement: null,
  displayed: false,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_ANNOUCEMENT:
      return {
        ...state,
        announcements: payload,
        loading: false,
      };
    case GET_ANNOUNCEMENT:
      return {
        ...state,
        announcement: payload,
        loading: false,
      };
    case DISPLAYED_ANNOUNCEMENT:
      return {
        ...state,
        displayed: payload,
        loading: false,
      };
    case GET_ALL_ANNOUCEMENT_ERROR:
      return {
        ...state,
        error: payload,
        announcements: [],
        announcement: null,

        loading: false,
      };
    case CLEAR_ANNOUNCEMENT:
      return {
        ...state,
        announcement: null,
        announcements: [],
        displayed: false,
        loading: true,
        error: {},
      };
    case DELETE_ANNOUCEMENT:
      return {
        ...state,
        announcements: state.announcements.filter(
          announcement => announcement._id !== payload
        ),
        loading: false,
      };
    default:
      return state;
  }
}
