import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setCreateIncident } from "../../actions/incident";

const CreateProfileModal = ({ onClose, show }) => {
  if (!show) {
    return null;
  }

  return (
    <Fragment>
      <div className='incident_modal' onClick={onClose}>
        <div
          className='incident-modal-content'
          style={{ width: "70%", height: "250px" }}
          onClick={e => e.stopPropagation()}
        >
          <audio autoPlay src='/sound/sound2.mp3'></audio>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              background: "#dc3545",
              borderRadius: "5px 5px 0 0",
              color: "#fff",
            }}
          >
            No Profile
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "90%",
              textAlign: "center",
              margin: "auto",
              padding: "30px 10px 30px 10px",
            }}
          >
            <img
              style={{ height: "50px", width: "50px" }}
              src='incidentGraphics/NoService.png'
              alt='No Service'
            />
            <p
              style={{
                fontSize: "16px",
                paddingBottom: "5px",
                fontWeight: "600",
                paddingBottom: "0px",
                color: " #215a75",
              }}
            >
              Sorry, you cannot report without profile.
            </p>

            <p style={{ fontSize: "14px" }}>Create a Profile first</p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",

              flexDirection: "column",
            }}
          >
            <Link
              to='/create-profile'
              className='btn btn-success shadow_me'
              style={{ width: "80%", paddingBottom: "5px" }}
            >
              Create Profile
            </Link>
            {/* <button
              onClick={onClose}
              className='btn btn-danger shadow_me'
              style={{ width: "80%", paddingBottom: "5px" }}
            >
              Close
            </button> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

CreateProfileModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.object.isRequired,
  scompleteaddress: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { setCreateIncident })(
  CreateProfileModal
);
