import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import ProfileTop from "./ProfileTop";
import ProfileAbout from "./ProfileAbout";
import ProfileEducation from "./ProfileEducation";
import ProfileExperience from "./ProfileExperience";
import { getProfileById, getProfileCRUD } from "../../actions/profile";

const Profile = ({
  getProfileById,
  match,
  profile: { profile, profileCRUD, loading },
  adminCRUD,
  getProfileCRUD,
  auth,
}) => {
  useEffect(() => {
    if (
      adminCRUD?.addResponderCRUD ||
      adminCRUD?.addPartnerIndividualCRUD ||
      adminCRUD?.postProfile
    ) {
      getProfileCRUD(match.params.id);
    } else {
      getProfileById(match.params.id);
    }
  }, [getProfileById, getProfileCRUD, match.params.id]);

  return (
    <Fragment>
      {profile === null || profileCRUD === null || loading ? (
        <Spinner />
      ) : (
        <Fragment>
          {/* <Link to='/profiles' className='btn btn-light'>
            Back To Profiles
          </Link> */}

          {adminCRUD?.addResponderCRUD ||
          adminCRUD?.addPartnerIndividualCRUD ||
          adminCRUD?.postProfile ? (
            <div className='profile-grid my-1'>
              <ProfileTop profile={profileCRUD} />
              <ProfileAbout profile={profileCRUD} />

              {/* Experience */}
              <div className='profile-exp bg-white p'>
                <h3 className='text-primary'>Experience</h3>

                {profileCRUD?.experience.length > 0 ? (
                  <Fragment>
                    {profileCRUD?.experience.map(experience => (
                      <ProfileExperience
                        key={experience._id}
                        experience={experience}
                      />
                    ))}
                  </Fragment>
                ) : (
                  <h5> Nothing to show </h5>
                )}
              </div>
              {/* Education */}

              <div className='profile-edu bg-white p'>
                <h3 className='text-primary'>Education</h3>
                {profileCRUD?.education.length > 0 ? (
                  <Fragment>
                    {profileCRUD?.education.map(education => (
                      <ProfileEducation
                        key={education._id}
                        education={education}
                      />
                    ))}
                  </Fragment>
                ) : (
                  <h5> Nothing to show </h5>
                )}
              </div>
            </div>
          ) : (
            <Fragment>
              {auth.isAuthenticated &&
                auth.loading === false &&
                auth.user._id === profile.user._id && (
                  <Link to='/edit-profile' className='btn btn-dark'>
                    {" "}
                    Edit Profile{" "}
                  </Link>
                )}
              <div className='profile-grid my-1'>
                <ProfileTop profile={profile} />
                <ProfileAbout profile={profile} />

                {/* Experience */}
                <div className='profile-exp bg-white p'>
                  <h3 className='text-primary'>Experience</h3>

                  {profile.experience.length > 0 ? (
                    <Fragment>
                      {profile.experience.map(experience => (
                        <ProfileExperience
                          key={experience._id}
                          experience={experience}
                        />
                      ))}
                    </Fragment>
                  ) : (
                    <h5> Nothing to show </h5>
                  )}
                </div>
                {/* Education */}

                <div className='profile-edu bg-white p'>
                  <h3 className='text-primary'>Education</h3>
                  {profile.education.length > 0 ? (
                    <Fragment>
                      {profile.education.map(education => (
                        <ProfileEducation
                          key={education._id}
                          education={education}
                        />
                      ))}
                    </Fragment>
                  ) : (
                    <h5> Nothing to show </h5>
                  )}
                </div>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

Profile.propTypes = {
  getProfileById: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getProfileCRUD: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth,
  adminCRUD: state.adminCRUD,
});

export default connect(mapStateToProps, { getProfileById, getProfileCRUD })(
  Profile
);
