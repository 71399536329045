import axios from "axios";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  CLEAR_PROFILE,
  RESET_PASSWORD,
  USER_OPCEN_ADMIN,
  CLEAR_OPCEN_PROFILE,
  CLEAR_INCIDENT,
  CLEAR_OPCEN,
  CLEAR_POST,
  CLEAR_SMS,
  CLEAR_RESPONDER,
  CLEAR_USERS,
  CLEAR_DISPATCHER,
  CLEAR_INCIDENT_MSG,
  CLEAR_CONVERSATION,
  CLEAR_ANNOUNCEMENT,
  CLEAR_VAX,
  REGISTER_OTP,
  USER_RESPONDER_ROLE,
  SET_VOLUNTEER_LAYOUT,
  SET_ADMIN_LAYOUT,
  SET_RESPONDER_LAYOUT,
  CLEAR_CRUD,
} from "./types";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";

export const registerUser =
  (name, lname, number, email, password) => dispatch => {
    try {
      dispatch({
        type: REGISTER_OTP,
        payload: name,
        lname,
        number,
        email,
        password,
      });
    } catch (err) {
      dispatch({
        type: REGISTER_FAIL,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };

//Load User check if there is a token for user
export const loadUser = () => async dispatch => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get("/api/auth");

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
    dispatch(loadRigths());
    dispatch(responderRole());
  } catch (err) {
    dispatch({ type: AUTH_ERROR });
  }
};

//Register User

export const register =
  ({ name, lname, number, email, password }) =>
  async dispatch => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      name,
      lname,
      number,
      email,
      password,
    });

    try {
      const res = await axios.post("/api/users", body, config);
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });

      dispatch(loadUser());

      // dispatch(loadRigths());
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

//Login User

export const login = (email, password) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password });
  try {
    const res = await axios.post("/api/auth", body, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Logout / Clear Profile

export const logout = () => dispatch => {
  dispatch({ type: LOGOUT });
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: CLEAR_POST });
  dispatch({ type: CLEAR_SMS });
  dispatch({ type: CLEAR_OPCEN });
  dispatch({ type: CLEAR_OPCEN_PROFILE });
  dispatch({ type: CLEAR_INCIDENT });
  dispatch({ type: CLEAR_RESPONDER });
  dispatch({ type: CLEAR_USERS });
  dispatch({ type: CLEAR_DISPATCHER });
  dispatch({ type: CLEAR_INCIDENT_MSG });
  dispatch({ type: CLEAR_CONVERSATION });
  dispatch({ type: CLEAR_ANNOUNCEMENT });
  dispatch({ type: CLEAR_VAX });
  dispatch({ type: CLEAR_CRUD });
};

export const go_offlline = email => async dispatch => {
  try {
    const res = await axios.delete(`/api/users/online/${email}`);
    dispatch(setAlert(res.data.msg, "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

//  Check if User Exist via email

export const forgot_password = email => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email });
  try {
    const res = await axios.post("/api/auth/forgot", body, config);
    dispatch({
      type: RESET_PASSWORD,
      payload: res.data,
    });

    // dispatch(send_otp());
    // dispatch(
    //   setAlert("One time pin (OTP) send to your mobile number.", "success")
    // );
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

// Add Access Rigths

export const accessrigths = rigths => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.put("api/users/accessrigths", rigths, config);

    dispatch({
      type: USER_OPCEN_ADMIN,
      payload: res.data,
    });

    dispatch(setAlert("Admin rights added", "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const loadRigths = () => async dispatch => {
  try {
    const res = await axios.get("/api/auth/accessrigths");

    dispatch({
      type: USER_OPCEN_ADMIN,
      payload: res.data,
    });
  } catch (err) {
    dispatch({ type: AUTH_ERROR });
  }
};

export const responderRole = () => async dispatch => {
  try {
    const res = await axios.get("/api/auth/responderRole");

    dispatch({
      type: USER_RESPONDER_ROLE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({ type: AUTH_ERROR });
  }
};

export const setVolunteerLayout = () => async dispatch => {
  try {
    dispatch({
      type: SET_VOLUNTEER_LAYOUT,
      payload: "volunteer",
    });
  } catch (err) {
    dispatch({ type: AUTH_ERROR });
  }
};

export const setAdminLayout = () => async dispatch => {
  try {
    dispatch({
      type: SET_ADMIN_LAYOUT,
      payload: "admin",
    });
  } catch (err) {
    dispatch({ type: AUTH_ERROR });
  }
};

export const setResponderLayout = () => async dispatch => {
  try {
    dispatch({
      type: SET_RESPONDER_LAYOUT,
      payload: "responder",
    });
  } catch (err) {
    dispatch({ type: AUTH_ERROR });
  }
};
