import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;

const AnnoucementCarousel = ({ activeAnnoucement }) => {
  console.log(activeAnnoucement);
  const activeAnnouncements = activeAnnoucement.map((res, i) => (
    <div key={i + 1}>
      <div style={{ position: "relative" }}>
        <a
          href={`${serverURI}/${res.articleImage}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="announcement shadow_me">
            <img
              style={{
                maxHeight: "225px",
                borderRadius: "10px",
                overflow: "hidden",
              }}
              src={`${serverURI}/${res.articleImage}`}
              alt="AnnouncementImage"
            />
          </div>

          <img
            className="announcementImg"
            src={`${serverURI}/${res.opcenLogo}`}
            alt="Issuing"
          />
        </a>
      </div>
    </div>
  ));

  return !activeAnnoucement ? (
    <Spinner />
  ) : (
    <div className="announcementGap">
      {activeAnnouncements}
      {activeAnnoucement.length === 1 && (
        <Fragment>
          <div className="shadow_me announcementTemplate"></div>
          <div className="shadow_me announcementTemplate"></div>
          <div className="shadow_me announcementTemplate"></div>
          <div className="shadow_me announcementTemplate"></div>
        </Fragment>
      )}
      {activeAnnoucement.length === 2 && (
        <Fragment>
          <div className="shadow_me announcementTemplate"></div>{" "}
          <div className="shadow_me announcementTemplate"></div>{" "}
          <div className="shadow_me announcementTemplate"></div>
        </Fragment>
      )}
      {activeAnnoucement.length === 3 && (
        <Fragment>
          <div className="shadow_me announcementTemplate"></div>{" "}
          <div className="shadow_me announcementTemplate"></div>
        </Fragment>
      )}
      {activeAnnoucement.length === 4 && (
        <Fragment>
          <div className="shadow_me announcementTemplate"></div>
        </Fragment>
      )}
    </div>
  );
};

AnnoucementCarousel.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnoucementCarousel);
