import axios from "axios";
import { CREATE_NOTIFICATION, ERROR_CREATE_NOTIFICATION } from "./types";
import { setAlert } from "./alert";

export const createNotification =
  ({ title, body }) =>
  async (dispatch) => {
    const content = JSON.stringify({
      title,
      body,
    });

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        "api/notifications/message",
        content,
        config
      );
      dispatch({ type: CREATE_NOTIFICATION, payload: res.data });
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: ERROR_CREATE_NOTIFICATION,
      });
    }
  };
