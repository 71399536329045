import axios from "axios";
import {
  ADD_RESPONDER,
  GET_RESPONDERS_BY_OPCEN,
  GET_RESPONDERS_BY_OPCEN_FAIL,
  GET_RESPONDER_BY_ID,
  GET_RESPONDERS,
  GET_RESPONDERS_FAIL,
  DELETE_RESPONDER_BY_ID,
  ADD_RESPONDER_FAIL,
  DELETE_RESPONDER_ERROR,
  CLEAR_RESPONDER,
} from "./types";
import { setAlert } from "./alert";

export const AddResponder =
  ({ user, profilee, opcen, type, history }) =>
  async dispatch => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ user, profilee, opcen, type });
    try {
      const res = await axios.post("/api/responder", body, config);
      dispatch({
        type: ADD_RESPONDER,
        payload: res.data,
      });

      dispatch(setAlert("Responder Added", "success"));
      history.push("operation-center/responder");
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: ADD_RESPONDER_FAIL,
      });
    }
  };

//Get Responder by Opcen ID

export const getResponderbyOpcen = opcen_id => async dispatch => {
  try {
    const res = await axios.get(`/api/responder/${opcen_id}`);
    dispatch({
      type: GET_RESPONDERS_BY_OPCEN,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_RESPONDERS_BY_OPCEN_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Get Responder by userID

export const getResponderbyUserId = user_id => async dispatch => {
  try {
    const res = await axios.get(`/api/responder/user/${user_id}`);
    dispatch({
      type: GET_RESPONDER_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_RESPONDERS_BY_OPCEN_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
//Get Responder by userID
// for incident

export const getResponderbyIncidentUserId = user_id => async dispatch => {
  try {
    const res = await axios.get(`/api/responder/user/incident/${user_id}`);
    dispatch({
      type: GET_RESPONDER_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_RESPONDERS_BY_OPCEN_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getResponders = () => async dispatch => {
  try {
    const res = await axios.get(`/api/responder/`);
    dispatch({
      type: GET_RESPONDERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_RESPONDERS_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const deleteResponder = res_id => async dispatch => {
  if (window.confirm("Are you sure, you want to delete this responder!"))
    try {
      dispatch(setAlert("Responder DELETED", "danger"));
      await axios.delete(`/api/responder/${res_id}`);
      dispatch({
        type: DELETE_RESPONDER_BY_ID,
        payload: res_id,
      });

      // history.push("./operation-center/responder");
    } catch (err) {
      dispatch({
        type: DELETE_RESPONDER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
};

// Clear Redux state for responder

export const clearResponder = () => dispatch => {
  try {
    dispatch({
      type: CLEAR_RESPONDER,
    });
  } catch (err) {
    dispatch({
      type: ADD_RESPONDER_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
