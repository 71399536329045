import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { format } from "timeago.js";
import Moment from "react-moment";
import Spinner from "../layout/Spinner";

const CancelledIncidents = ({ usercancelled, loading }) => {
  const cancelled_incident = usercancelled?.map(cancelled => (
    <tr
      key={cancelled._id}
      style={{
        padding: "3px",
        borderRadius: "5px",
      }}
    >
      <td style={{ display: "flex", height: "100%", justifyContent: "center" }}>
        {cancelled.incidentId.type === "Medical" ? (
          <img
            className='table_img shadow_me'
            alt='Medical'
            src='/icons/incidentType/Medical.png'
          />
        ) : cancelled.incidentId.type === "Fire" ? (
          <img
            className='table_img shadow_me'
            alt='Fire'
            src='/icons/incidentType/Fire.png'
          />
        ) : cancelled.incidentId.type === "Crime" ? (
          <img
            className='table_img shadow_me'
            alt='Crime'
            src='/icons/incidentType/Police.png'
          />
        ) : cancelled.incidentId.type === "Covid" ? (
          <img
            className='table_img shadow_me'
            alt='Corona'
            src='/icons/incidentType/Covid-res.png'
          />
        ) : (
          <img
            className='table_img shadow_me'
            alt='General'
            src='/icons/incidentType/General.png'
          />
        )}
      </td>
      <td>
        <div>
          <span className='f-2'>
            {cancelled.incidentId.scompleteaddress.substring(
              cancelled.incidentId.scompleteaddress.length - 19,
              cancelled.incidentId.scompleteaddress
            )}
          </span>
          <p style={{ fontWeight: "200", fontSize: "8px" }}>
            <Moment format='LLL'>{cancelled.incidentId.reportedDate}</Moment> |
            {format(cancelled.incidentId.reportedDate)}
          </p>
        </div>
      </td>
    </tr>
  ));
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div
        style={{
          overflow: "hidden",
          overflowY: "scroll",
          maxHeight: "55vh",

          // border: "solid 2px #ddd",
          borderRadius: "10px 10px 0px 0px",
        }}
      >
        <p
          style={{
            paddingTop: "5px",
            fontWeight: "600",
            padding: "10px 0px 0px 10px",
            fontSize: "12px",
          }}
        >
          Cancelled Reports
        </p>
        <table className='commonTable'>
          <thead>
            <tr>
              <th>Type</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>{cancelled_incident}</tbody>
        </table>
      </div>
    </Fragment>
  );
};

CancelledIncidents.propTypes = {};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CancelledIncidents);
