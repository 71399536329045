import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const notifMenu = (props) => {
  return (
    <Fragment>
      <div
        className=" main-container align-vertically"
        style={{
          height: "390px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "150px",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Link className="bigIcon" to="/posts">
            <div style={{ padding: "12px" }}>
              <i
                className="fa fa-fighter-jet fa-lg "
                aria-hidden="true"
                style={{ padding: "15px" }}
              ></i>
              <p style={{ fontSize: "15px", lineHeight: "10px" }}>Quick</p>
              <div
                style={{
                  padding: "8px 0px",
                  fontSize: "10px",
                  lineHeight: "10px",
                  color: "#333",
                }}
              >
                (Send notification and alert your volunteers.)
              </div>
            </div>
          </Link>
          <Link className="bigIcon" to={`/operation-center/responder`}>
            <div style={{ padding: "12px" }}>
              <i
                className="fa fa-bullseye fa-lg"
                style={{ padding: "15px" }}
                aria-hidden="true"
              ></i>
              <p style={{ fontSize: "15px", lineHeight: "15px" }}>
                Detailed Notification
              </p>
              <div
                style={{
                  padding: "8px 0px",
                  fontSize: "10px",
                  lineHeight: "10px",
                  color: "#333",
                }}
              >
                ( Send Notifications with detailed information )
              </div>
            </div>
          </Link>
          <Link className="bigIcon" to={`/announcement`}>
            <div style={{ padding: "12px" }}>
              <i
                className="fa fa-flag-o  fa-lg"
                style={{ padding: "15px" }}
                aria-hidden="true"
              ></i>
              <p style={{ fontSize: "15px", lineHeight: "10px" }}>
                Announcement
              </p>
              <div
                style={{
                  padding: "8px 0px",
                  fontSize: "10px",
                  lineHeight: "10px",
                  color: "#333",
                }}
              >
                (Send Announcements, and display them on top of Notifications)
              </div>
            </div>
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(notifMenu);
