import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ProfileItem from "../profiles/ProfileItem";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { getUser, addUserResponder } from "../../actions/users";
import { AddResponder } from "../../actions/responder";
import { withRouter } from "react-router-dom";
// Register Collaborator
import { registerOpcen } from "../../actions/opcen";
import { accessrigths } from "../../actions/auth";

const ResponderModal = ({
  onClose,
  show,
  users: { responder },
  profile: { profileCRUD, loading },
  getUser,
  opcenter,
  AddResponder,
  history,
  addUserResponder,
  usersLoading,
  adminCRUD,
  registerOpcen,
  accessrigths,
}) => {
  console.log("Profile CRUD", profileCRUD);
  const [formData, setFormData] = useState({
    type: "",
  });

  //destructure so you would do formData.name formData.number
  //Object Syntax use {}
  const { type } = formData;
  //  const user = user.responder.find(res => res._id);
  const user = responder?._id;
  const profilee = profileCRUD?._id;
  const opcen = opcenter?._id;

  const onChange = async c => {
    setFormData({ ...formData, [c.target.name]: c.target.value });
  };

  const onSubmit = async c => {
    c.preventDefault();
    if (adminCRUD?.addResponderCRUD) {
      AddResponder({ user, profilee, opcen, type, history });
      addUserResponder({ opcen, type, user });
    } else {
      //  AddResponder({ user, profilee, opcen, type, history });
      //  addUserResponder({ opcen, type, user });
      // registerOpcen({ user, name, category, description });
      // accessrigths({ type });
    }
  };

  if (!show || !profileCRUD) {
    return null;
  }
  return loading && usersLoading ? (
    <Spinner />
  ) : (
    <div className='modal_responder' onClick={onClose}>
      <div
        className='modal-responder-content'
        onClick={e => e.stopPropagation()}
        style={{ color: "#fff", textAlign: "left" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            borderRadius: "5px",
            justifyContent: "center",
            alignItems: "center",
            padding: "3px",
          }}
        >
          <ProfileItem key={profileCRUD?._id} profile={profileCRUD} />
        </div>
        {/* Put Condition here */}
        {
          adminCRUD?.addResponderCRUD ? (
            <div>
              <form className='form' onSubmit={c => onSubmit(c)}>
                <div className='form-group'>
                  <h3 style={{ color: "#fff" }}> Choose an Designation</h3>
                  <select
                    name='type'
                    value={type}
                    onChange={c => onChange(c)}
                    required
                  >
                    <option value='0'>* Type </option>
                    <option value='Medical'>Medical</option>
                    <option value='Fire'>Fire</option>
                    <option value='Law Enforcement'>Law Enforcement</option>
                    <option value='Dispatch Operator'>Dispatch Operator</option>
                    <option value='General Services'>General Services</option>
                  </select>
                </div>

                <input
                  type='submit'
                  className='btn btn-light'
                  style={{ width: "100%" }}
                />
              </form>
              <button
                className='btn btn-danger'
                onClick={onClose}
                style={{ width: "100%" }}
              >
                Back
              </button>
            </div>
          ) : adminCRUD?.addPartnerIndividualCRUD ? (
            <div>
              <form className='form' onSubmit={c => onSubmit(c)}>
                <div className='form-group'>
                  <h3 style={{ color: "#fff" }}> Choose an Designation</h3>
                  <select
                    name='type'
                    value={type}
                    onChange={c => onChange(c)}
                    required
                  >
                    <option value='0'>* Type </option>
                    <option value='Collaborator'>Collaborator</option>
                    <option value='Editor'>Editor</option>
                    <option value='Moderator'>Moderator</option>
                  </select>
                </div>

                <input
                  type='submit'
                  className='btn btn-light'
                  style={{ width: "100%" }}
                />
              </form>
              <button
                className='btn btn-danger'
                onClick={onClose}
                style={{ width: "100%" }}
              >
                Back
              </button>
            </div>
          ) : null
          // add other CRUD flags here of Add Organization / add facilities etc.
        }
      </div>
    </div>
  );
};

ResponderModal.propTypes = {
  users: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getUser: PropTypes.func.isRequired,
  AddResponder: PropTypes.func.isRequired,
  addUserResponder: PropTypes.func.isRequired,
  usersLoading: PropTypes.bool.isRequired,
  adminCRUD: PropTypes.object.isRequired,
  registerOpcen: PropTypes.func.isRequired,
  accessrigths: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  users: state.users,
  usersLoading: state.users.loading,
  profile: state.profile,
  opcenter: state.opcen.opcen,
  adminCRUD: state.adminCRUD,
});

export default connect(mapStateToProps, {
  getUser,
  AddResponder,
  addUserResponder,
  registerOpcen,
  accessrigths,
})(withRouter(ResponderModal));
