import React, { Fragment } from "react";

// eslint-disable-next-line
export default () => (
  <Fragment>
    {/* <div className='loader'>Loading...</div> */}
    <div
      className=' align-vertically'
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src='/opcenlogo/guardian_small.png' className='rotate_me'></img>
    </div>
  </Fragment>
);
