import React from "react";
import { Fragment } from "react";
import SocketContext from "../../SocketContext";
import IncidentCreate from "./IncidentCreate";
export const IncidentContainer = () => {
  return (
    <Fragment>
      <div>
        <SocketContext.Consumer>
          {socket => <IncidentCreate socket={socket} />}
        </SocketContext.Consumer>
      </div>
    </Fragment>
  );
};

export default IncidentContainer;
