import {
  ADD_RESPONDER_CRUD,
  ADD_PARTNER_INDIVIDUAL_CRUD,
  ADD_PARTNER_ORGANIZATION_CRUD,
  CRUD_ERROR,
  CLEAR_CRUD,
  POST_PROFILE,
} from "./types";

export const setPartnerIndividual = () => dispatch => {
  try {
    dispatch({
      type: ADD_PARTNER_INDIVIDUAL_CRUD,
    });
  } catch (err) {
    dispatch({
      type: CRUD_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
export const postProfileClick = () => dispatch => {
  try {
    dispatch({
      type: POST_PROFILE,
    });
  } catch (err) {
    dispatch({
      type: CRUD_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const setResponderCRUD = () => dispatch => {
  try {
    dispatch({
      type: ADD_RESPONDER_CRUD,
    });
  } catch (err) {
    dispatch({
      type: CRUD_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const clearCRUD = () => dispatch => {
  try {
    dispatch({
      type: CLEAR_CRUD,
    });
  } catch (err) {
    dispatch({
      type: CRUD_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
