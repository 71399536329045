import {
  GET_POSTS,
  GET_POST,
  POST_ERROR,
  UPDATE_LIKES,
  UPDATE_LOVES,
  UPDATE_WOWS,
  UPDATE_SADS,
  UPDATE_HAHAS,
  UPDATE_ANGRYS,
  DELETE_POST,
  ADD_POST,
  ADD_COMMENT,
  REMOVE_COMMENT,
  GET_POST_IMAGE,
  POST_IMG_ERROR,
  CLEAR_POST,
} from "../actions/types";

const initialState = {
  posts: [],
  post: null,
  loading: true,
  error: {},
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_POSTS:
      return {
        ...state,
        // Map posts per pagination
        // posts: payload, //from the action file
        posts: [...state.posts, ...payload],
        loading: false,
      };
    case GET_POST:
    case GET_POST_IMAGE:
      return {
        ...state,
        post: payload, //from the action file
        loading: false,
      };
    case ADD_POST:
      return {
        ...state,
        posts: [payload, ...state.posts],
        loading: false,
      };
    case DELETE_POST:
      return {
        ...state,
        posts: state.posts.filter(post => post._id !== payload),
        loading: false,
      };
    case POST_ERROR:
    case POST_IMG_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case UPDATE_LIKES:
      return {
        ...state,
        //update the likes buy post ID
        posts: state.posts.map(post =>
          post._id === payload.id ? { ...post, likes: payload.likes } : post
        ),

        loading: false,
      };
    case UPDATE_LOVES:
      return {
        ...state,
        //update the loves buy post ID
        posts: state.posts.map(post =>
          post._id === payload.id ? { ...post, loves: payload.loves } : post
        ),
        loading: false,
      };
    case UPDATE_WOWS:
      return {
        ...state,
        //update the wows buy post ID
        posts: state.posts.map(post =>
          post._id === payload.id ? { ...post, wows: payload.wows } : post
        ),
        loading: false,
      };
    case UPDATE_SADS:
      return {
        ...state,
        //update the sads buy post ID
        posts: state.posts.map(post =>
          post._id === payload.id ? { ...post, sads: payload.sads } : post
        ),
        loading: false,
      };
    case UPDATE_HAHAS:
      return {
        ...state,
        //update the hahas buy post ID
        posts: state.posts.map(post =>
          post._id === payload.id ? { ...post, hahas: payload.hahas } : post
        ),
        loading: false,
      };
    case UPDATE_ANGRYS:
      return {
        ...state,
        //update the angrys buy post ID
        posts: state.posts.map(post =>
          post._id === payload.id ? { ...post, angrys: payload.angrys } : post
        ),
        loading: false,
      };
    case ADD_COMMENT:
      return {
        ...state,
        post: { ...state.post, comments: payload },
        loading: false,
      };
    case REMOVE_COMMENT:
      return {
        ...state,
        post: {
          ...state.post,
          comments: state.post.comments.filter(
            comment => comment._id !== payload
          ),
        },
        loading: false,
      };
    case CLEAR_POST:
      return {
        posts: [],
        post: null,
        loading: true,
      };
    default:
      return state;
  }
}
