export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = " USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_OPCEN_ADMIN = "USER_OPCEN_ADMIN";
export const LOGOUT = "LOGOUT";
export const REGISTER_OTP = " REGISTER_OTP";
export const SEARCH_USERS = "SEARCH_USERS";
export const SEARCH_USERS_ERROR = "SEARCH_USERS_ERROR";
export const GET_USER = "GET_USER";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const CLEAR_USERS = "CLEAR_USERS";
export const CLEAR_USER = "CLEAR_USER";
export const PUT_OPCEN = "PUT_OPCEN";
export const ERROR_PUT_OPCEN = "ERROR_PUT_OPCEN";
export const GET_USERS = "GET_USERS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const GET_VOLUNTEERS = "GET_VOLUNTEERS";
export const GET_VOLUNTEERS_FAIL = "GET_VOLUNTEERS_FAIL";

// PROFILES
export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const GET_PROFILES = "GET_PROFILES";
export const GET_PROFILE_BY_INCIDENT_USER_ID =
  "GET_PROFILE_BY_INCIDENT_USER_ID";
export const CLEAR_PROFILE_CRUD = "CLEAR_PROFILE_CRUD";

export const GET_REPOS = "GET_REPOS";
// POST
export const GET_POSTS = "GET_POSTS";

export const POST_ERROR = "POST_ERROR";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const UPDATE_LOVES = "UPDATE_LOVES";
export const UPDATE_WOWS = "UPDATE_WOWS";
export const UPDATE_SADS = "UPDATE_SADS";
export const UPDATE_HAHAS = "UPDATE_HAHAS";
export const UPDATE_ANGRYS = "UPDATE_ANGRYS";
export const DELETE_POST = "DELETE_POST";
export const ADD_POST = "ADD_POST";
export const GET_POST = "GET_POST";
export const ADD_COMMENT = "ADD_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";
export const USER_DOEST_EXIST = "USER_DOEST_EXIST";
export const GET_POST_IMAGE = "GET_POST_IMAGE";
export const POST_IMG_ERROR = "POST_IMG_ERROR";
export const CLEAR_POST = "CLEAR_POST";
// Map Actions
export const GET_COMPLETE_ADDRESS = "GET_COMPLETE_ADDRESS";
export const GET_AREA = "GET_AREA";
export const GET_CITY = "GET_CITY";
export const GET_STATE = "GET_STATE";
export const GET_COUNTRY = "GET_COUNTRY";
export const MAP_LOCATE = "MAP_LOCATE";
export const MAP_SEARCH = "MAP_SEARCH";

// SMS/OTP/ Update Password

export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_FAIL = "SEND_OTP_FAIL";
export const REMOVE_OTP = "REMOVE_OTP";
export const OTP_MATCH = "OTP_MATCH";
export const OTP_NOT_MATCH = "OTP_NOT_MATCH";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
export const CLEAR_SMS = "CLEAR_SMS";

// OPCEN
export const CREATE_OPCEN = "CREATE_OPCEN";
export const ERROR_CREATE_OPCEN = "ERROR_CREATE_OPCEN";
export const OPCEN_REGISTER_SUCCESS = "OPCEN_REGISTER_SUCCESS";
export const OPCEN_REGISTER_FAIL = "OPCEN_REGISTER_FAIL";
export const GET_OPCENS = "GET_OPCENS";
export const GET_OPCENS_FAIL = "GET_OPCENS_FAIL";
export const GET_OPCEN = "GET_OPCEN";
export const GET_OPCEN_FAIL = "GET_OPCEN_FAIL";
export const CLEAR_OPCEN = "CLEAR_OPCEN";
export const GET_ALL_OPCENS = "GET_ALL_OPCENS";
export const GET_ALL_OPCENS_FAIL = "GET_ALL_OPCENS_FAIL";
export const SEARCH_OPCEN = "SEARCH_OPCEN";
export const SEARCH_OPCEN_ERROR = "SEARCH_OPCEN_ERROR";

// OPCEN PROFILE

export const GET_OPCEN_PROFILE = "GET_OPCEN_PROFILE";
export const PROFILE_OPCEN_ERROR = "PROFILE_OPCEN_ERROR";
export const CLEAR_OPCEN_PROFILE = "CLEAR_OPCEN_PROFILE";
export const UPDATE_OPCEN_PROFILE = "UPDATE_OPCEN_PROFILE";
export const ACCOUNT_OPCEN_DELETED = "ACCOUNT_OPCEN_DELETED";
export const GET_OPCEN_PROFILES = "GET_OPCEN_PROFILES";
export const GET_PROFILE_CRUD = "GET_PROFILE_CRUD";

// INCIDENTS
export const CREATE_INCIDENT = "CREATE_INCIDENT";
export const ERROR_CREATE_INCIDENT = "ERROR_CREATE_INCIDENT";
export const INCIDENT_SUMMIT_SUCCESS = "INCIDENT_SUMMIT_SUCCESS";
export const INCIDENT_SUMMIT_FAIL = "INCIDENT_SUMMIT_FAIL";
export const SAVE_MISSED_CALL = "SAVE_MISSED_CAL";
export const SAVE_MISSED_CALL_FAIL = "SAVE_MISSED_CALL_FAIL";

// Get incident by ID
export const GET_INCIDENT_BY_ID = "GET_INCIDENT_BY_ID";
export const GET_INCIDENT_BY_ID_FAIL = "GET_INCIDENT_BY_ID_FAIL";

// Get all Incident
export const GET_INCIDENTS = "GET_INCIDENTS";
export const GET_INCIDENTS_FAIL = "GET_INCIDENTS_FAIL";
export const CLEAR_INCIDENT = "CLEAR_INCIDENT";

// Incident by User
export const GET_INCIDENT_BY_USER = "GET_INCIDENTS_BY_USER";
export const GET_INCIDENT_BY_USER_FAIL = "GET_INCIDENT_BY_USER_FAIL";
export const GET_CLOSE_INCIDENT_BY_USER = "GET_CLOSE_INCIDENT_BY_USER";
export const INCIDENT_CANCELLED = "INCIDENT_CANCELLED";

// Incident by Opcen
export const GET_INCIDENT_BY_OPCEN = "GET_INCIDENT_BY_OPCEN";
export const GET_INCIDENT_BY_OPCEN_FAIL = "GET_INCIDENT_BY_OPCEN_FAIL";
export const CREATE_INCIDENT_OPCEN = "CREATE_INCIDENT_OPCEN";
export const PUT_INCIDENT_CLOSE = "PUT_INCIDENT_CLOSE";
export const GET_CANCELLED_INCIDENT_BY_USER = "GET_CANCELLED_INCIDENT_BY_USER";

// Incident Today
export const GET_INCIDENT_TODAY = " GET_INCIDENT_TODAY";
export const GET_INCIDENT_TODAY_FAIL = " GET_INCIDENT_TODAY_FAIL";
export const GET_INCIDENT_CLOSED = "GET_INCIDENT_CLOSED";
export const GET_INCIDENT_CLOSED_FAIL = "GET_INCIDENT_CLOSED_FAIL";
export const GET_INCIDENT_CANCELLED = "GET_INCIDENT_CANCELLED";
export const GET_INCIDENT_CANCELLED_FAIL = "GET_INCIDENT_CANCELLED_FAIL";

//Incident by number and date

export const GET_INCIDENT_BY_NUMBER_DATE = "GET_INCIDENT_BY_NUMBER_DATE";
export const GET_INCIDENT_BY_NUMBER_DATE_FAIL = "GET_INCIDENT_BY_NUMBER_DATE";
export const HANDLE_INCIDENT = "HANDLE_INCIDENT";
export const PUT_INCIDENT_DISPATCHER = "PUT_INCIDENT_DISPATCHER";
export const CLEAR_DISPATCHER = "CLEAR_DISPATCHER";

// Incident Close
export const INCIDENT_CLOSE = "INCIDENT_CLOSE";
export const VOLUNEER_INCIDENT_CLOSE = " VOLUNEER_INCIDENT_CLOSE";

// Accept Call
export const ACCEPT_CALL = "ACCEPT_CALL";

// RESPONDERS

export const ADD_RESPONDER = "ADD_RESPONDER";
export const GET_RESPONDERS_BY_OPCEN = "GET_RESPONDERS_BY_OPCEN";
export const GET_RESPONDERS = "GET_RESPONDERS";
export const DELETE_RESPONDER_BY_ID = "DELETE_RESPONDER_BY_ID";
export const GET_RESPONDER_BY_ID = "GET_RESPONDER_BY_ID";
export const ADD_RESPONDER_FAIL = "ADD_RESPONDER";
export const GET_RESPONDERS_BY_OPCEN_FAIL = "GET_RESPONDERS_BY_OPCEN_FAIL";
export const GET_RESPONDERS_FAIL = "GET_RESPONDERS_FAIL";
export const DELETE_RESPONDER_ERROR = "DELETE_RESPONDER_ERROR";
export const ADD_USER_RESPONDER = "ADD_USER_RESPONDER";
export const DELETE_USER_RESPONDER = "DELETE_USER_RESPONDER";
export const ERROR_ADD_USER_RESPONDER = "ERROR_ADD_USER_RESPONDER";
export const ERROR_DELETE_USER_RESPONDER = "ERROR_DELETE_USER_RESPONDER";
export const USER_RESPONDER_ROLE = "USER_RESPONDER_ROLE";
export const CLEAR_RESPONDER = "CLEAR_RESPONDER";

// Layouts

export const SET_VOLUNTEER_LAYOUT = "SET_VOLUNTEER_LAYOUT";
export const SET_ADMIN_LAYOUT = "SET_ADMIN_LAYOUT";
export const SET_RESPONDER_LAYOUT = "SET_RESPONDER_LAYOUT";

// Converations Messenger
export const GET_CONVERSATION = "GET_CONVERSATION";
export const CREATE_CONVERSATION = "CREATE_CONVERSATION";
export const ERROR_CONVERSATION = "ERROR_CONVERSATION";
export const CLEAR_CONVERSATION = "CLEAR_CONVERSATION";

// Messages

export const GET_MESSAGE = "GET_MESSAGE";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const ERROR_MESSAGE = "ERROR_MESSAGE";

//  Dispatcher

export const CHECK_IN_DISPATCH = "CHECK_IN_DISPATCH";
export const GET_ONLINE_DISPATCH_BY_OPCEN_ID =
  "GET_ONLINE_DISPATCH_BY_OPCEN_ID";
export const GET_ONLINE_DISPATCH_BY_RESPONDER_ID =
  "GET_ONLINE_DISPATCH_BY_RESPONDER_ID";
export const GET_ONLINE_DISPATCH_ALL = "GET_ONLINE_DISPATCH_ALL";
export const CHECK_OUT_DISPATCH = "CHECK_OUT_DISPATCH";
export const DISPATCH_ON_CALL = " DISPATCH_ON_CALL";
export const DISPATCH_AVAILABLE = "DISPATCH_AVAILABLE";

export const GET_ALL_DISPATCHERS = "GET_ALL_DISPATCHERS";
export const GET_ALL_DISPATCHERS_ERROR = "GET_ALL_DISPATCHERS_ERROR";

// Incident Messaging

export const CREATE_INCIDENT_MSG = "CREATE_INCIDENT_MSG";
export const CREATE_INCIDENT_MSG_ERROR = "CREATE_INCIDENT_MSG_ERROR";
export const CLEAR_INCIDENT_MSG = "CLEAR_INCIDENT_MSG";
export const GET_INCIDENT_MESSAGES = "GET_INCIDENT_MESSAGES";
export const GET_INCIDENT_MESSAGES_ERROR = "GET_INCIDENT_MESSAGES_ERROR";

// Suspend User

export const SUSPEND_USER = "SUSPEND_USER";
export const SUSPEND_USER_ERROR = "SUSPEND_USER_ERROR";
export const GET_ALL_SUSPENDED_USERS = " GET_ALL_SUSPENDED_USERS";

// Vaccination

export const GET_VAX_BY_ID = "GET_VAX_BY_ID";
export const GET_VAX_BY_ID_ERROR = "GET_VAX_BY_ID_ERROR";
export const GET_ALL_VAX = "GET_ALL_VAX";
export const GET_ALL_VAX_ERROR = "GET_ALL_VAX_ERROR";
export const ADD_VALIDATION = "ADD_VALIDATION";
export const ERROR_VALIDATION = "ERROR_VALIDATION";
export const CLEAR_VAX = "CLEAR_VAX";

// Annoucement
export const GET_ALL_ANNOUCEMENT = "GET_ALL_ANNOUCEMENT";
export const GET_ALL_ANNOUCEMENT_ERROR = "GET_ALL_ANNOUCEMENT_ERROR";
export const DELETE_ANNOUCEMENT = "DELETE_ANNOUNCEMENT";
export const GET_ANNOUNCEMENT = "GET_ANNOUNCEMENT";
export const CLEAR_ANNOUNCEMENT = "CLEAR_ANNOUNCEMENT";
export const DISPLAYED_ANNOUNCEMENT = " DISPLAYED_ANNOUNCEMENT";

// CRUD Functions

export const ADD_RESPONDER_CRUD = "ADD_RESPONDER_CRUD";
export const ADD_PARTNER_INDIVIDUAL_CRUD = "ADD_PARTNER_INDIVIDUAL_CRUD";
export const ADD_PARTNER_ORGANIZATION_CRUD = "ADD_PARTNER_ORGANIZATION_CRUD";
export const CRUD_ERROR = "CRUD_ERROR";
export const CLEAR_CRUD = "CLEAR_CRUD";
export const POST_PROFILE = "POST_PROFILE";

// Call Actions

export const CALL_SET_LOCAL_STREAM = "CALL_SET_LOCAL_STREAM";
export const ERROR_CALL_SET_LOCAL_STREAM = "ERROR_CALL_SET_LOCAL_STREAM";
export const callStates = {
  CALL_UNAVAILABLE: "CALL_UNAVAILABLE",
  CALL_AVAILABLE: "CALL_AVAILABLE",
  CALL_REQUESTED: "CALL_REQUESTED",
  CALL_IN_PROGRESS: "CALL_IN_PROGRESS",
};

export const CALL_SET_CALL_STATE = "CALL_SET_CALL_STATE";
export const CALL_SET_CALLING_DIALOG_VISIBLE =
  "CALL_SET_CALLING_DIALOG_VISIBLE";

// ExPo Notification

export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const ERROR_CREATE_NOTIFICATION = "ERROR_CREATE_NOTIFICATION";
