import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from "react-moment";
import { addValidation } from "../../actions/vaccination";
import { validate } from "uuid";
import { setAlert } from "../../actions/alert";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;

const VaxValidation = ({
  onClose,
  show,
  vaccinationData,
  addValidation,
  user,
}) => {
  console.log("Vaccination Data", vaccinationData?._id);
  console.log("user ", user?._id);
  const [vax, setVax] = useState(vaccinationData?._id);
  const [id, setId] = useState(user?._id);

  // useEffect(() => {
  //   setId(user?._id);
  // }, [user]);

  useEffect(() => {
    setVax(vaccinationData?._id);
  }, [vaccinationData]);

  // const Vax = vaccinationData?._id;
  // const Id = user?._id;

  const [formData, setFormData] = useState({
    approve: "",
    comment: "",
    validatedby: id,
    vaxID: vax,
  });

  console.log("Formdata", formData);
  const refreshPage = () => {
    setTimeout(function () {
      window.location.reload();
    }, 2 * 1000);
  };
  const { approve, comment } = formData;

  const onChange = async (c) =>
    setFormData({ ...formData, [c.target.name]: c.target.value });

  const onSubmit = async (c) => {
    c.preventDefault();
    if (vax && id) {
      addValidation(formData);
      refreshPage();
    } else {
      setAlert("Oops: Please try again", "danger");
    }
  };

  if (!show) {
    return null;
  }

  return (
    <Fragment>
      <div
        className="incident_modal-video"
        style={{ backgroundColor: "#ddd" }}
        onClick={onClose}
      >
        <div className="modal-vax" onClick={(e) => e.stopPropagation()}>
          <div className="sub-container">
            <div className="vax-credentials">
              <div className="vax_profile_pic">
                <img
                  className="profile-img"
                  src={`${serverURI}/${vaccinationData?.profilepic}`}
                  alt=""
                />
                <div>
                  <h4>
                    {vaccinationData?.user.name} {vaccinationData?.user.lname}{" "}
                  </h4>
                  <p className="small-txt-form">
                    {vaccinationData?.user.email}
                  </p>{" "}
                  <p className="small-txt-form">
                    {" "}
                    {vaccinationData?.user.number}
                  </p>
                </div>
              </div>
              <div className="vax_vax_id">
                <img
                  src={`${serverURI}/resize/${vaccinationData?.vaxCardPhoto}`}
                  alt=""
                />
              </div>

              <div className="vax-details">
                <p className="small-txt-form">
                  ID No.: {vaccinationData?.id_no}
                </p>
                <p className="small-txt-form">
                  Vaccination Site: {vaccinationData?.vaccinationSite}
                </p>
                <p className="small-txt-form">
                  Vaccine Type: {vaccinationData?.vaccineType}
                </p>
                <p className="small-txt-form">
                  Booster Type: {vaccinationData?.boosterType}
                </p>
              </div>
              <div className="vax-date">
                <p className="small-txt-form">
                  First Dose:{" "}
                  <Moment format="ll">{vaccinationData?.fDoseDate}</Moment>
                </p>
                <p className="small-txt-form">
                  Second Dose:{" "}
                  <Moment format="ll">{vaccinationData?.sDoseDate}</Moment>
                </p>
                <p className="small-txt-form">
                  Booster:{" "}
                  <Moment format="ll">{vaccinationData?.sBoosterDate}</Moment>
                </p>
              </div>
              <div className="vax-approve">
                <form className="vax-approve" onSubmit={(c) => onSubmit(c)}>
                  <div style={{ padding: " 0px 10px" }}>
                    <p className="text-primary" style={{ color: "#fff" }}>
                      Review vaccination credentials
                    </p>
                  </div>

                  <select
                    style={{ padding: " 10px ", margin: "10px" }}
                    type="text"
                    name="approve"
                    value={approve}
                    onChange={(c) => onChange(c)}
                    required
                  >
                    <option value="0">*Approve?</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>

                  <textarea
                    style={{ padding: " 10px ", margin: "10px" }}
                    placeholder="Please give reason of such action."
                    name="comment"
                    value={comment}
                    onChange={(c) => onChange(c)}
                    rows="6"
                  ></textarea>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      style={{ padding: "10px", width: "100px" }}
                      type="submit"
                      className="btn btn-primary my-1"
                      onClick={(e) => e.stopPropagation()}
                    />

                    <button
                      className="btn btn-light my-1"
                      style={{ padding: "7px", width: "100px" }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      Go Back
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

VaxValidation.propTypes = {
  addValidation: PropTypes.func.isRequired,
  vaccinationData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  vaccinationData: state.vaccination.vax,
});

const mapDispatchToProps = { addValidation };

export default connect(mapStateToProps, mapDispatchToProps)(VaxValidation);
