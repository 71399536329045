import React, { useEffect } from "react";
import { Fragment } from "react";
import SocketContext from "../../SocketContext";
import IncidentMap from "./IncidentMap";
import { getResponders } from "../../actions/responder";
import PropTypes from "prop-types";
import { connect } from "react-redux";

export const IncidentMapContainer = ({ getResponders }) => {
  useEffect(() => {
    getResponders();
  });

  return (
    <Fragment>
      <div>
        <SocketContext.Consumer>
          {socket => <IncidentMap socket={socket} />}
        </SocketContext.Consumer>
      </div>
    </Fragment>
  );
};

IncidentMapContainer.propTypes = {
  getResponders: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { getResponders })(
  IncidentMapContainer
);
