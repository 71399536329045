import React from "react";
import { Fragment } from "react";
import { connect } from "react-redux";
import SocketContext from "../../SocketContext";
import Login from "./Login";
export const LoginWrapper = props => {
  return (
    <Fragment>
      <div>
        <SocketContext.Consumer>
          {socket => <Login socket={socket} />}
        </SocketContext.Consumer>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LoginWrapper);
