import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import profile from "./profile";
import post from "./post";
import sms from "./sms";
import opcen from "./opcen";
import opcen_profile from "./opcen_profile";
import incident from "./incident";
import responder from "./responder";
import users from "./users";
import conversations from "./conversations";
import messages from "./messages";
import dispatchOnline from "./dispatchOnline";
import incident_msg from "./incident_msg";
import suspended from "./suspended";
import vaccination from "./vaccination";
import announcement from "./announcement";
import adminCRUD from "./adminCRUD";
import call from "./call";
import expoNotification from "./expoNotification";

export default combineReducers({
  alert,
  auth,
  profile,
  post,
  announcement,
  sms,
  opcen,
  opcen_profile,
  incident,
  responder,
  users,
  conversations,
  messages,
  dispatchOnline,
  incident_msg,
  suspended,
  vaccination,
  adminCRUD,
  call,
  expoNotification,
});
