import axios from "axios";
import {
  CREATE_INCIDENT_MSG,
  CREATE_INCIDENT_MSG_ERROR,
  GET_INCIDENT_MESSAGES,
  GET_INCIDENT_MESSAGES_ERROR,
} from "./types";
import { setAlert } from "./alert";

export const sendIncidentMsg =
  ({ incidentId, sender, text }) =>
  async dispatch => {
    const body = JSON.stringify({
      incidentId,
      sender,
      text,
    });

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post("api/incident_msg", body, config);
      dispatch({
        type: CREATE_INCIDENT_MSG,
        payload: res.data,
      });
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: CREATE_INCIDENT_MSG_ERROR,
      });
    }
  };

export const getIncidentMessages = incidentId => async dispatch => {
  try {
    const res = await axios.get(`/api/incident_msg/${incidentId}`);

    dispatch({
      type: GET_INCIDENT_MESSAGES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_INCIDENT_MESSAGES_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
