import axios from "axios";
import {
  GET_VAX_BY_ID,
  GET_VAX_BY_ID_ERROR,
  GET_ALL_VAX,
  GET_ALL_VAX_ERROR,
  ADD_VALIDATION,
  ERROR_VALIDATION,
  CLEAR_VAX,
} from "./types";
import { setAlert } from "./alert";

//Get All user profile
export const getVaccinationById = user_id => async dispatch => {
  try {
    //   change this
    const res = await axios.get(`/api/vaccination/${user_id}`);
    dispatch({
      type: GET_VAX_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_VAX_BY_ID_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

export const getVaccinations = () => async dispatch => {
  try {
    //   change this
    const res = await axios.get(`/api/vaccination`);
    dispatch({
      type: GET_ALL_VAX,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_VAX_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

//Add validation

export const addValidation = (formData, history) => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.put(
      "/api/vaccination/review/user",
      formData,
      config
    );

    dispatch({
      type: ADD_VALIDATION,
      payload: res.data,
    });

    dispatch(setAlert("Vaccination data validated", "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: ERROR_VALIDATION,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const clearVax = () => dispatch => {
  try {
    dispatch({
      type: CLEAR_VAX,
    });
  } catch (err) {
    dispatch({
      type: ERROR_VALIDATION,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
