import React, { Fragment } from "react";
import { connect } from "react-redux";
import SelectedUser from "./SelectedUser";

export const SUserContainer = ({ users }) => {
  return (
    <Fragment>
      <div
        style={{
          background: "#fff",
          display: "grid",
          alignItems: "center",
          borderRadius: "5px",
          maxHeight: "25vh",

          border: "1px solid #eee",
        }}
      >
        {users === null || users.length === 0 ? (
          <Fragment>
            <div
              style={{
                width: "100%",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p className='text-center text-red'>
                0 SEARCH MATCH <br /> Make sure you type the field values on
                case sensitive.
              </p>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <SelectedUser users={users} />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  users: state.users.users,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SUserContainer);
