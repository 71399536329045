import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setAlert } from "../../actions/alert";
import axios from "axios";
import Progressbar from "../layout/ProgressBar";
import Spinner from "../layout/Spinner";

const serverURI = process.env.REACT_APP_BACKEND_SERVER_DEVELOPMENT;
const EditPost = ({
  show,
  onClose,
  post: { post, loading },
  setAlert,
  //   { _id, title, text, name, lname, user, articleImage, date },
}) => {
  const [formData, setFormData] = useState({
    _id: "",
    title: "",
    text: "",
    articleImage: "",
  });

  useEffect(() => {
    setFormData({
      _id: loading || !post?._id ? "" : post?._id,
      title: loading || !post?.title ? "" : post?.title,
      text: loading || !post?.text ? "" : post?.text,
      articleImage: loading || !post?.articleImage ? "" : post?.articleImage,
    });
  }, [post, loading]);

  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [preview, setPreview] = useState();
  const inputRef = useRef();
  const [progress, setProgress] = useState();
  const [error, setError] = useState();

  const { _id, title, text } = formData;

  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [image]);

  const onFileChange = c => {
    setImage(c.target.files[0]);
    setImageName(c.target.files[0].name);
  };

  const onChange = c => {
    setFormData({ ...formData, [c.target.name]: c.target.value });
  };

  const postImage = image !== null ? image : `${post?.articleImage}`;

  const payload = new FormData();
  payload.append("_id", _id);
  payload.append("title", title);
  payload.append("text", text);
  payload.append("articleImage", postImage);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const sendNotif = async () => {
    try {
      const res = await axios.post("/api/posts", payload, {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      });

      setAlert("Notification updated", "success");
      await delay(2000);
      // Put flag
      window.location.reload();
    } catch (err) {
      console.log("Error from backend", err);
      setError(true);
      setAlert(`Notifcation error ${err.response.statusText}`, "danger");
    }
  };

  const onSubmit = c => {
    c.preventDefault();
    // addPost(payload);
    sendNotif();
    setFormData({ title: "", text: "", articleImage: "" });
    inputRef.current.value = "";
    // put error catcher
  };

  if (!show) {
    return null;
  }
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='notifModal'>
        <div className='notification'>
          <div className='notif_title'>
            <p> Edit notification</p>
          </div>
          <div className='previewtitle'>
            <p> Preview</p>
          </div>

          {/* Change Here */}

          <div className='titlebody'>
            <form
              className='form my-1'
              encType='multipart/form-data'
              onSubmit={c => onSubmit(c)}
            >
              <div style={{ padding: "8px" }}>
                <div className='Title'>
                  <input
                    name='title'
                    type='text'
                    placeholder='Title'
                    onChange={c => onChange(c)}
                    value={title}
                    required
                  />
                </div>

                <div className='Message'>
                  <textarea
                    name='text'
                    cols='50'
                    rows='13'
                    placeholder='Additional Info'
                    value={text}
                    onChange={c => onChange(c)}
                    required
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "60px",
                  }}
                >
                  <input
                    className='btn btn-dark my-1'
                    style={{ background: "#174052", width: "100%" }}
                    // id='upload-button'
                    ref={inputRef}
                    type='file'
                    onChange={c => onFileChange(c)}
                    accept='image/*,video/mp4,video/x-m4v,video/*'
                    multiple={false}
                    placeholder={imageName}
                    // style={{ display: "none" }}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  {" "}
                  <div className='Send'>
                    {/* <label htmlFor='file'> */}
                    <input
                      type='submit'
                      className='btn btn-success my-1'
                      value='Send'
                    />
                  </div>
                  <button onClick={onClose} className='btn  btn-danger'>
                    Cancel
                  </button>
                </div>
              </div>

              {/* add image button here */}
            </form>
          </div>
          {!error && progress && <Progressbar progress={progress} />}

          <div className='preview'>
            {!preview ? (
              <div
                style={{
                  width: "450px",
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    maxHeight: "500px",
                    padding: "10px",
                    borderRadius: "20px",
                  }}
                  className='shadow_me'
                  src={`${serverURI}/${post?.articleImage}`}
                  alt='notif image'
                />
              </div>
            ) : (
              <div
                style={{
                  width: "450px",
                  height: "500px",

                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  className='shadow_me'
                  style={{
                    maxHeight: "500px",
                    padding: "10px",
                    borderRadius: "20px",
                  }}
                  src={preview}
                  alt='notif image'
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

EditPost.propTypes = {
  post: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  post: state.post,
});

const mapDispatchToProps = { setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(EditPost);
